import React, { useEffect, useState, useRef } from "react";
import { useAsyncAxios, utilAxiosWithAppToken } from "../../utils/customAxios";
import { BrowserView, MobileView } from "react-device-detect";
import { Button, Space } from "antd";
import { useNavigate } from "react-router";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";

import * as Request from "../../commons/request";
import * as TypeDTO from "../../commons/typeDTO";
import * as TypeUtils from "../../commons/typeUtils";
import * as Common from "../../commons/common";

import IconList from "../../assets/images/home/rent/icon_list.png";
import IconGps from "../../assets/images/home/rent/icon_gps.png";
import styles from "./Map.module.css";
import KakaoMap from "../../component/KakaoMap";
import Search from "../../component/rent/Search";
import CustomSheet from "../../component/CustomSheet";
import Title from "../../component/rent/Title";
import GoodsCard from "../../component/rent/GoodsCard";

import type SwiperCore from "swiper";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

type slideType = {
    currentIndex: number;
    lastIndex: number;
};

function Map() {
    const navigate = useNavigate();
    const prevRef = useRef<HTMLDivElement>(null);
    const nextRef = useRef<HTMLDivElement>(null);
    const swiperRef = useRef<SwiperCore>();

    const [regions, setRegions] = useState<Array<TypeDTO.RentRegionDto>>([]);
    const [selectedRegionId, setSelectedRegionId] = useState<number>();
    const [availableCars, setAvailableCars] = useState<Array<TypeDTO.RentCarDto>>([]);
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [search, setSearch] = useState<TypeUtils.SearchType>();
    const [isClick, setIsClick] = useState<boolean>(false);
    const [currentSlide, setCurrentSlice] = useState<slideType>({ currentIndex: 0, lastIndex: 0 });

    useEffect(() => {
        requestGetRentRegions();
    }, []);

    useEffect(() => {
        requestGetRentRegions();
    }, [search]);

    const requestAxiosGetRentRegions = async () => {
        const response = await utilAxiosWithAppToken().get(Request.RENT_REGIONS_CAR_URL, {
            params: {
                rentRegionName: search?.regionId,
                startDate: search?.period && search?.period[0],
                endDate: search?.period && search?.period[1],
            },
        });
        return response.data;
    };

    const {
        loading: loadingGetRentRegions,
        error: errorGetRentRegions,
        data: resultGetRentRegions,
        execute: requestGetRentRegions,
    } = useAsyncAxios(requestAxiosGetRentRegions);

    useEffect(() => {
        if (!resultGetRentRegions) return;
        console.log("resultGetRentRegions", resultGetRentRegions);

        setRegions(resultGetRentRegions.rentRegions);
    }, [resultGetRentRegions]);

    useEffect(() => {
        if (!errorGetRentRegions) return;

        console.log("errorGetRentRegions", errorGetRentRegions);
    }, [errorGetRentRegions]);

    const onClickEvent = (data: number) => {
        setIsOpen(true);
        setSelectedRegionId(data);
        setAvailableCars(regions?.find((region) => region.rentRegionId === data)?.rentCars || []);
    };

    const onClick = (rentCarId: number) => {
        search?.period
            ? navigate(Common.PAGE_RENT_DETAIL + "/" + rentCarId + "/" + search?.regionId + "/" + search?.period[0] + "/" + search?.period[1])
            : navigate(Common.PAGE_RENT_DETAIL + "/" + rentCarId + "/" + search?.regionId);
    };

    const onChange = (value: TypeUtils.SearchType) => {
        setSearch({ regionId: value.regionId, period: value.period });
    };

    const displaySheet = () => {
        return (
            <div className="space-page" style={{ padding: "0 1.5rem 1.5rem" }}>
                <p className="mb-3 mt-3">총 {availableCars?.length || 0}건</p>
                {availableCars?.map((car, index) => (
                    <div className="mt-3" key={car.rentCarId} onClick={() => onClick(car.rentCarId)}>
                        <Title
                            searchType={true}
                            info={{
                                regionName: {
                                    content: car?.rentRegion?.rentRegionName,
                                },
                                title: {
                                    content: car?.title,
                                },
                                priceDiscounted: {
                                    content: car.discountPrice,
                                },
                                price: {
                                    content: car.price,
                                },
                                tags: car.rentRestrictions,
                            }}
                        />
                        {index + 1 < regions.length && <hr className="line" />}
                    </div>
                ))}
            </div>
        );
    };

    const displaySwiper = () => {
        return (
            <>
                {availableCars?.map((car, index) => (
                    <SwiperSlide key={car.rentCarId} style={{ display: "flex", flexWrap: "wrap", alignContent: "center" }}>
                        <GoodsCard alignType={true} rentCar={car} onClick={() => onClick(car.rentCarId)} />
                    </SwiperSlide>
                ))}
            </>
        );
    };

    useEffect(() => {
        console.log("JY", currentSlide);
    }, [currentSlide]);

    return (
        <>
            <MobileView className="mobile">
                <KakaoMap
                    myLocation={{
                        show: true,
                        click: isClick,
                        clickEvent: () => {
                            setIsClick(false);
                        },
                    }}
                    mapStyle={{ height: "calc(100vh - 70px)" }}
                    regions={{ list: regions, selected: selectedRegionId, clickEvent: onClickEvent }}
                />
                <div
                    className="w-100"
                    style={{
                        position: "fixed",
                        top: "74px",
                        zIndex: 1,
                    }}
                >
                    <Search type="map" className="p-3" onChange={(value) => setSearch(value)} />
                </div>
                <Space direction="vertical" style={{ position: "fixed", bottom: "14px", right: "14px", zIndex: 4 }}>
                    <Button style={{ width: "42px", height: "42px" }} className="p-0 btn-light" onClick={() => setIsClick(true)}>
                        <img src={IconGps} width={20} height={20} />
                    </Button>
                    <Button
                        style={{ width: "42px", height: "42px" }}
                        className="p-0 btn-dark"
                        type="primary"
                        onClick={() => navigate(Common.PAGE_RENT_HOME)}
                    >
                        <img src={IconList} width={20} height={20} />
                    </Button>
                </Space>
                <CustomSheet isOpen={isOpen} content={displaySheet()} snapHeight={400} onClose={() => setIsOpen(false)} />
            </MobileView>
            <BrowserView className="browser">
                <div style={{ position: "relative", height: "100vh" }}>
                    <KakaoMap
                        myLocation={{
                            show: true,
                            click: isClick,
                            clickEvent: () => {
                                setIsClick(false);
                            },
                        }}
                        mapStyle={{ width: "100%", height: "100%" }}
                        regions={{ list: regions, selected: selectedRegionId, clickEvent: onClickEvent }}
                    />
                    <div
                        style={{
                            position: "absolute",
                            top: "10px",
                            left: "50%",
                            width: "80%",
                            transform: "translate(-50%, 0)",
                            zIndex: "100",
                        }}
                    >
                        <Search onChange={onChange} />
                    </div>
                    <Button
                        style={{ width: "42px", height: "42px", position: "fixed", bottom: "14px", right: "14px", zIndex: 4 }}
                        className="p-0 btn-light"
                        onClick={() => setIsClick(true)}
                    >
                        <img src={IconGps} width={20} height={20} />
                    </Button>
                    {selectedRegionId && (
                        <div className={`${styles.swiperContainer}`}>
                            <p className="mb-3 mt-3" style={{ textAlign: "end", marginRight: "20px" }}>
                                총 {availableCars?.length || 0}건
                            </p>
                            <div
                                style={{
                                    display: "flex",
                                    flexWrap: "wrap",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    height: "80%",
                                }}
                            >
                                <Button
                                    type="text"
                                    disabled={currentSlide.currentIndex === 0}
                                    onClick={() => swiperRef.current?.slidePrev()}
                                    className={styles.swiperBtn}
                                >
                                    <LeftOutlined />
                                </Button>
                                <Swiper
                                    onBeforeInit={(swiper) => {
                                        swiperRef.current = swiper;
                                    }}
                                    navigation={{
                                        prevEl: prevRef.current,
                                        nextEl: nextRef.current,
                                    }}
                                    zoom={true}
                                    slidesPerView={4}
                                    spaceBetween={15}
                                    modules={[Navigation, Pagination]}
                                    className="mySwiper"
                                    style={{ width: "85%" }}
                                    onSlideChange={(swiper) =>
                                        setCurrentSlice({
                                            currentIndex: swiper.realIndex,
                                            lastIndex: Number(swiper.activeIndex) + Number(swiper.params.slidesPerView),
                                        })
                                    }
                                >
                                    {displaySwiper()}
                                </Swiper>
                                <Button
                                    disabled={availableCars.length < 4 || currentSlide.lastIndex === availableCars.length}
                                    onClick={() => swiperRef.current?.slideNext()}
                                    className={styles.swiperBtn}
                                    style={{ right: "0px" }}
                                >
                                    <RightOutlined />
                                </Button>
                            </div>
                        </div>
                    )}
                </div>
            </BrowserView>
        </>
    );
}

export default Map;
