import React from "react";
import { Col, Row, Space, Image } from "antd";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import ScrollToTopOnMount from "../component/ScrollTopOnMount";

import droneStationMain1 from "../assets/images/model/droneStation/droneStationMain1.jpg";
import droneStationMain2 from "../assets/images/model/droneStation/droneStationMain2.jpg";
import droneStationExterior1 from "../assets/images/model/droneStation/droneStationExterior1.jpg";
import droneStationExterior2 from "../assets/images/model/droneStation/droneStationExterior2.jpg";
import droneStationExterior3 from "../assets/images/model/droneStation/droneStationExterior3.jpg";
import droneStationExterior4 from "../assets/images/model/droneStation/droneStationExterior4.jpg";
import droneStationExterior5 from "../assets/images/model/droneStation/droneStationExterior5.jpg";
import droneStationExterior6 from "../assets/images/model/droneStation/droneStationExterior6.jpg";
import droneStationExterior7 from "../assets/images/model/droneStation/droneStationExterior7.jpg";
import droneStationExterior8 from "../assets/images/model/droneStation/droneStationExterior8.jpg";
import droneStationExterior9 from "../assets/images/model/droneStation/droneStationExterior9.jpg";
import droneStationSpec1 from "../assets/images/model/droneStation/droneStationSpec1.svg";
import droneStationSpec2 from "../assets/images/model/droneStation/droneStationSpec2.svg";
import fmsImage1 from "../assets/images/fms/fms1.jpg";
import fmsImage5 from "../assets/images/fms/fms5.jpg";

import Banner from "../assets/images/home/ruta40/banner_drone_station.jpg";

export default function ModelDroneStation() {
    const isMobile = useMediaQuery({ maxWidth: 700 });

    return (
        <div>
            <ScrollToTopOnMount />
            <div className="header-container model">
                <div className="container" style={{ backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.2),rgba(0, 0, 0, 0.2)),url(${Banner})` }}>
                    <div className="row justify-content-center">
                        <div className="fadeIn text-center mb-5">
                            <h5 className="text-center text-light px-3 mb-4">드론 스테이션</h5>
                            <h1 className="text-light">RT60</h1>
                        </div>
                    </div>
                </div>
            </div>
            <div className="model-tabs title model-body" style={{ backgroundColor: "white" }}>
                <Col
                    xs={{ span: 24 }}
                    sm={{ span: 22, offset: 1 }}
                    lg={{ span: 18, offset: 3 }}
                    xl={{ span: 16, offset: 4 }}
                    xxl={{ span: 14, offset: 5 }}
                    className="fadeInUp p-4"
                >
                    <div className={`mb-5 mt-3`}>
                        {isMobile ? (
                            <img className="w-100" src={droneStationMain2}></img>
                        ) : (
                            <img className="w-100 img-fluid" src={droneStationMain1} />
                        )}
                    </div>
                </Col>
                <Col
                    xs={{ span: 24 }}
                    sm={{ span: 22, offset: 1 }}
                    lg={{ span: 18, offset: 3 }}
                    xl={{ span: 16, offset: 4 }}
                    xxl={{ span: 14, offset: 5 }}
                    className="fadeInUp p-4"
                >
                    <div className="mb-5">
                        <div className="text-center mb-5 mt-3">
                            <h3>디자인</h3>
                        </div>

                        <Row className="row px-2">
                            <Col span={8} className="col-lg-4 px-1">
                                <Space direction="vertical" size={isMobile ? 4 : 8}>
                                    <Image src={droneStationExterior1} className="w-100 shadow-1-strong" alt="Wintry Mountain Landscape" />
                                    <Image src={droneStationExterior4} className="w-100 shadow-1-strong" alt="Mountains in the Clouds" />
                                    <Image src={droneStationExterior7} className="w-100 shadow-1-strong" alt="Mountains in the Clouds" />
                                </Space>
                            </Col>

                            <Col span={8} className="col-lg-4 px-1">
                                <Space direction="vertical" size={isMobile ? 4 : 8}>
                                    <Image src={droneStationExterior2} className="w-100 shadow-1-strong" alt="Wintry Mountain Landscape" />
                                    <Image src={droneStationExterior5} className="w-100 shadow-1-strong" alt="Mountains in the Clouds" />
                                    <Image src={droneStationExterior8} className="w-100 shadow-1-strong" alt="Mountains in the Clouds" />
                                </Space>
                            </Col>

                            <Col span={8} className="col-lg-4 px-1">
                                <Space direction="vertical" size={isMobile ? 4 : 8}>
                                    <Image src={droneStationExterior3} className="w-100 shadow-1-strong" alt="Waves at Sea" />
                                    <Image src={droneStationExterior6} className="w-100 shadow-1-strong" alt="Boat on Calm Water" />
                                    <Image src={droneStationExterior9} className="w-100 shadow-1-strong" alt="Boat on Calm Water" />
                                </Space>
                            </Col>
                        </Row>
                    </div>
                </Col>
                <Col
                    xs={{ span: 24 }}
                    sm={{ span: 22, offset: 1 }}
                    lg={{ span: 18, offset: 3 }}
                    xl={{ span: 16, offset: 4 }}
                    xxl={{ span: 14, offset: 5 }}
                    className="fadeInUp p-4"
                >
                    <div className="mb-5">
                        <div className="text-center mb-5">
                            <h3>서비스</h3>
                        </div>
                        <div className="col-lg-12 text-center pb-4">
                            <h6 className="text-type">차량 관제 시스템</h6>
                            <p style={{ wordBreak: "keep-all" }} className="b-md">
                                정밀한 정비 서비스 제공과 고객 안전을 위한 차량 관제 서비스를 지원합니다.
                            </p>
                            {isMobile ? <img className="w-100" src={fmsImage5}></img> : <img className="w-100" src={fmsImage1}></img>}
                        </div>
                    </div>
                </Col>
                <Col
                    xs={{ span: 24 }}
                    sm={{ span: 22, offset: 1 }}
                    lg={{ span: 18, offset: 3 }}
                    xl={{ span: 16, offset: 4 }}
                    xxl={{ span: 14, offset: 5 }}
                    className="fadeInUp p-4"
                >
                    <div className="mb-5">
                        <div className="text-center mb-5">
                            <h3>제원 정보</h3>
                        </div>
                        <div className="col-lg-12 text-center optionBox pb-4">
                            <div className="col-md-12 py-3">
                                {isMobile ? (
                                    <img className="px-3 d-block w-100" alt="First slide" src={droneStationSpec1} />
                                ) : (
                                    <img className="px-5 d-block img-fluid" alt="First slide" src={droneStationSpec1} />
                                )}
                            </div>
                            <div className="col-md-12 py-3">
                                {isMobile ? (
                                    <img className="px-3 d-block w-100" alt="First slide" src={droneStationSpec2} />
                                ) : (
                                    <img className="px-5 d-block img-fluid" alt="First slide" src={droneStationSpec2} />
                                )}
                            </div>
                            <div className="col-md-12">
                                <table className="table design-table">
                                    <thead>
                                        <tr>
                                            <th colSpan={2}>제원명</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th>치수(mm)</th>
                                            <td>5,960×2,020×2,640</td>
                                        </tr>
                                        <tr>
                                            <th>총 중량(kg)</th>
                                            <td>4,520</td>
                                        </tr>
                                        <tr>
                                            <th>차량 중량(kg)</th>
                                            <td>3,470</td>
                                        </tr>
                                        <tr>
                                            <th>최대 속도(km/h)</th>
                                            <td>100</td>
                                        </tr>
                                        <tr>
                                            <th>최대 주행거리(km)</th>
                                            <td>303.68(환경부공인 인증)</td>
                                        </tr>
                                        <tr>
                                            <th>배터리(KWh)</th>
                                            <td>106(Li-Ion)</td>
                                        </tr>
                                        <tr>
                                            <th>모터 PMSM Tyre</th>
                                            <td>215 / 75R</td>
                                        </tr>
                                        <tr>
                                            <th>제동장치</th>
                                            <td>ABS+EBD</td>
                                        </tr>
                                        <tr>
                                            <th>스티어링</th>
                                            <td>파워 스티어링</td>
                                        </tr>
                                        <tr>
                                            <th>구동방식</th>
                                            <td>후륜 구동 방식</td>
                                        </tr>
                                        <tr>
                                            <th>충전 모드</th>
                                            <td>Combo 1(CCS)</td>
                                        </tr>
                                        <tr>
                                            <th>안전사양</th>
                                            <td>ABS</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </Col>
            </div>
        </div>
    );
}
