import React, { useState, useEffect } from "react";
import { Button, Input, Modal } from "antd";

import * as String from "../commons/string";

import DaumPostcodeEmbed from "react-daum-postcode";
import styles from "./AddressMaker.module.css";

export type AddressType = {
    address: string;
    addressDetails: string;
};

function AddressMaker({ value, onChange }: { value?: AddressType; onChange?: (fullAddress: AddressType) => void }) {
    const [firstAddress, setFirstAddress] = useState("");
    const [secondAddress, setSecondAddress] = useState("");
    const [visible, setVisible] = useState<boolean>();

    const handleComplete = (data: any) => {
        let fullAddress = data.address;
        let extraAddress = "";

        if (data.addressType === "R") {
            if (data.bname !== "") {
                extraAddress += data.bname;
            }
            if (data.buildingName !== "") {
                extraAddress += extraAddress !== "" ? `, ${data.buildingName}` : data.buildingName;
            }
            fullAddress += extraAddress !== "" ? ` (${extraAddress})` : "";
        }

        setFirstAddress(fullAddress);
        setVisible(false);
    };

    const handleClick = () => {
        setVisible(true);
    };

    useEffect(() => {
        if (value) {
            setFirstAddress(value.address);
            setSecondAddress(value.addressDetails);
        }
    }, [value]);

    useEffect(() => {
        if (!firstAddress) return;

        if (firstAddress.length > 0) {
            onChange &&
                onChange({
                    address: firstAddress,
                    addressDetails: secondAddress,
                });
        } else {
            onChange &&
                onChange({
                    address: "",
                    addressDetails: "",
                });
        }
    }, [firstAddress, secondAddress]);

    return (
        <>
            <Input.Group compact>
                <Input
                    className="login-input mb-2"
                    value={firstAddress}
                    placeholder={String.address}
                    readOnly
                    onClick={handleClick}
                    onChange={(e) => setFirstAddress(e.target.value)}
                ></Input>
                <Button onClick={handleClick}>{String.search}</Button>
                <Input className="login-input" value={secondAddress} placeholder="상세주소" onChange={(e) => setSecondAddress(e.target.value)} />
            </Input.Group>

            <Modal
                className={styles.modal}
                bodyStyle={{ height: "520px", padding: "0px" }}
                open={visible}
                centered
                title={null}
                closable={false}
                footer={[
                    <Button key="cancel" type="default" onClick={() => setVisible(false)}>
                        {String.close}
                    </Button>,
                ]}
                onCancel={() => setVisible(false)}
            >
                <DaumPostcodeEmbed style={{ height: "520px", padding: 20 }} onComplete={handleComplete} autoClose={false} />
            </Modal>
        </>
    );
}

export default AddressMaker;
