import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAsyncAxios, utilAxiosWithAppToken } from "../../utils/customAxios";
import { Button, Col, Row, Space, Tag } from "antd";
import { BrowserView, MobileView } from "react-device-detect";
import { DownloadOutlined } from "@ant-design/icons";
import { useMediaQuery } from "react-responsive";
import { useAlert } from "../../provider/AlertProvider";

import * as Request from "../../commons/request";
import * as TypeDTO from "../../commons/typeDTO";
import * as Common from "../../commons/common";

import Banner from "../../assets/images/home/ruta40/banner_promotion.jpg";
import LocalPromotion from "../../assets/images/home/rent/local_promotion.png";

function PromotionDetail() {
    const isMobile = useMediaQuery({ maxWidth: 300 });
    const navigate = useNavigate();
    const alert = useAlert();
    const params = useParams();
    // const [promotion, setPromotion] = useState<TypeDTO.RentPromotionDto>();

    // useEffect(() => {
    //     requestGetRentPromotion(Number(params.rentPromotionId));
    // }, [params]);

    // const requestAxiosGetRentPromotion = async (rentPromotionId: number) => {
    //     const response = await utilAxiosWithAppToken().get(Request.RENT_NOTICE_URL + rentPromotionId);
    //     return response.data;
    // };

    // const {
    //     loading: loadingGetRentPromotion,
    //     error: errorGetRentPromotion,
    //     data: resultGetRentPromotion,
    //     execute: requestGetRentPromotion,
    // } = useAsyncAxios(requestAxiosGetRentPromotion);

    // useEffect(() => {
    //     if (resultGetRentPromotion === null) return;
    //     console.log("resultGetRentPromotion", resultGetRentPromotion);

    //     setPromotion(resultGetRentPromotion.rentPromotion);
    // }, [resultGetRentPromotion]);

    // useEffect(() => {
    //     if (errorGetRentPromotion === null) return;
    //     console.log("errorGetRentPromotion", errorGetRentPromotion);
    // }, [errorGetRentPromotion]);

    return (
        <div style={{ minHeight: "calc(100vh - 70px)" }}>
            <div className="header-container model">
                <div className="container fadeIn text-center mb-5" style={{ backgroundImage: `url(${Banner})` }}>
                    <h5 className="text-center px-3 mb-3" style={{ color: "#4d4d4d" }}>
                        프로모션
                    </h5>
                    <h1>PROMOTION</h1>
                </div>
            </div>
            <div className="model-tabs title model-body" style={{ backgroundColor: "white" }}>
                <MobileView className="mobile" style={{ minHeight: "calc(100vh - 70px)" }}>
                    <Col
                        sm={{ span: 24 }}
                        lg={{ span: 18, offset: 3 }}
                        xl={{ span: 18, offset: 3 }}
                        xxl={{ span: 16, offset: 4 }}
                        className="space-page pt-4"
                    >
                        <table className="table design-table">
                            <tbody>
                                <tr style={{ borderTop: "0.5px solid #dfe2e7", borderBottom: "0.5px solid #dfe2e7" }}>
                                    <Row className="w-100" style={{ minHeight: 48.8 }} justify="space-between" align="middle">
                                        <Col>카테고리: 프로모션</Col>
                                        <Col>등록일: 2024-09-05</Col>
                                    </Row>
                                </tr>
                                <tr style={{ borderBottom: "0.5px solid #dfe2e7" }}>
                                    <td className="pt-3 pb-3 ps-0 pe-0" style={{ display: "flex", justifyContent: "center" }}>
                                        <img src={LocalPromotion} />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        {/* <div className="text-center mt-4">
                            <Button
                                className="btn-dark px-5"
                                style={{ width: "auto" }}
                                size="large"
                                type="primary"
                                onClick={() => {
                                    navigate(Common.PAGE_RENT_PROMOTION);
                                }}
                            >
                                목록 보기
                            </Button>
                        </div> */}
                    </Col>
                </MobileView>
                <BrowserView className="browser">
                    <Col
                        xs={{ span: 24 }}
                        sm={{ span: 22, offset: 1 }}
                        lg={{ span: 18, offset: 3 }}
                        xl={{ span: 16, offset: 4 }}
                        xxl={{ span: 14, offset: 5 }}
                        className="mb-4 p-4 order fadeIn"
                    >
                        <table className="table design-table">
                            <tbody>
                                <tr style={{ borderTop: "0.5px solid #dfe2e7", borderBottom: "0.5px solid #dfe2e7" }}>
                                    <Row className="w-100" style={{ minHeight: 48.8 }} justify="space-between" align="middle">
                                        <Col>카테고리: 프로모션</Col>
                                        <Col>등록일: 2024-09-05</Col>
                                    </Row>
                                </tr>
                                <tr style={{ borderBottom: "0.5px solid #dfe2e7" }}>
                                    <td className="pt-3 pb-3 ps-0 pe-0 review-content" style={{ display: "flex", justifyContent: "center" }}>
                                        <img src={LocalPromotion} />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        {/* <div className="text-center mt-5">
                        <Button
                            className="btn-dark px-5"
                            style={{ width: "auto" }}
                            size="large"
                            type="primary"
                            onClick={() => {
                                navigate(Common.PAGE_RENT_PROMOTION);
                            }}
                        >
                            목록 보기
                        </Button>
                    </div> */}
                    </Col>
                </BrowserView>
            </div>
        </div>
    );
}

export default PromotionDetail;
