import React, { useEffect, useState } from "react";
import { BrowserView, MobileView } from "react-device-detect";
import { Col, Row, Skeleton, Space, Table, Tag } from "antd";
import { useAsyncAxios, utilAxiosWithAppToken } from "../../utils/customAxios";
import { ColumnsType, TableProps } from "antd/lib/table";
import { PushpinFilled } from "@ant-design/icons";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import { SorterResult } from "antd/lib/table/interface";

import * as Request from "../../commons/request";
import * as TypeDTO from "../../commons/typeDTO";
import * as Common from "../../commons/common";

import Banner from "../../assets/images/home/ruta40/banner_promotion.jpg";
import LocalPromotionBanner from "../../assets/images/home/rent/local_promotion_banner.jpg";

type RentNoticeType = {
    fixedPosition: boolean;
    data: TypeDTO.RentNoticeDto;
};

function Promotion() {
    const navigate = useNavigate();
    const isMobile = useMediaQuery({ maxWidth: 600 });
    const [rentNotices, setRentNotices] = useState<Array<RentNoticeType>>();
    const [sortedInfo, setSortedInfo] = useState<SorterResult<RentNoticeType>>({});

    useEffect(() => {
        requestGetRentNotices();
    }, []);

    const requestAxiosGetRentNotices = async () => {
        const response = await utilAxiosWithAppToken().get(Request.RENT_NOTICES_URL);
        return response.data;
    };

    const {
        loading: loadingGetRentNotices,
        error: errorGetRentNotices,
        data: resultGetRentNotices,
        execute: requestGetRentNotices,
    } = useAsyncAxios(requestAxiosGetRentNotices);

    useEffect(() => {
        if (!resultGetRentNotices) return;
        console.log("resultGetRentNotices", resultGetRentNotices);

        const fixedNotice: Array<RentNoticeType> = resultGetRentNotices.rentNotices
            .filter((notice: TypeDTO.RentNoticeDto) => {
                return notice.fixed === true;
            })
            .map((notice: TypeDTO.RentNoticeDto) => {
                return { fixedPosition: true, data: notice };
            });
        const unFixedNotice: Array<RentNoticeType> = resultGetRentNotices.rentNotices.map((notice: TypeDTO.RentNoticeDto) => {
            return { fixedPosition: false, data: notice };
        });
        const totalNotices = [...fixedNotice, ...unFixedNotice];

        setRentNotices(totalNotices);
    }, [resultGetRentNotices]);

    useEffect(() => {
        if (!errorGetRentNotices) return;
        console.log("errorGetRentNotices", errorGetRentNotices);
    }, [errorGetRentNotices]);

    const columns: ColumnsType<RentNoticeType> = [
        {
            align: "center",
            title: "카테고리",
            dataIndex: "noticeType",
            key: "noticeType",
            width: isMobile ? "auto" : "15%",
            render: (_, { data }) => {
                return data.noticeType === "NOTICE"
                    ? "공지사항"
                    : data.noticeType === "EVENT"
                    ? "이벤트"
                    : data.noticeType === "EVENT_ANNOUNCEMENT"
                    ? "이벤트 발표"
                    : "-";
            },
        },
        {
            align: "left",
            title: "제목",
            dataIndex: "title",
            key: "title",
            render: (_, { fixedPosition, data }) => {
                return data.title !== undefined ? (
                    <span className="d-flex" style={{ alignItems: "center" }}>
                        {fixedPosition === true && <PushpinFilled style={{ marginRight: 8 }} color="#001236" />}
                        {data.noticeStatus === "EVENT_IN_PROGRESS" && (
                            <Tag style={{ borderColor: "#006AF5", color: "#006AF5", backgroundColor: "white" }}>진행</Tag>
                        )}
                        {data.noticeStatus === "EVENT_FINISHED" && (
                            <Tag style={{ borderColor: "#77828F", color: "#77828F", backgroundColor: "white" }}>종료</Tag>
                        )}
                        {data.title}
                    </span>
                ) : (
                    "-"
                );
            },
        },
        {
            align: "center",
            title: "등록일",
            dataIndex: "registeredDate",
            key: "registeredDate",
            width: isMobile ? "auto" : "15%",
            render: (_, { data }) => {
                return data.registeredDate ? <span>{data.registeredDate.slice(0, 10)}</span> : "-";
            },
        },
    ];

    const onTableChange: TableProps<RentNoticeType>["onChange"] = (pagination, filters, sorter) => {
        setSortedInfo(sorter as SorterResult<RentNoticeType>);
    };

    const onRow = (value: RentNoticeType) => {
        navigate(Common.PAGE_RENT_NOTICE_DETAIL + "/" + value.data.rentNoticeId);
    };

    return (
        <div style={{ minHeight: "calc(100vh - 70px)" }}>
            <div className="header-container model">
                <div className="container fadeIn text-center mb-5" style={{ backgroundImage: `url(${Banner})` }}>
                    <h5 className="text-center px-3 mb-3" style={{ color: "#4d4d4d" }}>
                        프로모션
                    </h5>
                    <h1>PROMOTION</h1>
                </div>
            </div>
            <div className="model-tabs title model-body" style={{ backgroundColor: "white" }}>
                <MobileView className="mobile">
                    <Col
                        sm={{ span: 24 }}
                        lg={{ span: 18, offset: 3 }}
                        xl={{ span: 18, offset: 3 }}
                        xxl={{ span: 16, offset: 4 }}
                        className="space-page pt-4"
                    >
                        <Space size={24} direction="vertical">
                            <img
                                width="100%"
                                style={{ cursor: "pointer" }}
                                onClick={() => navigate(Common.PAGE_RENT_PROMOTION_DETAIL)}
                                src={LocalPromotionBanner}
                            />
                        </Space>
                    </Col>
                </MobileView>
                <BrowserView className="browser">
                    <Col
                        xs={{ span: 24 }}
                        sm={{ span: 22, offset: 1 }}
                        lg={{ span: 18, offset: 3 }}
                        xl={{ span: 16, offset: 4 }}
                        xxl={{ span: 14, offset: 5 }}
                        className="p-4 pt-5"
                    >
                        <Space size={24} direction="vertical">
                            <img
                                width="100%"
                                style={{ cursor: "pointer" }}
                                onClick={() => navigate(Common.PAGE_RENT_PROMOTION_DETAIL)}
                                src={LocalPromotionBanner}
                            />
                        </Space>
                    </Col>
                </BrowserView>
            </div>
        </div>
    );
}

export default Promotion;
