import React, { useEffect, useState } from "react";
import { Col, Button } from "antd";
import { FilePdfFilled } from "@ant-design/icons";
import { useAsyncAxios, utilAxiosWithAppToken } from "../../utils/customAxios";
import { useMediaQuery } from "react-responsive";
import { useAlert } from "../../provider/AlertProvider";

import * as Request from "../../commons/request";
import * as TypeDTO from "../../commons/typeDTO";

import Banner from "../../assets/images/home/ruta40/banner_customer.jpg";

function DownloadCenter() {
    const isMobile = useMediaQuery({ maxWidth: 700 });
    const [manuals, setManuals] = useState<Array<TypeDTO.ManualDto>>([]);
    const [catalogs, setCatalogs] = useState<Array<TypeDTO.CatalogDto>>([]);

    const alert = useAlert();

    useEffect(() => {
        requestGetManuals();
        requestGetCatalogs();
    }, []);

    const requestAxiosGetManuals = async () => {
        const response = await utilAxiosWithAppToken().get(Request.MANUALS_URL);
        return response.data;
    };

    const {
        loading: loadingGetManuals,
        error: errorGetManuals,
        data: resultGetManuals,
        execute: requestGetManuals,
    } = useAsyncAxios(requestAxiosGetManuals);

    useEffect(() => {
        if (!resultGetManuals) return;
        console.log("resultGetManuals", resultGetManuals);
        setManuals(resultGetManuals.manuals);
    }, [resultGetManuals]);

    useEffect(() => {
        if (!errorGetManuals) return;
        console.log("errorGetManuals", errorGetManuals);
    }, [errorGetManuals]);

    const downloadManual = async (manualId: number, manualFileName: string) => {
        await utilAxiosWithAppToken()
            .get(Request.DOWNLOAD_MANUAL_URL + "/" + manualId, { responseType: "blob" })
            .then((response) => {
                const file = new Blob([response.data], { type: "application/pdf;" });
                const element = document.createElement("a");
                element.href = URL.createObjectURL(file);
                element.download = manualFileName;
                element.click();
            })
            .catch((error) => {
                console.log("downloadManual error", error);

                alert.setAlert("", "파일 다운로드 실패", "파일 다운로드에 실패했습니다.");
            });
    };

    const displayManuals = () => {
        if (manuals.length === 0) return null;

        return (
            <div className={`mb-5 p-0`}>
                <div className="text-center mb-5 mt-3">
                    <h3>매뉴얼</h3>
                </div>
                <div className="p-0 mb-5">
                    <div className="row mb-4">
                        <div className="col-lg-12 col-md-12">
                            <table className="table design-table">
                                <thead>
                                    <tr>
                                        <th style={{ textAlign: "center" }}>버전</th>
                                        <th style={{ textAlign: "center" }}>자료명</th>
                                        <th style={{ textAlign: "center" }}>PDF 파일</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {manuals.map((manual) => {
                                        return (
                                            <tr key={manual.manualId}>
                                                <td style={{ textAlign: "center" }}>{manual.manualVersion}</td>
                                                <td style={{ textAlign: "center" }}>{manual.manualName}</td>
                                                <td style={{ textAlign: "center" }}>
                                                    <Button
                                                        icon={<FilePdfFilled />}
                                                        size="small"
                                                        onClick={() => downloadManual(manual.manualId, manual.manualFileName)}
                                                    />
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const requestAxiosGetCatalogs = async () => {
        const response = await utilAxiosWithAppToken().get(Request.CATALOGS_URL);
        return response.data;
    };

    const {
        loading: loadingGetCatalogs,
        error: errorGetCatalogs,
        data: resultGetCatalogs,
        execute: requestGetCatalogs,
    } = useAsyncAxios(requestAxiosGetCatalogs);

    useEffect(() => {
        if (resultGetCatalogs === null) return;
        console.log("resultGetCatalogs", resultGetCatalogs);
        setCatalogs(resultGetCatalogs.catalogs);
    }, [resultGetCatalogs]);

    useEffect(() => {
        if (errorGetCatalogs === null) return;
        console.log("errorGetCatalogs", errorGetCatalogs);
    }, [errorGetCatalogs]);

    const downloadCatalog = async (catalogId: number, catalogFileName: string) => {
        await utilAxiosWithAppToken()
            .get(Request.DOWNLOAD_CATALOG_URL + "/" + catalogId, { responseType: "blob" })
            .then((response) => {
                const file = new Blob([response.data], { type: "application/pdf;" });
                const element = document.createElement("a");
                element.href = URL.createObjectURL(file);
                element.download = catalogFileName;
                element.click();
            })
            .catch((error) => {
                console.log("downloadManual error", error);

                alert.setAlert("", "파일 다운로드 실패", "파일 다운로드에 실패했습니다.");
            });
    };

    const displayCatalogs = () => {
        if (catalogs.length === 0) return null;

        return (
            <div className="p-0">
                <div className="text-center mb-5 mt-3">
                    <h3>카탈로그</h3>
                </div>
                <div className="p-0 mb-5">
                    <div className="row mb-4">
                        <div className="col-lg-12 col-md-12">
                            <table className="table design-table">
                                <thead>
                                    <tr>
                                        <th style={{ textAlign: "center" }}>버전</th>
                                        <th style={{ textAlign: "center" }}>자료명</th>
                                        <th style={{ textAlign: "center" }}>PDF 파일</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {catalogs.map((catalog) => {
                                        return (
                                            <tr key={catalog.catalogId}>
                                                <td style={{ textAlign: "center" }}>{catalog.catalogVersion}</td>
                                                <td style={{ textAlign: "center" }}>{catalog.catalogName}</td>
                                                <td style={{ textAlign: "center" }}>
                                                    <Button
                                                        icon={<FilePdfFilled />}
                                                        size="small"
                                                        onClick={() => downloadCatalog(catalog.catalogId, catalog.catalogFileName)}
                                                    />
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <>
            <div className="header-container model">
                <div
                    className="container fadeIn text-center mb-5"
                    style={{ backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.3),rgba(0, 0, 0, 0.3)),url(${Banner})` }}
                >
                    <h5 className="text-center text-light px-3 mb-4">자료실</h5>
                    <h1 className="text-light">DOWNLOAD CENTER</h1>
                </div>
            </div>
            <div className="model-tabs title model-body" style={{ backgroundColor: "white" }}>
                <Col
                    xs={{ span: 24 }}
                    sm={{ span: 22, offset: 1 }}
                    lg={{ span: 18, offset: 3 }}
                    xl={{ span: 16, offset: 4 }}
                    xxl={{ span: 14, offset: 5 }}
                    className="fadeInUp p-4"
                >
                    {displayManuals()}
                    {displayCatalogs()}
                </Col>
            </div>
        </>
    );
}

export default DownloadCenter;
