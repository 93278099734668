import React from "react";
import { Col } from "antd";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import ScrollToTopOnMount from "../component/ScrollTopOnMount";

import refrigeratorMain1 from "../assets/images/model/refrigerator/refrigeratorMain1.jpg";
import refrigeratorMain2 from "../assets/images/model/refrigerator/refrigeratorMain2.jpg";
import refrigeratorSpec1 from "../assets/images/model/refrigerator/refrigeratorSpec1.svg";
import refrigeratorSpec2 from "../assets/images/model/refrigerator/refrigeratorSpec2.svg";
import refrigeratorService1 from "../assets/images/model/refrigerator/refrigeratorService1.jpg";
import refrigeratorService2 from "../assets/images/model/refrigerator/refrigeratorService2.jpg";

import Banner from "../assets/images/home/ruta40/banner_refrigerator.jpg";

export default function ModelRefrigerator() {
    const isMobile = useMediaQuery({ maxWidth: 700 });

    return (
        <div>
            <ScrollToTopOnMount />
            <div className="header-container model">
                <div className="container" style={{ backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.2),rgba(0, 0, 0, 0.2)),url(${Banner})` }}>
                    <div className="row justify-content-center">
                        <div className="fadeIn text-center mb-5">
                            <h5 className="text-center text-light px-3 mb-4">냉장차</h5>
                            <h1 className="text-light">RT50</h1>
                        </div>
                    </div>
                </div>
            </div>
            <div className="model-tabs title model-body" style={{ backgroundColor: "white" }}>
                <Col
                    xs={{ span: 24 }}
                    sm={{ span: 22, offset: 1 }}
                    lg={{ span: 18, offset: 3 }}
                    xl={{ span: 16, offset: 4 }}
                    xxl={{ span: 14, offset: 5 }}
                    className="fadeInUp p-4"
                >
                    <div className={`mb-5 mt-3`}>
                        {isMobile ? (
                            <img className="w-100" src={refrigeratorMain2}></img>
                        ) : (
                            <img className="w-100 img-fluid" src={refrigeratorMain1} />
                        )}
                    </div>
                </Col>
                <Col
                    xs={{ span: 24 }}
                    sm={{ span: 22, offset: 1 }}
                    lg={{ span: 18, offset: 3 }}
                    xl={{ span: 16, offset: 4 }}
                    xxl={{ span: 14, offset: 5 }}
                    className="fadeInUp p-4"
                >
                    <div className="mb-5">
                        <div className="text-center mb-5">
                            <h3>서비스</h3>
                        </div>
                        <div className="col-lg-12 text-center pb-4">
                            <h6 className="text-type">냉장 전기차량 관제 시스템</h6>
                            <p style={{ wordBreak: "keep-all" }} className="b-md">
                                정밀한 정비 서비스 제공과 고객 안전을 위한 차량 관제 서비스를 지원합니다.
                            </p>
                            {isMobile ? (
                                <img className="w-100" src={refrigeratorService2}></img>
                            ) : (
                                <img className="w-100" src={refrigeratorService1}></img>
                            )}
                        </div>
                    </div>
                </Col>
                <Col
                    xs={{ span: 24 }}
                    sm={{ span: 22, offset: 1 }}
                    lg={{ span: 18, offset: 3 }}
                    xl={{ span: 16, offset: 4 }}
                    xxl={{ span: 14, offset: 5 }}
                    className="fadeInUp p-4"
                >
                    <div className="mb-5">
                        <div className="text-center mb-5">
                            <h3>제원 정보</h3>
                        </div>
                        <div className="col-lg-12 text-center optionBox pb-4">
                            <div className="col-md-12 py-3">
                                {isMobile ? (
                                    <img className="px-3 d-block w-100" alt="First slide" src={refrigeratorSpec1} />
                                ) : (
                                    <img className="px-5 d-block img-fluid" alt="First slide" src={refrigeratorSpec1} />
                                )}
                            </div>
                            <div className="col-md-12 py-3">
                                {isMobile ? (
                                    <img className="px-3 d-block w-100" alt="First slide" src={refrigeratorSpec2} />
                                ) : (
                                    <img className="px-5 d-block img-fluid" alt="First slide" src={refrigeratorSpec2} />
                                )}
                            </div>
                            <div className="col-md-12">
                                <table className="table design-table">
                                    <thead>
                                        <tr>
                                            <th colSpan={2}>제원명</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th>치수(mm)</th>
                                            <td>5,960×2,020×2,860 (냉동기 포함)</td>
                                        </tr>
                                        <tr>
                                            <th>적재함 (mm)</th>
                                            <td>3,000×1,350×1,700</td>
                                        </tr>
                                        <tr>
                                            <th>최대 적재량 (kg)</th>
                                            <td>1,000</td>
                                        </tr>
                                        <tr>
                                            <th>Max power (kW)</th>
                                            <td>60/110</td>
                                        </tr>
                                        <tr>
                                            <th>Max torque (N*m)</th>
                                            <td>400/1,000</td>
                                        </tr>
                                        <tr>
                                            <th>배터리 용량 (kWh)</th>
                                            <td>106</td>
                                        </tr>
                                        <tr>
                                            <th>주행거리 (km)</th>
                                            <td>250 (냉동기 가동 시 200)</td>
                                        </tr>
                                        <tr>
                                            <th>충전 방식</th>
                                            <td>급속 / 완속</td>
                                        </tr>
                                        <tr>
                                            <th>냉장장 열량 0℃ (W)</th>
                                            <td>4,078</td>
                                        </tr>
                                        <tr>
                                            <th>냉동 열량 -20℃ (W)</th>
                                            <td>2,271</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </Col>
            </div>
        </div>
    );
}
