import React from "react";
import { useMediaQuery } from "react-responsive";
import { Col } from "antd";
import { BrowserView, MobileView } from "react-device-detect";

import digitalKeyImage1 from "../assets/images/digitalKey/digitalKey1.jpg";
import digitalKeyImage5 from "../assets/images/digitalKey/digitalKey10.jpg";
import fmsImage1 from "../assets/images/fms/fms1.jpg";
import fmsImage5 from "../assets/images/fms/fms10.jpg";
import Carousel from "react-bootstrap/Carousel";

function ModelRuta40Service() {
    const isMobile = useMediaQuery({ maxWidth: 700 });

    return (
        <>
            <MobileView>
                <div className="text-center mb-3">
                    <h3>서비스</h3>
                </div>
                <Carousel variant="light" nextIcon="" prevIcon="">
                    <Carousel.Item>
                        <div className="col-lg-12 text-center">
                            <h6 className="text-type">디지털 키 앱</h6>
                            <p style={{ wordBreak: "keep-all" }} className="b-md mt-2">
                                고객편의를 위한 열림/잠금 기능, 배터리 상태 정보, 충전소 위치 정보 서비스를 제공합니다.
                            </p>
                            {isMobile ? (
                                <img className="w-100 mt-3" src={digitalKeyImage5}></img>
                            ) : (
                                <img className="w-100" src={digitalKeyImage1}></img>
                            )}
                        </div>
                    </Carousel.Item>
                    <Carousel.Item>
                        <div className="col-lg-12 text-center">
                            <h6 className="text-type">차량 관제 시스템</h6>
                            <p style={{ wordBreak: "keep-all" }} className="b-md mt-2">
                                정밀한 정비 서비스 제공과 고객 안전을 위한 차량 관제 서비스를 지원합니다.
                            </p>
                            {isMobile ? <img className="w-100 mt-3" src={fmsImage5}></img> : <img className="w-100" src={fmsImage1}></img>}
                        </div>
                    </Carousel.Item>
                </Carousel>
            </MobileView>
            <BrowserView>
                <div className="mb-4">
                    <div className="text-center mb-4">
                        <h3>서비스</h3>
                    </div>
                    <div className="col-lg-12 text-center pb-4">
                        <h6 className="text-type">디지털 키 앱</h6>
                        <p style={{ wordBreak: "keep-all" }} className="b-md">
                            고객편의를 위한 차량 문 열림/잠금 기능, 차량 배터리 상태 정보, 충전소 위치 정보 서비스를 제공합니다.
                        </p>
                        {isMobile ? <img className="w-100" src={digitalKeyImage5}></img> : <img className="w-100" src={digitalKeyImage1}></img>}
                    </div>
                    <div className="col-lg-12 text-center py-4">
                        <h6 className="text-type">차량 관제 시스템</h6>
                        <p style={{ wordBreak: "keep-all" }} className="b-md">
                            정밀한 정비 서비스 제공과 고객 안전을 위한 차량 관제 서비스를 지원합니다.
                        </p>
                        {isMobile ? <img className="w-100" src={fmsImage5}></img> : <img className="w-100" src={fmsImage1}></img>}
                    </div>
                </div>
            </BrowserView>
        </>
    );
}

export default ModelRuta40Service;
