import { Spin } from "antd";
import * as React from "react";

const Loading = () => {
    return (
        <div className="w-100 text-center" style={{ margin: "auto", height: "100vh" }}>
            <Spin
                className="loading"
                size="large"
                tip={
                    <>
                        <p>LOADING...</p>
                        <span>잠시만 기다려 주세요.</span>
                    </>
                }
            />
        </div>
    );
};

export default Loading;
