import React, { useEffect, useState } from "react";
import { useAsyncAxios, utilAxiosWithAppToken, utilAxiosWithAuth } from "../../utils/customAxios";

import * as Request from "../../commons/request";
import * as TypeDTO from "../../commons/typeDTO";
import * as Utils from "../../utils/utils";
import * as Common from "../../commons/common";

import AutoSalonEvent from "../../component/AutoSalonEvent";
import KoreaCampingcarShowEvent from "../../component/KoreaCampingcarShowEvent";
import CampingFairEvent from "../../component/CampingFairEvent";
import GreenCampEvent from "../../component/GreenCampEvent";
import CampingFair2024Event from "../../component/CampingFair2024Event";
import EndEvent from "../../component/EndEvent";
import { useAuthState } from "../../provider/AuthProvider";
import CampnicFairEvent from "../../component/CampnicFairEvent";
import { useLocation, useNavigate } from "react-router-dom";
import { Modal } from "antd";
import moment from "moment";

type StateType = {
    phoneNumber: string;
};

function Event() {
    const navigate = useNavigate();
    const location = useLocation();
    const userDetails = useAuthState();
    const [state, setState] = useState<any>();

    useEffect(() => {
        requestGetEvents();
    }, []);

    useEffect(() => {
        const state = location.state as StateType;
        if (state !== null) setState(location.state.phoneNumber);
    }, [location.state]);

    const displayEvent = (id: number) => {
        switch (id) {
            case 1:
                return <CampingFairEvent eventId={id} />;
            case 2:
                return <KoreaCampingcarShowEvent eventId={id} />;
            case 3:
                return <AutoSalonEvent eventId={id} />;
            case 4:
                return <CampnicFairEvent eventId={id} phoneNumber={state} />;
            case 5:
                return <GreenCampEvent eventId={id} phoneNumber={state} />;
            case 6:
                return <CampingFair2024Event eventId={id} phoneNumber={state} />;
            default:
                return;
        }
    };

    const requestAxiosGetEvents = async () => {
        const response = await utilAxiosWithAppToken().get(Request.EVENTS_URL);
        return response.data;
    };

    const {
        loading: loadingGetEvents,
        error: errorGetEvents,
        data: resultGetEvents,
        execute: requestGetEvents,
    } = useAsyncAxios(requestAxiosGetEvents);

    useEffect(() => {
        if (resultGetEvents === null) return;

        console.log("resultGetEvents", resultGetEvents);

        const today = moment(new Date()).format(Common.FORMAT_DATE_TIME);
        const startDate = resultGetEvents.events[resultGetEvents.events.length - 1].startDate;
        const endDate = resultGetEvents.events[resultGetEvents.events.length - 1].endDate;

        if (!Utils.checkDev()) {
            if (startDate > today || today > endDate) {
                openEventPeriodPopup();
            }
        }
    }, [resultGetEvents]);

    useEffect(() => {
        if (errorGetEvents === null) return;

        console.log("errorGetEvents", errorGetEvents);
    }, [errorGetEvents]);

    const openEventPeriodPopup = () => {
        Modal.warning({
            title: "이벤트 종료",
            content: `지금은 이벤트 기간이 아닙니다. \n이벤트 기간을 확인해 주세요.`,
            okText: "홈으로 이동",
            onOk() {
                navigate(Common.PAGE_HOME);
            },
            centered: true,
        });
    };

    return (
        <>
            {/*Utils.checkDev()
                ? events?.eventId
                    ? displayEvent(events.eventId)
                    : displayEvent(2)
                : events?.eventId
                ? displayEvent(events.eventId)
                : latestEvents && <EndEvent event={latestEvents} />}
            displayEvent(2)*/}
            {displayEvent(6)}
        </>
    );
}

export default Event;
