import React from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { useAuthState } from "../../provider/AuthProvider";

import * as Common from "../../commons/common";
import * as Utils from "../../utils/utils";

import PageLayout from "../../layout/PageLayout";
import Login from "../common/Login";
import MyPage from "../common/MyPage";
import NotFound from "../common/NotFound";
import PrivacyPolicy from "../common/PrivacyPolicy";
import SignUp from "../common/SignUp";
import TOS from "../common/TOS";
import UpdateMember from "../common/UpdateMember";
import WithdrawMember from "../common/WithdrawMember";
import Details from "./Details";

import Home from "./Home";
import Map from "./Map";
import Reservation from "./Reservation";
import Result from "./Result";
import CouponDetails from "../common/CouponDetails";
import ReservationDetails from "./ReservationDetails";
import Event from "../common/Event";
import Faq from "./Faq";
import InformationRent from "./InformationRent";
import InformationUse from "./InformationUse";
import DigitalKeyController from "./DigitalKeyController";
import FindEVStation from "./FindEVStation";
import Manual from "./Manual";
import TravelSpot from "./TravelSpot";
import Notice from "./Notice";
import NoticeDetail from "./NoticeDetail";
import BestReview from "./BestReview";
import UserReview from "./UserReview";
import ReviewDetail from "./ReviewDetail";
import Promotion from "./Promotion";
import PromotionDetail from "./PromotionDetail";

const type = "rent";

declare const window: typeof globalThis & {
    Android: any;
    webkit: any;
};

const PrivateRoute = (props: any) => {
    const userDetails = useAuthState();
    const location = useLocation();

    if (props.role && props.role.length > 0) {
        const checkRole = userDetails?.user && props.role.includes(userDetails?.user.authority);
        return checkRole ? (
            props.children
        ) : (
            <PageLayout type={type} theme={{ header: "light" }}>
                <Home />
            </PageLayout>
        );
    }

    return userDetails?.user ? props.children : <Navigate to={Common.PAGE_LOGIN} replace={true} state={location.pathname} />;
};

function RentRouter() {
    const userDetails = useAuthState();
    const location = useLocation();

    return (
        <Routes>
            <Route path="*" element={<Navigate to={Common.PAGE_NOTFOUND} replace />} />
            <Route
                path={Common.PAGE_NOTFOUND}
                element={
                    <PageLayout type={type}>
                        <NotFound />
                    </PageLayout>
                }
            />
            <Route path="/" element={<Navigate to={Common.PAGE_RENT_HOME} />} />
            {Utils.checkRentAllOpenMode(userDetails?.user?.email) && (
                <>
                    <Route
                        path={Common.PAGE_RENT_HOME}
                        element={
                            <PageLayout type={type}>
                                <Home />
                            </PageLayout>
                        }
                    ></Route>
                    <Route
                        path={Common.PAGE_RENT_MAP}
                        element={
                            <PageLayout type={type} theme={{ footer: "none", scroll: "none" }}>
                                <Map />
                            </PageLayout>
                        }
                    ></Route>
                    <Route
                        path={Common.PAGE_RENT_DETAIL + "/:carId/:regionId"}
                        element={
                            <PageLayout type={type} theme={{ footer: "none" }}>
                                <Details />
                            </PageLayout>
                        }
                    ></Route>
                    <Route
                        path={Common.PAGE_RENT_DETAIL + "/:carId/:regionId/:startDate/:endDate"}
                        element={
                            <PageLayout type={type} theme={{ footer: "none" }}>
                                <Details />
                            </PageLayout>
                        }
                    ></Route>
                    <Route
                        path={Common.PAGE_RENT_RESERVATION}
                        element={
                            <PrivateRoute>
                                <PageLayout type={type} theme={{ footer: "none" }}>
                                    <Reservation />
                                </PageLayout>
                            </PrivateRoute>
                        }
                    ></Route>
                    <Route
                        path={Common.PAGE_RENT_RESULT + "/:rentId"}
                        element={
                            <PageLayout type={type} theme={{ footer: "none" }}>
                                <Result />
                            </PageLayout>
                        }
                    ></Route>
                    <Route
                        path={Common.PAGE_FAQ}
                        element={
                            <PageLayout type={type}>
                                <Faq />
                            </PageLayout>
                        }
                    ></Route>
                    <Route
                        path={Common.PAGE_INFORMATION_RENT}
                        element={
                            <PageLayout type={type}>
                                <InformationRent />
                            </PageLayout>
                        }
                    ></Route>
                    <Route
                        path={Common.PAGE_INFORMATION_USE}
                        element={
                            <PageLayout type={type}>
                                <InformationUse />
                            </PageLayout>
                        }
                    ></Route>
                    <Route
                        path={Common.PAGE_TRAVEL_SPOT}
                        element={
                            <PageLayout type={type}>
                                <TravelSpot />
                            </PageLayout>
                        }
                    ></Route>
                    <Route
                        path={Common.PAGE_RENT_BEST_REVIEW}
                        element={
                            <PageLayout type={type}>
                                <BestReview />
                            </PageLayout>
                        }
                    ></Route>
                    <Route
                        path={Common.PAGE_RENT_BEST_REVIEW + "/:boardArticleId"}
                        element={
                            <PageLayout type={type}>
                                <ReviewDetail boardId={1} />
                            </PageLayout>
                        }
                    ></Route>
                    <Route
                        path={Common.PAGE_RENT_USER_REVIEW}
                        element={
                            <PageLayout type={type}>
                                <UserReview />
                            </PageLayout>
                        }
                    ></Route>
                    <Route
                        path={Common.PAGE_RENT_USER_REVIEW + "/:boardArticleId"}
                        element={
                            <PageLayout type={type}>
                                <ReviewDetail boardId={2} />
                            </PageLayout>
                        }
                    ></Route>
                    <Route
                        path={Common.PAGE_RENT_NOTICE}
                        element={
                            <PageLayout type={type}>
                                <Notice />
                            </PageLayout>
                        }
                    ></Route>
                    <Route
                        path={Common.PAGE_RENT_NOTICE_DETAIL + "/:rentNoticeId"}
                        element={
                            <PageLayout type={type}>
                                <NoticeDetail />
                            </PageLayout>
                        }
                    ></Route>
                    <Route
                        path={Common.PAGE_RENT_PROMOTION}
                        element={
                            <PageLayout type={type}>
                                <Promotion />
                            </PageLayout>
                        }
                    ></Route>
                    <Route
                        path={Common.PAGE_RENT_PROMOTION_DETAIL /*+ "/:rentPromotionId"*/}
                        element={
                            <PageLayout type={type}>
                                <PromotionDetail />
                            </PageLayout>
                        }
                    ></Route>
                    <Route
                        path={Common.PAGE_RENT_MANUAL}
                        element={
                            <PageLayout type={type}>
                                <Manual />
                            </PageLayout>
                        }
                    ></Route>
                    <>
                        <Route
                            path={Common.PAGE_DIGITALKEY_CONTROL}
                            element={
                                <PrivateRoute>
                                    <PageLayout type={type} theme={{ footer: "none", scroll: "none" }}>
                                        <DigitalKeyController />
                                    </PageLayout>
                                </PrivateRoute>
                            }
                        ></Route>
                        <Route
                            path={Common.PAGE_FIND_EV_STATION}
                            element={
                                <PrivateRoute>
                                    <PageLayout type={type} theme={{ footer: "none", scroll: "none" }}>
                                        <FindEVStation />
                                    </PageLayout>
                                </PrivateRoute>
                            }
                        ></Route>
                    </>
                </>
            )}
            <Route
                path={Common.PAGE_COUPON_DETAIL}
                element={
                    <PrivateRoute>
                        <PageLayout type={type}>
                            <CouponDetails />
                        </PageLayout>
                    </PrivateRoute>
                }
            ></Route>
            <Route
                path={Common.PAGE_RESERVATION_DETAIL}
                element={
                    <PageLayout type={type}>
                        <ReservationDetails />
                    </PageLayout>
                }
            ></Route>
            <Route
                path={Common.PAGE_LOGIN}
                element={
                    <PageLayout type={type} theme={{ footer: "sub" }}>
                        <Login />
                    </PageLayout>
                }
            />
            <Route
                path={Common.PAGE_SIGNUP}
                element={
                    <PageLayout type={type} theme={{ footer: "sub", ...(location.state?.nextPath === Common.PAGE_EVENT ? { header: "none" } : {}) }}>
                        <SignUp />
                    </PageLayout>
                }
            />
            <Route
                path={Common.PAGE_MYPAGE}
                element={
                    <PrivateRoute>
                        <PageLayout type={type} theme={{ footer: "sub" }}>
                            <MyPage />
                        </PageLayout>
                    </PrivateRoute>
                }
            ></Route>
            <Route
                path={Common.PAGE_UPDATE_MEMBER}
                element={
                    <PrivateRoute>
                        <PageLayout type={type} theme={{ footer: "sub" }}>
                            <UpdateMember />
                        </PageLayout>
                    </PrivateRoute>
                }
            ></Route>
            <Route
                path={Common.PAGE_WITHDRAW_MEMBER}
                element={
                    <PrivateRoute>
                        <PageLayout type={type} theme={{ footer: "sub" }}>
                            <WithdrawMember />
                        </PageLayout>
                    </PrivateRoute>
                }
            ></Route>
            <Route
                path={Common.PAGE_TOS}
                element={
                    <PageLayout type={type}>
                        <TOS />
                    </PageLayout>
                }
            ></Route>
            <Route
                path={Common.PAGE_PRIVACPOLICY}
                element={
                    <PageLayout type={type}>
                        <PrivacyPolicy />
                    </PageLayout>
                }
            ></Route>
            <Route
                path={Common.PAGE_EVENT}
                element={
                    <PageLayout type={type} theme={{ header: "none", footer: "none" }}>
                        <Event />
                    </PageLayout>
                }
            ></Route>
        </Routes>
    );
}

export default RentRouter;
