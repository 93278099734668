import * as React from "react";
import { Space } from "antd";

type ValueType = {
    color: string;
    image: string;
    title: string;
    description: string;
};

const UsageCard = ({ values }: { values: Array<ValueType> }) => {
    return (
        <>
            {values.map((value, index) => (
                <div key={value.title}>
                    <div className="p-2 mb-2" style={{ border: "2px solid" + value.color, background: "white" }}>
                        <Space>
                            <img src={value.image} style={{ height: "40px" }} />
                            <div>
                                <p style={{ fontWeight: "bold" }}>{value.title}</p>
                                <p>{value.description}</p>
                            </div>
                        </Space>
                    </div>
                </div>
            ))}
        </>
    );
};

export default UsageCard;
