import React, { useEffect, useState, forwardRef, useImperativeHandle, useCallback } from "react";
import { Row, Col, Form, Input, Select } from "antd";
import { useAsyncAxios, utilAxiosFormWithAuth, utilAxiosWithAppToken, utilAxiosWithAuth } from "../../utils/customAxios";
import { useAlert } from "../../provider/AlertProvider";
import { BrowserView, MobileView } from "react-device-detect";
import { useAuthState } from "../../provider/AuthProvider";

import * as TypeDTO from "../../commons/typeDTO";
import * as Request from "../../commons/request";
import * as String from "../../commons/string";

declare const window: typeof globalThis & {
    Android: any;
    webkit: any;
};

type Mode = "view" | "update" | "register";

interface ChildProps {
    mode?: Mode;
    driverLicenseId?: number;
    onSuccess?: () => void;
    onRequest?: (email?: string | undefined) => void;
    isValid?: () => boolean;
    disabled?: boolean;
}

const DriverLicense = forwardRef<ChildProps, any>(({ mode, driverLicenseId, onSuccess, disabled }, ref) => {
    const { Option } = Select;
    const [form] = Form.useForm();
    const alert = useAlert();
    const userDetails = useAuthState();
    const licenseType = ["1종 대형", "1종 보통", "1종 소형", "대형견인", "소형견인", "구난차", "2종 보통", "2종 소형", "2종 원동기"];

    const [value, setValue] = useState<TypeDTO.DriverLicenseDto>();
    const [email, setEmail] = useState<string>();

    const pattern = {
        licenseNumber: {
            pattern: /^[0-9]{2}-[0-9]{2}-[0-9]{6}-[0-9]{2}$/,
            message: "운전면허번호가 올바르지 않습니다.",
        },
        serialNumber: {
            pattern: /^[0-9a-zA-Z]{6}$/,
            message: "암호일련번호 형식이 맞지 않습니다.",
        },
        birthDateAndGender: {
            pattern: /^\d{6}-\d{1}$/,
            message: "형식이 일치하지 않습니다.000000-0 형식으로 입력해 주세요",
        },
    };

    const onRequest = (email?: string | undefined) => {
        setEmail(email);
        form.submit();
    };

    const isValid = () => {
        const messages = form.getFieldsError(["licenseType", "licenseNumber"]);
        let valid = true;

        messages.forEach((item) => {
            if (valid) valid = item.errors.length === 0;
        });

        return valid;
    };

    useImperativeHandle(ref, () => ({ onRequest, isValid }));

    useEffect(() => {
        if (mode === "view" || mode === "update") {
            driverLicenseId && requestGetDriverLicense(driverLicenseId);
        }
    }, [driverLicenseId]);

    const validateLicenseType = () => {
        form.validateFields(["licenseType"]);
    };

    const validateLicenseNumber = () => {
        form.validateFields(["licenseNumber"]);
    };

    //getDriverLicense
    const requestAxiosGetDriverLicense = async (id: string) => {
        const response = await utilAxiosWithAuth().get(Request.DRIVER_LICENSE_URL + id);
        return response.data;
    };

    const {
        loading: loadingGetDriverLicense,
        error: errorGetDriverLicense,
        data: resultGetDriverLicense,
        execute: requestGetDriverLicense,
    } = useAsyncAxios(requestAxiosGetDriverLicense);

    useEffect(() => {
        if (!resultGetDriverLicense) return;
        console.log("resultGetDriverLicense", resultGetDriverLicense);

        if (resultGetDriverLicense.driverLicense.licenseType === "") {
            delete resultGetDriverLicense.driverLicense.licenseType;
        }

        form.setFieldsValue(resultGetDriverLicense?.driverLicense);
        setValue(resultGetDriverLicense?.driverLicense);
    }, [resultGetDriverLicense]);

    useEffect(() => {
        if (!errorGetDriverLicense) return;

        console.log("errorGetDriverLicenseCar", errorGetDriverLicense);
    }, [errorGetDriverLicense]);

    //addDriverLicense
    const requestAxiosAddDriverLicense = async (values: any) => {
        const response = await utilAxiosWithAppToken().post(Request.DRIVER_LICENSE_ADD_URL, values);
        return response.data;
    };

    const {
        loading: loadingAddDriverLicense,
        error: errorAddDriverLicense,
        data: resultAddDriverLicense,
        execute: requestAddDriverLicense,
    } = useAsyncAxios(requestAxiosAddDriverLicense);

    useEffect(() => {
        if (!resultAddDriverLicense) return;
        console.log("resultAddDriverLicense", resultAddDriverLicense);

        if (!email && resultAddDriverLicense?.driverLicense?.driverLicenseId) {
            requestGetDriverLicense(resultAddDriverLicense?.driverLicense?.driverLicenseId);
        }
        onSuccess && onSuccess();
    }, [resultAddDriverLicense]);

    useEffect(() => {
        if (!errorAddDriverLicense) return;
        console.log("errorAddDriverLicenseCar", errorAddDriverLicense);
    }, [errorAddDriverLicense]);

    //updateDriverLicense
    const requestAxiosUpdateDriverLicense = async (id: string, values: TypeDTO.DriverLicenseDto) => {
        const response = await utilAxiosFormWithAuth().put(Request.DRIVER_LICENSE_URL + id, values);
        return response.data;
    };

    const {
        loading: loadingUpdateDriverLicense,
        error: errorUpdateDriverLicense,
        data: resultUpdateDriverLicense,
        execute: requestUpdateDriverLicense,
    } = useAsyncAxios(requestAxiosUpdateDriverLicense);

    useEffect(() => {
        if (!resultUpdateDriverLicense) return;
        console.log("resultUpdateDriverLicense", resultUpdateDriverLicense);

        if (resultUpdateDriverLicense?.driverLicense?.driverLicenseId) {
            requestGetDriverLicense(resultUpdateDriverLicense?.driverLicense?.driverLicenseId);
        }
        onSuccess && onSuccess();
    }, [resultUpdateDriverLicense]);

    useEffect(() => {
        if (!errorUpdateDriverLicense) return;
        console.log("errorUpdateDriverLicenseCar", errorUpdateDriverLicense);
    }, [errorUpdateDriverLicense]);

    const onFinish = useCallback(
        (driverLicense: any) => {
            const formData = new FormData();
            if (!driverLicense.licenseType) driverLicense.licenseType = "";
            driverLicense.user = email ? { email } : userDetails?.user;
            formData.append("driverLicenseDto", new Blob([JSON.stringify(driverLicense)], { type: "application/json" }));
            mode === "update" ? requestUpdateDriverLicense(driverLicenseId, formData) : requestAddDriverLicense(formData);
        },
        [mode, driverLicenseId, userDetails, email]
    );

    const onInput = (event: React.FormEvent<HTMLInputElement>) => {
        if ((event.nativeEvent as InputEvent).inputType === "deleteContentBackward") return;
        (event.target as HTMLInputElement).value = (event.target as HTMLInputElement).value
            .replace(/[^0-9]/g, "")
            .replace(/^(\d{2})(\d{2})(\d{6})(\d+)$/, "$1-$2-$3-$4")
            .replace(/^(\d{2})(\d{2})(\d{6})$/, "$1-$2-$3-")
            .replace(/^(\d{2})(\d{2})(\d+)$/, "$1-$2-$3")
            .replace(/^(\d{2})(\d{2})$/, "$1-$2-")
            .replace(/^(\d{2})(\d+)$/, "$1-$2")
            .replace(/^(\d{2})$/, "$1-");
    };

    return (
        <>
            <MobileView className="mobile">
                {mode === "view" ? (
                    <div style={{ marginBottom: "1rem" }}>
                        <span>{String.license}</span>
                        {value?.licenseNumber ? (
                            <>
                                <p className="login-input mt-2 p-2">{value?.licenseType}</p>
                                <p className="login-input mt-2 p-2">{value?.licenseNumber}</p>
                            </>
                        ) : (
                            <p className="login-input mt-2 mb-3">운전면허를 추가해주세요.</p>
                        )}
                    </div>
                ) : (
                    <Form form={form} onFinish={onFinish} layout="vertical" className="license" disabled={disabled === true}>
                        <Form.Item
                            name="licenseType"
                            label={String.license}
                            className="mt-1 mb-0"
                            rules={[
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if (!value && getFieldValue("licenseNumber")) {
                                            return Promise.reject(new Error("면허 종류를 선택해 주세요."));
                                        }

                                        return Promise.resolve();
                                    },
                                }),
                            ]}
                        >
                            <Select
                                className="login-select"
                                placeholder={String.licenseType}
                                optionFilterProp="children"
                                allowClear
                                onChange={(e) => validateLicenseNumber()}
                            >
                                {licenseType.map((type) => (
                                    <Option key={type} value={type}>
                                        {type}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Form.Item
                            name="licenseNumber"
                            className="mt-2 mb-0"
                            rules={[
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if (!value && getFieldValue("licenseType")) {
                                            return Promise.reject(new Error("운전면허번호를 입력해 주세요."));
                                        }

                                        return Promise.resolve();
                                    },
                                }),
                                {
                                    pattern: pattern.licenseNumber.pattern,
                                    message: pattern.licenseNumber.message,
                                },
                            ]}
                        >
                            <Input
                                className="login-input"
                                placeholder={String.msg_licenseNumber}
                                allowClear
                                maxLength={15}
                                onInput={onInput}
                                onChange={(e) => validateLicenseType()}
                            />
                        </Form.Item>
                        <p className="mt-1 pb-3 text-danger">
                            ※ 1종 보통 이상 면허만 운전 가능합니다.
                            <br />※ 2014년 7월 이전 발급된 운전면허증은 지역명으로 표시되오니, 해당하는 지역번호를 찾아서 입력해 주세요.
                        </p>
                    </Form>
                )}
            </MobileView>

            <BrowserView className="browser">
                {mode === "view" ? (
                    <div className="login mb-3">
                        {value?.licenseNumber ? (
                            <>
                                <p className="login-input mt-2 p-2">{value?.licenseType}</p>
                                <p className="login-input mt-2 p-2">{value?.licenseNumber}</p>
                            </>
                        ) : (
                            <p className="login-input mt-2">운전면허를 추가해주세요.</p>
                        )}
                    </div>
                ) : (
                    <>
                        <Form form={form} onFinish={onFinish} layout="vertical" className="license" disabled={disabled === true}>
                            <Form.Item
                                name="licenseType"
                                label={String.license}
                                className="mb-0"
                                rules={[
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            if (!value && getFieldValue("licenseNumber")) {
                                                return Promise.reject(new Error("면허 종류를 선택해 주세요."));
                                            }

                                            return Promise.resolve();
                                        },
                                    }),
                                ]}
                            >
                                <Select
                                    className="login-select"
                                    placeholder={String.licenseType}
                                    optionFilterProp="children"
                                    allowClear
                                    onChange={(e) => validateLicenseNumber()}
                                >
                                    {licenseType.map((type) => (
                                        <Option key={type} value={type}>
                                            {type}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                            <Form.Item
                                name="licenseNumber"
                                className="mt-2 mb-0"
                                rules={[
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            if (!value && getFieldValue("licenseType")) {
                                                return Promise.reject(new Error("운전면허번호를 입력해 주세요."));
                                            }

                                            return Promise.resolve();
                                        },
                                    }),
                                    {
                                        pattern: pattern.licenseNumber.pattern,
                                        message: pattern.licenseNumber.message,
                                    },
                                ]}
                            >
                                <Input
                                    className="login-input"
                                    type="tel"
                                    placeholder={String.msg_licenseNumber}
                                    allowClear
                                    maxLength={15}
                                    onInput={onInput}
                                    onChange={(e) => validateLicenseType()}
                                />
                            </Form.Item>
                            <p className="mt-1 pb-3 text-danger">
                                ※ 1종 보통 이상 면허만 운전 가능합니다.
                                <br />※ 2014년 7월 이전 발급된 운전면허증은 지역명으로 표시되오니, 해당하는 지역번호를 찾아서 입력해 주세요.
                            </p>
                        </Form>
                    </>
                )}
            </BrowserView>
        </>
    );
});
export default DriverLicense;
DriverLicense.displayName = "DriverLicense";
