import { Space, Row, Col } from "antd";
import * as React from "react";
import styles from "./GrantGuidance.module.css";
import IconLogo from "../assets/images/home/ruta40/icon_grant_logo.svg";
import IconCustomer from "../assets/images/home/ruta40/icon_grant_customer.svg";
import IconDelivery from "../assets/images/home/ruta40/icon_grant_delivery.svg";

const GrantGuidance = () => {
    return (
        <div className={styles.body}>
            <p className={styles.title}>전기차 보조금 안내</p>
            <p>※ 전기차 보조금은 지자체별 지원금이 상이하며, 전기차 보조금 소진 시 지원이 불가할 수 있습니다.</p>
            <p>
                ※ 자격 조건 및 보조금 상세 현황은&nbsp;
                <a href="https://www.ev.or.kr/portal" target="_blank" rel="noreferrer" style={{ color: "#006AF5" }}>
                    무공해차 통합누리집 사이트
                </a>
                &nbsp;및 각 지자체에서 계약자 본인이 직접 확인하신 후 계약을 진행해 주세요.
            </p>
            <p>※ 자격 조건에 해당되더라도 보조금 대상자 선정 전까지는 보조금 소진 가능성이 있습니다.</p>
            <p>※ 전기차 보조금 미지원으로 인한 자부담금 추가 발생건은 계약 파기 및 계약금 환불의 사유가 될 수 없습니다.</p>
            <>
                <p className={styles.subTitle}>보조금 신청 절차</p>
                <Row gutter={0}>
                    <Col sm={4} md={2} lg={4} style={{ minWidth: "64px", maxWidth: "64px" }}>
                        <div>
                            <img src={IconLogo}></img>
                        </div>
                        <div>
                            <img src={IconCustomer}></img>
                        </div>
                    </Col>
                    <Col sm={6} md={7} lg={6} style={{ marginTop: "5px", minWidth: "95px" }}>
                        <p className={styles.content}>
                            [안내 전화]
                            <br />
                            보조금 신청 안내
                        </p>
                        <hr className={styles.hr} />
                        <p className={styles.content}>
                            보조금 신청서류
                            <br />
                            RUTA40에 제출
                        </p>
                    </Col>
                    <Col sm={4} md={6} lg={5} style={{ marginTop: "5px" }}>
                        <p className={styles.content}>
                            보조금
                            <br />
                            신청
                        </p>
                        <hr className={styles.hr} />
                        <p className={styles.content}>
                            [보조금
                            <br />
                            자격 부여]
                        </p>
                    </Col>
                    <Col sm={6} md={7} lg={5} style={{ marginTop: "5px" }}>
                        <p className={styles.content}>
                            주문 확인
                            <br />
                            메일 발송
                        </p>
                        <hr className={styles.hr} />
                        <p className={styles.content}>
                            주문 내용 확인
                            <br />
                            대금 지급 준비
                        </p>
                    </Col>
                    <Col sm={4} md={2} lg={4} style={{ marginTop: "5px", minWidth: "64px" }}>
                        <p className={styles.content}>
                            보조금
                            <br />
                            확정 안내
                        </p>
                        <hr className={styles.hr} />
                        <p className={styles.content}>
                            [보조금
                            <br />
                            대상자 선정]
                        </p>
                    </Col>
                </Row>
            </>
            <>
                <p className={styles.subTitle}>차량 대금 결제 절차(보조금 확정 후)</p>
                <Row gutter={0}>
                    <Col sm={3} md={2} lg={4} style={{ minWidth: "64px", maxWidth: "64px" }}>
                        <div>
                            <img src={IconLogo}></img>
                        </div>
                        <div>
                            <img src={IconCustomer}></img>
                        </div>
                    </Col>
                    <Col sm={6} md={7} lg={6} style={{ marginTop: "5px" }}>
                        <p className={styles.content}>
                            결제문자
                            <br />
                            발송
                        </p>
                        <hr className={styles.hr} />
                        <p className={styles.content}>
                            차량 대금
                            <br />
                            결제
                        </p>
                    </Col>
                    <Col sm={6} md={6} lg={5} style={{ marginTop: "5px" }}>
                        <p className={styles.content}></p>
                        <hr className={styles.hr} />
                        <p className={styles.content}>
                            보험 가입
                            <br />
                            증명서 제출
                        </p>
                    </Col>
                    <Col sm={6} md={7} lg={5} style={{ marginTop: "5px" }}>
                        <p className={styles.content}></p>
                        <hr className={styles.hr} />
                        <p className={styles.content}>차량 등록</p>
                    </Col>
                    <Col sm={3} md={2} lg={4} style={{ marginTop: "5px", minWidth: "64px" }}>
                        <img src={IconDelivery} />
                    </Col>
                </Row>
            </>
            <p>
                ※ 환경부 지침에 따라서 보조금 대상자 선정 후 10일 이내 인도되지 않으면 출고 지연에 따른 취소가 되므로, 고객님께서 지정하신 인도일정에
                맞추어 대상자 선정을 요청 할 예정입니다.
            </p>
            <p>※ 고객님께서는 늦어도 인도일로부터 영업일 기준 6일전 까지는 결제가 완료되고 차량등록을 신청할 수 있도록 준비 부탁 드립니다.</p>
            <p>
                ※ 고객님께서 직접 차량 등록 시, 인도일로부터 영업일 기준 3일전까지 등록 완료를 권장 드리고, 등록증 스캔본을 담당 어드바이저에게 송부해
                주시기 바랍니다. 번호판은 직접 설치할 수 있도록 공구 등을 미리 준비해 주시기 바랍니다.
            </p>
        </div>
    );
};

export default GrantGuidance;
