import React from "react";
import { useNavigate } from "react-router-dom";
import { Col } from "antd";
import * as Common from "../commons/common";
import * as Utils from "../utils/utils";

import instaLogo from "../assets/images/instagram.svg";
import youtubeLogo from "../assets/images/youtube.svg";
import playStoreLogo from "../assets/images/google-play-badge.svg";
import appStoreLogo from "../assets/images/app-store-badge.svg";
import { BrowserView, MobileView, isMobile } from "react-device-detect";

// declare const window: typeof globalThis & {
//     Android: any;
//     webkit: any;
// };

function LayoutFooter({ orderType, type }: { orderType?: boolean; type?: "rent" | "home" }) {
    const navigate = useNavigate();
    return (
        <>
            <MobileView className="mobile">
                <div className="footer">
                    <div className={`${orderType ? "order text-dark mb-4" : "bg-gray"} `}>
                        <Col
                            sm={{ span: 24 }}
                            lg={{ span: orderType ? 24 : 18, offset: orderType ? 0 : 3 }}
                            xl={{ span: orderType ? 24 : 18, offset: orderType ? 0 : 3 }}
                            xxl={{ span: orderType ? 24 : 16, offset: orderType ? 0 : 4 }}
                        >
                            <div className="container">
                                <div className="row">
                                    <div className={`${orderType ? "" : "col-lg-3 col-md-6 m-0"} `}>
                                        <p className={`${orderType ? "" : "mt-4 mb-2 company-name"} `}>주식회사 루타스</p>
                                    </div>
                                </div>
                            </div>
                            <div className="container">
                                <div className="copyright pt-2">
                                    <div className="row">
                                        <div className={`${orderType ? "" : "col-md-7 text-md-start mb-3 mb-md-0 no-order"}`}>
                                            <p>대표. 허성만</p>
                                            <p>공장. 대구 달성군 구지면 달성2차로 132, 2층 201호</p>
                                            <p>사업자 등록번호. 362-81-01755</p>
                                            <p>통신판매업신고번호. 제2022-대구달성-1084호</p>
                                            <p>이메일. support@rutas.co.kr</p>
                                            <p>고객상담. 평일 09:00~17:00(휴게시간 12:00~13:00)</p>
                                            {type === "rent" && (
                                                <>
                                                    <p className="TOS-wrapper">
                                                        -{" "}
                                                        <a
                                                            className="TOS-wrapper"
                                                            onClick={() => Utils.sendLinkToApp(Common.URL_KAKAOTALK_CHANNEL_CHAT)}
                                                        >
                                                            1:1 상담 바로가기
                                                        </a>
                                                    </p>
                                                    <p>- 070-4327-3730(렌트문의)</p>
                                                    <p>- 010-4906-7708(경주 영업소)</p>
                                                </>
                                            )}
                                            {type !== "rent" && (
                                                <>
                                                    <p>- 070-7707-1768(구매상담)</p>
                                                    <p>- 010-2680-0510(문자 전용)</p>
                                                </>
                                            )}
                                        </div>
                                        {!orderType && (
                                            <div className="col-md-5 text-md-end">
                                                <div className="footer-menu text-right">
                                                    {type === "rent" && (
                                                        <>
                                                            <img
                                                                onClick={() => {
                                                                    Utils.sendLinkToApp(Common.URL_ANDROID_PLAY_STORE);
                                                                }}
                                                                height={36}
                                                                src={playStoreLogo}
                                                            />

                                                            <img
                                                                onClick={() => {
                                                                    Utils.sendLinkToApp(Common.URL_IOS_APP_STORE);
                                                                }}
                                                                height={36}
                                                                src={appStoreLogo}
                                                            />
                                                        </>
                                                    )}
                                                    <MobileView className="footer-menu text-right">
                                                        <img
                                                            onClick={() => {
                                                                Utils.sendLinkToApp(
                                                                    type === "home" ? Common.URL_YOUTUBE_RT40 : Common.URL_YOUTUBE_RENT
                                                                );
                                                            }}
                                                            src={youtubeLogo}
                                                        />

                                                        <img
                                                            onClick={() => {
                                                                Utils.sendLinkToApp(Common.URL_INSTAGRAM);
                                                            }}
                                                            src={instaLogo}
                                                        />
                                                    </MobileView>
                                                    <BrowserView renderWithFragment={true}>
                                                        <img
                                                            onClick={() => {
                                                                Utils.sendLinkToApp(
                                                                    type === "home" ? Common.URL_YOUTUBE_RT40 : Common.URL_YOUTUBE_RENT
                                                                );
                                                            }}
                                                            src={youtubeLogo}
                                                        />

                                                        <img
                                                            onClick={() => {
                                                                Utils.sendLinkToApp(Common.URL_INSTAGRAM);
                                                            }}
                                                            src={instaLogo}
                                                        />
                                                    </BrowserView>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    <div className="row">
                                        <div className={`my-3 text-left text-md-start copyright-section ${!orderType && "col-md-5 no-order"}`}>
                                            <p className="mb-3 TOS-wrapper">
                                                <a onClick={() => navigate(Common.PAGE_TOS)}>이용약관</a> |{" "}
                                                <a onClick={() => navigate(Common.PAGE_PRIVACPOLICY)}>개인정보처리방침</a>
                                            </p>
                                            {!orderType && (
                                                <p style={{ fontSize: "8px" }}>
                                                    &copy;
                                                    <a className="footer-anchor" onClick={() => navigate(Common.PAGE_HOME)}>
                                                        RUTA40
                                                    </a>
                                                    , All Right Reserved. Designed By{" "}
                                                    <a className="footer-anchor" href="http://rutas.co.kr/">
                                                        RUTAS.
                                                    </a>
                                                </p>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </div>
                </div>
            </MobileView>
            <BrowserView className="browser">
                <div className="footer">
                    <div className={`${orderType ? "order text-dark mb-4" : "bg-gray text-light"} `}>
                        <Col
                            sm={{ span: 24 }}
                            lg={{ span: orderType ? 24 : 18, offset: orderType ? 0 : 3 }}
                            xl={{ span: orderType ? 24 : 18, offset: orderType ? 0 : 3 }}
                            xxl={{ span: orderType ? 24 : 16, offset: orderType ? 0 : 4 }}
                        >
                            <div className="container">
                                <div className="row">
                                    <div className={`${orderType ? "" : "col-lg-3 col-md-6 m-0"} `}>
                                        <p className={`${orderType ? "" : "mt-4 mb-2"} `}>주식회사 루타스</p>
                                    </div>
                                </div>
                            </div>
                            <div className="container">
                                <div className="copyright pt-2">
                                    <div className="row">
                                        <div className={`${orderType ? "" : "col-md-7 text-md-start mb-3 mb-md-0"}`}>
                                            <p>대표 : 허성만 | 공장 : 대구 달성군 구지면 달성2차로 132, 2층 201호</p>
                                            <p>사업자 등록번호 : 362-81-01755 | 통신판매업신고번호 : 제2022-대구달성-1084호</p>
                                            {type === "rent" && (
                                                <>
                                                    <p className="TOS-wrapper">
                                                        고객센터 :{" "}
                                                        <a
                                                            className="TOS-wrapper"
                                                            onClick={() => Utils.sendLinkToApp(Common.URL_KAKAOTALK_CHANNEL_CHAT)}
                                                        >
                                                            1:1 상담 바로가기
                                                        </a>{" "}
                                                        | 070-4327-3730(렌트문의) | 010-4906-7708(경주 영업소)
                                                    </p>
                                                    <p style={{ marginLeft: 60 }}>평일 09:00~17:00(휴게시간 12:00~13:00)</p>
                                                </>
                                            )}
                                            <p>이메일 : support@rutas.co.kr</p>
                                            {type !== "rent" && (
                                                <>
                                                    <p>고객센터 : 070-7707-1768(구매상담) | 010-2680-0510(문자 전용)</p>
                                                    <p style={{ marginLeft: 60 }}>평일 09:00~17:00(휴게시간 12:00~13:00)</p>
                                                </>
                                            )}
                                        </div>
                                        {!orderType && (
                                            <div className="col-md-5 text-md-end">
                                                <div className="footer-menu text-right">
                                                    {type === "rent" && (
                                                        <>
                                                            <img
                                                                onClick={() => {
                                                                    Utils.sendLinkToApp(Common.URL_ANDROID_PLAY_STORE);
                                                                }}
                                                                height={36}
                                                                src={playStoreLogo}
                                                            />

                                                            <img
                                                                onClick={() => {
                                                                    Utils.sendLinkToApp(Common.URL_IOS_APP_STORE);
                                                                }}
                                                                height={36}
                                                                src={appStoreLogo}
                                                            />
                                                        </>
                                                    )}
                                                    <MobileView className="footer-menu text-right">
                                                        <img
                                                            onClick={() => {
                                                                Utils.sendLinkToApp(
                                                                    type === "home" ? Common.URL_YOUTUBE_RT40 : Common.URL_YOUTUBE_RENT
                                                                );
                                                            }}
                                                            src={youtubeLogo}
                                                        />

                                                        <img
                                                            onClick={() => {
                                                                Utils.sendLinkToApp(Common.URL_INSTAGRAM);
                                                            }}
                                                            src={instaLogo}
                                                        />
                                                    </MobileView>
                                                    <BrowserView renderWithFragment={true}>
                                                        <img
                                                            onClick={() => {
                                                                Utils.sendLinkToApp(
                                                                    type === "home" ? Common.URL_YOUTUBE_RT40 : Common.URL_YOUTUBE_RENT
                                                                );
                                                            }}
                                                            src={youtubeLogo}
                                                        />

                                                        <img
                                                            onClick={() => {
                                                                Utils.sendLinkToApp(Common.URL_INSTAGRAM);
                                                            }}
                                                            src={instaLogo}
                                                        />
                                                    </BrowserView>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    <div className="row">
                                        <div className={`my-3 text-left text-md-start copyright-section ${!orderType && "col-md-5"}`}>
                                            <p className="mb-3 TOS-wrapper">
                                                <a onClick={() => navigate(Common.PAGE_TOS)}>이용약관</a> |{" "}
                                                <a onClick={() => navigate(Common.PAGE_PRIVACPOLICY)}>개인정보처리방침</a>
                                            </p>
                                            {!orderType && (
                                                <p style={{ fontSize: "8px" }}>
                                                    &copy;
                                                    <a className="footer-anchor" onClick={() => navigate(Common.PAGE_HOME)}>
                                                        RUTA40
                                                    </a>
                                                    , All Right Reserved. Designed By{" "}
                                                    <a className="footer-anchor" href="http://rutas.co.kr/">
                                                        RUTAS.
                                                    </a>
                                                </p>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </div>
                </div>
            </BrowserView>
        </>
    );
}

export default LayoutFooter;
