import { Button, Spin, Tabs, Col } from "antd";
import React, { useEffect, useState } from "react";

import * as TypeDTO from "../commons/typeDTO";
import { useAuthState } from "../provider/AuthProvider";
import * as Utils from "../utils/utils";

import ModelRuta40TypeBayrun3 from "./ModelRuta40TypeBayrun3";
import ModelRuta40TypeBayrun4 from "./ModelRuta40TypeBayrun4";
import ModelRuta40TypeS from "./ModelStype";
import ScrollToTopOnMount from "./ScrollTopOnMount";
import { useParams } from "react-router-dom";

import Banner from "../assets/images/home/ruta40/banner_campingCar.jpg";

function ModelRuta40({ modelInfo, onClickOrder }: { modelInfo?: TypeDTO.CarModelDto; onClickOrder?: (typeId: number) => void }) {
    const userDetails = useAuthState();
    const param = useParams();

    const [currentTypeId, setCurrentTypeId] = useState<number>();
    const [availableCarTypes, setAvailableCarTypes] = useState<Array<TypeDTO.CarTypeDto>>();

    useEffect(() => {
        if (param.typeId !== undefined) {
            setCurrentTypeId(Number(param.typeId));
        } else if (availableCarTypes !== undefined && availableCarTypes.length > 0) {
            setCurrentTypeId(availableCarTypes[0].typeId);
        }
    }, [param.typeId, availableCarTypes]);

    useEffect(() => {
        if (modelInfo !== undefined) {
            setAvailableCarTypes(modelInfo.types.filter((type) => type.unavailable !== true));
        }
    }, [modelInfo]);

    const getTypeComponent = () => {
        const currentType = modelInfo?.types.filter((type) => type.typeId === currentTypeId);

        if (currentType !== undefined && currentType.length > 0) {
            const typeName = currentType[0].typeName;

            if (typeName.includes("배이런(3인승)")) return <ModelRuta40TypeBayrun3 />;
            else if (typeName.includes("배이런(4인승)")) return <ModelRuta40TypeBayrun4 />;
            else if (typeName.includes("S타입")) return <ModelRuta40TypeS />;
        }

        return <ModelRuta40TypeBayrun3 />;
    };

    const displayTypes = () => {
        return (
            <>
                <div className="header-container model">
                    <div className="container" style={{ backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.2),rgba(0, 0, 0, 0.2)),url(${Banner})` }}>
                        <div className="row justify-content-center">
                            <div className="fadeIn text-center mb-5">
                                <h5 className="text-center text-light px-3 mb-4">{modelInfo?.category.categoryName}</h5>
                                <h1 className="text-light">{modelInfo?.modelName}</h1>
                            </div>
                        </div>
                    </div>
                </div>
                <Tabs
                    className="model-tabs tab-lg title model-body"
                    activeKey={currentTypeId !== undefined ? String(currentTypeId) : undefined}
                    items={
                        availableCarTypes?.map((type) => {
                            return {
                                key: String(type.typeId),
                                label: type.typeName,
                            };
                        }) || []
                    }
                    onChange={(value) => setCurrentTypeId(Number(value))}
                ></Tabs>

                <Col>{getTypeComponent()}</Col>

                {/* {currentTypeId === 3 && <ModelRuta40TypeS />}
                {currentTypeId === 4 && <ModelRuta40TypeBayrun3 />}
                {currentTypeId === 5 && <ModelRuta40TypeBayrun4 />} */}
            </>
        );
    };

    const displayOldTypes = () => {
        return <ModelRuta40TypeS />;
    };

    return (
        <div>
            {modelInfo ? (
                <>
                    <ScrollToTopOnMount />
                    {Utils.checkAllOpenMode(userDetails?.user?.email) === true ? displayTypes() : displayOldTypes()}
                    <div className="text-center mb-5">
                        <Button
                            className="fadeInUp btn-dark px-5"
                            size="large"
                            type="primary"
                            onClick={() => {
                                if (onClickOrder !== undefined && currentTypeId !== undefined) onClickOrder(currentTypeId);
                            }}
                        >
                            구매하기
                        </Button>
                    </div>
                </>
            ) : (
                <div style={{ width: "100%", height: "calc(100vh - 75px)", textAlign: "center" }}>
                    <Spin
                        className="loading"
                        size="large"
                        tip={
                            <>
                                <p>LOADING...</p>
                                <span>잠시만 기다려 주세요.</span>
                            </>
                        }
                    />
                </div>
            )}
        </div>
    );
}

export default ModelRuta40;
