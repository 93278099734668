import React, { useState, useEffect } from "react";

import { Button, Input, Modal } from "antd";
import PhoneInput from "./PhoneInput";

declare const window: typeof globalThis & {
    IMP: any;
};

function PhoneCertification({
    value,
    userName,
    onChangedResult,
    ...props
}: {
    value?: string;
    userName: string;
    onChangedResult?: (success: boolean, phoneNumber: string) => void;
}) {
    const [inputValue, setInputValue] = useState<string>("");
    const [disableInput, setDisableInput] = useState(false);
    const [disableButton, setDisableButton] = useState(true);

    const [isCertification, setIsCertification] = useState(false);

    useEffect(() => {
        if (value !== undefined) setInputValue(value);
    }, [value]);

    useEffect(() => {
        if (inputValue.length > 0) setDisableButton(false);
        else setDisableButton(true);
    }, [inputValue]);

    useEffect(() => {
        if (userName.length > 0) {
            setDisableInput(false);
        } else {
            setDisableInput(true);
        }
    }, [userName]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setInputValue(e.target.value);
    };

    const errorPhoneNumber = () => {
        Modal.error({
            title: "전화번호 오류",
            content: "정확한 휴대전화 번호를 입력해 주세요.",
            okText: "확인",
            onOk() {},
            centered: true,
        });
    };

    const handleCertification = () => {
        const regPhone = /^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$/;
        if (regPhone.test(inputValue) === false) {
            errorPhoneNumber();
            return;
        }

        const data = {
            name: userName,
            phone: inputValue,
        };

        const { IMP } = window;
        // IMP.init("imp93949381");
        IMP.certification(data, callbackCertification);
    };

    const callbackCertification = (response: any) => {
        if (onChangedResult !== undefined) onChangedResult(response.success, inputValue);

        if (response.success === true) {
            setDisableInput(true);
            setDisableButton(true);
            setIsCertification(true);
        }
    };

    return (
        <Input.Group compact>
            <PhoneInput disabled={disableInput} value={inputValue} onChange={handleChange} {...props} />
            <Button disabled={disableButton} onClick={handleCertification}>
                {isCertification ? "완료" : "인증"}
            </Button>
        </Input.Group>
    );
}

export default PhoneCertification;
