import React, { useEffect, useState } from "react";
import { CustomOverlayMap, Map, MapMarker, MapTypeControl } from "react-kakao-maps-sdk";
import { TypeRecommendStation } from "../../pages/rent/FindEVStation";

import * as TypeDTO from "../../commons/typeDTO";

import chaeviBlue from "../../assets/images/digitalKeyController/stn-chaevi-blue.svg";
import chaeviYellow from "../../assets/images/digitalKeyController/stn-chaevi-yellow.svg";
import skBlue from "../../assets/images/digitalKeyController/stn-sk-blue.svg";
import skYellow from "../../assets/images/digitalKeyController/stn-sk-yellow.svg";
import meBlue from "../../assets/images/digitalKeyController/stn-me-blue.svg";
import meYellow from "../../assets/images/digitalKeyController/stn-me-yellow.svg";
import chaeviRecommend from "../../assets/images/digitalKeyController/stn-chaevi-recommend.svg";
import skRecommend from "../../assets/images/digitalKeyController/stn-sk-recommend.svg";
import meRecommend from "../../assets/images/digitalKeyController/stn-me-recommend.svg";

import styles from "./KakaoMapEVStation.module.css";

export type gpsType = {
    lat: number;
    lng: number;
};

function KakaoMapEVStation({
    mapStyle,
    zoomLevel,
    mapCenter,
    myPosition,
    selectedStation,
    evcs,
    recommendStation,
    onClickEvStation,
    onChangedMapCenter,
    onChangedZoomLevel,
}: {
    mapStyle?: React.CSSProperties;
    zoomLevel?: number;
    mapCenter?: gpsType;
    myPosition?: gpsType;
    selectedStation?: TypeDTO.EvcsItemDto;
    evcs?: Array<TypeDTO.EvcsItemDto>;
    recommendStation?: Array<TypeRecommendStation>;
    onChangedZoomLevel?: (level: number) => void;
    onChangedMapCenter?: (lat: number, lng: number) => void;
    onClickEvStation?: (station: TypeDTO.EvcsItemDto) => void;
}) {
    const [kakaoMap, setKakaoMap] = useState<kakao.maps.Map | undefined>(undefined);
    const [level, setLevel] = useState<number>(12);
    const [center, setCenter] = useState<gpsType>();
    const CV = "CV";
    const ME = "ME";

    useEffect(() => {
        zoomLevel && setLevel(zoomLevel);
    }, [zoomLevel]);

    useEffect(() => {
        setCenter(mapCenter);
    }, [mapCenter]);

    useEffect(() => {
        if (kakaoMap && center) {
            kakaoMap.setCenter(new kakao.maps.LatLng(center.lat, center.lng));
        }
    }, [center]);

    const onZoomChanged = (map: kakao.maps.Map) => {
        setLevel(map.getLevel());

        if (onChangedZoomLevel) onChangedZoomLevel(map.getLevel());
    };

    const onCenterChanged = (map: kakao.maps.Map) => {
        setCenter({ lat: map.getCenter().getLat(), lng: map.getCenter().getLng() });
        onChangedMapCenter && onChangedMapCenter(map.getCenter().getLat(), map.getCenter().getLng());
    };

    const handleMap = (map: kakao.maps.Map) => {
        setKakaoMap(map);
    };

    const displayMyPostion = () => {
        if (myPosition === undefined) return null;

        return (
            <>
                <CustomOverlayMap
                    position={{
                        lat: myPosition.lat,
                        lng: myPosition.lng,
                    }}
                >
                    <div className={styles.myLocationShadow} />
                </CustomOverlayMap>
                <CustomOverlayMap
                    position={{
                        lat: myPosition.lat,
                        lng: myPosition.lng,
                    }}
                >
                    <div className={styles.myLocationDot} />
                </CustomOverlayMap>
            </>
        );
    };

    const displayEvStations = () => {
        if (!evcs) return null;

        return evcs.map((station) => {
            let selected = false;
            if (selectedStation && station.statId === selectedStation.statId) {
                selected = true;
            }

            let imageSrc = "";
            if (selected) {
                imageSrc = station.busiId === CV ? chaeviYellow : station.busiId === ME ? meYellow : skYellow;
            } else {
                const recommend = recommendStation?.find((recommend) => recommend.statId === station.statId);
                if (recommend) {
                    imageSrc = station.busiId === CV ? chaeviRecommend : station.busiId === ME ? meRecommend : skRecommend;
                } else {
                    imageSrc = station.busiId === CV ? chaeviBlue : station.busiId === ME ? meBlue : skBlue;
                }
            }
            return (
                <MapMarker
                    key={station.statId + "_" + station.chgerId}
                    position={{
                        lat: station.lat,
                        lng: station.lng,
                    }}
                    image={{
                        src: imageSrc,
                        size: {
                            width: 24,
                            height: 30,
                        },
                    }}
                    onClick={(marker: kakao.maps.Marker) => {
                        if (onClickEvStation !== undefined) onClickEvStation(station);
                    }}
                />
            );
        });
    };

    return (
        <>
            <Map
                center={mapCenter || { lat: 35.85810060700929, lng: 128.55729938820272 }}
                onCreate={(map) => handleMap(map)}
                style={mapStyle}
                level={level}
                isPanto={true}
                onZoomChanged={(map) => onZoomChanged(map)}
                onCenterChanged={(map) => onCenterChanged(map)}
            >
                <MapTypeControl position={kakao.maps.ControlPosition.TOPRIGHT} />
                {myPosition && displayMyPostion()}
                {evcs && displayEvStations()}
            </Map>
        </>
    );
}

export default KakaoMapEVStation;
