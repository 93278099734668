import * as React from "react";

import NoImage from "../../assets/images/home/rent/option/icon_no_image.png";
import IconTV from "../../assets/images/home/rent/option/icon_option_tv.png";
import IconMicroWafe from "../../assets/images/home/rent/option/icon_option_microwave.png";
import Iconrefrigeator from "../../assets/images/home/rent/option/icon_option_refrigerator.png";
import IconAirConditioner from "../../assets/images/home/rent/option/icon_option_air_conditioner.png";
import IconSinkBall from "../../assets/images/home/rent/option/icon_option_sink_ball.png";
import IconPan from "../../assets/images/home/rent/option/icon_option_pan.png";
import IconWater from "../../assets/images/home/rent/option/icon_option_water.png";
import IconFreshBottle from "../../assets/images/home/rent/option/icon_option_fresh_bottle.png";
import IconSewageBottle from "../../assets/images/home/rent/option/icon_option_sewage_bottle.png";
import IconScreen from "../../assets/images/home/rent/option/icon_option_screen.png";
import IconFreezer from "../../assets/images/home/rent/option/icon_option_freezer.png";
import IconHeater from "../../assets/images/home/rent/option/icon_option_heater.png";

import * as TypeDTO from "../../commons/typeDTO";
import { useDraggable } from "../../utils/useDraggable";

function CarOption({ options }: { options?: Array<TypeDTO.RentOptionDto> }) {
    const containerRef = React.useRef<HTMLDivElement>(null);
    const events = useDraggable(containerRef);
    const getOptionImage = (name: string) => {
        switch (name) {
            case "TV":
                return IconTV;
            case "전자렌지":
                return IconMicroWafe;
            case "냉장고":
                return Iconrefrigeator;
            case "에어컨":
                return IconAirConditioner;
            case "싱크대":
                return IconSinkBall;
            case "환기팬":
                return IconPan;
            case "온수기":
                return IconWater;
            case "청수통":
                return IconFreshBottle;
            case "오수통":
                return IconSewageBottle;
            case "방충망":
                return IconScreen;
            case "냉동고":
                return IconFreezer;
            case "무시동 히터":
                return IconHeater;
            default:
                return NoImage;
        }
    };

    return (
        <div
            ref={containerRef}
            className="mt-3 w-100 option-list"
            style={{ height: "auto", minHeight: "75px", overflowX: "auto", whiteSpace: "nowrap", margin: "0 auto" }}
            {...events}
        >
            {options?.map((item) => (
                <div key={item.rentOptionId} style={{ textAlign: "center", display: "inline-block", marginRight: "15px" }}>
                    <div className="mb-2 text-center" style={{ width: "40px", height: "40px", border: "2px solid #1C375A29", margin: "auto" }}>
                        <img src={getOptionImage(item.rentOptionName)} className="w-100 p-2" />
                    </div>
                    <p className="b-xs" style={{ color: "#1B2B41B0" }}>
                        {item.rentOptionName.replace(" ", "")}
                    </p>
                </div>
            ))}
        </div>
    );
}

export default CarOption;
