import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useAuthDispatch } from "./provider/AuthProvider";
import { useAsyncAxios, utilAxiosWithAppToken } from "./utils/customAxios";
import { ConfigProvider } from "antd";
import { createBrowserHistory } from "history";

import AlertPopup from "./component/AlertPopup";
import Ruta40Router from "./pages/ruta40/Ruta40Router";
import RentRouter from "./pages/rent/RentRouter";
import axios from "axios";
import locale from "antd/lib/locale/ko_KR";

import * as Request from "./commons/request";
import * as TypeDTO from "./commons/typeDTO";
import * as Common from "./commons/common";
import * as Utils from "./utils/utils";

import "./App.css";
import moment from "moment";
import { Helmet } from "react-helmet-async";

declare const window: typeof globalThis & {
    IMP: any;
};

function App() {
    const dispatch = useAuthDispatch();
    const location = useLocation();
    const history = createBrowserHistory();
    const [userIP, setUserIP] = useState();
    const [dateTime, setDateTime] = useState("");

    const { IMP } = window;
    IMP.init("imp93949381");

    if (
        (process.env.REACT_APP_RUTA40_PROD_URI !== undefined && window.location.hostname.includes(process.env.REACT_APP_RUTA40_PROD_URI)) ||
        (process.env.REACT_APP_RENT_PROD_URI !== undefined && window.location.hostname.includes(process.env.REACT_APP_RENT_PROD_URI))
    ) {
        console.log = () => {};
    }

    useEffect(() => {
        axios.get("https://ipinfo.io/").then((res) => {
            setUserIP(res.data.ip);
        });
    }, []);

    useEffect(() => {
        const visitedTime = new Date(window.performance.timeOrigin);
        setDateTime(visitedTime.toISOString().substring(0, 10) + " " + visitedTime.toISOString().substring(11, 19));

        if (userIP !== undefined) {
            if (Boolean(process.env.REACT_APP_PROD_TEST_MODE) === true || Boolean(process.env.REACT_APP_RENT_PROD_TEST_MODE) === true) {
                sessionStorage.setItem(Common.SESSION_USER_IP, userIP);
            }
            postVisitorSave();
        }
    }, [userIP]);

    const requestVisitorSave = async (url: string, data: TypeDTO.VisitorDto) => {
        const response = await utilAxiosWithAppToken().post(url, data);
        return response.data;
    };

    const { loading: loadingVisitorSave, error: errorVisitorSave, data: resultVisitorSave, execute: visitorSave } = useAsyncAxios(requestVisitorSave);

    useEffect(() => {
        if (!resultVisitorSave) return;

        console.log("resultVisitorSave", resultVisitorSave);
    }, [resultVisitorSave]);

    useEffect(() => {
        if (!errorVisitorSave) return;
    }, [errorVisitorSave]);

    const postVisitorSave = async () => {
        await visitorSave(
            Utils.checkDomain() === Common.Domain.RUTA40 ? Request.VISITOR_URL : Request.RENT_VISITOR_URL,
            JSON.stringify({ visitorIp: userIP, referer: document.referrer, userAgent: window.navigator.userAgent, visitedTime: dateTime })
        );
    };

    useEffect(() => {
        requestTime();

        const tabsOpen: string | null = localStorage.getItem(Common.CONTEXT_TABOPEN);

        if (tabsOpen === null) {
            localStorage.setItem(Common.CONTEXT_TABOPEN, "1");
        } else {
            localStorage.setItem(Common.CONTEXT_TABOPEN, (parseInt(tabsOpen) + 1).toString());
        }

        window.onunload = () => {
            const newTabCount: string | null = localStorage.getItem(Common.CONTEXT_TABOPEN);

            if (newTabCount === null || parseInt(newTabCount) === 1) {
                if (dispatch !== null) dispatch({ type: "LOGOUT" });
                localStorage.removeItem(Common.CONTEXT_TABOPEN);
            } else {
                localStorage.setItem(Common.CONTEXT_TABOPEN, (parseInt(newTabCount) - 1).toString());
            }
        };
        setScreenSize();
    }, []);

    const requestGetTime = async (url: string, data: TypeDTO.VisitorDto) => {
        const response = await utilAxiosWithAppToken().get(Request.TIME_URL);
        return response.data;
    };

    const { loading: loadingGetTime, error: errorGetTime, data: resultGetTime, execute: requestTime } = useAsyncAxios(requestGetTime);

    useEffect(() => {
        if (resultGetTime === null) {
            return;
        }
        const time = resultGetTime.time;
        sessionStorage.setItem(
            Common.CONTEXT_LOCAL_TIME_GAP,
            String(Math.round(moment.duration(moment.utc(time).local().diff(moment())).asSeconds()))
        );
    }, [resultGetTime]);

    useEffect(() => {
        if (errorGetTime === null) {
            return;
        }
        console.log("errorGetTime", errorGetTime);
    }, [errorGetTime]);

    window.onresize = () => {
        setScreenSize();
    };

    window.addEventListener("storage", (e) => {
        if (e.key?.includes(Common.CONTEXT_AUTH)) window.location.reload();
    });

    useEffect(() => {
        const historyEvent = history.listen((location) => {});
        sessionStorage.setItem(Common.SESSION_BEFORE_LOGIN_PATH, location.pathname);
        return historyEvent;
    }, [location]);

    function setScreenSize() {
        const vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty("--vh", `${vh}px`);
    }

    return (
        <ConfigProvider locale={locale}>
            <AlertPopup />
            {Utils.checkDomain() === Common.Domain.RUTA40 ? (
                <>
                    <Helmet>
                        <title>RUTA40</title>
                        <meta name="description" content="전기차 캠핑카 RUTA40(루타40), 사이클 카트, 냉장차, 드론 스테이션 등 판매사이트" />
                        <meta property="og:title" content="RUTA40" />
                        <meta property="og:description" content="전기차 캠핑카 RUTA40(루타40), 사이클 카트, 냉장차, 드론 스테이션 등 판매사이트" />
                    </Helmet>
                    <Ruta40Router />
                </>
            ) : (
                <>
                    <Helmet>
                        <title>RUTA40 렌트</title>
                        <meta name="description" content="전기차 캠핑카 루타40, 캠핑카 렌트 대여 | 행복하게 여행하려면 가볍게 떠나야 한다!" />
                        <meta property="og:title" content="RUTA40 렌트" />
                        <meta property="og:description" content="전기차 캠핑카 루타40, 캠핑카 렌트 대여 | 행복하게 여행하려면 가볍게 떠나야 한다!" />
                    </Helmet>
                    <RentRouter />
                </>
            )}
        </ConfigProvider>
    );
}

export default App;
