import React, { useEffect, useState } from "react";
import { BrowserView, MobileView } from "react-device-detect";
import { Col, Skeleton, Table, Tag } from "antd";
import { useAsyncAxios, utilAxiosWithAppToken } from "../../utils/customAxios";
import { ColumnsType, TableProps } from "antd/lib/table";
import { PushpinFilled } from "@ant-design/icons";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import { SorterResult } from "antd/lib/table/interface";

import * as Request from "../../commons/request";
import * as TypeDTO from "../../commons/typeDTO";
import * as Common from "../../commons/common";

import Banner from "../../assets/images/home/ruta40/banner_customer.jpg";

type NoticeType = {
    fixedPosition: boolean;
    data: TypeDTO.NoticeDto;
};

function Notice() {
    const navigate = useNavigate();
    const isMobile = useMediaQuery({ maxWidth: 600 });
    const [notices, setNotices] = useState<Array<NoticeType>>();
    const [sortedInfo, setSortedInfo] = useState<SorterResult<NoticeType>>({});

    useEffect(() => {
        requestGetNotices();
    }, []);

    const requestAxiosGetNotices = async () => {
        const response = await utilAxiosWithAppToken().get(Request.NOTICES_URL);
        return response.data;
    };

    const {
        loading: loadingGetNotices,
        error: errorGetNotices,
        data: resultGetNotices,
        execute: requestGetNotices,
    } = useAsyncAxios(requestAxiosGetNotices);

    useEffect(() => {
        if (!resultGetNotices) return;
        console.log("resultGetNotices", resultGetNotices);

        const fixedNotice: Array<NoticeType> = resultGetNotices.notices
            .filter((notice: TypeDTO.NoticeDto) => {
                return notice.fixed === true;
            })
            .map((notice: TypeDTO.NoticeDto) => {
                return { fixedPosition: true, data: notice };
            });
        const unFixedNotice: Array<NoticeType> = resultGetNotices.notices.map((notice: TypeDTO.NoticeDto) => {
            return { fixedPosition: false, data: notice };
        });
        const totalNotices = [...fixedNotice, ...unFixedNotice];

        setNotices(totalNotices);
    }, [resultGetNotices]);

    useEffect(() => {
        if (!errorGetNotices) return;
        console.log("errorGetNotices", errorGetNotices);
    }, [errorGetNotices]);

    const columns: ColumnsType<NoticeType> = [
        {
            align: "center",
            title: "카테고리",
            dataIndex: "noticeType",
            key: "noticeType",
            width: isMobile ? "auto" : "15%",
            render: (_, { data }) => {
                return data.noticeType === "NOTICE"
                    ? "공지사항"
                    : data.noticeType === "EVENT"
                    ? "이벤트"
                    : data.noticeType === "EVENT_ANNOUNCEMENT"
                    ? "이벤트 발표"
                    : "-";
            },
        },
        {
            align: "left",
            title: "제목",
            dataIndex: "title",
            key: "title",
            render: (_, { fixedPosition, data }) => {
                return data.title !== undefined ? (
                    <span className="d-flex" style={{ alignItems: "center" }}>
                        {fixedPosition === true && <PushpinFilled style={{ marginRight: 8 }} color="#001236" />}
                        {data.noticeStatus === "EVENT_IN_PROGRESS" && (
                            <Tag style={{ borderColor: "#006AF5", color: "#006AF5", backgroundColor: "white" }}>진행</Tag>
                        )}
                        {data.noticeStatus === "EVENT_FINISHED" && (
                            <Tag style={{ borderColor: "#77828F", color: "#77828F", backgroundColor: "white" }}>종료</Tag>
                        )}
                        {data.title}
                    </span>
                ) : (
                    "-"
                );
            },
        },
        {
            align: "center",
            title: "등록일",
            dataIndex: "registeredDate",
            key: "registeredDate",
            width: isMobile ? "auto" : "15%",
            render: (_, { data }) => {
                return data.registeredDate ? <span>{data.registeredDate.slice(0, 10)}</span> : "-";
            },
        },
    ];

    const onTableChange: TableProps<NoticeType>["onChange"] = (pagination, filters, sorter) => {
        setSortedInfo(sorter as SorterResult<NoticeType>);
    };

    const onRow = (value: NoticeType) => {
        navigate(Common.PAGE_NOTICE_DETAIL + "/" + value.data.noticeId);
    };

    return (
        <div style={{ minHeight: "calc(100vh - 70px)" }}>
            <div className="header-container model">
                <div
                    className="container fadeIn text-center mb-5"
                    style={{ backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.3),rgba(0, 0, 0, 0.3)),url(${Banner})` }}
                >
                    <h5 className="text-center text-light px-3 mb-4">공지/이벤트</h5>
                    <h1 className="text-light">NOTICE</h1>
                </div>
            </div>
            <div className="model-tabs title model-body" style={{ backgroundColor: "white" }}>
                <MobileView className="mobile">
                    <Col
                        sm={{ span: 24 }}
                        lg={{ span: 18, offset: 3 }}
                        xl={{ span: 18, offset: 3 }}
                        xxl={{ span: 16, offset: 4 }}
                        className="space-page fadeInUp pt-4"
                    >
                        {loadingGetNotices ? (
                            <Skeleton active={true} />
                        ) : (
                            <Table
                                className="table design-table mt-3"
                                rowKey={(render: NoticeType) =>
                                    render.fixedPosition === true ? render.data.noticeId + "_fixed" : render.data.noticeId
                                }
                                columns={columns}
                                dataSource={notices}
                                pagination={{
                                    position: ["bottomCenter"],
                                    pageSize: 30,
                                    showTotal: (total) => `총 ${total}개`,
                                    simple: isMobile ? true : false,
                                }}
                                onRow={(record, index) => {
                                    return {
                                        onClick: () => {
                                            onRow !== undefined && onRow(record);
                                        },
                                    };
                                }}
                                onChange={onTableChange}
                                rowClassName={(row) => {
                                    if (row.fixedPosition === true) {
                                        return "fixedRow";
                                    }
                                    return "";
                                }}
                            />
                        )}
                    </Col>
                </MobileView>
                <BrowserView className="browser">
                    <Col
                        xs={{ span: 24 }}
                        sm={{ span: 22, offset: 1 }}
                        lg={{ span: 18, offset: 3 }}
                        xl={{ span: 16, offset: 4 }}
                        xxl={{ span: 14, offset: 5 }}
                        className="fadeInUp p-4 pt-5"
                    >
                        {loadingGetNotices ? (
                            <Skeleton active={true} />
                        ) : (
                            <Table
                                className="table design-table"
                                rowKey={(render: NoticeType) =>
                                    render.fixedPosition === true ? render.data.noticeId + "_fixed" : render.data.noticeId
                                }
                                columns={columns}
                                dataSource={notices}
                                pagination={{
                                    position: ["bottomCenter"],
                                    pageSize: 30,
                                    showTotal: (total) => `총 ${total}개`,
                                    simple: isMobile ? true : false,
                                }}
                                onRow={(record, index) => {
                                    return {
                                        onClick: () => {
                                            onRow !== undefined && onRow(record);
                                        },
                                    };
                                }}
                                onChange={onTableChange}
                                rowClassName={(row) => {
                                    if (row.fixedPosition === true) {
                                        return "fixedRow";
                                    }
                                    return "pointerRow";
                                }}
                            />
                        )}
                    </Col>
                </BrowserView>
            </div>
        </div>
    );
}

export default Notice;
