import React from "react";

function ModelRuta40Specification() {
    return (
        <table className="table design-table">
            <thead>
                <tr>
                    <th scope="col">제원명</th>
                    <td>제원사양</td>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <th>치수(mm)</th>
                    <td>5,960×2,020×2,640 </td>
                </tr>
                <tr>
                    <th>최대 속도(km/h)</th>
                    <td>100</td>
                </tr>
                <tr>
                    <th>최대 주행거리(km)</th>
                    <td>303.68 (환경부공인 인증)</td>
                </tr>
                <tr>
                    <th>배터리(KW)</th>
                    <td>106(Li-Ion)</td>
                </tr>
                <tr>
                    <th>제동장치</th>
                    <td>ABS+EBD</td>
                </tr>
                <tr>
                    <th>스티어링</th>
                    <td>파워 스티어링</td>
                </tr>
                <tr>
                    <th>구동방식</th>
                    <td>후륜 구동 방식</td>
                </tr>
                <tr>
                    <th>충전 모드</th>
                    <td>Combo 1(CCS)</td>
                </tr>
                <tr>
                    <th>안전사양</th>
                    <td>LDWS, ESC, ADAS, AIR BAG</td>
                </tr>
            </tbody>
        </table>
    );
}

export default ModelRuta40Specification;
