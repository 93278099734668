import React, { useState, useEffect } from "react";
import { Row, Col, Button, Divider } from "antd";
import { BrowserView, MobileView } from "react-device-detect";
import { CalendarOutlined, EnvironmentOutlined } from "@ant-design/icons";
import { useAsyncAxios, utilAxiosWithAppToken } from "../../utils/customAxios";

import * as TypeDTO from "../../commons/typeDTO";
import * as Request from "../../commons/request";
import * as String from "../../commons/string";
import * as Common from "../../commons/common";
import * as TypeUtils from "../../commons/typeUtils";

import "moment/locale/ko";
import styles from "./Search.module.css";
import IconReset from "../../assets/images/home/rent/icon_reset.png";
import SelectRegion from "./SelectRegion";
import moment from "moment";
import MobileDrawer from "./MobileDrawer";
import CustomRangePicker from "./CustomRangePicker";

function Search({
    type,
    disabledDates,
    rentPeriodLimits,
    rentPeakSeasons,
    className,
    onChange,
}: {
    type?: string;
    disabledDates?: string[];
    rentPeriodLimits?: TypeDTO.RentPeriodLimitDto[];
    rentPeakSeasons?: TypeDTO.RentPeakSeasonDto[];
    className?: string;
    onChange?: (value: TypeUtils.SearchType) => void;
}) {
    const defaultData = { regionId: 0, period: undefined };

    const [selectedDatas, setSelectedDatas] = useState<TypeUtils.SearchType>(defaultData);
    const [region, setRegion] = useState<TypeDTO.RentRegionDto>();
    const [datas, setDatas] = useState<TypeUtils.SearchType>(defaultData);
    const [isOpen, setIsOpen] = useState<boolean>(false);

    const requestAxiosGetRegion = async (id: number) => {
        const response = await utilAxiosWithAppToken().get(Request.RENT_REGION_URL + id);
        return response.data;
    };

    const {
        loading: loadingGetRegion,
        error: errorGetRegion,
        data: resultGetRegion,
        execute: requestGetRegion,
    } = useAsyncAxios(requestAxiosGetRegion);

    useEffect(() => {
        if (!resultGetRegion) return;
        console.log("resultGetRegion", resultGetRegion);

        setRegion(resultGetRegion.rentRegion);
    }, [resultGetRegion]);

    useEffect(() => {
        if (!errorGetRegion) return;
        console.log("errorGetRegion", errorGetRegion);
    }, [errorGetRegion]);

    const onClick = () => {
        selectedDatas.regionId && selectedDatas.regionId && requestGetRegion(selectedDatas.regionId);

        onChange && onChange(selectedDatas);
        setDatas(selectedDatas);
        setIsOpen(false);
    };

    const onChangeEvent = (type: string, value?: string | string[] | number | [string | null, string | null]) => {
        setSelectedDatas((preState: TypeUtils.SearchType) => ({
            ...preState,
            [type]: value,
        }));
    };

    useEffect(() => {
        const isMobile = /Mobile/i.test(navigator.userAgent);

        if (!isMobile) {
            onChange && onChange(selectedDatas);
        }
    }, [selectedDatas]);

    return (
        <>
            <MobileView className="mobile">
                <Row gutter={10} className={`${className}`}>
                    <Col span={JSON.stringify(defaultData) === JSON.stringify(datas) ? 24 : 20}>
                        <Button
                            className={`h-100 w-100 btn-light`}
                            style={{ paddingTop: 0, paddingBottom: 0 }}
                            autoFocus={false}
                            onClick={() => {
                                setIsOpen(true);
                                setSelectedDatas(datas);
                            }}
                        >
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <EnvironmentOutlined style={{ marginRight: "10px" }} />
                                    {<p>{datas.regionId === 0 ? "전체" : region?.rentRegionName}</p>}
                                </div>
                                <Divider type="vertical" />
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <CalendarOutlined style={{ marginRight: "10px" }} />
                                    {datas.period ? (
                                        <p>
                                            {(datas.period[0] ? moment(datas.period[0]).format("YY.MM.DD") : "날짜 선택") +
                                                " ~ " +
                                                (datas.period[1] ? moment(datas.period[1]).format("YY.MM.DD") : "날짜 선택")}
                                        </p>
                                    ) : (
                                        <p style={{ color: "#1C2E4599" }}>{String.rentalDate}</p>
                                    )}
                                </div>
                            </div>
                        </Button>
                    </Col>
                    {JSON.stringify(defaultData) !== JSON.stringify(datas) && (
                        <Col span={4}>
                            <Button
                                className="w-100 h-100 p-0 btn-light-border"
                                onClick={() => {
                                    onChange && onChange(defaultData);
                                    setSelectedDatas(defaultData);
                                    setDatas(defaultData);
                                }}
                            >
                                <img src={IconReset} width={14} height={14} />
                            </Button>
                        </Col>
                    )}
                </Row>
                <MobileDrawer
                    isOpen={isOpen}
                    onClose={() => setIsOpen(false)}
                    title="검색 조건"
                    content={
                        <div className="space-page">
                            <SelectRegion
                                className="mb-2"
                                value={selectedDatas.regionId}
                                isOpen={true}
                                onChange={(id) => onChangeEvent("regionId", id)}
                            />
                            <CustomRangePicker
                                value={
                                    selectedDatas.period
                                        ? [
                                              selectedDatas.period[0] ? new Date(selectedDatas.period[0]) : null,
                                              selectedDatas.period[1] ? new Date(selectedDatas.period[1]) : null,
                                          ]
                                        : [null, null]
                                }
                                onChange={(startDate, endDate) => {
                                    onChangeEvent("period", [
                                        startDate ? moment(startDate, Common.FORMAT_DATE).format(Common.FORMAT_DATE) : null,
                                        endDate ? moment(endDate, Common.FORMAT_DATE).format(Common.FORMAT_DATE) : null,
                                    ]);
                                }}
                                disabledDates={disabledDates}
                                rentPeriodLimits={rentPeriodLimits}
                                rentPeakSeasons={rentPeakSeasons}
                            />
                            <Button
                                type="primary"
                                className="btn-dark"
                                style={{
                                    position: "fixed",
                                    bottom: "14px",
                                    minWidth: "90%",
                                    left: "50%",
                                    transform: "translate(-50%,0)",
                                    zIndex: 3,
                                }}
                                onClick={() => onClick()}
                            >
                                {String.search}
                            </Button>
                        </div>
                    }
                />
            </MobileView>
            <BrowserView className="browser">
                <Row gutter={20} className={styles.body}>
                    <Col span={11}>
                        <SelectRegion value={selectedDatas.regionId} onChange={(id) => onChangeEvent("regionId", id)} />
                    </Col>
                    <Col span={11}>
                        <CustomRangePicker
                            value={
                                selectedDatas.period
                                    ? [
                                          selectedDatas.period[0] ? new Date(selectedDatas.period[0]) : null,
                                          selectedDatas.period[1] ? new Date(selectedDatas.period[1]) : null,
                                      ]
                                    : undefined
                            }
                            onChange={(startDate, endDate) => {
                                onChangeEvent("period", [
                                    startDate ? moment(startDate, Common.FORMAT_DATE).format(Common.FORMAT_DATE) : null,
                                    endDate ? moment(endDate, Common.FORMAT_DATE).format(Common.FORMAT_DATE) : null,
                                ]);
                            }}
                            disabledDates={disabledDates}
                            rentPeriodLimits={rentPeriodLimits}
                            rentPeakSeasons={rentPeakSeasons}
                        />
                    </Col>
                    <Col span={2}>
                        <Button
                            className={`p-0 h-100 btn-light-border`}
                            onClick={() => {
                                onChange && onChange(defaultData);
                                setSelectedDatas(defaultData);
                                setDatas(defaultData);
                            }}
                        >
                            <img src={IconReset} width={20} height={20} />
                        </Button>
                    </Col>
                </Row>
            </BrowserView>
        </>
    );
}

export default Search;
