import React from "react";
import Carousel from "react-bootstrap/Carousel";
import { Col, Button } from "antd";
import { useMediaQuery } from "react-responsive";

import digitalKeyImage1 from "../../assets/images/digitalKey/digitalKey1.jpg";
import digitalKeyImage2 from "../../assets/images/digitalKey/digitalKey2.jpg";
import digitalKeyImage3 from "../../assets/images/digitalKey/digitalKey3.jpg";
import digitalKeyImage4 from "../../assets/images/digitalKey/digitalKey4.jpg";
import digitalKeyImage5 from "../../assets/images/digitalKey/digitalKey5.jpg";
import digitalKeyImage6 from "../../assets/images/digitalKey/digitalKey6.jpg";
import digitalKeyImage7 from "../../assets/images/digitalKey/digitalKey7.jpg";
import digitalKeyImage8 from "../../assets/images/digitalKey/digitalKey8.jpg";

import Banner from "../../assets/images/home/ruta40/banner_key.jpg";

function DigitalKey() {
    const isMobile = useMediaQuery({ maxWidth: 700 });

    return (
        <>
            <div className="header-container model">
                <div
                    className="container"
                    style={{ backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.2),rgba(0, 0, 0, 0.2)),url(${Banner})`, backgroundPosition: "top" }}
                >
                    <div className="row justify-content-center">
                        <div className="fadeIn text-center mb-5">
                            <h5 className="text-center text-light px-3 mb-4">디지털 키 앱</h5>
                            <h1 className="text-light">DIGITAL KEY APP</h1>
                        </div>
                    </div>
                </div>
            </div>

            <div className="model-tabs title model-body pt-3" style={{ backgroundColor: "white" }}>
                <Col
                    xs={{ span: 24 }}
                    sm={{ span: 22, offset: 1 }}
                    lg={{ span: 18, offset: 3 }}
                    xl={{ span: 16, offset: 4 }}
                    xxl={{ span: 14, offset: 5 }}
                    className="fadeInUp p-4"
                >
                    <Col span={24}>
                        {isMobile ? (
                            <Carousel variant="light">
                                <Carousel.Item>
                                    <img className="d-block img-fluid" src={digitalKeyImage5} />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img className="d-block img-fluid" src={digitalKeyImage6} />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img className="d-block img-fluid" src={digitalKeyImage7} />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img className="d-block img-fluid" src={digitalKeyImage8} />
                                </Carousel.Item>
                            </Carousel>
                        ) : (
                            <Carousel variant="light">
                                <Carousel.Item>
                                    <img className="d-block img-fluid" src={digitalKeyImage1} />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img className="d-block img-fluid" src={digitalKeyImage2} />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img className="d-block img-fluid" src={digitalKeyImage3} />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img className="d-block img-fluid" src={digitalKeyImage4} />
                                </Carousel.Item>
                            </Carousel>
                        )}
                    </Col>
                </Col>
                <div className="fadeInUp text-center py-5">
                    <Button
                        className="btn-dark px-5"
                        size="large"
                        type="primary"
                        href="https://play.google.com/store/apps/details?id=com.alphamotors.ruta40"
                    >
                        앱 다운로드
                    </Button>
                </div>
            </div>
        </>
    );
}

export default DigitalKey;
