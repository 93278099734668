import React from "react";
import { Col } from "antd";

function PrivacyPolicy() {
    return (
        <>
            <Col sm={{ span: 24 }} lg={{ span: 18, offset: 3 }} xl={{ span: 18, offset: 3 }} xxl={{ span: 16, offset: 4 }} className="faq p-4">
                <section className="row legal">
                    <div className="my-3">
                        <h2 className="title">개인정보처리방침</h2>
                        <p>
                            &lt; 주식회사 루타스 &gt;(‘http://ruta40.co.kr/’이하 ‘RUTA40’)은(는) 「개인정보 보호법」 제30조에 따라 정보주체의
                            개인정보를 보호하고 이와 관련한 고충을 신속하고 원활하게 처리할 수 있도록 하기 위하여 다음과 같이 개인정보 처리방침을
                            수립·공개합니다.
                        </p>
                        <p>*이 개인정보처리방침은 2022년 4월 1일부터 적용됩니다.</p>
                    </div>
                    <section className="mb-4">
                        <h4>제 1 조 개인정보의 처리 목적</h4>
                        <p>
                            *&lt; 주식회사 루타스 &gt;(‘http://ruta40.co.kr/’이하 ‘RUTA40’)은(는) 다음의 목적을 위하여 개인정보를 처리합니다. 처리하고
                            있는 개인정보는 다음의 목적 이외의 용도로는 이용되지 않으며 이용 목적이 변경되는 경우에는 「개인정보 보호법」 제18조에
                            따라 별도의 동의를 받는 등 필요한 조치를 이행할 예정입니다.
                        </p>
                        <p>
                            ① [홈페이지 회원가입 및 관리] 회원 가입의사 확인, 회원제 서비스 제공에 따른 본인 식별·인증, 회원자격 유지·관리, 서비스
                            부정이용 방지, 만14세 미만 아동의 개인정보 처리 시 법정대리인의 동의여부 확인, 각종 고지·통지, 고충처리 목적으로
                            개인정보를 처리합니다.
                        </p>
                        <p>
                            ② [민원 사무 처리] 민원인의 신원 확인, 민원사항 확인, 사실조사를 위한 연락·통지, 처리결과 통보 목적으로 개인정보를
                            처리합니다.
                        </p>
                        <p>
                            ③ [제화 또는 서비스 제공] 물품배송, 서비스 제공, 계약서·청구서 발송, 콘텐츠 제공, 맞춤서비스 제공, 본인인증, 연령인증,
                            요금결제·정산, 채권추심을 목적으로 개인정보를 처리합니다.
                        </p>
                        <p>
                            ④ [마케팅 및 광고의 활용] 신규 서비스(제품) 개발 및 맞춤 서비스 제공, 이벤트 및 광고성 정보 제공 및 참여기회 제공 ,
                            인구통계학적 특성에 따른 서비스 제공 및 광고 게재 , 서비스의 유효성 확인, 접속빈도 파악 또는 회원의 서비스 이용에 대한
                            통계 등을 목적으로 개인정보를 처리합니다.
                        </p>
                        <p>
                            ⑤ [개인영상정보] 범죄의 예방 및 수사, 시설안전 및 화재예방, 교통단속, 교통정보의 수집·분석 및 제공 등을 목적으로
                            개인정보를 처리합니다.
                        </p>
                    </section>
                    <section className="mb-4">
                        <h4>제 2 조 개인정보의 처리 및 보유 기간</h4>
                        <p>
                            <strong>
                                ▸&lt; 주식회사 루타스 &gt;은(는) 법령에 따른 개인정보 보유 · 이용 기간 또는 정보 주체로부터 개인정보를 수집 시에 동의
                                받은 개인정보 보유·이용 기간 내에서 개인정보를 처리·보유합니다.
                            </strong>
                        </p>
                        <p>
                            <strong>▸각각의 개인정보 처리 및 보유 기간은 다음과 같습니다.</strong>
                        </p>
                        <p className="mb-0">① &lt;홈페이지 회원 가입 및 관리&gt;</p>
                        <ul className="mb-3">
                            <li>
                                &lt;홈페이지 회원 가입 및 관리&gt;와 관련한 개인정보는 수집·이용에 관한 동의일로부터 &lt;3년&gt;까지 위 이용 목적을
                                위하여 보유·이용됩니다.
                            </li>
                            <li>보유 근거 : 이용 약관</li>
                            <li>관련 법령 : (1)신용 정보의 수집/ 처리 및 이용 등에 관한 기록 – 3년 (2)계약 또는 청약 철회 등에 관한 기록 – 5년</li>
                            <li>예외 사유 :</li>
                        </ul>
                        <p className="mb-0">② &lt;민원 사무 처리&gt;</p>
                        <ul className="mb-3">
                            <li>
                                &lt;민원 사무 처리&gt;와 관련한 개인정보는 수집·이용에 관한 동의일로부터 &lt;3년&gt;까지 위 이용 목적을 위하여
                                보유·이용됩니다.
                            </li>
                            <li>보유 근거 : 이용 약관</li>
                            <li>관련 법령 : 소비자의 불만 또는 분쟁 처리에 관한 기록 -3년</li>
                            <li>예외 사유 :</li>
                        </ul>
                        <p className="mb-0">③ &lt;재화 또는 서비스 제공&gt;</p>
                        <ul className="mb-3">
                            <li>
                                &lt;재화 또는 서비스 제공&gt;과 관련한 개인정보는 수집·이용에 관한 동의일로부터 &lt;5년&gt;까지 위 이용 목적을 위하여
                                보유·이용됩니다.
                            </li>
                            <li>보유 근거 : 이용 약관</li>
                            <li>관련 법령 : 계약 또는 청약 철회 등에 관한 기록 -5년</li>
                            <li>예외 사유 :</li>
                        </ul>
                        <p className="mb-0">④ &lt;마케팅 및 광고의 활용&gt;</p>
                        <ul className="mb-3">
                            <li>
                                &lt;마케팅 및 광고의 활용&gt;과 관련한 개인정보는 수집·이용에 관한 동의일로부터 &lt;3년&gt;까지 위 이용 목적을 위하여
                                보유·이용됩니다.
                            </li>
                            <li>보유 근거 : 이용 약관</li>
                            <li>
                                관련 법령 : (1)신용 정보의 수집/처리 및 이용 등에 관한 기록 – 3년 (2)계약 또는 청약 철회 등에 관한 기록 – 5년
                                (3)표시/광고에 관한 기록 – 6개월 (4)소비자의 불만 또는 분쟁 처리에 관한 기록 – 3년
                            </li>
                            <li>예외 사유 :</li>
                        </ul>
                    </section>
                    <section className="mb-4">
                        <h4>제 3 조 개인정보처리 위탁</h4>
                        <p className="mb-0">
                            ① &lt; 주식회사 루타스 &gt;은(는) 원활한 개인정보 업무 처리를 위하여 다음과 같이 개인정보 처리 업무를 위탁하고 있습니다.
                        </p>
                        <ul className="mb-3">
                            <li>&lt;수집한 개인정보의 위탁&gt;</li>
                            <li>위탁받는 자(수탁자) : 나이스신용정보(주)</li>
                            <li>
                                위탁하는 업무의 내용 : 구매 및 요금 결제, 물품 배송 또는 청구서 등 발송, 본인 인증(금융거래, 금융 서비스), 요금 추심,
                                회원제 서비스 이용에 따른 본인 확인, 불만 처리 등 민원 처리, 고지 사항 전달, 신규 서비스(제품) 개발 및 맞춤 서비스
                                제공, 이벤트 및 광고성 정보 제공 및 참여 기회 제공, 영상정보처리기기 운영
                            </li>
                            <li>위탁기간 : 5년</li>
                        </ul>
                        <p>
                            ② &lt; 주식회사 루타스 &gt;은(는) 위탁 계약 체결 시 「개인정보 보호법」 제26조에 따라 위탁 업무 수행 목적 외 개인정보 처리
                            금지, 기술적 관리적 보호조치, 재위탁 제한, 수탁자에 대한 관리․감독, 손해배상 등 책임에 관한 사항을 계약서 등 문서에
                            명시하고, 수탁자가 개인정보를 안전하게 처리 하는지를 감독하고 있습니다.
                        </p>
                    </section>
                    <section className="mb-4">
                        <h4>제 4 조 정보 주체와 법정대리인의 권리·의무 및 그 행사방법</h4>
                        <p>① 정보주체는 주식회사 루타스에 대해 언제든지 개인정보 열람·정정·삭제·처리정지 요구 등의 권리를 행사할 수 있습니다.</p>
                        <p>
                            ② 제1항에 따른 권리 행사는주식회사 루타스에 대해 「개인정보 보호법」 시행령 제41조제1항에 따라 서면, 전자우편,
                            모사전송(FAX) 등을 통하여 하실 수 있으며 주식회사 루타스은(는) 이에 대해 지체 없이 조치하겠습니다.
                        </p>
                        <p>
                            ③ 제1항에 따른 권리 행사는 정보주체의 법정대리인이나 위임을 받은 자 등 대리인을 통하여 하실 수 있습니다.이 경우 “개인정보
                            처리 방법에 관한 고시(제2020-7호)” 별지 제11호 서식에 따른 위임장을 제출하셔야 합니다.
                        </p>
                        <p>
                            ④ 개인정보 열람 및 처리정지 요구는 「개인정보 보호법」 제35조 제4항, 제37조 제2항에 의하여 정보주체의 권리가 제한 될 수
                            있습니다.
                        </p>
                        <p>
                            ⑤ 개인정보의 정정 및 삭제 요구는 다른 법령에서 그 개인정보가 수집 대상으로 명시되어 있는 경우에는 그 삭제를 요구할 수
                            없습니다.
                        </p>
                        <p>
                            ⑥ 주식회사 루타스은(는) 정보주체 권리에 따른 열람의 요구, 정정·삭제의 요구, 처리정지의 요구 시 열람 등 요구를 한 자가
                            본인이거나 정당한 대리인인지를 확인합니다.
                        </p>
                    </section>
                    <section className="mb-4">
                        <h4>제 5 조 처리하는 개인정보의 항목 작성</h4>
                        <p>
                            <strong>▸&lt; 주식회사 루타스 &gt;은(는) 다음의 개인정보 항목을 처리하고 있습니다.</strong>
                        </p>
                        <p className="mb-0">&lt;홈페이지 회원 가입 및 관리&gt;</p>
                        <ul className="mb-3">
                            <li>필수 항목 : 이메일, 휴대전화 번호, 비밀번호 질문과 답, 비밀번호, 로그인 ID, 성별, 생년월일, 이름</li>
                            <li>선택 항목 : 회사명, 직업</li>
                        </ul>
                    </section>
                    <section className="mb-4">
                        <h4>제 6 조 개인정보의 파기</h4>
                        <p>
                            <strong>
                                ▸&lt; 주식회사 루타스 &gt; 은(는) 개인정보 보유 기간의 경과, 처리 목적 달성 등 개인정보가 불필요하게 되었을 때에는
                                지체 없이 해당 개인정보를 파기합니다.
                            </strong>
                        </p>
                        <p>
                            <strong>
                                ▸정보 주체로부터 동의 받은 개인정보 보유 기간이 경과하거나 처리 목적이 달성 되었음에도 불구하고 다른 법령에 따라
                                개인정보를 계속 보존하여야 하는 경우에는, 해당 개인정보를 별도의 데이터베이스(DB)로 옮기거나 보관 장소를 달리하여
                                보존합니다.
                            </strong>
                        </p>
                        <ul className="mb-3">
                            <li>법령 근거 :</li>
                            <li>보존하는 개인 정보 항목 : 계좌정보, 거래날짜</li>
                        </ul>
                        <p>
                            <strong>▸개인정보 파기의 절차 및 방법은 다음과 같습니다</strong>
                        </p>
                        <ul className="mb-3">
                            <li>
                                파기 절차 : &lt; 주식회사 루타스 &gt; 은(는) 파기 사유가 발생한 개인정보를 선정하고, &lt;주식회사 루타스 &gt;의
                                개인정보 보호 책임자의 승인을 받아 개인정보를 파기합니다.
                            </li>
                            <li>
                                파기 방법 : 전자적 파일 형태의 정보는 기록을 재생 할 수 없는 기술적 방법을 사용합니다. 종이에 출력 된 개인정보는
                                분쇄기로 분쇄 하거나 소각을 통하여 파기합니다.
                            </li>
                        </ul>
                    </section>
                    <section className="mb-4">
                        <h4>제 7 조 개인정보의 안정성 확보 조치</h4>
                        <p>
                            <strong>▸&lt; 주식회사 루타스 &gt;은(는) 개인정보의 안전성 확보를 위해 다음과 같은 조치를 취하고 있습니다.</strong>
                        </p>
                        <p>① 정기적인 자체 감사 실시 : 개인정보 취급 관련 안정성 확보를 위해 정기적(분기 1회)으로 자체 감사를 실시하고 있습니다.</p>
                        <p>
                            ② 개인정보 취급 직원의 최소화 및 교육 : 개인정보를 취급하는 직원을 지정하고 담당자에 한정 시켜 최소화 하여 개인정보를
                            관리하는 대책을 시행하고 있습니다.
                        </p>
                        <p>③ 내부 관리 계획의 수립 및 시행 : 개인정보의 안전한 처리를 위하여 내부 관리 계획을 수립하고 시행하고 있습니다.</p>
                        <p>
                            ④ 해킹 등에 대비한 기술적 대책 : &lt; 주식회사 루타스 &gt;(‘RUTA40’) 해킹이나 컴퓨터 바이러스 등에 의한 개인정보 유출 및
                            훼손을 막기 위하여 보안 프로그램을 설치하고 주기적인 갱신·점검을 하며 외부로부터 접근이 통제된 구역에 시스템을 설치하고
                            기술적/물리적으로 감시 및 차단하고 있습니다.
                        </p>
                        <p>
                            ⑤ 개인정보의 암호화 : 이용자의 개인정보는 비밀번호가 암호화 되어 저장 및 관리되고 있어, 본인만이 알 수 있으며 중요한
                            데이터는 파일 및 전송 데이터를 암호화 하거나 파일 잠금 기능을 사용하는 등의 별도 보안 기능을 사용하고 있습니다.
                        </p>
                        <p>
                            ⑥ 접속 기록의 보관 및 위변조 방지 : 개인정보처리시스템에 접속한 기록을 최소 1년 이상 보관, 관리하고 있으며, 다만, 5만명
                            이상의 정보 주체에 관하여 개인정보를 추가하거나, 고유 식별 정보 또는 민감 정보를 처리하는 경우에는 2년 이상 보관, 관리하고
                            있습니다.
                        </p>
                        <p>
                            ⑦ 개인정보에 대한 접근 제한 : 개인정보를 처리하는 데이터베이스 시스템에 대한 접근 권한의 부여, 변경, 말소를 통하여
                            개인정보에 대한 접근 통제를 위하여 필요한 조치를 하고 있으며 침입 차단 시스템을 이용하여 외부로부터의 무단 접근을 통제하고
                            있습니다.
                        </p>
                        <p>
                            ⑧ 문서 보안을 위한 잠금 장치 사용 : 개인정보가 포함된 서류, 보조저장매체 등을 잠금 장치가 있는 안전한 장소에 보관하고
                            있습니다.
                        </p>
                        <p>
                            ⑨ 비인가자에 대한 출입 통제 : 개인정보를 보관하고 있는 물리적 보관 장소를 별도로 두고 이에 대해 출입 통제 절차를 수립,
                            운영하고 있습니다.
                        </p>
                    </section>
                    <section className="mb-4">
                        <h4>제 8 조 개인정보 자동 수집 장치의 설치·운영 및 거부에 관한 사항</h4>
                        <p>
                            <strong>
                                ▸주식회사 루타스 은(는) 이용자에게 개별적인 맞춤 서비스를 제공하기 위해 이용 정보를 저장하고 수시로 불러오는
                                ‘쿠키(cookie)’를 사용합니다.
                            </strong>
                        </p>
                        <p>
                            <strong>
                                ▸쿠키는 웹사이트를 운영하는데 이용되는 서버(http)가 이용자의 컴퓨터 브라우저에게 보내는 소량의 정보이며 이용자들의 PC
                                컴퓨터내의 하드디스크에 저장되기도 합니다.
                            </strong>
                        </p>
                        <ul className="mb-3">
                            <li>
                                쿠키의 사용 목적 : 이용자가 방문한 각 서비스와 웹 사이트들에 대한 방문 및 이용 형태, 인기 검색어, 보안 접속 여부, 등을
                                파악하여 이용자에게 최적화된 정보 제공을 위해 사용됩니다.
                            </li>
                            <li>
                                쿠키의 설치·운영 및 거부 : 웹브라우저 상단의 도구&gt;인터넷 옵션&gt;개인정보 메뉴의 옵션 설정을 통해 쿠키 저장을 거부
                                할 수 있습니다.
                            </li>
                            <li>쿠키 저장을 거부할 경우 맞춤형 서비스 이용에 어려움이 발생할 수 있습니다.</li>
                        </ul>
                    </section>
                    <section className="mb-4">
                        <h4>제 9 조 개인정보 보호책임자</h4>
                        <p>
                            <strong>
                                ▸주식회사 루타스 은(는) 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한 정보 주체의 불만 처리 및
                                피해 구제 등을 위하여 아래와 같이 개인정보 보호책임자를 지정하고 있습니다.
                            </strong>
                        </p>
                        <ul className="mb-3">
                            <li>개인정보 보호 책임자</li>
                            <li>성명 : 정욱수</li>
                            <li>직책 : 이사</li>
                            <li>직급 : 이사</li>
                            <li>
                                연락처 : 070-7707-1768 (평일 09:00~17:00, 휴게시간 12:00~13:00) | 010-2680-0510 (문자 전용) /
                                support@rutas.co.kr
                            </li>
                        </ul>
                        <p>*개인정보 보호 담당 부서로 연결 됩니다.</p>
                        <p>
                            <strong>
                                ▸정보주체께서는 주식회사 루타스 의 서비스(또는 사업)을 이용하시면서 발생한 모든 개인정보 보호 관련 문의, 불만처리,
                                피해구제 등에 관한 사항을 개인정보 보호책임자 및 담당부서로 문의하실 수 있습니다. 주식회사 루타스 은(는) 정보주체의
                                문의에 대해 지체 없이 답변 및 처리해드릴 것입니다.
                            </strong>
                        </p>
                    </section>
                    <section className="mb-4">
                        <h4>제 10 조 개인정보 열람청구</h4>
                        <p>
                            <strong>▸정보주체는 ｢개인정보 보호법｣ 제35조에 따른 개인정보의 열람 청구를 아래의 부서에 할 수 있습니다.</strong>
                        </p>
                        <p>
                            <strong>▸&lt; 주식회사 루타스 &gt;은(는) 정보주체의 개인정보 열람청구가 신속하게 처리되도록 노력하겠습니다. </strong>
                        </p>
                        <ul className="mb-3">
                            <li>개인정보 열람청구 접수·처리 부서</li>
                            <li>부서명 : 경영관리팀</li>
                            <li>담당자 : 정욱수</li>
                            <li>
                                연락처 : 070-7707-1768 (평일 09:00~17:00, 휴게시간 12:00~13:00) | 010-2680-0510 (문자 전용) /
                                support@rutas.co.kr
                            </li>
                        </ul>
                    </section>
                    <section className="mb-4">
                        <h4>제 11 조 권익침해 구제방법</h4>
                        <p>
                            <strong>
                                ▸정보주체는 개인정보침해로 인한 구제를 받기 위하여 개인정보분쟁조정위원회, 한국인터넷진흥원 개인정보침해신고센터 등에
                                분쟁해결이나 상담 등을 신청할 수 있습니다. 이 밖에 기타 개인정보침해의 신고, 상담에 대하여는 아래의 기관에 문의하시기
                                바랍니다.
                            </strong>
                        </p>
                        <ul className="mb-3">
                            <li>개인정보분쟁조정위원회 : (국번없이) 1833-6972 (www.kopico.go.kr)</li>
                            <li>개인정보침해신고센터 : (국번없이) 118 (privacy.kisa.or.kr)</li>
                            <li>대검찰청 : (국번없이) 1301 (www.spo.go.kr)</li>
                            <li>경찰청 : (국번없이) 182 (ecrm.cyber.go.kr)</li>
                        </ul>
                        <p>
                            「개인정보보호법」제35조(개인정보의 열람), 제36조(개인정보의 정정·삭제), 제37조(개인정보의 처리정지 등)의 규정에 의한
                            요구에 대 하여 공공기관의 장이 행한 처분 또는 부작위로 인하여 권리 또는 이익의 침해를 받은 자는 행정심판법이 정하는 바에
                            따라 행정심판을 청구할 수 있습니다.
                        </p>
                        <p>※ 행정심판에 대해 자세한 사항은 중앙행정심판위원회(www.simpan.go.kr) 홈페이지를 참고하시기 바랍니다.</p>
                    </section>
                    <section className="mb-4">
                        <h4>제 12 조 개인정보 처리방침 변경</h4>
                        <p>
                            <strong>▸이 개인정보처리방침은 2022년 4월 1일부터 적용됩니다.</strong>
                        </p>
                    </section>
                </section>
            </Col>
        </>
    );
}

export default PrivacyPolicy;
