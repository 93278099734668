import * as React from "react";
import { useMediaQuery } from "react-responsive";

import * as String from "../../commons/string";

import BgTableHeader from "../../assets/images/home/rent/bg_table_header.png";

type PositionType = {
    x?: string;
    y?: string;
};

type OptionType = {
    position?: Array<PositionType>;
    content: {
        tooltip?: string;
        title: string;
        description: string[];
    };
    image?: string;
};

const OptionTable = ({ options, hasNo, hasDesign }: { options: Array<OptionType>; hasNo?: boolean; hasDesign?: boolean }) => {
    const isMobile = useMediaQuery({ maxWidth: 700 });

    return (
        <div className="col-lg-12 text-center optionBox">
            <div className="col-md-12">
                <table className="table design-table option">
                    <thead style={hasDesign ? { backgroundImage: `url(${BgTableHeader})`, backgroundSize: "cover", border: "none" } : undefined}>
                        <tr>
                            {hasNo && <th style={{ width: "10%" }}>번호</th>}
                            <td>옵션명</td>
                            <td>옵션사양</td>
                        </tr>
                    </thead>
                    <tbody>
                        {options.map((option, index) => (
                            <tr key={index}>
                                {hasNo && <th style={{ width: "10%" }}>{index + 1}</th>}
                                <td>{option.content.title}</td>
                                <td>
                                    {option.content.description.length === 0 ? (
                                        isMobile ? (
                                            <p style={{ textAlign: "center" }}>{String.dash}</p>
                                        ) : (
                                            <p>{String.dash}</p>
                                        )
                                    ) : isMobile ? (
                                        <ul style={{ margin: 0 }}>
                                            {option.content.description.map((description) => (
                                                <li style={{ textAlign: "left" }} key={description}>
                                                    {description}
                                                </li>
                                            ))}
                                        </ul>
                                    ) : (
                                        option.content.description.map((description) => description + "\n")
                                    )}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default OptionTable;
