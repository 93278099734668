import React, { useEffect, useState } from "react";
import { Button, Modal } from "antd";

import * as Utils from "../../utils/utils";
import * as String from "../../commons/string";

import subBatteryLow from "../../assets/images/digitalKeyController/batteryLow.svg";

type DisplaySubBattery = {
    title: string;
    body: string;
};

function ModalSubBatteryState({
    subBatteryState,
    openModalSubBatteryState,
    onChangedOpenModalSubBatteryState,
}: {
    subBatteryState?: string;
    openModalSubBatteryState: boolean;
    onChangedOpenModalSubBatteryState: (opened: boolean) => void;
}) {
    const [subBatteryInfo, setSubBatteryInfo] = useState<DisplaySubBattery>();

    useEffect(() => {
        if (!subBatteryState) return;

        if (subBatteryState.toUpperCase() === "LOW") {
            setSubBatteryInfo({
                title: "보조 배터리 전압이 낮습니다.",
                body: "배터리 방전 우려가 있습니다. 메인 전원을 OFF 하거나, 시동을 걸어주세요.",
            });
        } else if (subBatteryState.toUpperCase() === "VERY_LOW") {
            setSubBatteryInfo({
                title: "보조 배터리 전압이 매우 낮습니다.",
                body: "즉시 시동을 걸어주시고,\n 10분 이상 시동 상태를 유지해 주시기 바랍니다.",
            });
        }
    }, [subBatteryState]);

    useEffect(() => {
        openModalSubBatteryState ? Utils.addModalStack() : Utils.popModalStack();
    }, [openModalSubBatteryState]);

    return subBatteryInfo ? (
        <Modal
            key="modalBatteryState"
            className="confirmModal"
            open={openModalSubBatteryState}
            centered
            closable={false}
            onCancel={() => onChangedOpenModalSubBatteryState(false)}
            footer={[
                <Button block type="primary" key="back" onClick={() => onChangedOpenModalSubBatteryState(false)}>
                    {String.confirm}
                </Button>,
            ]}
        >
            <img src={subBatteryLow} alt="image of low battery" width="115px" />
            <div>
                <p className="title">{subBatteryInfo.title}</p>
                <span>{subBatteryInfo.body}</span>
            </div>
        </Modal>
    ) : null;
}

export default ModalSubBatteryState;
