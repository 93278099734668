import React, { useEffect, useState } from "react";
import { Col } from "antd";
import { useMediaQuery } from "react-responsive";
import { useAsyncAxios, utilAxiosWithAppToken } from "../../utils/customAxios";

import * as Request from "../../commons/request";
import * as TypeDTO from "../../commons/typeDTO";

import Accordion from "react-bootstrap/Accordion";
import informationsImage1 from "../../assets/images/information/info_1.png";
import informationsImage2 from "../../assets/images/information/info_2.png";
import informationsImage3 from "../../assets/images/information/info_3.png";
import informationsImage4 from "../../assets/images/information/info_4.png";
import styles from "./Information.module.css";
import Banner from "../../assets/images/home/ruta40/banner_customer.jpg";

function Informations() {
    const isMobile = useMediaQuery({ maxWidth: 700 });
    const [informationTitles, setInformationTitles] = useState<Array<TypeDTO.InformationTitleDto>>([]);

    useEffect(() => {
        requestGetInformations();
    }, []);

    const requestAxiosGetInformations = async () => {
        const response = await utilAxiosWithAppToken().get(Request.INFORMATION_TITLES_URL);
        return response.data;
    };

    const {
        loading: loadingGetInformations,
        error: errorGetInformations,
        data: resultGetInformations,
        execute: requestGetInformations,
    } = useAsyncAxios(requestAxiosGetInformations);

    useEffect(() => {
        if (!resultGetInformations) return;
        console.log("resultGetInformations", resultGetInformations);
        setInformationTitles(resultGetInformations.informationTitles);
    }, [resultGetInformations]);

    useEffect(() => {
        if (!errorGetInformations) return;
        console.log("errorGetInformations", errorGetInformations);
    }, [errorGetInformations]);

    const displayInformationTitle = () => {
        if (informationTitles.length > 0) {
            return (
                <>
                    {informationTitles.map((informationTitle) => (
                        <div className={styles.infoWrapper} key={informationTitle.informationTitleId}>
                            <div className={"text-center my-3"}>
                                <h3>{informationTitle.informationTitle}</h3>
                            </div>
                            <Accordion alwaysOpen defaultActiveKey={["0"]} className="support">
                                {informationTitle.informationSubtitles.map((informationSubtitle, index) => {
                                    return (
                                        <Accordion.Item
                                            key={informationSubtitle.informationSubtitleId}
                                            eventKey={String(index)}
                                            className="accordion-item"
                                        >
                                            <Accordion.Header>{informationSubtitle.informationSubtitle}</Accordion.Header>
                                            <Accordion.Body>
                                                <ul style={{ padding: 0 }}>
                                                    {informationSubtitle.informationContent.split("\n").map((line, index) => (
                                                        <li
                                                            style={{ whiteSpace: "pre-wrap", listStyle: "none" }}
                                                            key={index}
                                                            dangerouslySetInnerHTML={{
                                                                __html: line.replace(". -", `.\n -`),
                                                            }}
                                                        />
                                                    ))}
                                                </ul>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    );
                                })}
                            </Accordion>
                        </div>
                    ))}
                </>
            );
        }
        return null;
    };

    return (
        <>
            <div className="header-container model">
                <div
                    className="container fadeIn text-center mb-5"
                    style={{ backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.3),rgba(0, 0, 0, 0.3)),url(${Banner})` }}
                >
                    <h5 className="text-center text-light px-3 mb-4">주요 안내</h5>
                    <h1 className="text-light">INFORMATION</h1>
                </div>
            </div>
            <div className="model-tabs title model-body" style={{ backgroundColor: "white" }}>
                <Col
                    xs={{ span: 24 }}
                    sm={{ span: 22, offset: 1 }}
                    lg={{ span: 18, offset: 3 }}
                    xl={{ span: 16, offset: 4 }}
                    xxl={{ span: 14, offset: 5 }}
                    className="fadeInUp p-4"
                >
                    <section className="row legal">
                        {informationTitles ? (
                            displayInformationTitle()
                        ) : (
                            <>
                                <div className="text-center my-3">
                                    <h3>차량 이용 안내</h3>
                                </div>
                                <Accordion alwaysOpen defaultActiveKey={["0"]} className="support">
                                    <Accordion.Item eventKey="0" className="accordion-item">
                                        <Accordion.Header>차량 이용 시 주의사항</Accordion.Header>
                                        <Accordion.Body>
                                            <div>
                                                <ul>
                                                    <li>차량 인도일로부터 3개월 이내에는 차량 개조를 하지 마세요.</li>
                                                    <li>
                                                        전기 계통과 관련된 사항을 수리 또는 변경할 경우 반드시 주식회사 루타스와 사전협의 하여 진행해
                                                        주세요.
                                                    </li>
                                                    <li>
                                                        차량 인도일 3개월 이내 임의 개조하거나 주식회사 루타스와 사전협의 없이 개조한 차량에 발생한
                                                        모든 문제와 비용은 주식회사 루타스에서 책임지지 않습니다.
                                                    </li>
                                                </ul>
                                            </div>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="1">
                                        <Accordion.Header>메인 배터리 주의사항</Accordion.Header>
                                        <Accordion.Body>
                                            <div>
                                                <ul>
                                                    <li>배터리 충전량은 40%~90% 이내로 유지해 주세요.</li>
                                                    <li>배터리 충전량이 20% 이하로 떨어지면 출력제한으로 인하여 주행 성능이 현저히 떨어집니다.</li>
                                                    <li>회생제동은 배터리 충전량이 90% 이하일 경우에만 동작됩니다.</li>
                                                </ul>
                                            </div>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="2">
                                        <Accordion.Header>보조 배터리 방전 주의사항</Accordion.Header>
                                        <Accordion.Body>
                                            <div>
                                                <ul>
                                                    <li>차량 정차 시 문이 제대로 닫혀있는지 확인해 주세요.</li>
                                                    <li>2일 이상 차량을 운행하지 않을 시 메인 전원을 OFF 해 주세요.</li>
                                                    <li>외부 전기를 끌어다 사용할 경우 운전석 아래 메인 전원을 OFF 해 주세요.</li>
                                                    <li>시거잭은 상시 전원이므로 사용하지 않을 경우 연결된 장치를 해제하여 주세요.</li>
                                                </ul>
                                            </div>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="3">
                                        <Accordion.Header>운행 시 주의사항</Accordion.Header>
                                        <Accordion.Body>
                                            <div>
                                                <ul>
                                                    <li>
                                                        기어 레버는 무조건 브레이크를 밟은 상태에서 변경을 해야 합니다.
                                                        <br />- 브레이크를 밟지 않고 기어 레버를 조작할 경우 기어 레버는 조작되어도 실제 기어는
                                                        변경되지 않습니다.
                                                        <br />- 브레이크를 밟지 않고 기어 레버를 조작했을 경우에는 브레이크에서 발을 떼고, 다시 밟으면
                                                        실제 기어 상태로 변경됩니다.
                                                    </li>
                                                    <li>
                                                        후진기어 상태에서는 악셀을 밟을 때만 차량이 움직입니다. (후진 최대 속도 20km/h)
                                                        <br />- 후진 기어를 넣은 상태에서 브레이크에서 밟은 발을 떼더라도 차량은 움직이지 않습니다.
                                                    </li>
                                                    <li>
                                                        경사진 곳에 차량 정차 후 출발할 때는 브레이크를 천천히 떼면 차량이 뒤로 밀리니, 브레이크를
                                                        빨리 떼고 악셀을 밟아서 출발하면 됩니다.
                                                    </li>
                                                    <li>
                                                        경사진 곳에 차량을 주차 시 반드시 비치되어 있는 고임목을 이용하여 차량이 미끄러지지 않게 해야
                                                        됩니다.
                                                        <br />- 고임목은 경사 방향의 앞쪽의 타이어에 밀착하여 앞/뒤 각각 설치를 해야 합니다.
                                                    </li>
                                                    <li>
                                                        차량의 무게로 인하여 브레이크가 밀리는 느낌이 다소 있을 수 있습니다.
                                                        <br />
                                                        운행 시 앞차와 안전거리를 충분히 확보하여 운행해 주세요.
                                                    </li>
                                                </ul>
                                            </div>
                                        </Accordion.Body>
                                    </Accordion.Item>

                                    <Accordion.Item eventKey="4">
                                        <Accordion.Header>전기 사용 관련 주의사항</Accordion.Header>
                                        <Accordion.Body>
                                            <div>
                                                <ul>
                                                    <li>전기 사용 전 모든 스위치는 OFF 상태로 만든 후 사용방법에 따라 작동해 주세요.</li>
                                                    <li>차량 운행 중에는 배터리 효율을 위하여 배선 차단기는 OFF 하시길 권장합니다.</li>
                                                    <li>외부 한전 전기 인입하여 사용 시 메인 전원을 OFF하고 사용해 주세요.</li>
                                                    <li>차량 외부 콘센트 사용 시 고용량 전기 장치는 사용하지 마세요. (10A 이하 장치 사용)</li>
                                                    <li>
                                                        냉장고 전원을 자주 껐다 켰다 할 경우 성에가 생겼다가 녹아 누수가 발생할 수 있습니다.
                                                        <br />
                                                        주행 중에는 되도록 냉장고를 꺼두시고, 주차하신 이후에 작동하시길 권장합니다.
                                                    </li>
                                                </ul>
                                            </div>
                                        </Accordion.Body>
                                    </Accordion.Item>

                                    <Accordion.Item eventKey="5">
                                        <Accordion.Header>공조 시스템 주의사항</Accordion.Header>
                                        <Accordion.Body>
                                            <div>
                                                <ul>
                                                    <li>
                                                        에어컨 동작 시 공조 디스플레이 상태 표시 영역에 “EP1”이 표시되는 경우 즉시 에어컨 작동을
                                                        꺼주시고, 차량 점검을 받으시기 바랍니다.
                                                    </li>
                                                    <li>
                                                        에어컨 사용 시 A/C 버튼을 반드시 켜고 사용해 주세요.
                                                        <br />
                                                        (캠핑카 사용 매뉴얼 “에어컨(운전석) 사용 방법” 참고)
                                                    </li>
                                                </ul>
                                            </div>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="6">
                                        <Accordion.Header>충전 시 주의사항</Accordion.Header>
                                        <Accordion.Body className="accordion-collapse">
                                            <div>
                                                <ul>
                                                    <li>
                                                        급속·완속 충전기 이용 중 이상 동작이 발생할 경우 그 즉시{" "}
                                                        <span className="text-danger">충전</span>을 <span className="text-danger">중지</span>해
                                                        주세요.
                                                        <br />
                                                        재차 시도할 경우 심각한 차량 오류가 발생할 수 있습니다
                                                    </li>
                                                    <li>
                                                        급속충전기를 이용하실 경우에는 환경부에서 설치한 대영채비와 시그넷 충전기만 사용해 주세요.
                                                        <br />
                                                        (대영채비나 시그넷의 경우도 환경부 발표 기준 평균 고장률 3-5% 입니다.)
                                                        <br />- 대영채비 충전기는 정면에 CHAEVI로 표시되어 있습니다.
                                                        <br />- 시그넷 충전기는 정면 또는 측면에 시그넷이브이 또는 SIGNET으로 표시되어 있습니다.
                                                    </li>
                                                    <div className="col-md-12 text-center">
                                                        <img className="info-img col-md-6 px-2" src={informationsImage1} />
                                                        <img className="info-img col-md-6 px-2" src={informationsImage2} />
                                                    </div>
                                                    <li>
                                                        중앙제어, KEPCO(클린일렉스 제조) 이용 시 에러 발생 제보가 다수 발생되고 있습니다.
                                                        <br />
                                                        현재 확인 중이며 검증&조치 후 알려드리겠습니다.
                                                    </li>
                                                    <div className="col-md-12 text-center">
                                                        <img className="info-img col-md-6 px-2" src={informationsImage3} />
                                                        <img className="info-img col-md-6 px-2" src={informationsImage4} />
                                                    </div>
                                                </ul>
                                            </div>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>

                                <div className={`mb-5 ${!isMobile && "mt-5"}`}>
                                    <div className="text-center my-3">
                                        <h3>겨울철 이용 안내</h3>
                                    </div>
                                    <Accordion alwaysOpen defaultActiveKey={["winter-0"]} className="support">
                                        <Accordion.Item eventKey="winter-0" className="accordion-item">
                                            <Accordion.Header>배터리 작동 온도에 따른 충전 주의사항</Accordion.Header>
                                            <Accordion.Body>
                                                <div className="mb-3">
                                                    <p className="mb-0">1. 배터리 정상 작동 온도</p>
                                                    <ul>
                                                        <li>충전 온도: 0~50℃</li>
                                                        <li>방전 온도: -10~55℃</li>
                                                    </ul>
                                                </div>
                                                <p className="mb-0">2. 충전 주의사항</p>
                                                <ul>
                                                    <li>
                                                        저온 환경(-20~0℃)에서 고속 충전 시 배터리에 일부 손상을 줄 수 있으며, 충전이 원활하지 않을 수
                                                        있습니다.
                                                    </li>
                                                    <li>배터리 손상 방지를 위해 배터리 온도가 0℃ 이하에서는 충전이 되지 않습니다.</li>
                                                    <li>
                                                        0℃ 이하일 경우 자동으로 배터리를 가열하며, 배터리 온도가 10℃가 되면 충전으로 전환됩니다.
                                                        <br />
                                                        따라서 배터리 온도가 10℃ 이하일 경우에는 충전 시간이 더 소요됩니다.
                                                    </li>
                                                    <li>배터리 손상 방지를 위해 영하의 날씨에서는 충전하기 전 예열 운전을 권장합니다.</li>
                                                </ul>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="winter-1">
                                            <Accordion.Header>동파 주의사항</Accordion.Header>
                                            <Accordion.Body>
                                                <div>
                                                    <p>
                                                        기온이 영하로 떨어질 경우 캠핑카 내 워터펌프, 온수기, 청수통 기타 배관이 동파될 위험이 있으니
                                                        방지를 위하여 각별히 주의해 주세요.
                                                    </p>
                                                    <ol type="1">
                                                        <li>청수통, 오수통을 모두 비워주세요.</li>
                                                        <li>워터펌프, 온수기 가동 후 샤워기, 싱크 수전을 이용하여 냉수/온수를 모두 비워주세요.</li>
                                                        <li>각 배관에 물이 남지 않도록 최대한 비워주세요.</li>
                                                        <li>
                                                            워터펌프, 온수기를 끈 후 샤워기와 싱크 수전 손잡이를 가운데로 둔 상태에서 열어놔주세요.
                                                        </li>
                                                    </ol>
                                                </div>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>
                                </div>
                            </>
                        )}
                    </section>
                </Col>
            </div>
        </>
    );
}

export default Informations;
