import { Space, Col, Image, Row, Button } from "antd";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import ScrollToTopOnMount from "../component/ScrollTopOnMount";

import cycleKartMain1 from "../assets/images/model/cyclekart/cycleKartMain1.jpg";
import cycleKartMain2 from "../assets/images/model/cyclekart/cycleKartMain2.jpg";
import cycleKartSpec1 from "../assets/images/model/cyclekart/cycleKartSpec.jpg";
import cycleKartExterior1 from "../assets/images/model/cyclekart/cycleKart-exterior-1.jpg";
import cycleKartExterior2 from "../assets/images/model/cyclekart/cycleKart-exterior-2.jpg";
import cycleKartExterior3 from "../assets/images/model/cyclekart/cycleKart-exterior-3.jpg";
import cycleKartExterior4 from "../assets/images/model/cyclekart/cycleKart-exterior-4.jpg";
import cycleKartExterior5 from "../assets/images/model/cyclekart/cycleKart-exterior-5.jpg";
import cycleKartExterior6 from "../assets/images/model/cyclekart/cycleKart-exterior-6.jpg";
import cycleKartExterior7 from "../assets/images/model/cyclekart/cycleKart-exterior-7.jpg";
import cycleKartExterior8 from "../assets/images/model/cyclekart/cycleKart-exterior-8.jpg";
import cycleKartExterior9 from "../assets/images/model/cyclekart/cycleKart-exterior-9.jpg";
import fmsImage1 from "../assets/images/model/cyclekart/cycleKartService1.jpg";
import fmsImage5 from "../assets/images/model/cyclekart/cycleKartService2.jpg";

import Banner from "../assets/images/home/ruta40/banner_cycle_kart.jpg";

export default function ModelCycleKart() {
    const isMobile = useMediaQuery({ maxWidth: 700 });
    const navigate = useNavigate();
    return (
        <div>
            <ScrollToTopOnMount />
            <div className="header-container model">
                <div
                    className="container"
                    style={{ backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.2),rgba(0, 0, 0, 0.2)),url(${Banner})`, backgroundPosition: "bottom" }}
                >
                    <div className="row justify-content-center">
                        <div className="fadeIn text-center mb-5">
                            <h5 className="text-center text-light px-3 mb-4">사이클 카트</h5>
                            <h1 className="text-light">CoCoKart</h1>
                        </div>
                    </div>
                </div>
            </div>
            <div className="model-tabs title model-body" style={{ backgroundColor: "white" }}>
                <Col
                    xs={{ span: 24 }}
                    sm={{ span: 22, offset: 1 }}
                    lg={{ span: 18, offset: 3 }}
                    xl={{ span: 16, offset: 4 }}
                    xxl={{ span: 14, offset: 5 }}
                    className="fadeInUp p-4 "
                >
                    <div className={`mb-5 mt-3`}>
                        {isMobile ? <img className="w-100" src={cycleKartMain2}></img> : <img className="w-100 img-fluid" src={cycleKartMain1} />}
                    </div>
                </Col>
                <Col
                    xs={{ span: 24 }}
                    sm={{ span: 22, offset: 1 }}
                    lg={{ span: 18, offset: 3 }}
                    xl={{ span: 16, offset: 4 }}
                    xxl={{ span: 14, offset: 5 }}
                    className="fadeInUp p-4"
                >
                    <div className="mb-5">
                        <div className="text-center mb-5 mt-3">
                            <h3>디자인</h3>
                        </div>

                        <Row className="row px-2">
                            <Col span={8} className="col-lg-4 px-1">
                                <Space direction="vertical" size={isMobile ? 4 : 8}>
                                    <Image src={cycleKartExterior1} className="w-100" />
                                    <Image src={cycleKartExterior4} className="w-100" />
                                    <Image src={cycleKartExterior7} className="w-100" />
                                </Space>
                            </Col>

                            <Col span={8} className="col-lg-4 px-1">
                                <Space direction="vertical" size={isMobile ? 4 : 8}>
                                    <Image src={cycleKartExterior2} className="w-100" />
                                    <Image src={cycleKartExterior8} className="w-100" />
                                    <Image src={cycleKartExterior6} className="w-100" />
                                </Space>
                            </Col>

                            <Col span={8} className="col-lg-4 px-1">
                                <Space direction="vertical" size={isMobile ? 4 : 8}>
                                    <Image src={cycleKartExterior3} className="w-100" />
                                    <Image src={cycleKartExterior9} className="w-100" />
                                    <Image src={cycleKartExterior5} className="w-100" />
                                </Space>
                            </Col>
                        </Row>
                    </div>
                </Col>
                <Col
                    xs={{ span: 24 }}
                    sm={{ span: 22, offset: 1 }}
                    lg={{ span: 18, offset: 3 }}
                    xl={{ span: 16, offset: 4 }}
                    xxl={{ span: 14, offset: 5 }}
                    className="fadeInUp p-4"
                >
                    <div className="fadeInUp mb-5">
                        <div className="text-center mb-5">
                            <h3>서비스</h3>
                        </div>
                        <div className="col-lg-12 text-center">
                            <h6 className="text-type">CoCoKart 관제·투어 서비스</h6>
                            <p style={{ wordBreak: "keep-all" }} className="b-md">
                                CoCoKart는 관제 서비스와 투어 운영 서비스를 패키지로 제공합니다.
                            </p>
                            {isMobile ? <img className="w-100" src={fmsImage5}></img> : <img className="w-100" src={fmsImage1}></img>}
                        </div>
                    </div>
                </Col>
                <Col
                    xs={{ span: 24 }}
                    sm={{ span: 22, offset: 1 }}
                    lg={{ span: 18, offset: 3 }}
                    xl={{ span: 16, offset: 4 }}
                    xxl={{ span: 14, offset: 5 }}
                    className="fadeInUp p-4"
                >
                    <div className="mb-5">
                        <div className="text-center mb-5">
                            <h3>제원 정보</h3>
                        </div>
                        <div className="col-lg-12 text-center optionBox pb-5">
                            <div className="col-md-12 py-3">
                                {isMobile ? (
                                    <img className="px-3 d-block w-100" alt="First slide" src={cycleKartSpec1} />
                                ) : (
                                    <img className="d-block img-fluid w-100" alt="First slide" src={cycleKartSpec1} />
                                )}
                            </div>
                            <div className="col-md-12">
                                <table className="table design-table">
                                    <thead>
                                        <tr>
                                            <th colSpan={2}>제원명</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th>치수 (mm)</th>
                                            <td>2498×1578×1606</td>
                                        </tr>
                                        <tr>
                                            <th>최대 속도 (km/h)</th>
                                            <td>60</td>
                                        </tr>
                                        <tr>
                                            <th>휠베이스 (mm)</th>
                                            <td>1,710</td>
                                        </tr>
                                        <tr>
                                            <th>최저지상고 (mm)</th>
                                            <td>180</td>
                                        </tr>
                                        <tr>
                                            <th>타이어 (FR, RR)</th>
                                            <td>130/70B18</td>
                                        </tr>
                                        <tr>
                                            <th>총 중량 (kg)</th>
                                            <td>약 403</td>
                                        </tr>
                                        <tr>
                                            <th>모터 파워 (kW)</th>
                                            <td>최고 11</td>
                                        </tr>
                                        <tr>
                                            <th>모터 토크 (Nm)</th>
                                            <td>최고 79</td>
                                        </tr>
                                        <tr>
                                            <th>분당 회전수 (r/min)</th>
                                            <td>최대 4,400</td>
                                        </tr>
                                        <tr>
                                            <th>배터리 (V)</th>
                                            <td>72.8 (Li-Ion)</td>
                                        </tr>
                                        <tr>
                                            <th>스티어링</th>
                                            <td>매뉴얼 스티어링</td>
                                        </tr>
                                        <tr>
                                            <th>구동방식</th>
                                            <td>후방 엔진 후륜 구동 방식</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </Col>
            </div>
        </div>
    );
}
