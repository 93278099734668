import React, { useEffect, useState } from "react";
import { Row, Col } from "antd";
import { useAsyncAxios, utilAxiosWithAppToken } from "../../utils/customAxios";

import * as Request from "../../commons/request";
import * as TypeDTO from "../../commons/typeDTO";

import Banner from "../../assets/images/home/ruta40/banner_media.jpg";

function Media() {
    const MAX_COLUMN_COUNT = 3;
    const [medias, setMedias] = useState<Array<TypeDTO.MediaDto>>([]);

    useEffect(() => {
        requestGetMedias();
    }, []);

    const requestAxiosGetMedias = async () => {
        const response = await utilAxiosWithAppToken().get(Request.MEDIAS_URL);
        return response.data;
    };

    const {
        loading: loadingGetMedias,
        error: errorGetMedias,
        data: resultGetMedias,
        execute: requestGetMedias,
    } = useAsyncAxios(requestAxiosGetMedias);

    useEffect(() => {
        if (!resultGetMedias) return;
        console.log("resultGetMedias", resultGetMedias);
        setMedias(resultGetMedias.medias);
    }, [resultGetMedias]);

    useEffect(() => {
        if (!errorGetMedias) return;
        console.log("errorGetMedias", errorGetMedias);
    }, [errorGetMedias]);

    const displayMedias = () => {
        if (medias.length === 0) return null;

        let tempMedia = [...medias];
        let rowMedias = [];
        const allMedias = [];
        do {
            rowMedias = tempMedia.splice(MAX_COLUMN_COUNT);
            allMedias.push(tempMedia);

            tempMedia = [...rowMedias];
        } while (rowMedias.length > 0);

        if (allMedias.length > 0) {
            return (
                <div className="model-tabs title model-body" style={{ backgroundColor: "white" }}>
                    <Col
                        xs={{ span: 24 }}
                        sm={{ span: 22, offset: 1 }}
                        lg={{ span: 18, offset: 3 }}
                        xl={{ span: 16, offset: 4 }}
                        xxl={{ span: 14, offset: 5 }}
                        className="fadeInUp p-4"
                    >
                        {allMedias.map((rowMedias, index) => {
                            return (
                                <Row key={index} className={`row mb-5`}>
                                    {rowMedias.map((media, i) => {
                                        return (
                                            <Col key={i} sm={{ span: 24 }} lg={{ span: 8 }} className="p-3">
                                                <div className="media-card ">
                                                    <img
                                                        src={media.imageUrl ? media.imageUrl : media.thumbnailUrl}
                                                        alt=""
                                                        className="media-card-img"
                                                    />
                                                    <h5 className="mb-2">
                                                        <a className="text-dark" href={media.mediaUrl}>
                                                            {media.mediaTitle}
                                                        </a>
                                                    </h5>
                                                    <div className="media-card-content">
                                                        <p className="news-text">{media.mediaCopyright}</p>
                                                        <p className="news-text">{media.mediaWriter}</p>
                                                    </div>
                                                    <div className="media-button-wrapper">
                                                        <a
                                                            href={media.mediaUrl}
                                                            target="_blank"
                                                            rel="noreferrer"
                                                            className="btn btn-primary media-button"
                                                            style={{ WebkitLineClamp: 1 }}
                                                        >
                                                            보러가기
                                                        </a>
                                                    </div>
                                                </div>
                                            </Col>
                                        );
                                    })}
                                </Row>
                            );
                        })}
                    </Col>
                </div>
            );
        }

        return null;
    };

    return (
        <>
            <div className="header-container model">
                <div className="container" style={{ backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.2),rgba(0, 0, 0, 0.2)),url(${Banner})` }}>
                    <div className="row justify-content-center">
                        <div className="fadeIn text-center mb-5">
                            <h5 className="text-center text-light px-3 mb-4">미디어</h5>
                            <h1 className="text-light">MEDIA</h1>
                        </div>
                    </div>
                </div>
            </div>
            {displayMedias()}
        </>
    );
}

export default Media;
