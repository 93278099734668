import { createContext, useState, useContext } from "react";

export const AlertType = {
    NONE: "NONE",

    SUCCESS: "SUCCESS",

    UNAUTHORIZED: "UNAUTHORIZED",
    INACCESSIBLE_DATA: "INACCESSIBLE_DATA",
    INVALID_PARAM: "INVALID_PARAM",
    NONEXISTENT_USER: "NONEXISTENT_USER",
    ALREADY_EXISTENT_USER: "ALREADY_EXISTENT_USER",
    CANNOT_UPDATE_USER: "CANNOT_UPDATE_USER",
    NONEXISTENT_APPLICATION: "NONEXISTENT_APPLICATION",
    ALREADY_EXISTENT_APPLICATION: "ALREADY_EXISTENT_APPLICATION",
    NONEXISTENT_CATEGORY: "NONEXISTENT_CATEGORY",
    ALREADY_EXISTENT_CATEGORY: "ALREADY_EXISTENT_CATEGORY",
    NONEXISTENT_MODEL: "NONEXISTENT_MODEL",
    ALREADY_EXISTENT_MODEL: "ALREADY_EXISTENT_MODEL",
    NONEXISTENT_TYPE: "NONEXISTENT_TYPE",
    ALREADY_EXISTENT_TYPE: "ALREADY_EXISTENT_TYPE",
    NONEXISTENT_COLOR: "NONEXISTENT_COLOR",
    ALREADY_EXISTENT_COLOR: "ALREADY_EXISTENT_COLOR",
    NONEXISTENT_OPTION: "NONEXISTENT_OPTION",
    ALREADY_EXISTENT_OPTION: "ALREADY_EXISTENT_OPTION",
    NONEXISTENT_ORDER: "NONEXISTENT_ORDER",
    NONEXISTENT_PREORDER: "NONEXISTENT_PREORDER",
    ALREADY_EXISTENT_PREORDER: "ALREADY_EXISTENT_PREORDER",
    NONEXISTENT_PREORDER_EVENT: "NONEXISTENT_PREORDER_EVENT",
    ALREADY_EXISTENT_PREORDER_EVENT: "ALREADY_EXISTENT_PREORDER_EVENT",
    NOT_ENOUGH_SELECTED_PREORDER: "NOT_ENOUGH_SELECTED_PREORDER",
    NONEXISTENT_QUESTION: "NONEXISTENT_QUESTION",
    ALREADY_EXISTENT_QUESTION: "ALREADY_EXISTENT_QUESTION",
    ALREADY_EXISTENT_USER_COUPON: "ALREADY_EXISTENT_USER_COUPON",
    NONEXISTENT_USER_COUPON: "NONEXISTENT_USER_COUPON",
    COUPON_ALREADY_EXPIRED: "COUPON_ALREADY_EXPIRED",
    PAYMENT_CANCEL_EXCEPTION: "PAYMENT_CANCEL_EXCEPTION",
    ALREADY_ORDER_DUPLICATE: "ALREADY_ORDER_DUPLICATE",
    ALREADY_EXISTENT_RENT: "ALREADY_EXISTENT_RENT",
    CREATE_RENT_DENIED: "CREATE_RENT_DENIED",
    ALREADY_EXISTENT_DRIVER_LICENSE: "ALREADY_EXISTENT_DRIVER_LICENSE",
    NONEXISTENT_B2B: "NONEXISTENT_B2B",

    ERR_NETWORK: "ERR_NETWORK",
    ECONNABORTED: "ECONNABORTED",
};

const AlertContext = createContext({
    type: AlertType.NONE,
    title: "",
    message: "",
    setAlert: (type: string, title: string, message: string) => {},
});

export const AlertProvider = (props: any) => {
    const [type, setType] = useState(AlertType.NONE);
    const [title, setTitle] = useState("");
    const [message, setMessage] = useState("");

    const setAlert = (type: string, title: string, message: string) => {
        setType(type);
        setTitle(title);
        setMessage(message);
    };

    return (
        <AlertContext.Provider
            value={{
                type,
                title,
                message,
                setAlert,
            }}
        >
            {props.children}
        </AlertContext.Provider>
    );
};

export function useAlert() {
    const context = useContext(AlertContext);
    if (context === undefined) {
        throw new Error("useAlert must be used within a AlertProvider");
    }

    return context;
}
