import styles from "./DriveInfo.module.css";
import { Row, Col } from "antd";

function DriveInfo({ icon, title, data, unit }: { icon: string; title: string; data: string; unit: string }) {
    return (
        <Row align="middle" justify={"space-between"} className={styles.body}>
            <div>
                <img className={styles.icon} src={icon} />
                <p className={styles.title}>{title}</p>
            </div>
            <div className={styles.dataBody}>
                <p className={styles.data}>{data}</p>
                <p className={styles.unit}>{unit}</p>
            </div>
        </Row>
    );
}

export default DriveInfo;
