import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { useAsyncAxios, utilAxiosWithAuth } from "../../utils/customAxios";
import { Button, Modal, Row } from "antd";
import { useNavigate } from "react-router-dom";

import * as Common from "../../commons/common";
import * as Request from "../../commons/request";
import * as Strings from "../../commons/string";
import * as TypeDTO from "../../commons/typeDTO";
import * as Utils from "../../utils/utils";

import Smartkey from "../../component/rent/Smartkey";
import DriveInfo from "../../component/rent/DriveInfo";
import AddOnsDrawer from "../../component/rent/AddOnsDrawer";
import dash from "../../assets/images/digitalKeyController/dash-image.svg";
import distanceIcon from "../../assets/images/digitalKeyController/distanceIcon.svg";
import batteryIcon from "../../assets/images/digitalKeyController/batteryIcon.svg";
import driveAvgIcon from "../../assets/images/digitalKeyController/driveAvgIcon.svg";
import refreshIcon from "../../assets/images/digitalKeyController/refreshIcon.svg";
import drawerBtn from "../../assets/images/digitalKeyController/drawerBtn.svg";
import styles from "./DigitalKeyController.module.css";
import ModalManageCar from "../../component/rent/ModalManageCar";

function DigitalKeyController() {
    const [activeMyShareCar, setActiveMyShareCar] = useState<TypeDTO.CarSharerDto>();
    const [myShareCars, setMyShareCars] = useState<Array<TypeDTO.CarSharerDto>>([]);
    const [updateActiveCar, setUpdateActiveCar] = useState(false);
    const [openDrawer, setOpenDrawer] = useState(false);
    const [openModalSmartkeyActionError, setOpenModalSmartkeyActionError] = useState(false);
    const [openCarChangeModal, setOpenCarChangeModal] = useState(false);
    const [smartkeyActionErrorMsg, setSmartkeyActionErrorMsg] = useState<string>();

    useEffect(() => {
        Utils.sendFCMToken();

        Utils.onEvent(Common.EVENT_CLOSE_ALL_DRAWER, handleCloseAllDrawer);
        Utils.onEvent(Common.EVENT_CLOSE_ALL_MODAL, handleCloseAllModal);
        Utils.onEvent(Common.EVENT_HEADER_INFO, handleOpenCarChangeModal);

        return () => {
            Utils.offEvent(Common.EVENT_CLOSE_ALL_DRAWER, handleCloseAllDrawer);
            Utils.offEvent(Common.EVENT_CLOSE_ALL_MODAL, handleCloseAllModal);
            Utils.offEvent(Common.EVENT_HEADER_INFO, handleOpenCarChangeModal);
        };
    }, []);

    useEffect(() => {
        openModalSmartkeyActionError ? Utils.addModalStack() : Utils.popModalStack();
    }, [openModalSmartkeyActionError]);

    useEffect(() => {
        updateActiveCar && requestGetMyShareCars();
    }, [updateActiveCar]);

    useEffect(() => {
        if (!myShareCars || myShareCars.length === 0) {
            setActiveMyShareCar(undefined);
        } else {
            const activeCars = myShareCars.filter((car) => car.active === true);
            if (activeCars && activeCars.length > 0) {
                setActiveMyShareCar(activeCars[0]);
            } else setActiveMyShareCar(undefined);
        }
    }, [myShareCars]);

    const requestAxiosGetMyShareCars = async () => {
        const response = await utilAxiosWithAuth().get(Request.SHARER_MINE_URL);
        return response.data;
    };

    const {
        loading: loadingGetMyShareCars,
        error: errorGetMyShareCars,
        data: resultGetMyShareCars,
        execute: requestGetMyShareCars,
    } = useAsyncAxios(requestAxiosGetMyShareCars);

    useEffect(() => {
        if (!resultGetMyShareCars) return;
        console.log("resultGetMyShareCars", resultGetMyShareCars);
        setMyShareCars(resultGetMyShareCars.carSharers);
    }, [resultGetMyShareCars]);

    useEffect(() => {
        if (!errorGetMyShareCars) return;

        console.log("errorGetMyShareCars", errorGetMyShareCars);
    }, [errorGetMyShareCars]);

    useEffect(() => {
        if (
            activeMyShareCar &&
            activeMyShareCar.carOwner?.car &&
            activeMyShareCar.carOwner?.car?.batterySoc &&
            activeMyShareCar.carOwner?.car?.subBatteryStatus
        ) {
            Utils.triggerEvent(Common.EVENT_BATTERY, {
                battery: activeMyShareCar.carOwner?.car?.batterySoc,
                subBattery: activeMyShareCar.carOwner?.car?.subBatteryStatus,
            });
        }
    }, [activeMyShareCar]);

    useEffect(() => {
        requestGetMyShareCars();
    }, []);

    const updateVehicleInfo = () => {
        requestGetMyShareCars();
    };

    const handleDrawerBtnClick = () => {
        setOpenDrawer(true);
    };

    const handleCloseAllDrawer = () => {
        setOpenDrawer(false);
    };

    const handleCloseAllModal = () => {
        setOpenModalSmartkeyActionError(false);
    };

    const handleOpenCarChangeModal = () => {
        setOpenCarChangeModal(true);
    };

    const onSmartkeyActionError = (message: string) => {
        setOpenModalSmartkeyActionError(true);
        setSmartkeyActionErrorMsg(message);
    };

    return (
        <div className={styles.digitalKeyPage}>
            <div className={styles.container}>
                <div className={styles.topSection}>
                    <div className={styles.pageTitle}>
                        <h1 className={styles.titleText}>
                            {activeMyShareCar && activeMyShareCar.carOwner ? activeMyShareCar.carOwner.car.carNumber : "차량을 등록해 주세요."}
                        </h1>
                        <img width={56} height={10} src={dash} alt="dash design in yellow" className={styles.dash} />
                    </div>
                    <div className={styles.refreshIconContainer}>
                        <motion.img
                            initial={{ rotate: 360 }}
                            animate={loadingGetMyShareCars ? { rotate: [0, 360] } : "stop"}
                            transition={{
                                type: "spring",
                                damping: 20,
                            }}
                            src={refreshIcon}
                            width="22"
                            height="22"
                            onClick={updateVehicleInfo}
                        />
                    </div>
                </div>

                <Smartkey activeCar={activeMyShareCar?.carOwner} onSmartkeyActionError={onSmartkeyActionError} />

                <div className={styles.dataContainer}>
                    <div className={styles.batteryWrapper}>
                        <div style={{ display: "flex", gap: 8 }}>
                            <img className={styles.icon} src={batteryIcon} />
                            <p className={styles.title} style={{ margin: 0 }}>
                                현재 배터리
                            </p>
                        </div>
                        <div className={styles.dataBody}>
                            {activeMyShareCar && activeMyShareCar?.carOwner ? (
                                <span
                                    className={`${styles.itemData} ${
                                        (activeMyShareCar?.carOwner.car.batterySoc <= 40 &&
                                            activeMyShareCar?.carOwner.car.batterySoc > 30 &&
                                            styles.littleLowBattery) ||
                                        (activeMyShareCar?.carOwner.car.batterySoc <= 30 &&
                                            activeMyShareCar?.carOwner.car.batterySoc > 20 &&
                                            styles.lowBattery) ||
                                        (activeMyShareCar?.carOwner.car.batterySoc <= 20 &&
                                            activeMyShareCar?.carOwner.car.batterySoc > 0 &&
                                            styles.veryLowBattery)
                                    }`}
                                >
                                    {String(Math.round(activeMyShareCar?.carOwner.car.batterySoc * 10) / 10)} <span style={{ fontSize: 18 }}>%</span>
                                </span>
                            ) : (
                                <span className={`${styles.itemData}`}>{Strings.dash}</span>
                            )}
                        </div>
                    </div>

                    {/* <div className={styles.item}>
                        <span className={styles.itemTitle}>보조배터리 전압</span>
                        {activeMyShareCar && activeMyShareCar?.carOwner ? (
                            <span
                                className={`${styles.itemData} ${
                                    (activeMyShareCar?.carOwner.car.subBatteryStatus === "LOW" && styles.lowBattery) ||
                                    (activeMyShareCar?.carOwner.car.subBatteryStatus === "VERY_LOW" && styles.veryLowBattery)
                                }`}
                            >
                                {activeMyShareCar?.carOwner.car.subBatteryStatus !== null
                                    ? Utils.convertSubBatteryStatus(activeMyShareCar?.carOwner.car.subBatteryStatus)
                                    : Strings.dash}
                            </span>
                        ) : (
                            <span className={`${styles.itemData}`}>{Strings.dash}</span>
                        )}
                    </div> */}
                </div>

                <div style={{ marginBottom: 28 }}>
                    <p className={styles.infotext}>최근 주행 정보</p>
                    <div className={styles.driveInfo}>
                        <DriveInfo
                            icon={distanceIcon}
                            title="운행 거리"
                            data={
                                activeMyShareCar && activeMyShareCar?.carOwner && activeMyShareCar?.carOwner.car.recentDrivingDistance
                                    ? String(Math.round(activeMyShareCar?.carOwner.car.recentDrivingDistance * 10) / 10)
                                    : "- -"
                            }
                            unit="km"
                        />
                        <DriveInfo
                            icon={batteryIcon}
                            title="배터리 사용량"
                            data={
                                activeMyShareCar && activeMyShareCar?.carOwner && activeMyShareCar?.carOwner.car.recentBatteryUsage
                                    ? String(Math.round(activeMyShareCar?.carOwner.car.recentBatteryUsage * 10) / 10)
                                    : "- -"
                            }
                            unit="kWh"
                        />
                        <DriveInfo
                            icon={driveAvgIcon}
                            title="평균 주행 전비"
                            data={
                                activeMyShareCar && activeMyShareCar?.carOwner && activeMyShareCar?.carOwner.car.recentElectricMileage
                                    ? String(Math.round(activeMyShareCar?.carOwner.car.recentElectricMileage * 10) / 10)
                                    : "- -"
                            }
                            unit="km/kWh"
                        />
                    </div>
                </div>

                <motion.div className={styles.drawerBtnContainer} onClick={handleDrawerBtnClick} whileTap={{ y: 4 }}>
                    <img className={styles.drawerBtn} alt="icon for opening bottom drawer" src={drawerBtn} height={26} width={32} />
                </motion.div>
                <AddOnsDrawer openDrawer={openDrawer} setOpenDrawer={setOpenDrawer} />
                <Modal
                    className="confirmModal"
                    centered
                    key="modalSmartkeyActionError"
                    open={openModalSmartkeyActionError}
                    closable={false}
                    onCancel={() => setOpenModalSmartkeyActionError(false)}
                    footer={[
                        <Button block type="primary" key="back" onClick={() => setOpenModalSmartkeyActionError(false)}>
                            {Strings.confirm}
                        </Button>,
                    ]}
                >
                    <Row justify={"center"} align={"middle"}>
                        {smartkeyActionErrorMsg}
                    </Row>
                </Modal>
                <Modal className="digitalKeyModal" centered key="modalCarChange" open={openCarChangeModal} closable={false} footer={false}>
                    <ModalManageCar setOpenCarChangeModal={setOpenCarChangeModal} setUpdateActiveCar={setUpdateActiveCar} />
                </Modal>
            </div>
        </div>
    );
}

export default DigitalKeyController;
