import * as React from "react";
import { BrowserView, MobileView } from "react-device-detect";
import { Divider, Modal, Space, Image } from "antd";

import * as String from "../../commons/string";

import BgTableHeader from "../../assets/images/home/rent/bg_table_header.png";
import RadiusMap from "../../assets/images/home/rent/radius_map.jpg";

type showType = {
    show?: boolean;
    showTitle?: boolean;
    showDivider?: boolean;
    className?: string;
};

const PenaltyInfo = ({
    backgroundColor,
    showReturnDelay,
    showUncharged,
    showBreach,
    showEtc,
}: {
    backgroundColor?: string;
    showReturnDelay?: showType;
    showUncharged?: showType;
    showBreach?: showType;
    showEtc?: showType;
}) => {
    const penaltyInfos = [
        {
            th: "1시간 이내 지연 시",
            td: "50,000원",
        },
        {
            th: "1시간 초과 2시간 내 지연 시",
            td: "100,000원",
        },
        {
            th: "2시간 초과 지연 시",
            td: "반납일 정상가 요금 100%",
        },
        {
            th: "다음 예약건에 영향을 미칠 경우",
            td: "반납일 정상가 요금 200%",
        },
    ];

    const breachInfos = [
        {
            th: "차량 내 흡연",
            td: "200,000원",
        },
        {
            th: "차량 내 냄새가 강한 음식 조리(육류, 생선구이, 청국장 등)",
            td: "200,000원",
        },
        {
            th: "샤워실 용변",
            td: "200,000원",
        },
        {
            th: "반려동물",
            td: "100,000원",
        },
        {
            th: "제한인원초과",
            td: "100,000원",
        },
        {
            th: "차량 내 쓰레기 및 오물 투기·방치",
            td: "100,000원",
        },
    ];

    const warning = () => {
        Modal.warning({
            centered: true,
            title: "초과 반경 위약금",
            content: (
                <Space direction="vertical" size={10}>
                    <Image width="100%" src={RadiusMap} />
                    <p style={{ marginBottom: 0 }}>
                        차고지 기준 반경 50km 이내로 이용하실 수 있으며, 초과 시 초과 범위에 따라 위약금이 발생됩니다. (반경 50km 지역 이탈시 위약금
                        20만원, 이후 10km 당 10만원씩 추가 부과)
                    </p>
                </Space>
            ),
            okText: String.confirm,
            onOk() {},
        });
    };

    return (
        <>
            <MobileView className="mobile">
                {showReturnDelay?.show && (
                    <div className={showReturnDelay.className}>
                        {showReturnDelay.showTitle && <h5 className="text-center mb-4">차량 반납 지연</h5>}
                        <table className="mb-4 table design-table" style={{ backgroundColor: backgroundColor }}>
                            <thead style={{ backgroundImage: `url(${BgTableHeader})`, backgroundSize: "cover", border: "none" }}>
                                <tr>
                                    <th colSpan={2} className="text-center">
                                        반납 지연 위약금(반납일 기준)
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {penaltyInfos.map((info) => (
                                    <tr key={info.th}>
                                        <th style={{ textAlign: "left" }}>{info.th}</th>
                                        <td style={{ textAlign: "right" }} className="text-danger">{info.td}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <div className="custom-bolder-box">
                            <p className="text-danger text-bold">※ {String.caution}</p>
                            <ul className="listSpace mb-0 mt-2">
                                <li>차량 반납 지연으로 인하여 다음 예약건에 영향을 미칠 경우 손해배상금이 추가로 발생됩니다.</li>
                            </ul>
                        </div>
                        {showReturnDelay.showDivider && <Divider style={{ margin: "30px 0" }} />}
                    </div>
                )}
                {showUncharged?.show && (
                    <div className={`${showUncharged.className}`}>
                        {showUncharged.showTitle && <h5 className="text-center mb-4">배터리 미충전 반납</h5>}
                        <ul style={{ paddingLeft: 20 }} className="mb-0 listSpace">
                            <li>배터리 충전량이 출차 상태보다 부족할 경우 미충전 배터리량 1%당 600원으로 정산됩니다.</li>
                        </ul>
                        <div className="custom-bolder-box mt-3">
                            <p className="text-danger text-bold">※ 출차 시 배터리량이 80%였던 차량을 40%로 반납할 경우 예시</p>
                            <ul className="listSpace mb-0 mt-2">
                                <li>미충전 배터리량 계산 : 80(%) - 40(%) = 40(%)</li>
                                <li>정산 : 40(%) X 600(원) = 24,000원</li>
                            </ul>
                        </div>
                        {showUncharged.showDivider && <Divider style={{ margin: "30px 0" }} />}
                    </div>
                )}
                {showBreach?.show && (
                    <div className={`${showBreach.className}`}>
                        {showBreach.showTitle && <h5 className="text-center mb-4">규정 위반</h5>}
                        <ul style={{ paddingLeft: 20 }} className="listSpace">
                            <li>이용 안내 미숙지로 인하여 영업에 손해를 끼친 경우 위약금이 발생될 수 있습니다.</li>
                        </ul>
                        <table className="mb-4 table design-table" style={{ backgroundColor: backgroundColor }}>
                            <thead style={{ backgroundImage: `url(${BgTableHeader})`, backgroundSize: "cover", border: "none" }}>
                                <tr>
                                    <th colSpan={2} className="text-center">
                                        규정 위반 위약금
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {breachInfos.map((info) => (
                                    <tr key={info.th}>
                                        <th style={{ textAlign: "left" }}>{info.th}</th>
                                        <td style={{ textAlign: "right" }} className="text-danger">{info.td}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        {showBreach.showDivider && <Divider style={{ margin: "30px 0" }} />}
                    </div>
                )}
                {showEtc?.show && (
                    <div className={`${showEtc.className}`}>
                        {showEtc.showTitle && <h5 className="text-center mb-4">기타 위약금</h5>}
                        <ul style={{ paddingLeft: 20 }} className="listSpace mb-0">
                            <li>
                                차량 이용 안내 및 사전 교육 미숙지로 인하여 영업에 손해를 끼친 경우 위약금이 발생될 수 있으며, 모든 책임은 이용자에게
                                있습니다.
                            </li>
                            <li>차량 파손/분실/훼손에 대한 위약금이 발생될 수 있습니다.</li>
                            <li>비품 파손/분실/훼손에 대한 위약금이 발생될 수 있습니다.</li>
                            <li>
                                차고지 기준 반경 50km 이내로 이용하실 수 있으며, 초과 시 초과 범위에 따라 위약금이 발생됩니다.
                                <span style={{ color: "rgb(0, 95, 219)", fontWeight: "bold", marginLeft: 8, cursor: "pointer" }} onClick={warning}>
                                    자세히 보기
                                </span>
                            </li>
                        </ul>
                        {showEtc.showDivider && <Divider style={{ margin: "30px 0" }} />}
                    </div>
                )}
            </MobileView>
            <BrowserView className="browser">
                {showReturnDelay?.show && (
                    <div className={`${showReturnDelay.className}`}>
                        {showReturnDelay.showTitle && <h5 className="text-center mb-4">차량 반납 지연</h5>}
                        <table className="mb-4 table design-table" style={{ backgroundColor: backgroundColor }}>
                            <thead style={{ backgroundImage: `url(${BgTableHeader})`, backgroundSize: "cover", border: "none" }}>
                                <tr>
                                    <th colSpan={2} className="text-center">
                                        반납 지연 위약금(반납일 기준)
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {penaltyInfos.map((info) => (
                                    <tr key={info.th}>
                                        <th style={{ textAlign: "left" }}>{info.th}</th>
                                        <td style={{ textAlign: "right" }} className="text-danger">{info.td}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <div className="custom-bolder-box">
                            <p className="text-danger text-bold">※ {String.caution}</p>
                            <ul className="listSpace mb-0 mt-2">
                                <li>차량 반납 지연으로 인하여 다음 예약건에 영향을 미칠 경우 손해배상금이 추가로 발생합니다.</li>
                            </ul>
                        </div>
                        {showReturnDelay.showDivider && <Divider />}
                    </div>
                )}
                {showUncharged?.show && (
                    <div className={`${showUncharged.className}`}>
                        {showUncharged.showTitle && <h5 className="text-center mb-4">배터리 미충전 반납</h5>}
                        <ul className="listSpace mb-3">
                            <li>배터리 충전량이 출차 상태보다 부족할 경우 미충전 배터리량 1%당 600원으로 정산됩니다.</li>
                        </ul>
                        <div className="custom-bolder-box mt-3">
                            <p className="text-danger text-bold">※ 출차 시 배터리량이 80%였던 차량을 40%로 반납할 경우 예시</p>
                            <ul className="listSpace mb-0 mt-2">
                                <li>미충전 배터리량 계산 : 80(%) - 40(%) = 40(%)</li>
                                <li>정산 : 40(%) X 600(원) = 24,000원</li>
                            </ul>
                        </div>
                        {showUncharged.showDivider && <Divider />}
                    </div>
                )}
                {showBreach?.show && (
                    <div className={`${showBreach.className}`}>
                        {showBreach.showTitle && <h5 className="text-center mb-4">규정 위반</h5>}
                        <ul className="listSpace mb-3">
                            <li>이용 안내 미숙지로 인하여 영업에 손해를 끼친 경우 위약금이 발생될 수 있습니다.</li>
                        </ul>
                        <table className="mb-4 table design-table" style={{ backgroundColor: backgroundColor }}>
                            <thead style={{ backgroundImage: `url(${BgTableHeader})`, backgroundSize: "cover", border: "none" }}>
                                <tr>
                                    <th colSpan={2} className="text-center">
                                        규정 위반 위약금
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {breachInfos.map((info) => (
                                    <tr key={info.th}>
                                        <th style={{ textAlign: "left" }}>{info.th}</th>
                                        <td style={{ textAlign: "right" }} className="text-danger">{info.td}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        {showBreach.showDivider && <Divider />}
                    </div>
                )}
                {showEtc?.show && (
                    <div className={`${showEtc.className}`}>
                        {showEtc.showTitle && <h5 className="text-center mb-4">기타 위약금</h5>}
                        <ul style={{ paddingLeft: 20 }} className="listSpace mb-0">
                            <li>
                                차량 이용 안내 및 사전 교육 미숙지로 인하여 영업에 손해를 끼친 경우 위약금이 발생될 수 있으며, 모든 책임은 이용자에게
                                있습니다.
                            </li>
                            <li>차량 파손/분실/훼손에 대한 위약금이 발생될 수 있습니다.</li>
                            <li>비품 파손/분실/훼손에 대한 위약금이 발생될 수 있습니다.</li>
                            <li>
                                차고지 기준 반경 50km 이내로 이용하실 수 있으며, 초과 시 초과 범위에 따라 위약금이 발생됩니다.
                                <span style={{ color: "rgb(0, 95, 219)", fontWeight: "bold", marginLeft: 8, cursor: "pointer" }} onClick={warning}>
                                    자세히 보기
                                </span>
                            </li>
                        </ul>
                        {showEtc.showDivider && <Divider />}
                    </div>
                )}
            </BrowserView>
        </>
    );
};

export default PenaltyInfo;
