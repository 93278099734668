import React from "react";
import Carousel from "react-bootstrap/Carousel";
import { Col, Button } from "antd";
import { useMediaQuery } from "react-responsive";

import fmsImage1 from "../../assets/images/fms/fms1.jpg";
import fmsImage2 from "../../assets/images/fms/fms2.jpg";
import fmsImage3 from "../../assets/images/fms/fms3.jpg";
import fmsImage4 from "../../assets/images/fms/fms4.jpg";
import fmsImage5 from "../../assets/images/fms/fms5.jpg";
import fmsImage6 from "../../assets/images/fms/fms6.jpg";
import fmsImage7 from "../../assets/images/fms/fms7.jpg";
import fmsImage8 from "../../assets/images/fms/fms8.jpg";

import Banner from "../../assets/images/home/ruta40/banner_fms.jpg";

function FMS() {
    const isMobile = useMediaQuery({ maxWidth: 700 });

    return (
        <>
            <div className="header-container model">
                <div
                    className="container"
                    style={{ backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.2),rgba(0, 0, 0, 0.2)),url(${Banner})`, backgroundPosition: "bottom" }}
                >
                    <div className="row justify-content-center">
                        <div className="fadeIn text-center mb-5">
                            <h5 className="text-center text-light px-3 mb-4">차량 관제 시스템</h5>
                            <h1 className="text-light">ezMobility</h1>
                        </div>
                    </div>
                </div>
            </div>

            <div className="model-tabs title model-body pt-3" style={{ backgroundColor: "white" }}>
                <Col
                    xs={{ span: 24 }}
                    sm={{ span: 22, offset: 1 }}
                    lg={{ span: 18, offset: 3 }}
                    xl={{ span: 16, offset: 4 }}
                    xxl={{ span: 14, offset: 5 }}
                    className="fadeInUp p-4"
                >
                    <Col span={24}>
                        {isMobile ? (
                            <Carousel variant="light">
                                <Carousel.Item>
                                    <img className="d-block img-fluid" src={fmsImage5} />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img className="d-block img-fluid" src={fmsImage6} />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img className="d-block img-fluid" src={fmsImage7} />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img className="d-block img-fluid" src={fmsImage8} />
                                </Carousel.Item>
                            </Carousel>
                        ) : (
                            <Carousel variant="light">
                                <Carousel.Item>
                                    <img className="d-block img-fluid" src={fmsImage1} />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img className="d-block img-fluid" src={fmsImage2} />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img className="d-block img-fluid" src={fmsImage3} />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img className="d-block img-fluid" src={fmsImage4} />
                                </Carousel.Item>
                            </Carousel>
                            // <Space size={20} direction="vertical">
                            //     <Image src={fmsImage1} />
                            //     <Image src={fmsImage2} />
                            //     <Image src={fmsImage3} />
                            //     <Image src={fmsImage4} />
                            // </Space>
                        )}
                    </Col>
                </Col>

                <div className="fadeInUp text-center py-5">
                    <Button
                        className="btn-dark px-5"
                        href="/files/ezMobility_catalog.pdf"
                        download="차량관제시스템_브로슈어"
                        size="large"
                        type="primary"
                    >
                        브로슈어 다운로드
                    </Button>
                </div>
            </div>
        </>
    );
}

export default FMS;
