import React, { useEffect } from "react";
import { notification } from "antd";
import { LogoutOutlined } from "@ant-design/icons";
import { AlertType, useAlert } from "../provider/AlertProvider";

type NotificationType = "success" | "info" | "warning" | "error";

const MessageType: Record<string, string> = {
    "Unauthorized token": "로그인 정보가 만료되었습니다.\n다시 로그인 해 주세요.",
    "Invalid password": "비밀번호가 틀립니다.",
    "Withdrawn user": "가입이력이 있습니다.\n고객센터에 문의해 주세요.",
    "Account locked": "로그인 실패 횟수 초과로 계정이 잠겼습니다.\n비밀번호 재설정 후 로그인 해 주세요.",
    "Access is denied": "해당 데이터에 대한 접근권한이 없습니다.",
    "Invalid data": "입력한 값이 오류입니다.",
    "Invalid email": "등록되지 않은 이메일입니다.\n회원가입 후 이용해 주세요.",
    "Invalid phone": "등록되지 않은 전화번호입니다.",
    "Invalid name": "등록되지 않은 사용자입니다.",
    "Email already exists": "이미 가입된 이메일입니다.",
    "Phone already exists": "이미 가입된 휴대전화 번호입니다.",
    "Invalid application name": "해당 어플리케이션 정보가 없습니다.",
    "Application name already exists": "중복된 어플리케이션 이름입니다.",
    "Invalid category id": "해당 카테고리 정보가 없습니다.",
    "Category name already exists": "중복된 카테고리 이름입니다.",
    "Invalid model id": "해당 모델 정보가 없습니다.",
    "Model name already exists": "중복된 모델 이름입니다.",
    "Invalid type id": "해당 타입 정보가 없습니다.",
    "Type name already exists": "중복된 타입 이름입니다.",
    "Invalid color id": "해당 색상 정보가 없습니다.",
    "Color name already exists": "중복된 색상 이름입니다.",
    "Invalid option id": "해당 옵션 정보가 없습니다.",
    "Option name already exists": "중복된 옵션 이름입니다.",
    "Invalid order id": "해당 주문 정보가 없습니다.",
    "Invalid preorder id": "해당 사전예약 정보가 없습니다.",
    "Preorder already exists": "중복된 사전예약 정보입니다.",
    "Invalid preorder event id": "사전예약이벤트 정보가 없습니다.",
    "Preorder event already exists": "중복된 사전예약이벤트 정보입니다.",
    "Selected preorder is not enough": "사전예약이벤트 당첨자가 부족합니다.",
    "Invalid question no": "해당 질문 정보가 없습니다.",
    "Question no already exists": "중복된 질문 정보입니다.",
    "Invalid serial number": "유효하지 않은 쿠폰 번호입니다.",
    "already assigned to user": "이미 등록되었거나 사용된 쿠폰입니다.",
    "already have same coupon": "동일한 쿠폰을 보유하고 있습니다.",
    "The coupon has expired as the registration date has passed": "등록기한이 만료된 쿠폰 번호입니다.",
    "Payment already canceled": "결제가 이미 취소되었습니다.",
    "Portone error": "오류가 발생했습니다. 렌트 고객센터로 연락해 주세요.",
    "Order already canceled": "주문이 이미 취소되었습니다.",
    "Rent date already exists": "해당 날짜는 예약 결제 대기중입니다.",
    "Rent date already paid": "해당 날짜는 이미 예약 되어있습니다.",
    "Account expired": "1년간 활동 이력이 없어 휴면회원으로 전환되었습니다. 휴면 해제를 위해 비밀번호를 재설정 해 주세요.",
    "Blocked user": "활동에 제한이 있는 계정입니다.\n고객센터로 문의해 주세요.",
    "Driving license does not exist": "계정 - 계정 정보 - 운전면허 정보 추가 후 예약 가능합니다.",
    "Driver license number already exists": "이미 사용중인 운전면허번호입니다.",
    "Invalid promotion code": "단체 회원 코드가 올바르지 않습니다.",
    ERR_NETWORK: "서버 접속이 지연되고 있습니다.\n잠시 후 다시 시도해 주세요",
    ECONNABORTED: "서버 접속이 지연되고 있습니다.\n잠시 후 다시 시도해 주세요",
};

function AlertPopup() {
    const { type, title, message, setAlert } = useAlert();
    const [api, contextHolder] = notification.useNotification();

    useEffect(() => {
        let description = "";
        const date = message?.includes("Can't update email within") ? message.slice(-7, 28) : "";
        switch (type) {
            case AlertType.NONE:
                break;

            case AlertType.SUCCESS:
                openNotificationWithIcon("success", title, message);
                break;

            case AlertType.NONEXISTENT_MODEL:
                openNotificationWithIcon("info", title, message);
                break;

            case AlertType.UNAUTHORIZED:
            case AlertType.INACCESSIBLE_DATA:
            case AlertType.INVALID_PARAM:
            case AlertType.NONEXISTENT_USER:
            case AlertType.ALREADY_EXISTENT_USER:
            case AlertType.CANNOT_UPDATE_USER:
            case AlertType.NONEXISTENT_APPLICATION:
            case AlertType.ALREADY_EXISTENT_APPLICATION:
            case AlertType.NONEXISTENT_CATEGORY:
            case AlertType.ALREADY_EXISTENT_CATEGORY:
            case AlertType.ALREADY_EXISTENT_MODEL:
            case AlertType.NONEXISTENT_TYPE:
            case AlertType.ALREADY_EXISTENT_TYPE:
            case AlertType.NONEXISTENT_COLOR:
            case AlertType.ALREADY_EXISTENT_COLOR:
            case AlertType.NONEXISTENT_OPTION:
            case AlertType.ALREADY_EXISTENT_OPTION:
            case AlertType.NONEXISTENT_ORDER:
            case AlertType.NONEXISTENT_PREORDER:
            case AlertType.ALREADY_EXISTENT_PREORDER:
            case AlertType.NONEXISTENT_PREORDER_EVENT:
            case AlertType.ALREADY_EXISTENT_PREORDER_EVENT:
            case AlertType.NOT_ENOUGH_SELECTED_PREORDER:
            case AlertType.NONEXISTENT_QUESTION:
            case AlertType.ALREADY_EXISTENT_QUESTION:
            case AlertType.ALREADY_EXISTENT_USER_COUPON:
            case AlertType.NONEXISTENT_USER_COUPON:
            case AlertType.COUPON_ALREADY_EXPIRED:
            case AlertType.PAYMENT_CANCEL_EXCEPTION:
            case AlertType.ALREADY_ORDER_DUPLICATE:
            case AlertType.ERR_NETWORK:
            case AlertType.ECONNABORTED:
            case AlertType.ALREADY_EXISTENT_RENT:
            case AlertType.CREATE_RENT_DENIED:
            case AlertType.ALREADY_EXISTENT_DRIVER_LICENSE:
            case AlertType.NONEXISTENT_B2B:
                description = MessageType[message];

                openNotificationWithIcon(
                    "error",
                    title,
                    date !== ""
                        ? "가입일 또는 변경일 기준 30일이 초과되지 않았습니다. " + date + "일 이후 재시도 해 주세요."
                        : description
                        ? description
                        : message
                );
                break;

            default:
                openNotificationWithIcon("error", title, message);
                break;
        }
    }, [type, message]);

    const openNotificationWithIcon = (type: NotificationType, title: string, message: string) => {
        api[type]({
            message: message && message.length > 0 ? message : title,
            // message: title,
            // description: message,
            onClose: () => {
                setAlert(AlertType.NONE, "", "");
            },
            top: 100,
            duration: 3,
        });
    };
    return <>{contextHolder}</>;
}

export default AlertPopup;
