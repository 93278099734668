import React from "react";
import { Col } from "antd";

function TOS() {
    return (
        <>
            <Col sm={{ span: 24 }} lg={{ span: 18, offset: 3 }} xl={{ span: 18, offset: 3 }} xxl={{ span: 16, offset: 4 }} className="faq p-4">
                <section className="row legal">
                    <h2 className="title my-3">주식회사 루타스 이용약관</h2>
                    <section className="mb-4">
                        <h4>제 1 조 목적</h4>
                        <p>
                            본 약관은 주식회사 루타스(이하 “회사”)가 운영하는 대표 사이트(이하 ‘서비스’)의 이용과 관련하여 회사와 회원과의 권리, 의무
                            및 책임사항, 기타 필요한 사항을 규정하는 것을 목적으로 합니다.
                        </p>
                    </section>

                    <section className="mb-4">
                        <h4>제 2 조 용어의 정의</h4>
                        <p>본 약관에서 사용하는 용어의 정의는 다음과 같습니다.</p>
                        <p>
                            ① “서비스”란 구현되는 단말기와 무관하게 회사가 루타스 플랫폼 및 이를 지원하기 위한 서버를 통하여 제공하는 용역을 말합니다.
                            외부 제휴 서비스도 일부 포함하고 있습니다.
                        </p>
                        <p>② “루타스 플랫폼”이란 회사를 대표하는 채널로써 승용,상용 홈페이지, 모바일앱 등을 포함합니다.</p>
                        <p>③ “회원”이란 이 약관에 따라 이용계약이 체결되어 회사 가 제공하는 서비스를 이용할 수 있는 고객을 말합니다.</p>
                        <p>
                            ④ “아이디(ID)”란 회원의 식별과 서비스의 이용을 위하여 회사가 정한 정책에 따라 회원이 정하고 회사가 승인하는 ID유형(이메일,
                            전화번호 등)을 말합니다.
                        </p>
                        <p>
                            ⑤ “비밀번호”란 회원의 정보보호를 위해 회원 자신이 설정한 8자리 이상의 알파벳 대∙소문자, 숫자 및 특수문자의 조합을
                            의미합니다.
                        </p>
                        <p>
                            ⑥ “회원 작성 자료”란 서비스를 통하여 회원이 작성한 자료로서 공개∙비공개 여부와 무관하게 루타스 대표사이트 및 이를 지원하기
                            위한 서버에 등록된 자료 일체를 말합니다. 회원이 명시적으로 동의한 경우(스스로 본인의 개인정보가 기재된 자료를 공개적으로
                            게시하는 경우는 동의가 있는 것으로 간주합니다)를 제외하면, 회원 작성 자료의 범위에는 개인정보 보호법상 개인정보에 해당하는
                            자료를 포함하지 않습니다.
                        </p>
                        <p>
                            ⑦ “제휴업체 및 제휴 서비스”란 서비스를 제공하기 위해 루타스와 계약을 체결하여 서비스를 제공하는 회사를 말합니다. (이하
                            ”제휴 서비스”)
                        </p>
                        <p>⑧ 본 조에서 정의되지 않은 약관상의 용어의 의미는 일반적인 거래관행을 따릅니다.</p>
                    </section>

                    <section className="mb-4">
                        <h4>제 3 조 약관의 게시와 개정</h4>
                        <p>① 회사는 본 약관의 내용을 회원이 쉽게 확인할 수 있도록 플랫폼을 통해 게시합니다.</p>
                        <p>
                            ② 회사가 약관을 개정할 경우 개정 내용과 그 사유를 명시하여 개정된 약관을 적용하고자 하는 날 최소 7일 전에 플랫폼을 통해
                            공지합니다. 다만, 회원에게 불리하거나 중요한 약관 내용 개정의 경우에는 공지 외에 일정 기간 이메일 통보 방법으로 회원에게
                            개별 고지합니다.
                        </p>
                        <p>
                            ③ 회사가 제2항에 따라 개정 약관을 공지 또는 개별 고지하면서 회원에게 적용 기간 내에 의사표시를 하지 않으면 의사표시가
                            표명된 것으로 본다는 뜻을 명확하게 공지 또는 개별 고지하였음에도 회원이 명시적으로 거부의 의사표시를 하지 않을 경우 회원이
                            개정 약관에 동의한 것으로 봅니다.
                        </p>
                        <p>
                            ④ 회사가 이메일 통보의 방법으로 회원에게 개별 고지하는 경우에, 회원이 제공한 이메일 주소 중 가장 최근에 제공된 곳으로
                            통보하며, 회원이 최근의 정보로 변경하지 않아 발생한 손해에 대해서는 어떠한 책임도 지지 않습니다.
                        </p>
                        <p>
                            ⑤ 회원이 개정 약관의 적용에 동의하지 않는 경우 회사는 개정 약관의 내용을 적용할 수 없으며, 이 경우 회원은 회원탈퇴를 할 수
                            있습니다. 단, 이의가 있음에도 불구하고 제2항의 정해진 바에 따른 회사의 공지 또는 개별 고지 일자부터 적용 일자 이내에
                            회원탈퇴를 하지 않은 회원은 개정 약관에 동의한 것으로 간주합니다.
                        </p>
                        <p>
                            ⑥ 회원이 본 약관에 동의하는 것은 플랫폼을 통해 정기적으로 약관의 변경사항을 확인하는 것에 동의함을 의미합니다. 회사는
                            변경된 약관에 대한 정보를 알지 못하여 발생하는 회원의 손해에 대하여 책임을 부담하지 않습니다.
                        </p>
                    </section>

                    <section className="mb-4">
                        <h4>제 4 조 약관의 해석</h4>
                        <p>
                            ① 회사는 개별 서비스에 대해서는 별도의 약관(이하 “개별약관”) 및 정책(이하 “개별정책”)을 둘 수 있으며, 해당 내용이 본
                            약관과 충돌할 경우에는 개별약관 및 개별정책이 우선하여 적용됩니다.
                        </p>
                        <p>
                            ② 본 약관에 명시되지 않은 사항에 대해서는 약관의 규제에 관한 법률, 전자문서 및 전자거래 기본법, 전자서명법, 정보통신망
                            이용촉진 및 정보보호 등에 관한 법률, 전자상거래 등에서의 소비자보호에 관한 법률 등 관계 법령 또는 상관례에 따릅니다.
                        </p>
                    </section>

                    <section className="mb-4">
                        <h4>제 5 조 이용계약의 성립</h4>
                        <p>
                            ① 이용계약은 회원이 되고자 하는 자가 이 약관의 내용에 동의한 다음 회원가입 신청을 하고 회사가 위 신청에 대하여
                            승인함으로써 성립합니다.
                        </p>
                        <p>② 회원은 이 약관에 대하여 전자적 방법으로 동의할 수 있습니다.</p>
                        <p>③ 회사는 회원의 자격에 따라 서비스 제공 범위를 제한할 수 있습니다.</p>
                        <p>④ 회사는 다음 각 호의 경우 회원가입 신청을 승인하지 않을 수 있습니다.</p>
                        <ol>
                            <li>기술상 서비스 제공이 불가능한 경우</li>
                            <li>회원정보를 누락하거나 사실과 다르게 기재한 경우</li>
                            <li>개인정보 처리방침에 동의하지 아니한 경우</li>
                            <li>정당한 접근권한 없이 또는 허용된 접근권한을 넘어 정보통신망에 침입하기 위한 시도가 확인된 경우</li>
                            <li>회원의 의무위반으로 인하여 이용계약이 해지된 적이 있는 경우</li>
                            <li>선량한 풍속 기타 사회 질서에 위반되는 경우</li>
                            <li>기타 관련 법령 및 회사의 공개∙비공개된 정책 등에 부합하지 아니한 경우</li>
                        </ol>
                    </section>

                    <section className="mb-4">
                        <h4>제 6 장 이용계약의 내용</h4>
                        <p>① 이용계약은 회사가 회원에게 서비스를 제공하는 것을 내용으로 합니다.</p>
                        <p>
                            ② 회사는 회원임을 확인하기 위하여 일정한 인증 절차를 요구할 수 있으며, 이러한 절차에 응하지 아니하는 경우 회사는
                            회원으로서의 서비스를 제공할 수 없습니다.
                        </p>
                    </section>

                    <section className="mb-4">
                        <h4>제 7 조 회원의 의무</h4>
                        <p>회원은 서비스를 이용할 때 다음 각 호의 행위를 하지 않아야 합니다.</p>
                        <ol>
                            <li>
                                회사 또는 제3자의 권리를 침해하거나 침해할 우려가 있는 행위(공연성 없는 모욕∙명예훼손, 업무방해미수, 회사 또는 제3자에
                                대한 무권대리 등을 포함합니다)
                            </li>
                            <li>회원정보를 사실과 다르게 기재하는 행위</li>
                            <li>회사의 승인 없이 서비스를 통하여 얻은 정보를 영리목적으로 이용하는 행위</li>
                            <li>회사의 승인 없이 서비스를 자신 또는 제3자를 위한 영리목적으로 이용하는 행위</li>
                            <li>선량한 풍속 기타 사회 질서에 위반되는 행위</li>
                            <li>기타 관련 법령 및 회사의 공개된 정책 등에 위반되는 행위</li>
                        </ol>
                    </section>

                    <section className="mb-4">
                        <h4>제 8 조 아이디와 비밀번호 관리</h4>
                        <p>
                            ① 회원의 비밀번호는 회사를 포함한 제3자로부터 복호화 되지 아니하도록 일방향 암호화하여 저장되므로, 아이디와 비밀번호의
                            관리의무는 회원 본인에게 있습니다.
                        </p>
                        <p>② 회사는 아이디와 비밀번호 관리에 관한 정책을 별도로 정할 수 있습니다.</p>
                    </section>

                    <section className="mb-4">
                        <h4>제 9 조 회원정보의 수정</h4>
                        <p>
                            ① 회원은 회원가입 신청 시 기재한 회원정보가 변경되었을 경우 회사가 정한 양식 및 방법에 의하여 회원정보를 수정하여야
                            합니다.
                        </p>
                        <p>② 회사는 회원이 회원정보를 적시에 수정하지 않음으로 인하여 발생한 손해에 대해서는 책임을 지지 않습니다.</p>
                    </section>

                    <section className="mb-4">
                        <h4>제 10 조 회원에 대한 통지</h4>
                        <p>
                            ① 이 약관에서 별도로 정하지 아니하는 한, 회사는 회원이 등록한 회원정보(휴대전화번호, 이메일)를 바탕으로 전자적 전송매체를
                            이용하여 회원에 대하여 통지할 수 있습니다.
                        </p>
                        <p>② 제1항에도 불구하고, 회사는 루타스 플랫폼에 공지함으로써 불특정 다수 회원에 대한 통지에 갈음할 수 있습니다.</p>
                    </section>

                    <section className="mb-4">
                        <h4>제 11 조 이용계약의 해지</h4>
                        <p>
                            ① 회원은 이용계약을 해지하고자 할 경우 언제든지 서비스상에서 또는 회사가 정한 별도의 방법으로 회사에 회원탈퇴 신청을
                            하여야 합니다.
                        </p>
                        <p>② 회사는 다음 각 호의 경우 통지 없이 이용계약을 해지할 수 있습니다.</p>
                        <ol>
                            <li>회원이 제 7 조에서 정한 회원의 의무를 이행하지 않은 경우</li>
                            <li> 회원이 약관 개정에 부동의 한 경우</li>
                        </ol>
                        <p>
                            ③ 회사는 다음 각 호의 경우 14일 이상의 상당한 기간을 정하여 회원에게 통지하고 그 기간 내에 회원이 회신하지 아니한 때에는
                            이용계약을 해지할 수 있습니다.
                        </p>
                        <ol>
                            <li>회원이 회원정보를 사실과 다르게 기재한 것으로 의심되는 경우</li>
                        </ol>
                        <p>④ 이용계약 해지 시 회사는 관련 법령 등에 의하여 보존할 의무가 있는 정보를 제외한 나머지 정보를 즉시 삭제합니다.</p>
                        <p>⑤ 회사는 본 조에서 정한 해지 사유가 발생한 경우 해지에 갈음하여 접속을 제한하는 등의 상당한 조치를 취할 수 있습니다.</p>
                        <p>
                            ⑥ 회사는 개인정보보호 관련법률 및 관련기관의 규제나 지침에 따른 요청이 있는 경우 회원의 탈회처리 등의 조치를 취할 수
                            있습니다.
                        </p>
                    </section>

                    <section className="mb-4">
                        <h4>제 12 조 양도 금지</h4>
                        <p>회원은 회사의 승인 없이 이용계약상 지위 및 그로부터 발생하는 권리와 의무를 제3자에게 양도, 증여할 수 없습니다.</p>
                    </section>

                    <section className="mb-4">
                        <h4>제 13 조 서비스의 내용</h4>
                        <p>① 서비스는 회사 및 회사가 제공하는 재화와 용역의 소개를 내용으로 합니다.</p>
                        <p>
                            ② 제1항에도 불구하고, 회사는 서비스에서 제공한 정보의 정확성에 대하여 보증하지 않습니다. 회원은 서비스에서 취득한 정보를
                            의사결정에 이용하기 전 해당 정보를 신뢰할 수 있는지 여부에 대하여 회사에 문의하여야 할 의무가 있으며, 회사는 회원이 이러한
                            의무를 해태하고 서비스상의 정보를 신뢰함으로써 발생한 손해에 대하여 책임을 지지 않습니다.
                        </p>
                        <p>
                            ③ 회사는 회원에게 특정한 내용의 서비스를 제공하여야 할 의무를 부담하지 않으며, 기존에 제공된 서비스의 내용은 회사가 미래에
                            제공할 서비스의 내용을 보장하지 않습니다.
                        </p>
                        <p>
                            ④ 루타스 플랫폼에서 서비스 외의 외부 웹페이지로의 링크를 직접 제공하더라도, 회사는 외부 웹페이지와 무관하며 이에 대한
                            관리∙감독 의무를 부담하지 않습니다. 회사는 회원이 외부 웹페이지를 이용하면서 형성한 법률관계에 있어서 어떠한 책임도
                            부담하지 않습니다.
                        </p>
                        <p>
                            ⑤ 회사는 수시로 서비스의 향상 또는 회사 정책의 변경 등의 이유가 있는 경우에 운영상, 기술상의 필요에 따라 제공하고 있는
                            서비스의 전부 또는 일부 내용을 별도의 통지 없이 변경할 수 있습니다.
                        </p>
                        <p>⑥ 회사는 서비스의 변경, 중단으로 발생하는 문제에 대해서는 어떠한 책임도 지지 않습니다.</p>
                        <p>
                            ⑦ 회사는 개인별 서비스 사용 편의를 포함한 더 나은 서비스를 위하여 서비스에 필요한 소프트웨어의 업데이트 버전을 제공할 수
                            있습니다. 소프트웨어의 업데이트에는 서비스 제공·개선, 신규 서비스 개발을 통한 신규 기능 추가와 불필요 기능 제거 등이
                            포함되어 있습니다.
                        </p>
                    </section>

                    <section className="mb-4">
                        <h4>제 14 조 서비스의 개시</h4>
                        <p>
                            다른 특별한 사정이 없는 한, 회사는 회원의 회원가입 신청을 승인한 때부터 서비스를 개시합니다. 단, 서비스 중 일부는 지정된
                            때부터 개시할 수 있습니다.
                        </p>
                    </section>

                    <section className="mb-4">
                        <h4>제 15 조 서비스의 이용시간</h4>
                        <ol>
                            <li>서비스의 이용시간은 연중무휴 1일 24시간을 원칙으로 합니다.</li>
                            <li>회사는 서비스를 일정범위로 분할하여 각 범위 별로 이용 가능한 시간을 별도로 정할 수 있습니다.</li>
                            <li>정비 관련 일부 서비스에 한하여 매일 오후 10시부터 익일 오후 6시까지 실시간성 서비스의 이용이 제한될 수 있습니다.</li>
                        </ol>
                    </section>

                    <section className="mb-4">
                        <h4>제 16 조 서비스의 중지</h4>
                        <p>
                            ① 회사는 사업 종목의 전환, 업체 간의 통합, 사업 폐지 등 기타 회사의 판단에 의해 불가피한 영업상의 이유가 있다고 인정되는
                            경우 제공하는 서비스의 내용을 변경할 수 있습니다. 이 경우에는 변경된 서비스의 내용 및 제공 일자를 명시하여 서비스 내용을
                            게시한 곳에 그 제공 일자의 30일 전부터 공지합니다.
                        </p>
                        <p>② 회사는 업무상 필요한 경우 서비스의 전부 또는 일부를 일정 기간 동안 중지할 수 있습니다.</p>
                        <p>③ 회사는 서비스를 중지할 경우 해당 내용을 사전 또는 사후에 루타스 플랫폼에 공지하거나 회원에게 통지합니다.</p>
                        <p>
                            ④ 회사는 다음 각 호의 어느 하나에 해당하는 사유가 발생한 경우 제13조 서비스의 내용에 명시된 서비스의 일부 또는 전부를
                            중단할 수 있습니다.
                        </p>
                        <ol>
                            <li>
                                천재지변, 전쟁, 폭동, 화재, 파업 등 쟁의행위, 정부 기관의 통제, 기타 회사의 합리적인 노력으로 제어할 수 없는 사유가
                                발생하거나 발생할 우려가 있는 경우
                            </li>
                            <li>기간통신사업자로부터 전기통신 서비스가 제공되지 않은 경우</li>
                            <li>회사의 시스템을 포함한 정보통신 설비의 보수점검, 교체 또는 고장, 통신의 두절 등의 사유가 발생한 경우</li>
                            <li>제3자와의 제휴를 통하여 제공되는 서비스의 경우에 당해 제휴사업자의 사정에 따라 변경되거나 중지되는 경우</li>
                            <li>기타 회사의 원활한 운영을 현저히 저해하는 사유가 발생한 경우</li>
                        </ol>
                        <p>
                            ⑤ 회사는 제1항 또는 제2항의 사유로 서비스 내용이 변경 또는 중단되는 경우 이로 인해 회원이 입은 손해에 대하여 고의 또는
                            과실이 없는 이상 책임을 부담하지 않습니다.
                        </p>
                    </section>

                    <section className="mb-4">
                        <h4>제 17 조 서비스의 변경</h4>
                        <p>회사는 서비스의 향상을 위하여 별도의 통지 없이 서비스의 전부 또는 일부를 변경할 수 있습니다.</p>
                    </section>

                    <section className="mb-4">
                        <h4>제 18 조 서비스상 정보의 제공</h4>
                        <p>
                            ① 회사는 서비스를 운영하며 제공하고자 하는 각종 정보를 루타스 플랫폼에 게재하거나 우편 및 전자적 전송매체를 이용하여
                            회원에게 전송할 수 있습니다.
                        </p>
                        <p>
                            ② 제1항에도 불구하고, 회사가 전자적 전송매체를 이용하여 영리목적의 광고성 정보를 전송하고자 하는 경우 회원은
                            수신거부의사를 표시하거나 사전 동의를 철회할 수 있습니다. 이 경우 회사는 정보통신망 이용촉진 및 정보보호 등에 관한
                            법률에서 정하는 내용을 준수합니다.
                        </p>
                    </section>

                    <section className="mb-4">
                        <h4>제 19 조 회원 작성 자료</h4>
                        <p>
                            ① 회원 작성 자료에 대한 권리와 의무는 해당 회원에게 있습니다. 회사는 관련 법령에서 정한 사유가 아닌 한 회원 작성 자료에
                            관한 회원과 제3자 사이의 법률관계에 개입하지 않습니다.
                        </p>
                        <p>
                            ② 회사는 마케팅 등을 위하여 필요한 최소한의 범위 내에서 회원 작성 자료에 대하여 복제, 공연, 공중송신, 전시, 배포,
                            대여하거나 2차적저작물을 작성할 수 있습니다. 이러한 범위 안에서 회원은 저작권법에 따라 회사 및 그 계열회사에 회원 작성
                            자료의 이용을 무상으로 허락한 것으로 간주합니다.
                        </p>
                        <p>
                            ③ 회원은 각자의 회원 작성 자료가 제3자의 권리를 침해하지 않도록 하여야 합니다. 회사는 회원 작성 자료의 제3자 권리 침해
                            여부에 대해서는 어떠한 적극적∙실질적 조사도 수행하지 아니하므로, 회원은 회원 작성 자료가 회사의 승인 절차를 거쳤다거나
                            일정 기간 동안 회사에 의하여 삭제되지 않았다는 등의 이유로 회사가 제3자에 대한 권리 침해에 기여한 것이라 주장할 수
                            없습니다.
                        </p>
                        <p>
                            ④ 회사가 회원으로부터 서비스 이용에 대한 대가를 지급받지 않는 한, 회사는 회원 작성 자료를 안전하게 보관할 의무를 부담하지
                            아니하며, 정책에 따라 회원 작성 자료를 삭제함으로써 발생하는 어떠한 책임도 부담하지 않습니다. 개별 회원은 필요 시 각자의
                            회원 작성 자료를 복제하여 별도의 장치에 안전하게 보관하여야 합니다.
                        </p>
                        <p>⑤ 회사는 회원 작성 자료가 다음 각 호의 경우에 해당한다고 판단되는 경우 사전 통지 없이 삭제할 수 있습니다.</p>
                        <ol>
                            <li>
                                회사 또는 제3자의 권리를 침해하거나 침해할 우려가 있는 경우(공연성 없는 모욕∙명예훼손, 욕설, 업무방해미수, 회사 또는
                                제3자에 대한 무권대리 등을 포함합니다)
                            </li>
                            <li>회사가 정한 게시기간을 초과한 경우</li>
                            <li>회사에서 제공하는 서비스와 관련 없는 경우</li>
                            <li>회사의 사전 승인 없이 광고성 정보를 게시하는 경우</li>
                            <li>선량한 풍속 기타 사회 질서에 위반되는 경우</li>
                            <li>기타 관련 법령 및 회사의 공개∙비공개된 정책 등에 위반되는 경우</li>
                        </ol>
                        <p>
                            ⑥ 서비스상 일반에게 공개를 목적으로 제공된 정보로 사생활 침해나 명예훼손 등 회원의 권리가 침해된 경우 그 침해를 받은
                            회원은 고객센터에 침해사실을 소명하여 정보통신망 이용촉진 및 정보보호 등에 관한 법률에 따라 해당 정보의 삭제 또는
                            반박내용의 게재를 요청할 수 있습니다.
                        </p>
                        <p>⑦ 회사는 회원 작성 자료에 관한 정책을 별도로 정할 수 있습니다.</p>
                    </section>

                    <section className="mb-4">
                        <h4>제 20조 방어의무</h4>
                        <p>
                            ① 회원의 행위로 인하여 제3자가 권리 침해를 이유로 회사에 소송이나 이의를 제기하는 경우, 해당 회원은 자신의 비용으로 회사를
                            방어하고 제3자에게 손해를 배상하여야 합니다.
                        </p>

                        <p>
                            ② 회사의 최고에도 불구하고 해당 회원이 상당한 기간 내에 제1항의 방어의무를 이행하지 않는 경우, 회사는 자신의 비용으로
                            제3자의 청구를 방어할 수 있으며 이를 방어하기 위하여 지출한 재판상 또는 재판 외의 비용(변호사보수 등 사건 수행과 관련된
                            제반 비용을 포함합니다)을 해당 회원에게 구상할 수 있습니다. 이 경우 회사는 해당 회원에 대하여 그 비용의 선급을 청구할 수
                            있습니다.
                        </p>
                    </section>

                    <section className="mb-4">
                        <h4>제 21조 면책사항</h4>
                        <p>
                            ① 회사가 제공하는 서비스로 인하여 회원에게 손해가 발생한 경우 그러한 손해가 회사의 고의나 중과실에 기해 발생한 경우에
                            한하여 회사가 책임을 부담하며, 그 책임의 범위는 통상손해에 한합니다.
                        </p>

                        <p>
                            ② 회사는 회원이 서비스에 게재한 정보, 자료, 사실의 정확성, 신뢰성 등 그 내용에 관하여는 어떠한 책임을 부담하지 아니하고,
                            회원은 자신의 책임아래 서비스를 이용하며, 서비스를 이용하여 게시 또는 전송한 자료 등에 관하여 손해가 발생하거나 자료의
                            취사선택, 기타 서비스 이용과 관련하여 어떠한 불이익이 발생하더라도 이에 대한 모든 책임은 회원에게 있습니다.
                        </p>

                        <p>
                            ③ 회사는 회원의무 규정에 위반하여 회원간 또는 회원과 제 3자간에 서비스를 매개로 한 물품 거래 등과 관련하여 어떠한 책임도
                            부담하지 아니하고, 회원이 서비스의 이용과 관련하여 기대하는 이익에 관하여 책임을 부담하지 않습니다.
                        </p>

                        <p>
                            ④ 회사는 회원 상호간 또는 회원과 제3자 상호간에 서비스를 매개로 발생한 분쟁에 대해서는 개입할 의무가 없으며 이로 인한
                            손해를 배상할 책임도 없습니다.
                        </p>

                        <p>
                            ⑤ 회원이 회원의무 규정 기타 이 약관의 규정을 위반함으로 인하여 회사가 회원 또는 제 3자에 대하여 책임을 부담하게 되고,
                            이로써 회사에게 손해가 발생하게 되는 경우, 이 약관을 위반한 회원은 회사에게 발생하는 모든 손해를 배상하여야 하며, 동
                            손해로부터 회사를 면책시켜야 합니다.
                        </p>

                        <p>
                            ⑥ 회사는 천재지변 또는 이에 준하는 불가항력으로 인하여 서비스를 제공할 수 없는 경우에는 서비스 제공에 관한 책임이
                            면제됩니다.
                        </p>

                        <p>⑦ 회사는 이용자의 귀책사유로 인한 서비스 이용의 장애에 대하여는 책임을 지지 않습니다.</p>
                    </section>

                    <section className="mb-4">
                        <h4>제 22조 준거법</h4>
                        <p>이 약관의 준거법은 대한민국법으로 합니다.</p>
                    </section>

                    <section className="mb-4">
                        <h4>제 23조 관할법원</h4>
                        <p>
                            ① 서비스 이용과 관련하여 회사와 회원 사이에 분쟁이 발생한 경우, 회사와 회원은 분쟁의 해결을 위해 1개월의 협의기간을 두어
                            성실히 협의합니다.
                        </p>
                        <p>
                            ② 제1항의 협의에도 불구하고 분쟁이 해결되지 않을 경우, 양 당사자는 제1항의 협의기간이 도과한 후 대한민국 내의 법정
                            관할법원에 소를 제기할 수 있습니다.
                        </p>
                    </section>

                    <section className="mb-4">
                        <h4>부칙</h4>
                        <p>▸본 약관은 2022년 05월 25일부터 적용됩니다.</p>
                    </section>
                </section>
            </Col>
        </>
    );
}

export default TOS;
