import React, { useEffect } from "react";

import Sheet from "react-modal-sheet";

import * as Utils from "../utils/utils";
import * as Common from "../commons/common";

const CustomSheet = ({
    isOpen,
    content,
    className,
    snapHeight,
    onClose,
}: {
    isOpen: boolean;
    content: any;
    className?: string;
    snapHeight?: number;
    onClose: () => void;
}) => {
    useEffect(() => {
        Utils.onEvent(Common.EVENT_CLOSE_ALL_MODAL, onClose);

        return () => {
            Utils.offEvent(Common.EVENT_CLOSE_ALL_MODAL, onClose);
        };
    }, []);

    useEffect(() => {
        if (isOpen) Utils.addModalStack();
        else Utils.popModalStack();
    }, [isOpen]);

    return (
        <Sheet
            className={`${className} custom-sheet`}
            isOpen={isOpen}
            onClose={onClose}
            style={{ zIndex: 999 }}
            snapPoints={[snapHeight || 700, 0]}
            initialSnap={0}
        >
            <Sheet.Container>
                <Sheet.Header></Sheet.Header>
                <Sheet.Content>{content}</Sheet.Content>
            </Sheet.Container>
            <Sheet.Backdrop />
        </Sheet>
    );
};

export default CustomSheet;
