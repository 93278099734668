import React from "react";
import { Button, Row, Modal, Col } from "antd";

import * as String from "../../commons/string";

import styles from "../../pages/ruta40/Home.module.css";

declare const window: typeof globalThis & {
    webkit: any;
};

function NoEntryPopup({ open, className, onChangedOpen }: { open: boolean; className: string; onChangedOpen: (opened: boolean) => void }) {
    const handleClose = () => {
        onChangedOpen(false);
    };

    const handleCloseToday = () => {
        const saveDay = new Date();
        const obj = {
            value: saveDay.toDateString(),
            expire: new Date(saveDay.setDate(saveDay.getDate() + 1)),
        };

        const noEntryPopupClosePeriod = JSON.stringify(obj);
        window.localStorage.setItem("noEntryPopupClosePeriod", noEntryPopupClosePeriod);
        onChangedOpen(false);
    };

    return (
        <Modal
            closable={false}
            maskClosable={false}
            mask={false}
            className={`${styles.popup} homePopup ${className}`}
            open={open}
            onOk={handleClose}
            onCancel={handleClose}
            footer={
                <Row>
                    <Col style={{ textAlign: "start" }} span={12}>
                        <Button className={styles.popupCloseAlldayButton} type="text" onClick={handleCloseToday}>
                            오늘 하루 닫기
                        </Button>
                    </Col>
                    <Col span={12}>
                        <Button className={styles.popupCloseButton} type="text" onClick={handleClose}>
                            {String.close}
                        </Button>
                    </Col>
                </Row>
            }
        >
            <div className={styles.noEntryPopupBody}>
                {/* <Button
                    size="large"
                    className={styles.linkPopupButton}
                    onClick={() => {
                        window.open("http://autosalonweek.com/2023/v2.php?s=32");
                    }}
                >
                    티켓 예매하기
                </Button> */}
            </div>
        </Modal>
    );
}

export default NoEntryPopup;
