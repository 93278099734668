import React, { useEffect } from "react";
import { Button, Modal, Space } from "antd";

import * as Common from "../../commons/common";
import * as Utils from "../../utils/utils";
import * as String from "../../commons/string";

import styles from "./ModalEvSearchInfo.module.css";

function ModalEvSearchInfo({ openModal, onChangedOpenModal }: { openModal: boolean; onChangedOpenModal: (opened: boolean) => void }) {
    useEffect(() => {
        openModal ? Utils.addModalStack() : Utils.popModalStack();
    }, [openModal]);

    const handleDonotShowInfo = () => {
        Utils.setLocalStorage(Common.CONTEXT_DONOT_SHOW_EVINFO, "true");
    };

    return (
        <Modal
            key="modalEvSearchInfo"
            className={styles.confirmModal}
            centered
            open={openModal}
            closable={false}
            onCancel={() => onChangedOpenModal(false)}
            footer={false}
        >
            <div className={styles.modalText}>
                <div>현재 충전소 찾기 기능은</div>
                <div>
                    <span style={{ color: "#3B6BCC", fontWeight: 700, fontSize: 16 }}>대영채비, 시그넷(SK시그넷), 환경부</span>의
                </div>
                <div>급속 충전기가 설치되어 있는</div>
                <div style={{ marginBottom: "8px" }}>충전소 위치만 안내하고 있으며,</div>

                <div>에러 발생률이 낮은</div>
                <div>
                    <span style={{ color: "#3B6BCC", fontWeight: 700, fontSize: 16 }}>대영채비, 시그넷(SK시그넷)</span>의
                </div>
                <div>급속 충전기만</div>
                <div>이용하실 것을 권고 드립니다.</div>
            </div>
            <Space>
                <Button
                    key="notShow"
                    className={styles.secondaryBtn}
                    onClick={() => {
                        handleDonotShowInfo();
                        onChangedOpenModal(false);
                    }}
                >
                    {String.ignore}
                </Button>
                <Button className={styles.primaryBtn} type="primary" key="confirm" onClick={() => onChangedOpenModal(false)}>
                    {String.confirm}
                </Button>
            </Space>
        </Modal>
    );
}

export default ModalEvSearchInfo;
