import React, { useEffect, useState } from "react";
import { useAsyncAxios, utilAxiosWithAuth } from "../../utils/customAxios";
import { Col, Tabs } from "antd";
import { AlertType, useAlert } from "../../provider/AlertProvider";
import { BrowserView, MobileView } from "react-device-detect";
import type { TabsProps } from "antd";

import ReservationCard from "../../component/rent/ReservationCard";
import moment from "moment";
import NoData from "../../component/NoData";

import * as String from "../../commons/string";
import * as TypeDTO from "../../commons/typeDTO";
import * as Request from "../../commons/request";
import CustomLoading from "../../component/rent/CustomLoading";

type RentType = {
    currentRents: Array<TypeDTO.RentDto>;
    lastRents: Array<TypeDTO.RentDto>;
};

const ReservationDetails = () => {
    const alert = useAlert();
    const [rents, setRents] = useState<RentType>();
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        requestGetRents();
    }, []);

    const items: TabsProps["items"] = [
        {
            key: "1",
            label: "예약 내역",
            children: (
                <>
                    <h6>총 {rents?.currentRents.length}건</h6>
                    {loading ? (
                        <CustomLoading />
                    ) : !rents || rents.currentRents.length === 0 ? (
                        <NoData msg="예약 내역이 없습니다."></NoData>
                    ) : (
                        rents.currentRents.map((rent, index) => (
                            <ReservationCard
                                key={rent.rentId}
                                rentId={rent.rentId}
                                isLast={rents.currentRents.length <= index + 1}
                                isPast={false}
                                onCancel={(cancelAmount) => onCancel(rent, cancelAmount)}
                            />
                        ))
                    )}
                </>
            ),
        },
        {
            key: "2",
            label: "지난 내역",
            children: (
                <>
                    <h6>총 {rents?.lastRents.length}건</h6>
                    {loading ? (
                        <CustomLoading />
                    ) : !rents || rents.lastRents.length === 0 ? (
                        <NoData msg="예약 내역이 없습니다."></NoData>
                    ) : (
                        rents.lastRents.map((rent, index) => (
                            <ReservationCard key={rent.rentId} rentId={rent.rentId} isPast={true} isLast={rents.lastRents.length <= index + 1} />
                        ))
                    )}
                </>
            ),
        },
    ];

    const checkType = (value: TypeDTO.RentDto) => {
        if (value?.canceledDate) {
            return String.cancellationComplete;
        } else if (value.returnDate) {
            return String.useComplete;
        } else if (value.usedDate) {
            return String.inUse;
        } else if (moment(value?.endDate).isBefore(moment(), "day")) {
            return String.expiration;
        } else if (value?.rentPayments?.find((payment) => payment.rentPaymentType === "RENT")?.paidDate) {
            return String.reservationComplete;
        } else {
            return String.dash;
        }
    };

    const requestAxiosGetRents = async () => {
        const response = await utilAxiosWithAuth().get(Request.RENT_MINE_URL);
        return response.data;
    };

    const { loading: loadingGetRents, error: errorGetRents, data: resultGetRents, execute: requestGetRents } = useAsyncAxios(requestAxiosGetRents);

    useEffect(() => {
        setLoading(true);
    }, [loadingGetRents]);

    useEffect(() => {
        if (!resultGetRents) return;

        const currentRents: Array<TypeDTO.RentDto> = [];
        const lastRents: Array<TypeDTO.RentDto> = [];

        resultGetRents.rents.map((item: TypeDTO.RentDto) =>
            checkType(item) === String.reservationComplete || checkType(item) === String.inUse ? currentRents.push(item) : lastRents.push(item)
        );

        setRents({
            currentRents: currentRents,
            lastRents: lastRents,
        });

        setLoading(false);
    }, [resultGetRents]);

    useEffect(() => {
        if (!errorGetRents) return;
        console.log("errorGetRents", errorGetRents);

        setLoading(false);
    }, [errorGetRents]);

    //paymentCancel
    const requestAxiosCancelPayment = async (rentPaymentId: number, cancelAmount: number) => {
        const response = await utilAxiosWithAuth().post(Request.RENT_PAYMENT_CANCEL_URL + rentPaymentId + "?cancelAmount=" + cancelAmount);
        return response.data;
    };

    const {
        loading: loadingCancelPayment,
        error: errorCancelPayment,
        data: resultCancelPayment,
        execute: requestCancelPayment,
    } = useAsyncAxios(requestAxiosCancelPayment);

    useEffect(() => {
        setLoading(true);
    }, [loadingCancelPayment]);

    useEffect(() => {
        if (!resultCancelPayment) return;
        console.log("resultCancelPayment", resultCancelPayment);

        requestCancelRent(resultCancelPayment.rentPayment.rent.rentId);
        setLoading(false);
    }, [resultCancelPayment]);

    useEffect(() => {
        if (!errorCancelPayment) return;
        console.log("errorPaymentCarComplete", errorCancelPayment);

        setLoading(false);
    }, [errorCancelPayment]);

    //rentCancel
    const requestAxiosCancelRent = async (rentId: number) => {
        const response = await utilAxiosWithAuth().post(Request.RENT_URL + rentId + Request.RENT_CANCEL_URL);
        return response.data;
    };

    const {
        loading: loadingCancelRent,
        error: errorCancelRent,
        data: resultCancelRent,
        execute: requestCancelRent,
    } = useAsyncAxios(requestAxiosCancelRent);

    useEffect(() => {
        setLoading(true);
    }, [loadingCancelRent]);

    useEffect(() => {
        if (!resultCancelRent) return;
        console.log("resultCancelRent", resultCancelRent);

        alert.setAlert(AlertType.SUCCESS, "취소 성공", "예약이 취소되었습니다.");
        requestGetRents();
        setLoading(false);
    }, [resultCancelRent]);

    useEffect(() => {
        if (!errorCancelRent) return;

        console.log("errorRentCarComplete", errorCancelRent);
        setLoading(false);
    }, [errorCancelRent]);

    const onCancel = (value: TypeDTO.RentDto, cancelAmount: number) => {
        requestCancelPayment(value?.rentPayments?.find((payment) => payment?.rentPaymentType === "RENT")?.rentPaymentId, cancelAmount);
    };

    return (
        <>
            <MobileView className="mobile" style={{ minHeight: "calc(100vh - 70px)" }}>
                <div className="header-container">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="fadeIn text-center">
                                <h2 className="fs-title">{String.reservationInfo}</h2>
                            </div>
                        </div>
                    </div>
                </div>
                <Tabs className="model-tabs tab-lg mt-3 space-page" defaultActiveKey="1" items={items} />
            </MobileView>
            <BrowserView className="browser">
                <div className="header-container">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="fadeIn text-center">
                                <h2 className="rent-title">{String.reservationInfo}</h2>
                            </div>
                        </div>
                    </div>
                </div>
                <Col
                    xs={{ span: 24 }}
                    sm={{ span: 22, offset: 1 }}
                    lg={{ span: 18, offset: 3 }}
                    xl={{ span: 16, offset: 4 }}
                    xxl={{ span: 14, offset: 5 }}
                    className="mb-4 p-4"
                >
                    <Tabs className="order-tabs" defaultActiveKey="1" items={items} />
                </Col>
            </BrowserView>
        </>
    );
};

export default ReservationDetails;
