import { Input, Select, Space } from "antd";
import React, { useEffect, useState } from "react";
import { BrowserView, MobileView } from "react-device-detect";

function InputEmail({ value, disabled, onChange, ...props }: { value?: string; disabled?: boolean; onChange?: (value: string) => void }) {
    const { Option } = Select;

    const [domain, setDomain] = useState("none");

    const [email, setEmail] = useState("");
    const [emailDomain, setEmailDomain] = useState("");

    useEffect(() => {
        if (domain !== "none") setEmailDomain(domain);
        else setEmailDomain("");
    }, [domain]);

    useEffect(() => {
        if (value && value.length > 0) {
            const words = value.split("@");
            setEmail(words[0]);
            setEmailDomain(words[1]);
        }
    }, [value]);

    useEffect(() => {
        if (disabled) return;
        onChange && onChange(email.length > 0 || emailDomain.length > 0 ? email + "@" + emailDomain : "");
    }, [email, emailDomain]);

    return (
        <>
            <MobileView className="mobile">
                <Space className="space-w-100" direction="vertical">
                    <Input
                        className="login-input"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        onInput={(e) => ((e.target as HTMLInputElement).value = (e.target as HTMLInputElement).value.toLowerCase())}
                        {...props}
                        placeholder="이메일"
                    />
                    <Space>
                        <Input
                            className="login-input"
                            disabled={domain === "none" ? false : true}
                            prefix="@"
                            value={emailDomain}
                            onChange={(e) => setEmailDomain(e.target.value)}
                            onInput={(e) => ((e.target as HTMLInputElement).value = (e.target as HTMLInputElement).value.toLowerCase())}
                        />
                        <Select className="login-select" value={domain} onChange={(value) => setDomain(value)}>
                            <Option value="none">직접 입력</Option>
                            <Option value="rutas.co.kr">rutas.co.kr</Option>
                            <Option value="naver.com">naver.com</Option>
                            <Option value="daum.net">daum.net</Option>
                            <Option value="kakao.com">kakao.com</Option>
                            <Option value="hanmail.net">hanmail.net</Option>
                            <Option value="gmail.com">gmail.com</Option>
                            <Option value="msn.com">msn.com</Option>
                            <Option value="nate.com">nate.com</Option>
                        </Select>
                    </Space>
                </Space>
            </MobileView>
            <BrowserView className="browser">
                <Space className="space-w-100">
                    <Input
                        className="login-input"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        onInput={(e) => ((e.target as HTMLInputElement).value = (e.target as HTMLInputElement).value.toLowerCase())}
                        {...props}
                    />
                    <span>@</span>
                    <Input
                        className="login-input"
                        disabled={domain === "none" ? false : true}
                        value={emailDomain}
                        onChange={(e) => setEmailDomain(e.target.value)}
                        onInput={(e) => ((e.target as HTMLInputElement).value = (e.target as HTMLInputElement).value.toLowerCase())}
                    />
                    <Select className="login-select" value={domain} onChange={(value) => setDomain(value)}>
                        <Option value="none">직접 입력</Option>
                        <Option value="rutas.co.kr">rutas.co.kr</Option>
                        <Option value="naver.com">naver.com</Option>
                        <Option value="daum.net">daum.net</Option>
                        <Option value="kakao.com">kakao.com</Option>
                        <Option value="hanmail.net">hanmail.net</Option>
                        <Option value="gmail.com">gmail.com</Option>
                        <Option value="msn.com">msn.com</Option>
                        <Option value="nate.com">nate.com</Option>
                    </Select>
                </Space>
            </BrowserView>
        </>
    );
}

export default InputEmail;
