import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { BrowserView, MobileView } from "react-device-detect";
import { Modal, Menu, Space, Row, Col, Tooltip, Button } from "antd";
import { useAuthDispatch, useAuthState } from "../provider/AuthProvider";
import { AlertType, useAlert } from "../provider/AlertProvider";
import { InfoCircleFilled, UserOutlined, LeftOutlined } from "@ant-design/icons";

import type { MenuProps } from "antd";

import * as Common from "../commons/common";
import * as Utils from "../utils/utils";

import logoImageLight from "../assets/images/logo_light.svg";
import logoImageDark from "../assets/images/logo_dark.svg";
import iconCarChange from "../assets/images/home/rent/digitalkey_car_change.svg";
import iconInfo from "../assets/images/infoIcon.svg";
import Navbar from "react-bootstrap/Navbar";
import styles from "./LayoutHeader.module.css";

declare const window: typeof globalThis & {
    Android: any;
    webkit: any;
};

type MenuItem = Required<MenuProps>["items"][number];
const rootSubmenuKeys = ["model", "shop", "brand", "info", "support", "user", "account"];

function LayoutHeader({
    type,
    theme,
    closeMenuEvent,
    lineBanner,
}: {
    type: "rent" | "home";
    theme: string;
    closeMenuEvent?: boolean;
    lineBanner?: boolean;
}) {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useAuthDispatch();
    const userDetails = useAuthState();
    const [current, setCurrent] = useState("home");
    const [openKeys, setOpenKeys] = useState(["home"]);
    const [resize, setResize] = useState(window.innerWidth);
    const alert = useAlert();
    const { confirm } = Modal;

    const [menuExpanded, setMenuExpanded] = useState(false);

    const noModelnoCatalog = () => {
        Modal.info({
            title: "오픈 예정",
            content: "해당 모델은 서비스 준비중입니다.\n정식 오픈까지 기대해 주세요.",
            okText: "확인",
            onOk() {},
            centered: true,
        });
    };

    const noModelyesCatalog = () => {
        confirm({
            icon: <InfoCircleFilled />,
            title: "오픈 예정",
            content: "해당 모델은 서비스 준비중입니다.\n정식 오픈 전까지 카탈로그를 참고해 주세요.",
            okText: "확인",
            cancelText: "카탈로그 보기",
            onCancel() {
                navigate(Common.PAGE_DOWNLOADCENTER);
            },
            centered: true,
        });
    };

    const noService = () => {
        Modal.info({
            title: "오픈 예정",
            content: "서비스 준비중입니다.",
            okText: "확인",
            onOk() {},
            centered: true,
        });
    };

    const noServicePromotion = () => {
        Modal.info({
            title: "오픈 예정",
            content: "상품 준비중입니다.\n정식 오픈까지 기대해 주세요.",
            okText: "확인",
            onOk() {},
            centered: true,
        });
    };

    useEffect(() => {
        if (closeMenuEvent !== undefined) {
            setMenuExpanded(false);
        }
    }, [closeMenuEvent]);

    const handleLogin = () => {
        // sessionStorage.setItem(Common.SESSION_AFTER_LOGIN_PATH, location.pathname);
        navigate(Common.PAGE_LOGIN, { state: location.pathname });
    };

    const handleLogout = () => {
        if (dispatch !== null) dispatch({ type: "LOGOUT" });
        alert.setAlert(AlertType.SUCCESS, "로그아웃", "로그아웃 하였습니다.");
        navigate(0);
    };

    const handleModel = (modelId: number) => {
        navigate(Common.PAGE_MODEL + "/" + modelId);
    };

    const onClick: MenuProps["onClick"] = (e) => {
        console.log("click ", e);
        setCurrent(e.key);
        setMenuExpanded(false);
    };

    const onOpenChange: MenuProps["onOpenChange"] = (keys) => {
        const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
        if (rootSubmenuKeys.indexOf(latestOpenKey!) === -1) {
            setOpenKeys(keys);
        } else {
            setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
        }
    };
    const handleResize = () => {
        setResize(window.innerWidth);
    };

    useEffect(() => {
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const getMyOrderMenu = () => {
        if (Utils.checkAllOpenMode(userDetails?.user?.email)) {
            return getItem(
                <Link to={Common.PAGE_MY_ORDER} className="nav-link">
                    주문내역
                </Link>,
                "order-list"
            );
        }

        return null;
    };

    function getItem(label: React.ReactNode, key: React.Key, children?: MenuItem[], type?: "group"): MenuItem {
        return {
            key,
            children,
            label,
            type,
        } as MenuItem;
    }

    const menuitemsHome: MenuItem[] = [
        !Utils.checkAllOpenMode()
            ? getItem(
                  <a onClick={() => navigate(Common.PAGE_HOME)} className="text-light nav-link">
                      홈
                  </a>,
                  "home"
              )
            : null,
        getItem(<span className="text-light">모델</span>, "model", [
            getItem(
                <a className="nav-link" onClick={() => handleModel(Common.CAMPING_CAR_CATEGORY_INDEX + 1)}>
                    캠핑카
                </a>,
                "campingCar"
            ),
            getItem(
                <a className="nav-link" onClick={() => handleModel(Common.KART_CATEGORY_INDEX + 1)}>
                    사이클 카트
                </a>,
                "cycleKart"
            ),
            getItem(
                <span onClick={() => handleModel(Common.REFRIGERATION_CAR_CATEGORY_INDEX + 1)} className="nav-link">
                    냉장차
                </span>,
                "refrigerator"
            ),
            getItem(
                <a className="nav-link" onClick={() => handleModel(Common.DRON_STATTION_CATEGORY_INDEX + 1)}>
                    드론 스테이션
                </a>,
                "droneStation"
            ),
            getItem(
                <span onClick={noModelnoCatalog} className="nav-link">
                    셔틀
                </span>,
                "shuttle"
            ),
        ]),
        !Utils.checkAllOpenMode()
            ? getItem(
                  <span onClick={noService} className="text-light nav-link">
                      카셰어링
                  </span>,
                  "sharing"
              )
            : null,
        getItem(<span className="text-light">브랜드</span>, "brand", [
            getItem(
                <a onClick={() => navigate(Common.PAGE_STORY)} className="nav-link">
                    RUTA40 : 스토리
                </a>,
                "story"
            ),
            getItem(
                <a onClick={() => navigate(Common.PAGE_DIGITALKEY)} className="nav-link">
                    디지털 키 앱
                </a>,
                "digital"
            ),
            getItem(
                <a onClick={() => navigate(Common.PAGE_FMS)} className="nav-link">
                    차량 관제 시스템
                </a>,
                "fms"
            ),
        ]),

        Utils.checkAllOpenMode()
            ? getItem(
                  <a onClick={() => navigate(Common.PAGE_MEDIA)} className="text-light nav-link">
                      미디어
                  </a>,
                  "media"
              )
            : null,
        getItem(<span className="text-light">고객지원</span>, "support", [
            getItem(
                <a onClick={() => navigate(Common.PAGE_INFORMATION)} className="nav-link">
                    주요 안내
                </a>,
                "information"
            ),
            getItem(
                <a onClick={() => navigate(Common.PAGE_FAQ)} className="nav-link">
                    자주 하는 질문
                </a>,
                "faq"
            ),
            getItem(
                <a onClick={() => navigate(Common.PAGE_DOWNLOADCENTER)} className="nav-link">
                    자료실
                </a>,
                "downloadCenter"
            ),
        ]),
        Utils.checkAllOpenMode()
            ? getItem(
                  <a onClick={() => navigate(Common.PAGE_NOTICE)} className="text-light nav-link">
                      공지/이벤트
                  </a>,
                  "notice"
              )
            : null,
        Utils.checkAllOpenMode()
            ? getItem(
                  <a
                      onClick={() => {
                          Utils.sendLinkToApp(Utils.getRentSiteUrl());
                      }}
                      className="text-special nav-link"
                  >
                      렌트
                  </a>,
                  "렌트"
              )
            : null,
        getItem(
            <Space className="text-light">
                <UserOutlined />
                <span>계정</span>
            </Space>,
            userDetails?.user ? "user" : "account",
            userDetails?.user
                ? [
                      getItem(
                          <a onClick={() => navigate(Common.PAGE_MYPAGE)} className="nav-link">
                              계정정보
                          </a>,
                          "user-information"
                      ),
                      getMyOrderMenu(),
                      getItem(
                          <a onClick={() => navigate(Common.PAGE_COUPON_DETAIL)} className="nav-link">
                              쿠폰내역
                          </a>,
                          "coupon"
                      ),
                      getItem(
                          <a className="nav-link" onClick={handleLogout}>
                              로그아웃
                          </a>,
                          "logout"
                      ),
                  ]
                : [
                      getItem(
                          <a className="nav-link" onClick={handleLogin}>
                              로그인
                          </a>,
                          "login"
                      ),
                      getItem(
                          <a onClick={() => navigate(Common.PAGE_SIGNUP)} className="nav-link">
                              회원가입
                          </a>,
                          "signUp"
                      ),
                  ]
        ),
    ];

    const menuitemsRent: MenuItem[] = [
        Utils.checkRentAllOpenMode(userDetails?.user?.email)
            ? getItem(
                  <Space className="text-light">
                      <span>고객지원</span>
                  </Space>,
                  "support",
                  [
                      getItem(
                          <a onClick={() => navigate(Common.PAGE_INFORMATION_RENT)} className="nav-link">
                              렌트 이용 안내
                          </a>,
                          "rentInformation"
                      ),
                      getItem(
                          <a onClick={() => navigate(Common.PAGE_INFORMATION_USE)} className="nav-link">
                              운행 주의사항
                          </a>,
                          "information"
                      ),
                      getItem(
                          <a onClick={() => navigate(Common.PAGE_FAQ)} className="nav-link">
                              자주 하는 질문
                          </a>,
                          "faq"
                      ),
                      getItem(
                          <a
                              onClick={() => {
                                  navigate(Common.PAGE_RENT_MANUAL);
                              }}
                              className="nav-link"
                          >
                              매뉴얼
                          </a>,
                          "manual"
                      ),
                  ]
              )
            : null,
        Utils.checkRentAllOpenMode(userDetails?.user?.email)
            ? getItem(
                  <a onClick={() => navigate(Common.PAGE_TRAVEL_SPOT)} className="text-light nav-link">
                      추천 여행지
                  </a>,
                  "travelSpot"
              )
            : null,
        Utils.checkRentAllOpenMode(userDetails?.user?.email)
            ? getItem(
                  <a onClick={() => navigate(Common.PAGE_RENT_BEST_REVIEW)} className="text-light nav-link">
                      이용 후기
                  </a>,
                  "review"
              )
            : null,
        Utils.checkRentAllOpenMode(userDetails?.user?.email)
            ? getItem(
                  <a onClick={() => navigate(Common.PAGE_RENT_NOTICE)} className="text-light nav-link">
                      공지/이벤트
                  </a>,
                  "notice"
              )
            : null,
        Utils.checkRentAllOpenMode(userDetails?.user?.email)
            ? getItem(
                  <Space className="text-light" onClick={() => noServicePromotion()}>
                      <span>프로모션</span>
                  </Space>,
                  "promotion"
                  /* TODO: 서비스 오픈 할 때 메뉴 오픈
                  [
                      getItem(
                          <a onClick={() => noServicePromotion()} className="nav-link">
                              단체 회원 상품
                          </a>,
                          "promotionGroup"
                      ),
                      getItem(
                          <a onClick={() => noServicePromotion()} className="nav-link">
                              우리 아파트 전용 캠핑카
                          </a>,
                          "promotionApt"
                      ),
                      getItem(
                          <a onClick={() => noServicePromotion()} className="nav-link">
                              이용권 공동구매 특가
                          </a>,
                          "promotion09"
                      ),
                  ]
                  */
              )
            : null,
        getItem(
            <a
                onClick={() => {
                    Utils.sendLinkToApp(Utils.getRuta40SiteUrl());
                }}
                className="text-special nav-link"
            >
                구매
            </a>,
            "purchase"
        ),
        getItem(
            <Space className="text-light">
                <UserOutlined />
                <span>계정</span>
            </Space>,
            userDetails?.user ? "user" : "account",
            userDetails?.user
                ? [
                      getItem(
                          <a onClick={() => navigate(Common.PAGE_MYPAGE)} className="nav-link">
                              계정정보
                          </a>,
                          "user-information"
                      ),
                      getItem(
                          <a onClick={() => navigate(Common.PAGE_RESERVATION_DETAIL)} className="nav-link">
                              예약내역
                          </a>,
                          "usageDetails"
                      ),
                      getItem(
                          <a onClick={() => navigate(Common.PAGE_COUPON_DETAIL)} className="nav-link">
                              쿠폰내역
                          </a>,
                          "coupon"
                      ),
                      getItem(
                          <a className="nav-link" onClick={handleLogout}>
                              로그아웃
                          </a>,
                          "logout"
                      ),
                  ]
                : [
                      getItem(
                          <a className="nav-link" onClick={handleLogin}>
                              로그인
                          </a>,
                          "login"
                      ),
                      getItem(
                          <a onClick={() => navigate(Common.PAGE_SIGNUP)} className="nav-link">
                              회원가입
                          </a>,
                          "signUp"
                      ),
                  ]
        ),
    ];

    const getMenuItems = () => {
        if (Utils.checkDomain() === Common.Domain.RENT) {
            return menuitemsRent;
        }

        return menuitemsHome;
    };

    useEffect(() => {
        Utils.onEvent(Common.EVENT_CLOSE_MENU, handleClose);

        return () => {
            Utils.offEvent(Common.EVENT_CLOSE_MENU, handleClose);
        };
    }, []);

    useEffect(() => {
        if (menuExpanded) {
            Utils.openMenuStack();
        } else {
            Utils.closeMenuStack();
        }
    }, [menuExpanded]);

    const handleClose = () => {
        setMenuExpanded(false);
    };

    return (
        <>
            {type === "rent" ? (
                <>
                    <MobileView className="mobile">
                        <Navbar
                            style={{ backgroundColor: theme === "light" ? "white" : undefined, padding: 0, top: lineBanner ? 40 : 0 }}
                            collapseOnSelect
                            variant={theme}
                            expand="lg"
                            expanded={menuExpanded}
                            onToggle={(expended) => setMenuExpanded(expended)}
                        >
                            <Row align="middle" justify="space-around" style={{ width: "100%", height: "100%" }}>
                                <Col span={4} style={{ textAlign: "center" }}>
                                    <LeftOutlined
                                        style={{
                                            display:
                                                location.pathname === Common.PAGE_HOME || location.pathname === Common.PAGE_RENT_MAP
                                                    ? "none"
                                                    : "inline-block",
                                        }}
                                        className="backBtn navbar-toggler navbar-toggler-icon"
                                        onClick={() => {
                                            setMenuExpanded(false);
                                            location.pathname.includes("detail") ? navigate(Common.PAGE_HOME) : navigate(-1);
                                        }}
                                    />
                                </Col>

                                <Col span={15} style={{ textAlign: "center" }}>
                                    <Navbar.Brand
                                        className="navbar-brand align-items-center"
                                        onClick={() => {
                                            setMenuExpanded(false);

                                            if (Utils.checkDomain() === Common.Domain.RUTA40) navigate(Common.PAGE_HOME);
                                            else if (Utils.checkDomain() === Common.Domain.RENT) {
                                                navigate(Common.PAGE_RENT_HOME);
                                            }
                                        }}
                                        style={{ margin: 0 }}
                                    >
                                        <img src={theme === "dark" ? logoImageLight : logoImageDark} style={{ cursor: "pointer" }} />
                                    </Navbar.Brand>
                                </Col>
                                <Col span={5} style={{ textAlign: "center" }}>
                                    {location.pathname === Common.PAGE_DIGITALKEY_CONTROL || location.pathname === Common.PAGE_FIND_EV_STATION ? (
                                        <>
                                            <div className={styles.subRightButton}>
                                                <Tooltip
                                                    overlayClassName={styles.mainPowerTooltip}
                                                    defaultOpen={true}
                                                    trigger="click"
                                                    placement="bottomRight"
                                                    color="white"
                                                    title={
                                                        <>
                                                            <p>메인전원 켜진 상태</p>
                                                            <p style={{ marginBottom: 8 }}>- 차량도어 잠금/해제 가능</p>
                                                            <p>KEY ON(2단계) 또는 시동 상태 </p>
                                                            <p>- 배터리/주행 정보</p>
                                                            <p>- 스윙도어 잠금/해제 가능</p>
                                                        </>
                                                    }
                                                >
                                                    <Button type="text">
                                                        <img width={24} height={24} src={iconInfo} alt="rightside icon" />
                                                    </Button>
                                                </Tooltip>
                                            </div>
                                            <img
                                                src={location.pathname === Common.PAGE_FIND_EV_STATION ? iconInfo : iconCarChange}
                                                className="navbar-toggler"
                                                onClick={() => {
                                                    Utils.triggerEvent(Common.EVENT_HEADER_INFO);
                                                }}
                                            />
                                        </>
                                    ) : (
                                        <Navbar.Toggle aria-controls="basic-navbar-nav" />
                                    )}
                                </Col>
                            </Row>
                            <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
                                <Menu
                                    openKeys={openKeys}
                                    onOpenChange={onOpenChange}
                                    triggerSubMenuAction="hover"
                                    className="menuLayout"
                                    onClick={onClick}
                                    selectedKeys={[current]}
                                    mode={resize !== undefined && resize >= 992 ? "horizontal" : "inline"}
                                    items={getMenuItems()}
                                />
                            </Navbar.Collapse>
                        </Navbar>
                    </MobileView>
                    <BrowserView className="browser">
                        <Navbar
                            style={{ backgroundColor: theme === "light" ? "white" : undefined, padding: 0, top: lineBanner ? 40 : 0 }}
                            collapseOnSelect
                            variant={theme}
                            expand="lg"
                            expanded={menuExpanded}
                            onToggle={(expended) => setMenuExpanded(expended)}
                        >
                            <Row align="middle" justify="space-around" style={{ width: "100%" }}>
                                <Col span={12}>
                                    <Navbar.Brand
                                        className="navbar-brand d-flex align-items-center px-4 px-lg-5 navbar-brand"
                                        onClick={() => {
                                            if (Utils.checkDomain() === Common.Domain.RUTA40) navigate(Common.PAGE_HOME);
                                            else if (Utils.checkDomain() === Common.Domain.RENT) {
                                                navigate(Common.PAGE_RENT_HOME);
                                            }
                                        }}
                                        style={{ margin: 0 }}
                                    >
                                        <img src={theme === "dark" ? logoImageLight : logoImageDark} style={{ cursor: "pointer" }} />
                                    </Navbar.Brand>
                                </Col>
                                <Col span={12} style={{ textAlign: "end" }}>
                                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                                    <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
                                        <Menu
                                            openKeys={openKeys}
                                            onOpenChange={onOpenChange}
                                            triggerSubMenuAction="hover"
                                            className="menuLayout"
                                            onClick={onClick}
                                            selectedKeys={[current]}
                                            mode={resize !== undefined && resize >= 992 ? "horizontal" : "inline"}
                                            items={getMenuItems()}
                                        />
                                    </Navbar.Collapse>
                                </Col>
                            </Row>
                        </Navbar>
                    </BrowserView>
                </>
            ) : (
                <Navbar
                    style={{ top: lineBanner ? 40 : 0 }}
                    collapseOnSelect
                    variant={theme}
                    expand="lg"
                    expanded={menuExpanded}
                    onToggle={(expended) => setMenuExpanded(expended)}
                >
                    <Navbar.Brand
                        className="navbar-brand d-flex align-items-center px-4 px-lg-5"
                        onClick={() => {
                            if (Utils.checkDomain() === Common.Domain.RUTA40) navigate(Common.PAGE_HOME);
                            else if (Utils.checkDomain() === Common.Domain.RENT) {
                                navigate(Common.PAGE_RENT_HOME);
                            }
                        }}
                    >
                        <img src={theme === "dark" ? logoImageLight : logoImageDark} style={{ cursor: "pointer" }} />
                    </Navbar.Brand>
                    <Navbar.Toggle className="me-4" aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
                        <Menu
                            openKeys={openKeys}
                            onOpenChange={onOpenChange}
                            triggerSubMenuAction="hover"
                            className="menuLayout"
                            onClick={onClick}
                            selectedKeys={[current]}
                            mode={resize !== undefined && resize >= 992 ? "horizontal" : "inline"}
                            items={getMenuItems()}
                        />
                    </Navbar.Collapse>
                </Navbar>
            )}
        </>
    );
}

export default LayoutHeader;
