import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import ScrollToTop from "./component/ScrollTop";

import { BrowserRouter } from "react-router-dom";
import { AuthProvider } from "./provider/AuthProvider";
import { AlertProvider } from "./provider/AlertProvider";
import { AliveScope } from "react-activation";

import "bootstrap/dist/css/bootstrap.min.css";
import { HelmetProvider } from "react-helmet-async";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
    <AuthProvider>
        <AlertProvider>
            <HelmetProvider>
                <BrowserRouter>
                    <AliveScope>
                        <ScrollToTop />
                        <App />
                    </AliveScope>
                </BrowserRouter>
            </HelmetProvider>
        </AlertProvider>
    </AuthProvider>
);

reportWebVitals();
