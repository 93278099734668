import React, { useEffect, useState } from "react";
import { Button, Card, Col, Divider, Row, Space } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { BrowserView, MobileView } from "react-device-detect";
import { useAsyncAxios, utilAxiosWithAuth } from "../../utils/customAxios";

import moment from "moment";
import Title from "../../component/rent/Title";
import ReservationDateInfo from "../../component/rent/ReservationDateInfo";
import LayoutFooter from "../../component/LayoutFooter";

import * as String from "../../commons/string";
import * as Common from "../../commons/common";
import * as Utils from "../../utils/utils";
import * as Request from "../../commons/request";
import * as TypeDTO from "../../commons/typeDTO";

const Result = () => {
    const navigate = useNavigate();
    const param = useParams();
    const sessionData = sessionStorage.getItem(Common.SESSION_RESERVATION_INFO);
    const values = sessionData ? JSON.parse(sessionData) : null;

    const [rent, setRent] = useState<TypeDTO.RentDto>();
    const [defaultParam, setDefaultParam] = useState<any>();

    useEffect(() => {
        setDefaultParam(param);

        if (param && defaultParam !== param) {
            requestGetPayment();
        }
    }, [param]);

    const title = {
        title: {
            content: values?.rentCar?.title,
        },
        tags: values?.rentCar?.rentRestrictions,
    };

    const titlePC = {
        title: {
            content: values?.rentCar?.title,
        },
        description: {
            content: values?.rentCar?.description,
        },
        tags: values?.rentCar?.rentRestrictions,
    };

    const requestAxiosGetPayment = async () => {
        const response = await utilAxiosWithAuth().get(Request.RENT_URL + param.rentId);
        return response.data;
    };

    const {
        loading: loadingGetPayment,
        error: errorGetPayment,
        data: resultGetPayment,
        execute: requestGetPayment,
    } = useAsyncAxios(requestAxiosGetPayment);

    useEffect(() => {
        if (!resultGetPayment) return;
        console.log("resultGetPayment", resultGetPayment);

        setRent(resultGetPayment.rent);
    }, [resultGetPayment]);

    useEffect(() => {
        if (!errorGetPayment) return;
        console.log("errorGetPayment", errorGetPayment);
    }, [errorGetPayment]);

    const onClick = (type: "addAdditionalDriver" | "addMyDriverLicense" | "showDetails" | "confirm") => {
        switch (type) {
            case "addAdditionalDriver":
                navigate(Common.PAGE_RESERVATION_DETAIL);
                break;
            case "addMyDriverLicense":
                navigate(Common.PAGE_MYPAGE);
                break;
            case "showDetails":
                navigate(Common.PAGE_RESERVATION_DETAIL);
                break;
            case "confirm":
                navigate(Common.PAGE_RENT_HOME);
                break;
        }
    };

    const getPaidDate = () => {
        return rent?.rentPayments?.find((payment) => payment?.rentPaymentType === "RENT")?.paidDate;
    };

    return (
        <>
            <MobileView className="mobile space-page">
                <h2 className="text-center mt-4 mb-4 fadeIn">{String.reservationComplete}</h2>
                <div className="mb-3">
                    <span style={{ fontWeight: "bold", color: "#005FDB" }}>예약 완료</span>
                    <span style={{ margin: "0 10px" }}>|</span>
                    <span style={{ color: "#192739F0" }}>
                        {getPaidDate() !== undefined && Utils.utcToLocalTime(getPaidDate() as string, Common.FORMAT_DATE_TIME)}
                    </span>
                </div>
                <Title searchType={true} info={title} />
                <Divider className="custom-divider" />
                <h5>{String.reservationInfo}</h5>
                <ReservationDateInfo
                    rentNo={rent?.rentNo}
                    period={
                        moment(values?.startDate).format(Common.FORMAT_DATE_WITH_DAY) +
                        " ~ " +
                        moment(values?.endDate).format(Common.FORMAT_DATE_WITH_DAY)
                    }
                />
                <Button block className="btn-light-border w-100 mt-3" onClick={() => onClick("showDetails")}>
                    {String.showReservationDetails}
                </Button>
                <Divider className="custom-divider" />
                <>
                    <h5>운전면허 등록</h5>
                    <p>
                        렌트 이용일 전까지 <span className="text-bold">예약자</span> 또는 <span className="text-bold">추가 운전자</span>의
                        <span className="text-bold"> 운전면허</span>를 등록하셔야 이용 가능합니다.
                    </p>
                    <p className="mt-1">※ 미등록 시 렌트카를 이용하실 수 없습니다.</p>
                    <Row gutter={10} className="mt-3">
                        <Col span={!rent?.user?.driverLicense ? 12 : 24}>
                            <Button block className="btn-light-border" onClick={(e) => onClick("addAdditionalDriver")}>
                                추가 운전자 등록
                            </Button>
                        </Col>
                        {!rent?.user.driverLicense && (
                            <Col>
                                <Button block className="btn-dark" type="primary" onClick={(e) => onClick("addMyDriverLicense")}>
                                    내 운전면허 등록
                                </Button>
                            </Col>
                        )}
                    </Row>
                </>
                <Divider className="custom-divider" style={{ marginBottom: "100px" }} />
                <LayoutFooter orderType={true} />
            </MobileView>
            <BrowserView className="browser">
                <Col
                    xs={{ span: 24 }}
                    sm={{ span: 22, offset: 1 }}
                    lg={{ span: 18, offset: 3 }}
                    xl={{ span: 16, offset: 4 }}
                    xxl={{ span: 14, offset: 5 }}
                    className="p-4"
                >
                    <div className="header-container">
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="fadeIn text-center mb-3">
                                    <h1 className="rent-title">{String.reservationComplete}</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Card>
                        <div className="mb-3">
                            <span style={{ fontWeight: "bold", color: "#005FDB" }}>예약 완료</span>
                            <span style={{ margin: "0 10px" }}>|</span>
                            <span style={{ color: "#192739F0" }}>
                                {getPaidDate() !== undefined && Utils.utcToLocalTime(getPaidDate() as string, Common.FORMAT_DATE_TIME)}
                            </span>
                        </div>
                        <Title searchType={true} info={titlePC} />
                        <Divider className="custom-divider" />
                        <Row gutter={20}>
                            <Col span={12}>
                                <h6>{String.reservationInfo}</h6>
                                <Space className="box mb-3" style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                                    <p>예약번호</p>
                                    <p>{rent?.rentNo}</p>
                                </Space>
                                <div className="box mb-3">
                                    <ReservationDateInfo
                                        period={
                                            moment(values?.startDate).format(Common.FORMAT_DATE_WITH_DAY) +
                                            " ~ " +
                                            moment(values?.endDate).format(Common.FORMAT_DATE_WITH_DAY)
                                        }
                                    />
                                </div>
                            </Col>
                            <Col span={12}>
                                <h6>운전면허 등록</h6>
                                <div className="box">
                                    <p>운전면허 미등록건이 있습니다.</p>
                                    <p>
                                        렌트 이용일 전까지 <strong>예약자</strong> 또는
                                        <strong>추가 운전자</strong>의 <strong>운전면허</strong>를 <br />
                                        등록하셔야 이용 가능합니다.
                                    </p>
                                    <Row justify="end" gutter={10} className="mt-3">
                                        <Col span={!rent?.user?.driverLicense ? 12 : 24}>
                                            <Button block className="btn-light-border" onClick={(e) => onClick("addAdditionalDriver")}>
                                                추가 운전자 등록
                                            </Button>
                                        </Col>
                                        {!rent?.user.driverLicense && (
                                            <Col>
                                                <Button block className="btn-dark" type="primary" onClick={(e) => onClick("addMyDriverLicense")}>
                                                    내 운전면허 등록
                                                </Button>
                                            </Col>
                                        )}
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                    </Card>
                    <Row gutter={10} className="mt-3 text-center" style={{ padding: "0 30%" }}>
                        <Col span={12}>
                            <Button className="btn-light-border w-100" onClick={() => onClick("showDetails")}>
                                {String.showReservationDetails}
                            </Button>
                        </Col>
                        <Col span={12}>
                            <Button className="btn-dark w-100" type="primary" onClick={() => onClick("confirm")}>
                                {String.confirm}
                            </Button>
                        </Col>
                    </Row>
                </Col>
            </BrowserView>
        </>
    );
};

export default Result;
