import * as React from "react";
import { Space } from "antd";
import { BrowserView, MobileView } from "react-device-detect";
import { CalendarOutlined, ClockCircleOutlined } from "@ant-design/icons";

import * as String from "../../commons/string";

const ReservationDateInfo = ({ rentNo, period }: { rentNo?: string; period?: string }) => {
    const reservationDates = {
        startDate: {
            name: "출차 시간",
            date: "오후 2시(안전교육 30분 포함)",
        },
        endDate: {
            name: "반납 시간",
            date: "오전 9시 ~ 오전 11시",
        },
    };
    return (
        <>
            <MobileView className="mobile">
                {rentNo && (
                    <Space style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                        <p>예약 번호</p>
                        <p>{rentNo}</p>
                    </Space>
                )}
                <Space style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                    <p style={{ textAlign: "right", wordBreak: "keep-all", whiteSpace: "nowrap" }}>{String.rentalDate}</p>
                    <p style={{ textAlign: "right", wordBreak: "keep-all" }}>{period}</p>
                </Space>
                <Space style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                    <p>{reservationDates.startDate.name}</p>
                    <p>{reservationDates.startDate.date}</p>
                </Space>
                <Space style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                    <p>{reservationDates.endDate.name}</p>
                    <p>{reservationDates.endDate.date}</p>
                </Space>
            </MobileView>
            <BrowserView className="browser">
                {rentNo && (
                    <Space style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                        <p>예약 번호</p>
                        <p>{rentNo}</p>
                    </Space>
                )}
                <Space style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <CalendarOutlined style={{ marginRight: "8px" }} />
                        <p>{String.rentalDate}</p>
                    </div>
                    <p>{period}</p>
                </Space>
                <Space style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <ClockCircleOutlined style={{ marginRight: "8px" }} />
                        <p>{reservationDates.startDate.name}</p>
                    </div>
                    <p>{reservationDates.startDate.date}</p>
                </Space>
                <Space style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                    <p style={{ paddingLeft: "22px" }}>{reservationDates.endDate.name}</p>
                    <p>{reservationDates.endDate.date}</p>
                </Space>
            </BrowserView>
        </>
    );
};

export default ReservationDateInfo;
