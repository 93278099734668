import React from "react";
import { Col } from "antd";
import { useMediaQuery } from "react-responsive";

import storyImage1 from "../../assets/images/story/ruta40_img_1.jpg";
import storyImage2 from "../../assets/images/story/ruta40_img_2.jpg";
import storyImage3 from "../../assets/images/story/ruta40_img_3.jpg";
import storyImage4 from "../../assets/images/story/ruta40_img_4.jpg";
import storyImage5 from "../../assets/images/story/ruta40_img_5.jpg";
import storyImage6 from "../../assets/images/story/ruta40_img_6.jpg";
import storyImage7 from "../../assets/images/story/ruta40_img_7.jpg";
import storyImage8 from "../../assets/images/story/ruta40_img_8.jpg";
import storyImage9 from "../../assets/images/story/ruta40_img_9.jpg";
import storyImage10 from "../../assets/images/story/ruta40_img_10.jpg";
import storyImage11 from "../../assets/images/story/ruta40_img_11.jpg";
import Banner from "../../assets/images/home/ruta40/banner_story.jpg";

function Story() {
    const isMobile = useMediaQuery({ maxWidth: 700 });

    return (
        <>
            <div className="header-container model">
                <div className="container" style={{ backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.2),rgba(0, 0, 0, 0.2)),url(${Banner})` }}>
                    <div className="row justify-content-center">
                        <div className="fadeIn text-center mb-5">
                            <h5 className="text-center text-light px-3 mb-4">Story : RUTA40</h5>
                            <h1 className="text-light">RUTA40</h1>
                        </div>
                    </div>
                </div>
            </div>
            <div className="model-tabs title model-body" style={{ backgroundColor: "white" }}>
                <Col
                    xs={{ span: 24 }}
                    sm={{ span: 22, offset: 1 }}
                    lg={{ span: 18, offset: 3 }}
                    xl={{ span: 16, offset: 4 }}
                    xxl={{ span: 14, offset: 5 }}
                    className="fadeInUp p-4"
                >
                    <div className={` row mb-4 ${!isMobile && "mt-3"}`}>
                        <div className="col-lg-6">
                            <div className="mb-3">
                                <span className="b-lg">아르헨티나 40번 국도 </span>
                                <h3 className="my-2 d-inline-block">RUTA40</h3>
                                <h3 className="my-2">그리고 영화 “모터싸이클 다이어리”</h3>
                            </div>
                            <h4 className="mb-3 section-title bg-white text-start text-skyblue pe-3">지구 가장 낯선 길 5,244km</h4>
                            <p className="mb-3">
                                RN40 또는 “Ruta 40”이라고도 알려진 국도 40은 아르헨티나 서부의 루트로, 남쪽으로는 산타 크루스 주 리오 갈레고스 인근의
                                푼타 로욜라에서 북쪽으로는 후후이 주의 라 퀴아카까지 뻗어 있 습니다. 경로는 안데스 산맥과 평행합니다. 현재 대부분
                                포장된 경로의 남쪽 부분은 유명한 모험 관광 여행이 되었으며 전체 도로를 포장할 계획이 있습니다.
                            </p>
                            <p className="mb-3">
                                RN40은 길이가 5,000km(3,100mi) 이상인 미국 루트 66, 캐나다 횡단 캐나다 고속도로, 호주 스 튜어트 고속도로와 함께
                                아르헨티나에서 가장 긴 루트이자 세계에서 가장 긴 루트 중 하나입니다. Río Gallegos시 근처의 전통적인 남쪽 끝에서
                                해수면에서 시작합니다. 그런 다음 안데스 산맥의 20개 국립공원, 18개 주요 강, 27개 고개를 가로지릅니다. Route 40의 가장
                                높은 지점은 Salta 지방의 Abra del Acay에 있는 5,000m(16,404피트)입니다.
                            </p>
                            <p className="mb-3">
                                도로는 Santa Cruz, Chubut, Río Negro, Neuquen, Mendoza, San Juan, La Rioja, Catamarca, Tucumán, Salta 및 Jujuy를
                                가로지릅니다.
                            </p>
                        </div>
                        <div className="col-lg-6" style={{ minHeight: "400px" }}>
                            <div className="position-relative h-100">
                                <img className="img-fluid position-absolute w-100 h-100" src={storyImage1} alt="" style={{ objectFit: "cover" }} />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-12 mb-0">
                        <p className="mb-0">
                            지구에서 여덟 번째 넓은 나라 아르헨티나를 세로로 관통하는 루타 40은 파타고니아, 쿠요, 노르테, 이렇게 세 지역으로 나뉜다.
                            각 지역은 저마다의 특색으로 여행가들을 매료시킨다. 남쪽에는 빙하와 호수가 그림처럼 펼쳐지고, 중부 내륙에서는 포도와
                            올리브가 어마어마한 규모로 익어 가며, 북쪽으론 끝없이 펼쳐진 선인장의 고향 캐니언이 자리한다.
                            <br />
                            <span className="source-text">– 박지현. (2012). 『루타 40 지구 가장 낯선 길 5244km』. 한국: 책읽는수요일, 9.</span>
                        </p>
                    </div>
                    <div className="row gy-4 mt-0">
                        <div className="col-lg-4 col-md-4 col-12">
                            <img className="img-fluid position-relative" src={storyImage2} alt="" style={{ objectFit: "cover" }} />
                        </div>
                        <div className="col-lg-4 col-md-4 col-12">
                            <img className="img-fluid position-relative" src={storyImage3} alt="" style={{ objectFit: "cover" }} />
                        </div>
                        <div className="col-lg-4 col-md-4 col-12">
                            <img className="img-fluid position-relative" src={storyImage4} alt="" style={{ objectFit: "cover" }} />
                        </div>
                    </div>
                    <div className="row gy-4 mt-0 mb-5">
                        <div className="col-lg-3 col-md-3 col-12">
                            <img className="img-fluid position-relative" src={storyImage5} alt="" style={{ objectFit: "cover" }} />
                        </div>
                        <div className="col-lg-3 col-md-3 col-12">
                            <img className="img-fluid position-relative" src={storyImage6} alt="" style={{ objectFit: "cover" }} />
                        </div>
                        <div className="col-lg-3 col-md-3 col-12">
                            <img className="img-fluid position-relative" src={storyImage7} alt="" style={{ objectFit: "cover" }} />
                        </div>
                        <div className="col-lg-3 col-md-3 col-12">
                            <img className="img-fluid position-relative" src={storyImage8} alt="" style={{ objectFit: "cover" }} />
                        </div>
                    </div>
                    <div className="col-lg-12">
                        <h4 className="mb-3 section-title bg-white text-start text-skyblue pe-3 ">체게바라와 모터싸이클 다이어리</h4>
                    </div>
                    <div className="col-lg-12">
                        <div className="mb-4">
                            <img className="img-fluid position-relative" src={storyImage9} />
                            <span className="source-text text-center">
                                Walter Salles. (2004). [The Motorcycle Diaries]. 미국, 독일, 영국: UIP코리아
                            </span>
                        </div>
                    </div>
                    <div className="col-lg-12">
                        <p className="mb-3">
                            젊음의 아이콘, 세기의 혁명가, 금세기 가장 완벽한 인간이라 불리는 체 게바라. 하나 된 라틴아메리카를 꿈꾸었던 그의 꿈은 그저
                            이상이었을까?
                        </p>
                        <p className="mb-3">
                            불가능한 꿈으로 비춰졌던 쿠바혁명을 실현시켰고, 또 다른 혁명 속에서 죽어갔다. 안정된 의사의 삶을 버리고 민중의 삶 속으로
                            들어가 가슴 속에 커다란 불꽃을 안고 살았던 체 게바라. 세상을 변화시키고 싶었던 그의 혁명을 따라가면 라틴아메리카는 물론
                            세상을 다르게 볼 수 있는 시각을 가질 수 있다.
                        </p>
                        <p className="mb-4">
                            모터사이클 타고 라틴아메리카 여행에 나선 호기심 어린 청년, 에르니스토 푸세르 게바라의 삶부터 혁명 천재가 된 체 게바라의
                            열정적이고 격정적인 여행 : 루타40.
                            <span className="source-text">
                                “체 게바라 루트를 가다 특강,” 비욘드코리아 네이버 블로그. 2019년12월4일 수정, 2021년8월26일 접속,
                                https://bit.ly/3jhsMMn
                            </span>
                        </p>
                    </div>
                    <div className="row mb-4">
                        <div className="col-lg-6 col-md-6">
                            <img className="img-fluid position-relative" src={storyImage10} alt="" style={{ objectFit: "cover" }} />
                        </div>
                        <div className="col-lg-6 col-md-6">
                            <img className="img-fluid position-relative" src={storyImage11} alt="" style={{ objectFit: "cover" }} />
                        </div>
                        <span className="source-text text-center">Walter Salles. (2004). [The Motorcycle Diaries]. 미국, 독일, 영국: UIP코리아</span>
                    </div>
                    <div className="col-lg-12">
                        <p className="mb-3">
                            1952년 1월, 23세 에르네스토 게바라는 자신의 친구 29세 알베르토 그라나도와 함께 몇 년째 꿈꾸던 라틴아메리카 대륙 종단
                            여행을 시작한다. La Poderosa(the mighty one, 힘 있는 자)라는 애칭을 붙여준 1939년식 노튼500 모터사이클과 함께 그들은
                            부에노스아이레스에서 오래된 엔진의 배기음을 울리며 출발한다. 목적지 근처였던 페루에서 몇 주간 의료봉사를 포함하여 4개월
                            정도로 예정되어 있던 그들의 계획은 아르헨티나, 칠레, 페루를 넘어 에콰도르, 콜롬비아, 베네수엘라, 파나마까지 이어진다.
                        </p>
                        <p className="mb-5">
                            타이어가 퍼지고, 엔진이 고장 나는 등 우여곡절을 거치다 결국 포데로사의 엔진은 칠레와 페루 근처 안데스산맥을 오르다 명이
                            다하고 만다. 그들은 포데로사 없이 발로 걷고 히치하이킹을 하며 여행을 이어간다. 체 게바라의 여행 동반자였던 알베르토
                            그라나도는 2004년 인터뷰에서 만약 포데로사가 끝까지 버텨줬다면 결코 그 여행이 그만큼 유익하고 감명 깊지 않았을 것이라
                            말한다. 묵묵히 발이 되어주던 모터사이클에서 내려왔을 때 비로소 보이기 시작한 것들이 있었다. 여비를 벌기 위해 짐꾼, 선원,
                            경찰, 의사가 되어 일을 하며 가까운 거리에서 사람들의 삶을 들여다보고 부대끼면서 민중의 숨결을 느끼고 그들의 아픔과 정치,
                            사회의 문제를 인식하게 되지 않았을까.
                        </p>
                    </div>
                </Col>
            </div>
        </>
    );
}

export default Story;
