import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { useAsyncAxios, utilAxiosWithAuth } from "../../utils/customAxios";
import { Space } from "antd";

import * as TypeDTO from "../../commons/typeDTO";
import * as Request from "../../commons/request";
import * as String from "../../commons/string";

import bukgi from "../../assets/images/digitalKeyController/bukgi-car.png";
import yaxing from "../../assets/images/digitalKeyController/yaxing-car.png";
import yaxingSwingDoorMode from "../../assets/images/digitalKeyController/yaxing-car-swingdoor.png";
import spotlight from "../../assets/images/digitalKeyController/spotlight-stage-bg.jpg";
import locked from "../../assets/images/digitalKeyController/locked.svg";
import closed from "../../assets/images/digitalKeyController/closed.svg";
import unlocked from "../../assets/images/digitalKeyController/unlocked.svg";
import styles from "./Smartkey.module.css";

function Smartkey({ activeCar, onSmartkeyActionError }: { activeCar?: TypeDTO.CarOwnerDto; onSmartkeyActionError?: (message: string) => void }) {
    const [swingDoorMode, setSwingDoorMode] = useState<boolean>(false);
    const btnAnim = {
        whileTap: { scale: 0.75 },
    };

    const requestAxiosCloseDoor = async (carId: number) => {
        const response = await utilAxiosWithAuth().post(Request.DOOR_CAR_URL + carId + Request.DOOR_CAR_CLOSE_URL);
        return response.data;
    };

    const {
        loading: loadingCloseDoor,
        error: errorCloseDoor,
        data: resultCloseDoor,
        execute: requestCloseDoor,
    } = useAsyncAxios(requestAxiosCloseDoor);

    useEffect(() => {
        if (!resultCloseDoor) return;

        console.log("resultCloseDoor", resultCloseDoor);
    }, [resultCloseDoor]);

    useEffect(() => {
        if (!errorCloseDoor) return;

        onSmartkeyActionError && onSmartkeyActionError(String.msg_networkError);
    }, [errorCloseDoor]);

    const handleDoorMode = () => {
        setSwingDoorMode(!swingDoorMode);
    };

    const handleLock = () => {
        activeCar && requestCloseDoor(activeCar.car.carId);
    };

    const requestAxiosOpenDoor = async (carId: number) => {
        const response = await utilAxiosWithAuth().post(Request.DOOR_CAR_URL + carId + Request.DOOR_CAR_OPEN_URL);
        return response.data;
    };

    const { loading: loadingOpenDoor, error: errorOpenDoor, data: resultOpenDoor, execute: requestOpenDoor } = useAsyncAxios(requestAxiosOpenDoor);

    useEffect(() => {
        if (!resultOpenDoor) return;
        console.log("resultOpenDoor", resultOpenDoor);
    }, [resultOpenDoor]);

    useEffect(() => {
        if (!errorOpenDoor) return;

        console.log("errorOpenDoor", errorOpenDoor);
        onSmartkeyActionError && onSmartkeyActionError("네트워크에 접속할 수 없습니다. \n연결상태를 확인해 주세요.");
    }, [errorOpenDoor]);

    const handleUnlock = () => {
        activeCar && requestOpenDoor(activeCar.car.carId);
    };

    const requestAxiosCloseSwingDoor = async (carId: number) => {
        const response = await utilAxiosWithAuth().post(Request.DOOR_CAR_URL + carId + Request.SWING_DOOR_CAR_CLOSE_URL);
        return response.data;
    };

    const {
        loading: loadingCloseSwingDoor,
        error: errorCloseSwingDoor,
        data: resultCloseSwingDoor,
        execute: requestCloseSwingDoor,
    } = useAsyncAxios(requestAxiosCloseSwingDoor);

    useEffect(() => {
        if (!resultCloseSwingDoor) return;
        console.log("resultCloseSwingDoor", resultCloseSwingDoor);
    }, [resultCloseSwingDoor]);

    useEffect(() => {
        if (!errorCloseSwingDoor) return;

        console.log("errorCloseSwingDoor", errorCloseSwingDoor);
        onSmartkeyActionError && onSmartkeyActionError(String.msg_networkError);
    }, [errorCloseSwingDoor]);

    const handleSwingDoorLock = () => {
        if (activeCar) {
            const carId = activeCar.car.carId;
            requestCloseSwingDoor(carId);
        }
    };

    const requestAxiosOpenSwingDoor = async (carId: number) => {
        const response = await utilAxiosWithAuth().post(Request.DOOR_CAR_URL + carId + Request.SWING_DOOR_CAR_OPEN_URL);
        return response.data;
    };

    const {
        loading: loadingOpenSwingDoor,
        error: errorOpenSwingDoor,
        data: resultOpenSwingDoor,
        execute: requestOpenSwingDoor,
    } = useAsyncAxios(requestAxiosOpenSwingDoor);

    useEffect(() => {
        if (!resultOpenSwingDoor) return;
        console.log("resultOpenSwingDoor", resultOpenSwingDoor);
    }, [resultOpenSwingDoor]);

    useEffect(() => {
        if (!errorOpenSwingDoor) return;

        console.log("errorOpenSwingDoor", errorOpenSwingDoor);
        onSmartkeyActionError && onSmartkeyActionError(String.msg_networkError);
    }, [errorOpenDoor]);

    const handleSwingDoorUnlock = () => {
        activeCar && requestOpenSwingDoor(activeCar.car.carId);
    };

    return (
        <div className={`${styles.smartkeyContainer}`}>
            <div className={`${styles.spotlightBackground}`} style={{ opacity: activeCar ? 1 : 0.4 }}>
                <div
                    style={{
                        position: "relative",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <img style={{ position: "absolute", top: "50%", zIndex: 0 }} src={spotlight} className={`${styles.spotlight}`} width={"100%"} />
                    {activeCar?.car.hasSwingDoor && (
                        <img
                            width={"75%"}
                            src={!activeCar?.car.hasSwingDoor ? bukgi : swingDoorMode ? yaxingSwingDoorMode : yaxing}
                            className={
                                !activeCar?.car.hasSwingDoor
                                    ? `${styles.bukgiCar}`
                                    : swingDoorMode
                                    ? `${styles.yaxingCar}`
                                    : `${styles.yaxingCarHighlight}`
                            }
                            style={{ position: "absolute", top: "30px", zIndex: 1 }}
                        />
                    )}
                </div>
            </div>

            {activeCar?.car.carId ? (
                !activeCar?.car.doorModuleImei ? (
                    <div className={`${styles.btnWrapper}`}>
                        <motion.button className={styles.registerBtn}>
                            <div className={`${styles.btnText} ${styles.noSmartkey}`}>스마트키를 사용할 수 없는 상태입니다.</div>
                        </motion.button>
                    </div>
                ) : activeCar?.car.hasSwingDoor ? (
                    <div className={`${styles.btnWrapper}`}>
                        <button className={styles.lockBtn} onClick={handleDoorMode}>
                            <motion.div
                                variants={btnAnim}
                                whileTap="whileTap"
                                style={{
                                    width: "100%",
                                    height: "100%",
                                }}
                            >
                                <Space style={{ height: "100%" }}>
                                    <span>
                                        {swingDoorMode ? "스윙도어" : "차량도어"}
                                        <br />
                                        조작모드
                                    </span>
                                    <span>▶</span>
                                </Space>
                            </motion.div>
                        </button>
                        <button className={styles.lockBtn} onClick={swingDoorMode ? handleSwingDoorLock : handleLock}>
                            <motion.div
                                variants={btnAnim}
                                whileTap="whileTap"
                                style={{
                                    width: "100%",
                                    height: "100%",
                                }}
                            >
                                <img width={40} height={40} src={swingDoorMode ? closed : locked} className={styles.btnIcon} alt="locked padlock" />
                            </motion.div>
                        </button>
                        <button className={styles.lockBtn} onClick={swingDoorMode ? handleSwingDoorUnlock : handleUnlock}>
                            <motion.div
                                variants={btnAnim}
                                whileTap="whileTap"
                                style={{
                                    width: "100%",
                                    height: "100%",
                                }}
                            >
                                <img width={40} height={40} src={unlocked} className={styles.btnIcon} alt="unlocked padlock" />
                            </motion.div>
                        </button>
                    </div>
                ) : (
                    <div className={`${styles.btnWrapper}`}>
                        <button className={styles.lockBtn} onClick={handleLock}>
                            <motion.div
                                variants={btnAnim}
                                whileTap="whileTap"
                                style={{
                                    width: "100%",
                                    height: "100%",
                                }}
                            >
                                <img width={40} height={40} src={locked} className={styles.btnIcon} alt="locked padlock" />
                            </motion.div>
                        </button>
                        <button className={styles.lockBtn} onClick={handleUnlock}>
                            <motion.div
                                variants={btnAnim}
                                whileTap="whileTap"
                                style={{
                                    width: "100%",
                                    height: "100%",
                                }}
                            >
                                <img width={40} height={40} src={unlocked} className={styles.btnIcon} alt="unlocked padlock" />
                            </motion.div>
                        </button>
                    </div>
                )
            ) : (
                <div className={`${styles.btnWrapper}`}>
                    <motion.button className={styles.registerBtn} whileTap={{ scale: 0.9 }}>
                        <div className={styles.btnText}>공유된 차량이 없습니다.</div>
                    </motion.button>
                </div>
            )}
        </div>
    );
}

export default Smartkey;
