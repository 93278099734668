import React, { useEffect, useState } from "react";
import KakaoMapEVStation, { gpsType } from "../../component/rent/KakaoMapEVStation";
import { useAsyncAxios, utilAxiosWithAuth } from "../../utils/customAxios";
import { Button, Drawer, Spin, Space } from "antd";

import * as Common from "../../commons/common";
import * as Request from "../../commons/request";
import * as TypeDTO from "../../commons/typeDTO";
import * as Utils from "../../utils/utils";
import * as String from "../../commons/string";

import tmap from "../../assets/images/digitalKeyController/tmap.png";
import navermap from "../../assets/images/digitalKeyController/navermap.png";
import kakaomap from "../../assets/images/digitalKeyController/kakaomap.png";

import evStationIcon from "../../assets/images/digitalKeyController/evStationIcon.svg";
import locateIcon from "../../assets/images/digitalKeyController/locateIcon.svg";
import refreshIcon from "../../assets/images/digitalKeyController/mapRefreshIcon.svg";
import evStationRecommend from "../../assets/images/digitalKeyController/stn-recommend.svg";

import styles from "./FindEVStation.module.css";
import ModalEvSearchInfo from "../../component/rent/ModalEvSearchInfo";
import useGeolocation from "../../utils/useGeolocation";

type mapCenter = {
    zcode: string;
    zscode: string;
    latitude: number;
    longitude: number;
};

declare const window: typeof globalThis & {
    webkit: any;
};

export type TypeRecommendStation = {
    statNm: string;
    statId: string;
};

const RecommendStation: Array<TypeRecommendStation> = [
    { statNm: "평생학습관 주차장", statId: "ME18C249" },
    { statNm: "성건동 주민자치센터", statId: "ME20C152" },
    { statNm: "경주시 청소년수련관", statId: "ME21A127" },
    { statNm: "경주시청", statId: "ME183129" },
    { statNm: "경주시청", statId: "ME184052" },
    { statNm: "경주시청주차장", statId: "ME20C301" },
    { statNm: "황성공원 주차장", statId: "ME18C222" },
    { statNm: "강동동 주민센터", statId: "ME195017" },
    { statNm: "울산광역시 교육연수원", statId: "ME20C292" },
    { statNm: "양남면사무소", statId: "ME184054" },
    { statNm: "플럭스 호텔", statId: "CV001052" },
    { statNm: "월성원자력본부 홍보관 주차장", statId: "ME19E133" },
    { statNm: "감포읍복지회관", statId: "ME20C247" },
    { statNm: "감포읍 공설시장 주차장", statId: "ME19C128" },
    { statNm: "감포읍사무소", statId: "ME187002" },
    { statNm: "양북면사무소", statId: "ME18C140" },
    { statNm: "한국도로공사 경주지사", statId: "ME20C246" },
];

function FindEVStation({ onClickInfo }: { onClickInfo?: () => void }) {
    const myLocation = useGeolocation();
    const [myPosition, setMyPosition] = useState<gpsType>();
    const [mapCenter, setMapCenter] = useState<gpsType>();
    const [currentRegionName, setCurrentRegionName] = useState<string>();

    const [evStations, setEvStations] = useState<Array<TypeDTO.EvcsItemDto>>();
    const [displayEvStations, setDisplayEvStations] = useState<Array<TypeDTO.EvcsItemDto>>([]);

    const [selectedStation, setSelectedStation] = useState<TypeDTO.EvcsItemDto>();
    const [selectedAllStation, setSelectedAllStation] = useState<Array<TypeDTO.EvcsItemDto>>();

    const [openDrawerStationInfo, setOpenDrawerStationInfo] = useState(false);
    const [openDrawerNavi, setOpenDrawerNavi] = useState(false);

    useEffect(() => {
        Utils.onEvent(Common.EVENT_HEADER_INFO, handleInfoPress);
        Utils.onEvent(Common.EVENT_CLOSE_ALL_MODAL, handleCloseAllModal);
        Utils.onEvent(Common.EVENT_CLOSE_ALL_DRAWER, handleCloseAllDrawer);

        return () => {
            Utils.offEvent(Common.EVENT_HEADER_INFO, handleInfoPress);
            Utils.offEvent(Common.EVENT_CLOSE_ALL_MODAL, handleCloseAllModal);
            Utils.offEvent(Common.EVENT_CLOSE_ALL_DRAWER, handleCloseAllDrawer);
        };
    }, []);

    useEffect(() => {
        if (myLocation.loaded && myLocation.coordinates) {
            setMyPosition({ lat: myLocation.coordinates?.lat, lng: myLocation.coordinates?.lng });
        }
    }, [myLocation]);

    useEffect(() => {
        if (myPosition) {
            if (mapCenter === undefined) {
                setMapCenter(myPosition);
                getEvStation(myPosition.lat, myPosition.lng);
            }
        }
    }, [myPosition]);

    const getEvStation = (lat: number, lng: number) => {
        const geocoder = new kakao.maps.services.Geocoder();
        geocoder.coord2RegionCode(lng, lat, (result, status) => {
            if (status === kakao.maps.services.Status.OK && result && result.length > 0) {
                const hRegions = result.filter((region) => region.region_type === "H");
                console.log("hRegions", hRegions);
                if (hRegions && hRegions.length > 0) {
                    const regionName = hRegions[0].region_1depth_name;
                    const code = hRegions[0].code;
                    const zcode = code.substring(0, 2);
                    const zscode = code.substring(0, 5);
                    if (zcode) {
                        setCurrentRegionName(regionName);
                        requestGetEvStation({ zcode: zcode, zscode: zscode, latitude: lat, longitude: lng });
                    }
                }
            }
        });
    };

    const requestAxiosGetEvStation = async (center: mapCenter) => {
        const response = await utilAxiosWithAuth().get(Request.FIND_EV_STATION_URL, {
            params: center,
        });
        return response.data;
    };

    const {
        loading: loadingGetEvStation,
        error: errorGetEvStation,
        data: resultGetEvStation,
        execute: requestGetEvStation,
    } = useAsyncAxios(requestAxiosGetEvStation);

    useEffect(() => {
        if (!resultGetEvStation) return;
        console.log("resultGetEvStation", resultGetEvStation);
        setEvStations(resultGetEvStation.evcs);
    }, [resultGetEvStation]);

    useEffect(() => {
        if (!errorGetEvStation) return;

        console.log("errorGetEvStation", errorGetEvStation);
    }, [errorGetEvStation]);

    useEffect(() => {
        if (!evStations || evStations.length === 0) return;

        const tempDisplayEvStations: Array<TypeDTO.EvcsItemDto> = [];
        evStations.forEach((station) => {
            const existStation = tempDisplayEvStations.filter((temp) => temp.statId === station.statId);
            if (!existStation || existStation.length === 0) {
                tempDisplayEvStations.push(station);
            }
        });

        setDisplayEvStations(tempDisplayEvStations);
    }, [evStations]);

    const onClickEvStation = (station: TypeDTO.EvcsItemDto) => {
        const selectedAllStation = evStations?.filter((evStation) => evStation.statId === station.statId);
        setSelectedAllStation(selectedAllStation);

        setSelectedStation(station);
        setOpenDrawerStationInfo(true);
    };

    const onChangedMapCenter = (lat: number, lng: number) => {
        setMapCenter({ lat: lat, lng: lng });
    };

    const handleLocateBtnClick = () => {
        if (myPosition) {
            setMapCenter(myPosition);
            getEvStation(myPosition.lat, myPosition.lng);
        }
    };

    const handleRefreshBtnClick = () => {
        if (mapCenter) getEvStation(mapCenter.lat, mapCenter.lng);
    };

    const handleNavigateBtnClick = () => {
        setOpenDrawerStationInfo(false);
        setOpenDrawerNavi(true);
    };

    const onGotoNavigation = (appName: string) => {
        if (!selectedStation) return;

        if (appName === "naver") {
            window.location.href = `nmap://route/car?dlat=${selectedStation.lat}&dlng=${selectedStation.lng}&dname=${selectedStation.addr}`;
        } else if (appName === "tmap") {
            window.location.href = `tmap://route?goalname=${selectedStation.addr}&goalx=${selectedStation.lng}&goaly=${selectedStation.lat}`;
        } else if (appName === "kakao") {
            if (myPosition !== undefined)
                window.location.href = `kakaomap://route?sp=${myPosition.lat},${myPosition.lng}&ep=${selectedStation.lat},${selectedStation.lng}&by=CAR`;
        }
    };

    const getStatusStyle = (stations: Array<TypeDTO.EvcsItemDto> | undefined) => {
        if (!stations || stations.length === 0) return styles.red;
        const enableStations = stations.filter((station) => station.stat === 2);
        if (enableStations && enableStations.length > 0) return styles.lightblue;

        return styles.red;
    };

    const getStatusText = (stations: Array<TypeDTO.EvcsItemDto> | undefined) => {
        if (!stations || stations.length === 0) return "충전 불가";
        const enableStations = stations.filter((station) => station.stat === 2);
        if (enableStations && enableStations.length > 0) return "충전가능 : " + enableStations.length + "/" + stations.length;

        return "충전 불가";
    };

    useEffect(() => {
        if (openDrawerStationInfo === false && openDrawerNavi === false) {
            setSelectedStation(undefined);
        }
    }, [openDrawerStationInfo, openDrawerNavi]);

    const handleCloseAllDrawer = () => {
        setOpenDrawerStationInfo(false);
        setOpenDrawerNavi(false);
    };

    useEffect(() => {
        if (openDrawerStationInfo === true) Utils.addDrawerStack();
        else Utils.popDrawerStack();
    }, [openDrawerStationInfo]);

    useEffect(() => {
        if (openDrawerNavi === true) Utils.addDrawerStack();
        else Utils.popDrawerStack();
    }, [openDrawerNavi]);

    const [openModalEvSearchInfo, setOpenModalEvSearchInfo] = useState(false);
    useEffect(() => {
        if (Utils.getLocalStorage(Common.CONTEXT_DONOT_SHOW_EVINFO) !== "true") {
            setOpenModalEvSearchInfo(true);
        }
    }, []);

    const handleInfoPress = () => {
        setOpenModalEvSearchInfo(true);
    };

    const handleCloseAllModal = () => {
        setOpenModalEvSearchInfo(false);
    };

    return (
        <>
            <section
                style={{
                    position: "relative",
                    width: "100vw",
                    height: "calc(100vh - var(--header-height))",
                }}
            >
                {!myLocation.loaded && (
                    <div className={styles.loadingWrapper}>
                        <Space size={10} className={styles.loadingBox}>
                            <p className={styles.title}>LOADING...</p>
                            <p>내 위치정보를 불러오는 중입니다</p>
                            <Spin className={styles.loadingSpin} />
                        </Space>
                    </div>
                )}

                {loadingGetEvStation && (
                    <div className={styles.loadingWrapper}>
                        <Space size={10} className={styles.loadingBox}>
                            <p className={styles.title}>{currentRegionName}</p>
                            <p>충전소 정보를 불러오는 중입니다</p>
                            <Spin className={styles.loadingSpin} />
                        </Space>
                    </div>
                )}

                <Space className={styles.legend}>
                    <img width={16} src={evStationRecommend} />: 추천 충전소
                </Space>

                <KakaoMapEVStation
                    mapStyle={{ height: "calc(100vh - 75px)", width: "100%" }}
                    zoomLevel={4}
                    myPosition={myPosition}
                    mapCenter={mapCenter}
                    selectedStation={selectedStation}
                    evcs={displayEvStations}
                    recommendStation={RecommendStation}
                    onClickEvStation={onClickEvStation}
                    onChangedMapCenter={onChangedMapCenter}
                />
                <div className={styles.mapBtnCircle} onClick={handleLocateBtnClick}>
                    <img src={locateIcon} alt="locate icon" width="24" height="24" />
                </div>

                <div className={`${styles.mapBtnCircle} ${styles.searchOnThisMapBtn}`} onClick={handleRefreshBtnClick}>
                    <img src={refreshIcon} alt="refresh icon" height="20" />
                </div>

                {selectedStation && (
                    <Drawer
                        placement="bottom"
                        closable={false}
                        height={200}
                        onClose={() => setOpenDrawerStationInfo(false)}
                        open={openDrawerStationInfo}
                    >
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "space-between",
                                height: "100%",
                            }}
                        >
                            <div className={styles.sheetContentBox}>
                                <div className={styles.stationName}>{selectedStation.statNm}</div>
                                <div className={styles.stationAddr}>{selectedStation.addr}</div>
                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                    }}
                                >
                                    <img height="30" width="30" src={evStationIcon} alt="charge station icon" />
                                    <div className={`${styles.pill} ${styles.companyPill}`}>{selectedStation.busiNm}</div>
                                    <div className={`${styles.pill} ${getStatusStyle(selectedAllStation)}`}>{getStatusText(selectedAllStation)}</div>
                                </div>
                            </div>
                            <Button type="primary" block className={`${styles.navigateBtn}`} onClick={handleNavigateBtnClick}>
                                {String.navigation}
                            </Button>
                        </div>
                    </Drawer>
                )}

                <Drawer placement="bottom" closable={false} height={200} onClose={() => setOpenDrawerNavi(false)} open={openDrawerNavi}>
                    <h3 className={styles.sheetTitle}>연결할 앱을 선택해 주세요</h3>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: 32,
                        }}
                    >
                        <a className={styles.naviIconContainer} onClick={() => onGotoNavigation("naver")} target="_self" rel="noreferrer">
                            <img className={styles.naviIcon} src={navermap} alt="naver app icon" />
                            <div className={styles.naviAppText}>{String.naverMap}</div>
                        </a>
                        <a className={styles.naviIconContainer} onClick={() => onGotoNavigation("tmap")} target="_self" rel="noreferrer">
                            <img className={styles.naviIcon} src={tmap} alt="tmap app icon" />
                            <div className={styles.naviAppText}>{String.tMap}</div>
                        </a>
                        <a className={styles.naviIconContainer} onClick={() => onGotoNavigation("kakao")} target="_self" rel="noreferrer">
                            <img className={styles.naviIcon} src={kakaomap} alt="kakao app icon" />
                            <div className={styles.naviAppText}>{String.kakaoMap}</div>
                        </a>
                    </div>
                </Drawer>
            </section>

            <ModalEvSearchInfo openModal={openModalEvSearchInfo} onChangedOpenModal={(opened) => setOpenModalEvSearchInfo(opened)} />
        </>
    );
}

export default FindEVStation;
