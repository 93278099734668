import React, { useEffect, useState } from "react";
import { Col } from "antd";
import { BrowserView, MobileView } from "react-device-detect";
import { useAsyncAxios, utilAxiosWithAppToken } from "../../utils/customAxios";

import * as Request from "../../commons/request";
import * as TypeDTO from "../../commons/typeDTO";

import Banner from "../../assets/images/home/ruta40/banner_customer.jpg";
import FaqComponent from "../../component/rent/Faq";

function Faq() {
    const [faqs, setFaqs] = useState<Array<TypeDTO.RentFaqDto>>();

    useEffect(() => {
        requestGetFaqs();
    }, []);

    const requestAxiosGetFaqs = async () => {
        const response = await utilAxiosWithAppToken().get(Request.RENT_FAQS_URL);
        return response.data;
    };

    const { loading: loadingGetFaqs, error: errorGetFaqs, data: resultGetFaqs, execute: requestGetFaqs } = useAsyncAxios(requestAxiosGetFaqs);

    useEffect(() => {
        if (resultGetFaqs === null) return;
        console.log("resultGetFaqs", resultGetFaqs);

        setFaqs(resultGetFaqs.rentFaqs);
    }, [resultGetFaqs]);

    useEffect(() => {
        if (errorGetFaqs === null) return;
        console.log("errorGetFaqs", errorGetFaqs);
    }, [errorGetFaqs]);

    return (
        <div style={{ minHeight: "calc(100vh - 70px)" }}>
            <div className="header-container model">
                <div
                    className="container fadeIn text-center mb-5"
                    style={{ backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.3),rgba(0, 0, 0, 0.3)),url(${Banner})` }}
                >
                    <h5 className="text-center text-light px-3 mb-4">자주 하는 질문</h5>
                    <h1 className="text-light">FAQ</h1>
                </div>
            </div>
            <div className="model-tabs title model-body customer-center" style={{ backgroundColor: "white" }}>
                <MobileView className="mobile">
                    <div className="space-page legal">
                        <FaqComponent isAccordion={true} />
                    </div>
                </MobileView>
                <BrowserView className="browser">
                    <Col
                        xs={{ span: 24 }}
                        sm={{ span: 22, offset: 1 }}
                        lg={{ span: 18, offset: 3 }}
                        xl={{ span: 16, offset: 4 }}
                        xxl={{ span: 14, offset: 5 }}
                        className={`fadeIn p-4`}
                    >
                        <section className="row legal">
                            <FaqComponent isAccordion={true} />
                        </section>
                    </Col>
                </BrowserView>
            </div>
        </div>
    );
}

export default Faq;
