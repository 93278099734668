import { Radio, Space } from "antd";
import { BrowserView, MobileView } from "react-device-detect";
import styles from "../pages/rent/Reservation.module.css";

const PaymentSelector = ({ onChangePayment }: { onChangePayment: (payment: string) => void }) => {
    return (
        <>
            <MobileView className="mobile">
                <Space className="w-100" direction="vertical">
                    <Radio.Group
                        className={styles.paymentButtonWrapper}
                        defaultValue={"card"}
                        onChange={(e) => onChangePayment && onChangePayment(e.target.value)}
                    >
                        <Radio.Button className={styles.paymentButton} value="card">
                            신용/체크카드
                        </Radio.Button>
                        <Radio.Button className={styles.paymentButton} value="trans">
                            계좌이체
                        </Radio.Button>
                    </Radio.Group>
                </Space>
            </MobileView>

            <BrowserView className="browser">
                <Space className="w-100" direction="vertical">
                    <Radio.Group
                        className={styles.paymentButtonWrapper}
                        defaultValue={"card"}
                        onChange={(e) => onChangePayment && onChangePayment(e.target.value)}
                    >
                        <Radio.Button className={styles.paymentButton} value="card">
                            신용/체크카드
                        </Radio.Button>
                        <Radio.Button className={styles.paymentButton} value="trans">
                            계좌이체
                        </Radio.Button>
                    </Radio.Group>
                </Space>
            </BrowserView>

            <p className="mt-2 pb-0">
                ※ 신용카드 결제 시<span className="text-danger"> 하나카드</span>는 카드사 자체 미지원으로
                <span className="text-danger"> 결제</span>가 <span className="text-danger">불가</span>합니다.
            </p>
        </>
    );
};

export default PaymentSelector;
