import React, { useEffect, useState } from "react";
import { Button, Modal } from "antd";

import * as Utils from "../../utils/utils";
import * as String from "../../commons/string";

import openDoorIcon from "../../assets/images/digitalKeyController/openDoorIcon.svg";
import closeDoorIcon from "../../assets/images/digitalKeyController/closeDoorIcon.svg";
import doorErrorIcon from "../../assets/images/digitalKeyController/doorErrorIcon.svg";

type DisplayDoorState = {
    image: string;
    title: string;
    body: string;
};

function ModalDoorState({
    doorState,
    openModalDoorState,
    onChangedOpenModalDoorState,
}: {
    doorState?: string;
    openModalDoorState: boolean;
    onChangedOpenModalDoorState: (opened: boolean) => void;
}) {
    const [subDoorInfo, setDoorInfo] = useState<DisplayDoorState>();

    useEffect(() => {
        if (!doorState) return;

        if (doorState.toUpperCase() === "OPEN") {
            setDoorInfo({ image: openDoorIcon, title: String.success, body: "차량의 도어가 열렸습니다." });
        } else if (doorState.toUpperCase() === "CLOSED") {
            setDoorInfo({ image: closeDoorIcon, title: String.success, body: "차량의 도어가 잠겼습니다." });
        } else if (doorState.toUpperCase() === "FAIL_NOT_P") {
            setDoorInfo({ image: doorErrorIcon, title: String.alarm, body: "기어상태를 P로 변경한 다음\n 디지털 키를 작동해 주세요." });
        } else if (doorState.toUpperCase() === "S_OPEN") {
            setDoorInfo({ image: openDoorIcon, title: String.success, body: "차량의 스윙도어가 열립니다." });
        } else if (doorState.toUpperCase() === "S_CLOSED") {
            setDoorInfo({ image: closeDoorIcon, title: String.success, body: "차량의 스윙도어가 잠깁니다." });
        } else if (doorState.toUpperCase() === "S_FAIL_NOT_P") {
            setDoorInfo({ image: doorErrorIcon, title: String.alarm, body: "기어상태를 P로 변경한 다음\n 디지털 키를 작동해 주세요." });
        }
    }, [doorState]);

    useEffect(() => {
        openModalDoorState ? Utils.addModalStack() : Utils.popModalStack();
    }, [openModalDoorState]);

    return subDoorInfo ? (
        <Modal
            className="confirmModal"
            centered
            key="modalDoorState"
            open={openModalDoorState}
            closable={false}
            onCancel={() => onChangedOpenModalDoorState(false)}
            footer={[
                <Button block type="primary" key="back" onClick={() => onChangedOpenModalDoorState(false)}>
                    {String.confirm}
                </Button>,
            ]}
        >
            <img src={subDoorInfo.image} alt="image of door state" width="90px" />
            <div>
                <p className="title">{subDoorInfo.title}</p>
                <span>{subDoorInfo.body}</span>
            </div>
        </Modal>
    ) : null;
}

export default ModalDoorState;
