import React from "react";
import { Col } from "antd";

import Accordion from "react-bootstrap/Accordion";
import informationsImage1 from "../../assets/images/information/info_1.png";
import informationsImage2 from "../../assets/images/information/info_2.png";
import informationsImage3 from "../../assets/images/information/info_3.png";
import informationsImage4 from "../../assets/images/information/info_4.png";
import Banner from "../../assets/images/home/ruta40/banner_customer.jpg";
import { isMobile } from "react-device-detect";
import styles from "./Information.module.css";

function Informations() {
    return (
        <>
            <div className="header-container model">
                <div
                    className="container fadeIn text-center mb-5"
                    style={{ backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.3),rgba(0, 0, 0, 0.3)),url(${Banner})` }}
                >
                    <h5 className="text-center text-light px-3 mb-4">운행 주의사항</h5>
                    <h1 className="text-light">INFORMATION</h1>
                </div>
            </div>
            <div className="model-tabs title model-body customer-center" style={{ backgroundColor: "white" }}>
                <Col
                    xs={{ span: 24 }}
                    sm={{ span: 22, offset: 1 }}
                    lg={{ span: 18, offset: 3 }}
                    xl={{ span: 16, offset: 4 }}
                    xxl={{ span: 14, offset: 5 }}
                    className={`fadeIn p-4`}
                >
                    <section className="row legal">
                        <Accordion alwaysOpen defaultActiveKey={["0"]} className={`support ${isMobile ? "mobile" : ""}`}>
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>메인 배터리 주의사항</Accordion.Header>
                                <Accordion.Body>
                                    <ul className="listSpace mb-0">
                                        <li>
                                            배터리 충전량은 40%~90% 이내로 유지하세요. 배터리 충전량이 20% 이하로 떨어지면 출력제한으로 인하여 주행
                                            성능이 현저히 떨어집니다.
                                        </li>
                                        <li>회생제동은 배터리 충전량이 90% 이하일 경우에만 동작됩니다.</li>
                                    </ul>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="1">
                                <Accordion.Header>보조 배터리 방전 주의사항</Accordion.Header>
                                <Accordion.Body>
                                    <ul className="listSpace mb-0">
                                        <li>차량 정차 시 문이 제대로 닫혀있는지 확인해 주세요.</li>
                                        <li>2일 이상 차량을 운행하지 않을 시 메인 전원을 OFF 해주세요.</li>
                                        <li>외부 전기를 끌어다 사용할 경우 운전석 아래 메인 전원을 OFF 해주세요.</li>
                                        <li>시거잭은 상시 전원이므로 사용하지 않을 경우 연결된 장치를 해제하여 주세요.</li>
                                    </ul>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="2">
                                <Accordion.Header>기어 레버 변경 방법</Accordion.Header>
                                <Accordion.Body>
                                    <ul className="listSpace mb-0">
                                        <li>기어 레버는 무조건 브레이크를 밟은 상태에서 변경을 해야 합니다.</li>
                                        <li>브레이크를 밟지 않고 기어 레버를 조작할 경우 기어 레버는 조작되어도 실제 기어는 변경되지 않습니다.</li>
                                        <li>
                                            브레이크를 밟지 않고 기어 레버를 조작했을 경우 브레이크에서 발을 떼고, 다시 밟으면 실제 기어 상태로
                                            변경됩니다.
                                        </li>
                                    </ul>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="3">
                                <Accordion.Header>기어 상태 관련 주의사항</Accordion.Header>
                                <Accordion.Body>
                                    <ul className="listSpace mb-0">
                                        <li>후진기어 상태에서는 악셀을 밟을 때만 차량이 움직입니다. (후진 최대 속도 20km/h)</li>
                                        <li>후진 기어를 넣은 상태일 때 브레이크에서 밟은 발을 떼더라도 차량은 움직이지 않습니다.</li>
                                    </ul>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="4">
                                <Accordion.Header>경사진 곳 정차 주의사항</Accordion.Header>
                                <Accordion.Body>
                                    <ul className="listSpace mb-0">
                                        <li>
                                            경사진 곳에 차량 정차 후 출발할 때는 브레이크를 천천히 떼면 차량이 뒤로 밀리니, 브레이크를 빨리 떼고
                                            악셀을 밟아서 출발하면 됩니다.
                                        </li>
                                        <li>경사진 곳에 차량을 주차 시 반드시 비치되어 있는 고임목을 이용하여 차량이 미끄러지지 않게 해야 됩니다.</li>
                                        <li>고임목은 경사 방향의 앞쪽의 타이어에 밀착하여 앞/뒤 각각 설치를 해야 합니다.</li>
                                    </ul>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="5">
                                <Accordion.Header>주행 중 주의사항</Accordion.Header>
                                <Accordion.Body className="accordion-collapse">
                                    <ul className="listSpace mb-0">
                                        <li>차량의 무게로 인하여 브레이크가 밀리는 느낌이 다소 있을 수 있습니다.</li>
                                        <li>운행 시 앞차와 안전거리를 충분히 확보하여 운행해주세요.</li>
                                    </ul>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="6">
                                <Accordion.Header>전기 사용 관련 주의사항</Accordion.Header>
                                <Accordion.Body className="accordion-collapse">
                                    <ul className="listSpace mb-0">
                                        <li>전기 사용은 매뉴얼에 따라 작동해 주세요.</li>
                                        <li>외부 한전 전기 인입하여 사용 시 메인 전원을 OFF 하고 사용해 주세요.</li>
                                        <li>차량 외부 콘센트 사용 시 고용량 전기 장치는 사용하지 마세요. (10A 이하 장치 사용)</li>
                                        <li>냉장고 전원을 자주 껐다 켰다 할 경우 성에가 생겼다가 녹아 누수가 발생할 수 있습니다.</li>
                                    </ul>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="7">
                                <Accordion.Header>충전 시 주의사항</Accordion.Header>
                                <Accordion.Body className="accordion-collapse">
                                    <ul className="listSpace ">
                                        <li>
                                            급속·완속 충전기 이용 중 이상 동작이 발생할 경우 그 즉시 충전을 중지해 주세요.
                                            <br />
                                            재차 시도할 경우 심각한 차량 오류가 발생할 수 있습니다.
                                        </li>
                                        <li>
                                            급속충전기를 이용하실 경우에는 환경부에서 설치한 대영채비와 시그넷 충전기만 사용해 주세요.
                                            <br />
                                            (대영채비나 시그넷의 경우도 환경부 발표 기준 평균 고장률 3-5% 입니다.)
                                            <p className="bullet-dash">대영채비 충전기는 정면에 CHAEVI로 표시되어 있습니다.</p>
                                            <p className="bullet-dash">
                                                시그넷 충전기는 정면 또는 측면에 시그넷이브이 또는 SIGNET으로 표시되어 있습니다.
                                            </p>
                                        </li>
                                        <div className="col-md-12 text-center">
                                            <img className="info-img col-md-6 px-2" src={informationsImage1} />
                                            <img className="info-img col-md-6 px-2" src={informationsImage2} />
                                        </div>
                                        <li>
                                            중앙제어, KEPCO(클린일렉스 제조) 이용 시 에러 발생 제보가 다수 발생되고 있습니다.
                                            <br />
                                            현재 확인 중이며 검증&조치 후 알려드리겠습니다.
                                        </li>
                                        <div className="col-md-12 text-center">
                                            <img className="info-img col-md-6 px-2" src={informationsImage3} />
                                            <img className="info-img col-md-6 px-2" src={informationsImage4} />
                                        </div>
                                    </ul>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="8">
                                <Accordion.Header>여름철 배터리 작동 온도에 따른 충전 주의사항</Accordion.Header>
                                <Accordion.Body className="accordion-collapse">
                                    <ul className="listSpace mb-0">
                                        <li>
                                            배터리 충/방전 적정 온도: 0~50℃
                                            <p className="bullet-dash">배터리 온도는 운전석 앞 차량단말기에 표시되오니, 참고해 주세요.</p>
                                        </li>
                                        <li className="mt-4">
                                            충전 주의사항
                                            <p className="bullet-dash">
                                                고온 환경에서 장시간 운행 후 즉시 충전하는 것은 가급적 삼가해주세요. 배터리 온도에 따른 충전량 감소
                                                현상이 발생됩니다.
                                            </p>
                                            <p className="bullet-dash">
                                                충전 효율을 높이기 위하여 장시간 운행 후에는 잠시 휴식하여 온도를 낮춰주신 후 충전하시길 권장합니다.
                                            </p>
                                            <p className="bullet-dash">환경 온도가 50℃ 이상일 경우 배터리, 차량 충전을 중단하여 주시기 바랍니다.</p>
                                        </li>
                                    </ul>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="9">
                                <Accordion.Header>겨울철 배터리 작동 온도에 따른 충전 주의사항</Accordion.Header>
                                <Accordion.Body className="accordion-collapse">
                                    <ul className="listSpace mb-0">
                                        <li>
                                            배터리 충/방전 적정 온도: 0~50℃
                                            <p className="bullet-dash">배터리 온도는 운전석 앞 차량단말기에 표시되오니, 참고해 주세요.</p>
                                        </li>
                                        <li className="mt-4">
                                            충전 주의사항
                                            <p className="bullet-dash">
                                                영하의 기온에서 배터리 온도가 10℃ 이하로 떨어질 경우 충전 효율이 매우 나빠지며, 배터리 온도가 0℃
                                                미만에서는 충전이 되지 않습니다. (급속/완속 동일)
                                                <br />
                                                이를 보완하기 위하여 히팅코일이 내장되어 있으니 안내드리는 사용 방법에 정확히 따라 이용해 주시길
                                                부탁드립니다.
                                            </p>
                                            <p className="bullet-dash">
                                                최초 충전기 연결 후 배터리 히팅모드로 인하여 적정 온도(5℃ 이상)가 되어도 충전기에 표시되는 전류가 1A
                                                미만인 상황에서는 충전이 정상적으로 진행되지 않습니다.
                                                <br />
                                                이때, 충전기를 분리하신 후 다시 연결해 주시면 정상적으로 충전이 가능합니다.
                                            </p>
                                            <p className="bullet-dash">
                                                절대 충전이 되지 않는 상태에서 장시간 방치하지 마십시오. 배터리 수명에 악영향을 줍니다.
                                            </p>
                                            <p className="bullet-dash">
                                                배터리 손상 방지를 위해 영하의 날씨에서는 충전하기 전 예열 운전을 권장합니다.
                                            </p>
                                        </li>
                                        <li className="mt-4">
                                            완속충전 주의사항
                                            <p className="bullet-dash">반드시 영상의 기온에서 배터리 온도가 10℃ 이상일 경우 이용해 주세요.</p>
                                            <p className="bullet-dash">
                                                기온차가 급격하여 배터리 온도를 10℃ 이상 일정하게 유지하기 어려운 환경에서는 절대 완속 충전을 오래
                                                시도하지 말아 주세요.
                                            </p>
                                        </li>
                                        <li className="mt-4">
                                            급속충전 주의사항
                                            <p className="bullet-dash">배터리 온도가 0℃ 이상 상태에서만 시도해 주세요.</p>
                                            <p className="bullet-dash">
                                                배터리 온도가 0℃ 미만인 상태에서 충전기를 연결하면 배터리 히팅 모드가 되며, 해당 모드에서는 충전이
                                                되지 않습니다.
                                            </p>
                                            <p className="bullet-dash">
                                                배터리 히팅 모드에서 충분히 예열되어도 충전 모드 전환이 자동으로 안됩니다. 배터리 온도가 5℃ 이상일 때
                                                충전기를 분리하신 후 다시 연결하여 충전해 주세요.
                                            </p>
                                        </li>
                                    </ul>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="10">
                                <Accordion.Header>동파 주의사항</Accordion.Header>
                                <Accordion.Body className="accordion-collapse">
                                    <ul className="listSpace mb-0">
                                        <li>
                                            기온이 영하로 떨어질 경우 캠핑카 내 워터펌프, 온수기, 청수통 기타 배관이 동파될 위험이 있으니 방지를
                                            위하여 각별히 주의해 주세요.
                                            <ol style={{ paddingLeft: "1rem" }}>
                                                <li style={{ margin: 0 }}>청수통, 오수통을 모두 비워주세요.</li>
                                                <li style={{ margin: 0 }}>
                                                    워터펌프, 온수기 가동 후 샤워기, 싱크 수전을 이용하여 냉수/온수를 모두 비워주세요
                                                </li>
                                                <li style={{ margin: 0 }}>각 배관에 물이 남지 않도록 최대한 비워주세요.</li>
                                                <li style={{ margin: 0 }}>
                                                    워터펌프, 온수기를 끈 후 샤워기와 싱크 수전 손잡이를 가운데로 둔 상태에서 열어놔주세요.
                                                </li>
                                            </ol>
                                        </li>
                                    </ul>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </section>
                </Col>
            </div>
        </>
    );
}

export default Informations;
