import React, { useState } from "react";
import { Button, Space } from "antd";
import { BrowserView, MobileView } from "react-device-detect";

import * as String from "../../commons/string";

import KakaoMap from "../KakaoMap";
import CustomSheet from "../CustomSheet";
import IconNaver from "../../assets/images/home/rent/icon_navi_naver.png";
import IconKakao from "../../assets/images/home/rent/icon_navi_kakao.png";
import IconTmap from "../../assets/images/home/rent/icon_tmap.png";

type RentPlace = {
    rentRegionId?: number;
    rentRegionDescription: string;
    latitude: number;
    longitude: number;
    address: string;
    marker?: Array<{ facilityType: string; facilityLatitude: number; facilityLongitude: number }>;
    region?: Array<{ lat: number; lng: number }>;
};

const RentPlaceMap = ({ rentRegion, isGuide }: { rentRegion: RentPlace; isGuide?: boolean }) => {
    const [isOpenMap, setIsOpenMap] = useState<boolean>(false);

    const sendMapUrl = (type: "naver" | "tmap" | "kakao") => {
        let uri = "";

        if (type === "naver") {
            uri = `nmap://route/car?dlat=${rentRegion?.latitude}&dlng=${rentRegion?.longitude}&dname=${rentRegion?.address}`;
        } else if (type === "tmap") {
            uri = `tmap://route?goalname=${rentRegion?.address}&goalx=${rentRegion?.longitude}&goaly=${rentRegion?.latitude}`;
        } else if (type === "kakao") {
            uri = `kakaomap://route?ep=${rentRegion?.latitude},${rentRegion?.longitude}&by=CAR`;
        }

        window.location.href = uri;
    };

    return (
        <>
            <MobileView className="mobile">
                <div style={{ border: "1px solid #1C375A29", borderRadius: "4px" }}>
                    <KakaoMap
                        mapCenter={{
                            lat: Number(rentRegion?.latitude ?? 0),
                            lng: Number(rentRegion?.longitude ?? 0),
                        }}
                        mapStyle={{ height: "200px", width: "100%" }}
                        rentalLocation={{ list: rentRegion }}
                        guideRegion={rentRegion.region ? rentRegion.region : undefined}
                        guideMarker={rentRegion.marker ? rentRegion.marker : undefined}
                    />
                    <Space className="p-1" style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                        <p>{rentRegion?.rentRegionDescription}</p>
                        <Button
                            type="text"
                            className="h-100 p-0"
                            style={{ fontWeight: "bold", color: "#005FDB", float: "right" }}
                            onClick={() => setIsOpenMap(true)}
                        >
                            {String.navigation}
                        </Button>
                    </Space>
                    <p className="p-1" style={{ backgroundColor: "#E1E3E4" }}>
                        {rentRegion?.address}
                    </p>
                </div>
                <CustomSheet
                    isOpen={isOpenMap}
                    content={
                        <div style={{ padding: "0 1.5rem 1.5rem 1.5rem", overflowX: "hidden" }}>
                            <h5 className="mb-3">{String.linkProgram}</h5>
                            <div className="px-1 text-center" style={{ display: "flex", justifyContent: "space-around" }}>
                                <a className="h-100" onClick={() => sendMapUrl("naver")} target="_self" rel="noreferrer">
                                    <img src={IconNaver} width={60} />
                                    <p className="text-black">{String.naverMap}</p>
                                </a>
                                <a className="h-100" onClick={() => sendMapUrl("tmap")} target="_self" rel="noreferrer">
                                    <img src={IconTmap} width={60} style={{ marginBottom: "6px" }} />
                                    <p className="text-black">{String.tMap}</p>
                                </a>
                                <a className="h-100" onClick={() => sendMapUrl("kakao")} target="_self" rel="noreferrer">
                                    <img src={IconKakao} width={60} />
                                    <p className="text-black">{String.kakaoMap}</p>
                                </a>
                            </div>
                        </div>
                    }
                    snapHeight={200}
                    onClose={() => setIsOpenMap(false)}
                />
            </MobileView>
            <BrowserView className="browser">
                <KakaoMap
                    mapCenter={{
                        lat: Number(rentRegion.latitude ?? 0),
                        lng: Number(rentRegion.longitude ?? 0),
                    }}
                    mapStyle={{ height: isGuide ? 240 : 300, width: "100%", marginTop: isGuide ? 0 : 10 }}
                    rentalLocation={{ list: rentRegion }}
                    guideRegion={rentRegion.region ? rentRegion.region : undefined}
                    guideMarker={rentRegion.marker ? rentRegion.marker : undefined}
                />
                {isGuide && (
                    <Space className="p-1" style={{ width: "100%", display: "flex", justifyContent: "space-between", border: "1px solid #E1E3E4" }}>
                        <p>{rentRegion?.address}</p>
                        <Button
                            type="text"
                            className="h-100 p-0"
                            style={{ fontWeight: "bold", color: "#005FDB", float: "right" }}
                            onClick={() =>
                                window.open(
                                    `https://map.kakao.com/link/to/${rentRegion?.rentRegionDescription},${rentRegion?.latitude},${rentRegion?.longitude}`
                                )
                            }
                        >
                            {String.navigation}
                        </Button>
                    </Space>
                )}
            </BrowserView>
        </>
    );
};

export default RentPlaceMap;
