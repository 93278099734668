import React, { useEffect, useState } from "react";
import { Button, Input, Modal, Result } from "antd";
import { Form } from "antd";
import PhoneCertification from "./PhoneCertification";

import * as TypeDTO from "../commons/typeDTO";
import * as Request from "../commons/request";
import { useAsyncAxios, utilAxiosWithAppToken } from "../utils/customAxios";
import { useAlert } from "../provider/AlertProvider";
import { email } from "../commons/string";

function ModalSearchEmail({ open, onChangedOpen }: { open: boolean; onChangedOpen: (opened: boolean) => void }) {
    const alert = useAlert();

    const [form] = Form.useForm();
    const [userName, setUserName] = useState("");
    const [certificationResult, setCertificationResult] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState("");

    const [result, setResult] = useState("");

    useEffect(() => {
        form.getFieldValue("phone") && form.validateFields(["phone"]);
    }, [certificationResult])

    const requestFindEmail = async (url: string, option: Record<string, string>) => {
        const response = await utilAxiosWithAppToken().post(url, option);
        return response.data;
    };

    const { loading, error: errorFindEmail, data: resultFindEmail, execute: findEmail } = useAsyncAxios(requestFindEmail);

    useEffect(() => {
        if (resultFindEmail === null) return;

        //console.log("resultFindEmail", resultFindEmail);
        setResult(resultFindEmail.email);
    }, [resultFindEmail]);

    useEffect(() => {
        if (errorFindEmail === null) return;

        alert.setAlert(errorFindEmail.response?.data?.code, "이메일 찾기 실패", errorFindEmail.response?.data?.message);
    }, [errorFindEmail]);

    const actionFindEmail = async (data: TypeDTO.FindEmailDto) => {
        await findEmail(Request.FIND_EMAIL_URL, data);
    };

    const onFinish = (values: any) => {
        const data: TypeDTO.FindEmailDto = {
            ...values,
            phone: phoneNumber,
        };
        console.log("Received values of form: ", data);
        actionFindEmail(data);
    };

    const handleCertificationResult = (success: boolean, phoneNumber: string) => {
        setPhoneNumber(phoneNumber);
        setCertificationResult(success);
    };

    const handleClose = () => {
        //setIsModalOpen(false);
        onChangedOpen(false);
    };

    return (
        <Modal
            centered
            className="login"
            key="test"
            open={open}
            title="이메일 찾기"
            onOk={handleClose}
            onCancel={handleClose}
            footer={
                result.length > 0
                    ? [
                          <Button key="back" onClick={handleClose}>
                              닫기
                          </Button>,
                      ]
                    : [
                          <Button key="back" onClick={handleClose}>
                              취소
                          </Button>,
                          <Button key="search" type="primary" onClick={() => form.submit()}>
                              찾기
                          </Button>,
                      ]
            }
        >
            <Form form={form} onFinish={onFinish} layout="vertical">
                <Form.Item
                    name="name"
                    label="이름"
                    initialValue={userName}
                    rules={[
                        {
                            required: true,
                            message: "이름을 입력해 주세요.",
                        },
                    ]}
                >
                    <Input className="login-input" placeholder="이름" onChange={(e) => setUserName(e.target.value)} />
                </Form.Item>

                <Form.Item
                    name="phone"
                    label="휴대전화 번호"
                    required={true}
                    rules={[
                        () => ({
                            validator(_, value) {
                                if (certificationResult) {
                                    return Promise.resolve();
                                }

                                return Promise.reject(new Error("휴대전화 본인 인증을 해 주세요."));
                            },
                        }),
                    ]}
                >
                    <PhoneCertification userName={userName} onChangedResult={handleCertificationResult} />
                </Form.Item>
            </Form>
            {result.length > 0 && <Result status="success" title={result} />}
        </Modal>
    );
}

export default ModalSearchEmail;
