import React, { useEffect, useState } from "react";
import { useAsyncAxios, utilAxiosWithAppToken } from "../../utils/customAxios";
import { BrowserView, MobileView } from "react-device-detect";
import { Accordion } from "react-bootstrap";

import * as Request from "../../commons/request";
import * as TypeDTO from "../../commons/typeDTO";

const Faq = ({ isAccordion, className, faqIds }: { isAccordion?: boolean; className?: string; faqIds?: number[] }) => {
    const [faqs, setFaqs] = useState<Array<TypeDTO.RentFaqDto>>();

    useEffect(() => {
        requestGetFaqs();
    }, []);

    const requestAxiosGetFaqs = async () => {
        const response = await utilAxiosWithAppToken().get(Request.RENT_FAQS_URL);
        return response.data;
    };

    const { error: errorGetFaqs, data: resultGetFaqs, execute: requestGetFaqs } = useAsyncAxios(requestAxiosGetFaqs);

    useEffect(() => {
        if (!resultGetFaqs) return;

        setFaqs(resultGetFaqs.rentFaqs);
    }, [resultGetFaqs]);

    useEffect(() => {
        if (!errorGetFaqs) return;

        console.log("errorGetFaqs", errorGetFaqs);
    }, [errorGetFaqs]);

    return (
        <>
            <MobileView>
                {isAccordion ? (
                    <Accordion alwaysOpen defaultActiveKey={["0"]} className="support mobileFaqAccordion">
                        {faqs &&
                            faqs.map((faq: TypeDTO.RentFaqDto, index) => (
                                <Accordion.Item key={faq.rentFaqId} eventKey={index.toString()} className="accordion-item">
                                    <Accordion.Header style={{ marginTop: "0" }}>{faq.faqQuestion}</Accordion.Header>
                                    <Accordion.Body>
                                        <ul style={{ paddingLeft: 20 }}>
                                            {faq.faqAnswer.split("\n").map((line, index) => (
                                                <li
                                                    style={{ whiteSpace: "pre-wrap" }}
                                                    key={index}
                                                    dangerouslySetInnerHTML={{
                                                        __html: line.replace(". -", `.\n -`),
                                                    }}
                                                />
                                            ))}
                                        </ul>
                                    </Accordion.Body>
                                </Accordion.Item>
                            ))}
                    </Accordion>
                ) : (
                    <div className={className}>
                        {faqs &&
                            faqs.map(
                                (faq: TypeDTO.RentFaqDto, index) =>
                                    (!faqIds || faqIds.includes(faq.rentFaqId)) && (
                                        <div key={faq.rentFaqId}>
                                            <p className="text-bold">{faq.faqQuestion}</p>
                                            {faq.faqAnswer.split("\n").map((line, index) => (
                                                <p
                                                    key={index}
                                                    dangerouslySetInnerHTML={{
                                                        __html: line.replace(". -", `.\n -`),
                                                    }}
                                                />
                                            ))}
                                            {index + 1 < faqs.length && (
                                                <hr style={{ height: "1px", background: "#1C304A85", transform: "scaleY(0.4)", margin: "8px 0" }} />
                                            )}
                                        </div>
                                    )
                            )}
                    </div>
                )}
            </MobileView>
            <BrowserView>
                {isAccordion ? (
                    <Accordion alwaysOpen defaultActiveKey={["0"]} className="support">
                        {faqs &&
                            faqs.map((faq: TypeDTO.RentFaqDto, index) => (
                                <Accordion.Item
                                    style={{ marginBottom: "1.5rem" }}
                                    key={faq.rentFaqId}
                                    eventKey={index.toString()}
                                    className="accordion-item"
                                >
                                    <Accordion.Header style={{ marginTop: "0" }}>{faq.faqQuestion}</Accordion.Header>
                                    <Accordion.Body>
                                        <ul>
                                            {faq.faqAnswer.split("\n").map((line, index) => (
                                                <li
                                                    style={{ whiteSpace: "pre-wrap" }}
                                                    key={index}
                                                    dangerouslySetInnerHTML={{
                                                        __html: line.replace(". -", `.\n -`),
                                                    }}
                                                />
                                            ))}
                                        </ul>
                                    </Accordion.Body>
                                </Accordion.Item>
                            ))}
                    </Accordion>
                ) : (
                    <div className={`${className}`}>
                        {faqs &&
                            faqs.map(
                                (faq: TypeDTO.RentFaqDto, index) =>
                                    (!faqIds || faqIds.includes(faq.rentFaqId)) && (
                                        <div key={faq.rentFaqId}>
                                            <p className="text-bold">{faq.faqQuestion}</p>
                                            {faq.faqAnswer.split("\n").map((line, index) => (
                                                <p
                                                    key={index}
                                                    dangerouslySetInnerHTML={{
                                                        __html: line.replace(". -", `.\n -`),
                                                    }}
                                                />
                                            ))}
                                            {index + 1 < faqs.length && (
                                                <hr style={{ height: "1px", background: "#1C304A85", transform: "scaleY(0.4)", margin: "8px 0" }} />
                                            )}
                                        </div>
                                    )
                            )}
                    </div>
                )}
            </BrowserView>
        </>
    );
};

export default Faq;
