import * as React from "react";
import { BrowserView, MobileView } from "react-device-detect";
import { Divider, Image, Space } from "antd";

import * as String from "../../commons/string";

import Gallery from "../Gallery";
import ItemCard from "./ItemCard";
import OptionTable from "../ruta40/OptionTable";
import OptionCar from "../ruta40/OptionCar";

import BgTableHeader from "../../assets/images/home/rent/bg_table_header.png";
import ImgDigitalKeyBrowser from "../../assets/images/digitalKey/digitalKey12.jpg";
import ImgDigitalKeyMobile from "../../assets/images/digitalKey/digitalKey11.jpg";
import bayrunTypeInterior1 from "../../assets/images/model/bayrun/BayrunType-interior-1.jpg";
import bayrunTypeInterior2 from "../../assets/images/model/bayrun/BayrunType-interior-2.jpg";
import bayrunTypeInterior3 from "../../assets/images/model/bayrun/BayrunType-interior-3.jpg";
import bayrunTypeInterior4 from "../../assets/images/model/bayrun/BayrunType-interior-4.jpg";
import bayrunTypeInterior5 from "../../assets/images/model/bayrun/BayrunType-interior-5.jpg";
import bayrunTypeInterior6 from "../../assets/images/model/bayrun/BayrunType-interior-6.jpg";
import bayrunTypeInterior7 from "../../assets/images/model/bayrun/BayrunType-interior-7.jpg";
import bayrunTypeInterior8 from "../../assets/images/model/bayrun/BayrunType-interior-8.jpg";
import sTypeInterior1 from "../../assets/images/model/stype/SType-interior-1.jpg";
import sTypeInterior2 from "../../assets/images/model/stype/SType-interior-2.jpg";
import sTypeInterior3 from "../../assets/images/model/stype/SType-interior-3.jpg";

type InfoType = {
    carType: any;
    options: any;
    specifications: any[];
    freeItems: any[];
    rentPaidItems: any[];
};

const DetailCarInfo = ({ info }: { info: InfoType }) => {
    const weekdayPrice = "199,000 원";
    const weekendPrice = "249,000 원";

    const getCarImages = () => {
        switch (info.carType) {
            case "sType":
                return [sTypeInterior1, sTypeInterior2, sTypeInterior3];
            case "bayrun3":
                return [bayrunTypeInterior1, bayrunTypeInterior2, bayrunTypeInterior3];
            case "bayrun4":
                return [bayrunTypeInterior4, bayrunTypeInterior5, bayrunTypeInterior6, bayrunTypeInterior7, bayrunTypeInterior8];
            default:
                return [bayrunTypeInterior4, bayrunTypeInterior5, bayrunTypeInterior6];
        }
    };

    return (
        <>
            <MobileView className="mobile">
                <Space direction="vertical" style={{ width: "100%" }} size={16}>
                    <Space direction="vertical" style={{ width: "100%" }} size={16}>
                        {getCarImages().map((image) => (
                            <Image key={image} src={image} />
                        ))}
                    </Space>
                    <Divider style={{ margin: "14px 0" }} />
                    <Space direction="vertical" style={{ width: "100%" }} size={16}>
                        <h5 className="text-center">{String.layout}</h5>
                        <OptionCar carType={info.carType} options={info.options} rent={true} />
                        <OptionTable hasNo={true} hasDesign={true} options={info.options} />
                        {info.carType === "bayrun4" && (
                            <div className="custom-bolder-box">
                                <p className="text-danger text-bold">※ {String.caution}</p>
                                <ul className="listSpace mb-0 mt-2">
                                    <li>취침인원 3인 권장, 최대 4인(성인2명+어린이 2명)으로 이용하실 수 있습니다.</li>
                                </ul>
                            </div>
                        )}
                    </Space>
                    <Divider style={{ margin: "14px 0" }} />
                    <Space direction="vertical" style={{ width: "100%" }} size={16}>
                        <h5 className="text-center">{String.rentPrice}</h5>
                        <table className="table design-table">
                            <thead style={{ backgroundImage: `url(${BgTableHeader})`, backgroundSize: "cover", border: "none" }}>
                                <tr>
                                    <th colSpan={2} className="text-center">
                                        1박 기준
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th>{String.weekday}</th>
                                    <td>
                                        <p>{weekdayPrice}</p>
                                    </td>
                                </tr>
                                <tr>
                                    <th>{String.weekend} (금, 토, 일)</th>
                                    <td>
                                        <p>{weekendPrice}</p>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div className="custom-bolder-box">
                            <p className="text-danger text-bold">※ {String.caution}</p>
                            <ul className="listSpace mb-0  mt-2">
                                <li>성수기 비용은 별도 공지됩니다.</li>
                                <li>주말(금,토,일)은 2박 3일 예약만 가능합니다.</li>
                            </ul>
                        </div>
                    </Space>
                    <Divider style={{ margin: "14px 0" }} />
                    <Space direction="vertical" style={{ width: "100%" }} size={16}>
                        <h5 className="text-center">{String.freeItems}</h5>
                        <ItemCard items={info.freeItems} />
                    </Space>
                    <Divider style={{ margin: "14px 0" }} />
                    <Space direction="vertical" style={{ width: "100%" }} size={16}>
                        <h5 className="text-center">{String.paidItems}</h5>
                        <ItemCard items={info.rentPaidItems} />
                    </Space>
                    <Divider style={{ margin: "14px 0" }} />
                    <Space direction="vertical" style={{ width: "100%" }} size={16}>
                        <h5 className="text-center">{String.digitalKeyService}</h5>
                        <img className="w-100" src={ImgDigitalKeyMobile}></img>
                    </Space>
                    <Divider style={{ margin: "14px 0" }} />
                    <Space direction="vertical" style={{ width: "100%" }} size={16}>
                        <h5 className="text-center">{String.specificationInfo}</h5>
                        <table className="table design-table">
                            <thead style={{ backgroundImage: `url(${BgTableHeader})`, backgroundSize: "cover", border: "none" }}>
                                <tr>
                                    <th scope="col">{String.specificationName}</th>
                                    <td>{String.specifications}</td>
                                </tr>
                            </thead>
                            <tbody>
                                {info.specifications.map((specification) => (
                                    <tr key={specification.th}>
                                        <th>{specification.th}</th>
                                        <td>
                                            <p>{specification.td}</p>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <div className="custom-bolder-box">
                            <p className="text-danger text-bold">※ {String.caution}</p>
                            <ul className="listSpace mb-0 mt-2">
                                <li>차량 높이가 3m 이므로 통행 시 유의해 주세요.</li>
                                <li>차량 무게가 약 4톤이므로, 경사로에 장기간 주차를 금지합니다.</li>
                            </ul>
                        </div>
                    </Space>
                </Space>
            </MobileView>
            <BrowserView className="browser">
                <Space direction="vertical" style={{ width: "100%" }} size={50}>
                    <Gallery images={getCarImages()} />
                    <Space direction="vertical" style={{ width: "100%" }} size={20}>
                        <h5 className="text-center">{String.layout}</h5>
                        <OptionCar carType={info.carType} options={info.options} rent={true} />
                        <OptionTable hasNo={true} hasDesign={true} options={info.options} />
                        {info.carType === "bayrun4" && (
                            <div className="custom-bolder-box">
                                <p className="text-danger text-bold">※ {String.caution}</p>
                                <ul className="listSpace mb-0  mt-2">
                                    <li>취침인원 3인 권장, 최대 4인(성인2명+어린이 2명)으로 이용하실 수 있습니다.</li>
                                </ul>
                            </div>
                        )}
                    </Space>
                    <Space direction="vertical" style={{ width: "100%" }} size={20}>
                        <h5 className="text-center">{String.rentPrice}</h5>
                        <table className="table design-table">
                            <thead style={{ backgroundImage: `url(${BgTableHeader})`, backgroundSize: "cover", border: "none" }}>
                                <tr>
                                    <th colSpan={2} className="text-center">
                                        1박 기준
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th>{String.weekday}</th>
                                    <td>
                                        <p>{weekdayPrice}</p>
                                    </td>
                                </tr>
                                <tr>
                                    <th>{String.weekend} (금, 토, 일)</th>
                                    <td>
                                        <p>{weekendPrice}</p>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div className="custom-bolder-box">
                            <p className="text-danger text-bold">※ {String.caution}</p>
                            <ul className="listSpace mb-0  mt-2">
                                <li>성수기 비용은 별도 공지됩니다.</li>
                                <li>주말(금,토,일)은 2박 3일 예약만 가능합니다.</li>
                            </ul>
                        </div>
                    </Space>
                    <Space direction="vertical" style={{ width: "100%" }} size={20}>
                        <h5 className="text-center">{String.freeItems}</h5>
                        <ItemCard items={info.freeItems} />
                    </Space>
                    <Space direction="vertical" style={{ width: "100%" }} size={20}>
                        <h5 className="text-center">{String.paidItems}</h5>
                        <ItemCard items={info.rentPaidItems} />
                    </Space>
                    <Space direction="vertical" style={{ width: "100%" }} size={20}>
                        <h5 className="text-center">{String.digitalKeyService}</h5>
                        <img className="w-100 m-0 p-0" src={ImgDigitalKeyBrowser}></img>
                    </Space>
                    <Space direction="vertical" style={{ width: "100%" }} size={20}>
                        <h5 className="text-center">{String.specificationInfo}</h5>
                        <table className="table design-table">
                            <thead style={{ backgroundImage: `url(${BgTableHeader})`, backgroundSize: "cover", border: "none" }}>
                                <tr>
                                    <th scope="col">{String.specificationName}</th>
                                    <td>{String.specifications}</td>
                                </tr>
                            </thead>
                            <tbody>
                                {info.specifications.map((specification) => (
                                    <tr key={specification.th}>
                                        <th>{specification.th}</th>
                                        <td>
                                            <p>{specification.td}</p>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <div className="custom-bolder-box">
                            <p className="text-danger text-bold">※ {String.caution}</p>
                            <ul className="listSpace mb-0  mt-2">
                                <li>차량 높이가 3m 이므로 통행 시 유의해 주세요.</li>
                                <li>차량 무게가 약 4톤이므로, 경사로에 장기간 주차를 금지합니다.</li>
                            </ul>
                        </div>
                    </Space>
                </Space>
            </BrowserView>
        </>
    );
};

export default DetailCarInfo;
