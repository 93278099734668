import React, { useState } from "react";
import { Space, Col, Row } from "antd";
import { InfoCircleFilled, LeftCircleFilled } from "@ant-design/icons";

import * as TypeDTO from "../commons/typeDTO";
import * as Common from "../commons/common";
import * as String from "../commons/string";
import * as Utils from "../utils/utils";

import styles from "./Coupon.module.css";
import moment from "moment";

const Coupon = ({ mobile, userCoupons }: { mobile?: boolean; userCoupons: Array<TypeDTO.UserCouponDto> }) => {
    const [infoCard, setInfoCard] = useState<number>();
    const FORMAT_DATE = "YY.MM.DD";

    const convertCouponCoverage = (couponCoverage: string) => {
        switch (couponCoverage) {
            case "RENT":
                return "렌트";
            case "ORDER":
                return "구매";
            default:
                return "";
        }
    };

    const displayCoupon = (userCoupon: TypeDTO.UserCouponDto) => {
        const dDay = moment(userCoupon.expiredDate ?? userCoupon.coupon.expiredDate).diff(moment(), "days");
        const isExpiration = (userCoupon.expiredDate ?? userCoupon.coupon.expiredDate) < moment().format(Common.FORMAT_DATE_TIME);

        const lines = userCoupon.coupon.guidanceText?.replace(/\n/g, "<br />").split("<br />");
        const restLines = lines?.join("<br />") as string;

        return (
            <div className={`${styles.wrapper} ${mobile ? "" : styles.browser}`}>
                <Space size={10} align="start" className={mobile ? `${styles.mobileBody} ${styles.body}` : styles.body}>
                    {infoCard !== userCoupon.userCouponId && (
                        <div
                            className={
                                userCoupon.usedDate || isExpiration
                                    ? `${styles.status} ${styles.usedStatus}`
                                    : `${styles.status} ${styles.usableStatus}`
                            }
                        >
                            <span className="b-md">
                                {!userCoupon.coupon.couponName.startsWith("[") && userCoupon.coupon.multiCoupon
                                    ? "중복할인"
                                    : userCoupon.coupon.couponName.substring(1, userCoupon.coupon.couponName.indexOf("]"))}
                            </span>
                        </div>
                    )}
                    {mobile &&
                        (infoCard === userCoupon.userCouponId ? (
                            <LeftCircleFilled
                                onClick={() => {
                                    setInfoCard(undefined);
                                }}
                                className={styles.infoIcon}
                            />
                        ) : (
                            <InfoCircleFilled
                                onClick={() => {
                                    setInfoCard(userCoupon.userCouponId);
                                }}
                                className={styles.infoIcon}
                            />
                        ))}
                    <Space className="w-100" direction="vertical" size={4}>
                        <div style={{ width: "90%" }}>
                            {userCoupon.usedDate ? (
                                <>
                                    <span className={styles.dateText}>{Utils.utcToLocalTime(userCoupon.usedDate, Common.FORMAT_DATE)}</span>{" "}
                                    <span className={`${styles.careText} ${styles.boldText}`}>사용 완료</span>
                                </>
                            ) : (
                                <>
                                    {/* <span className={styles.boldText}>
                                        D{dDay < 0 ? "+" : "-"}
                                        {dDay < 0 ? -dDay : dDay}일
                                    </span> */}
                                    <span className={styles.dateText}>~</span>
                                    <span className={styles.dateText}>
                                        {moment(userCoupon.expiredDate ?? userCoupon.coupon.expiredDate).format(FORMAT_DATE)}
                                    </span>
                                    <span
                                        className={`ms-2 ${isExpiration ? styles.careText + " " + styles.boldText : styles.blueText}`}
                                        style={{ width: "48px", whiteSpace: "nowrap" }}
                                    >
                                        {isExpiration ? String.expiration : "사용 가능"}
                                    </span>
                                </>
                            )}
                        </div>
                        {infoCard === userCoupon.userCouponId ? (
                            <div className={styles.box}>
                                <span dangerouslySetInnerHTML={{ __html: restLines }} />
                            </div>
                        ) : (
                            <>
                                <div>
                                    <h6 className={styles.couponTitle}>
                                        {userCoupon.coupon.couponName.substring(userCoupon.coupon.couponName.indexOf("]") + 1)}
                                    </h6>
                                </div>
                                {mobile && userCoupon.coupon.showSerialNumber && (
                                    <div className={styles.couponCode}>
                                        쿠폰 코드<span>{userCoupon.serialNumber}</span>
                                    </div>
                                )}
                                {!mobile && (
                                    <div className={styles.box}>
                                        <span dangerouslySetInnerHTML={{ __html: restLines }} />
                                    </div>
                                )}
                            </>
                        )}
                    </Space>
                </Space>
                {!mobile && userCoupon.coupon.showSerialNumber && (
                    <div className={styles.couponCode}>
                        쿠폰 코드<span>{userCoupon.serialNumber}</span>
                    </div>
                )}
            </div>
        );
    };

    return (
        <Row gutter={[20, 20]}>
            {userCoupons.map((coupon) => (
                <Col key={coupon.userCouponId} xs={24} xxl={12}>
                    {displayCoupon(coupon)}
                </Col>
            ))}
        </Row>
    );
};

export default Coupon;
