import React, { useEffect, useState } from "react";

import { Input } from "antd";

function PhoneInput({
    disabled,
    onChange,
    value,
    ...props
}: {
    disabled?: boolean;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    value?: string;
}) {
    const [inputValue, setInputValue] = useState(value !== undefined ? value : "");
    const [disableInput, setDisableInput] = useState(disabled !== undefined ? disabled : false);

    useEffect(() => {
        if (value !== undefined) setInputValue(value);
    }, [value]);

    useEffect(() => {
        if (disabled !== undefined) setDisableInput(disabled);
    }, [disabled]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const tempString = e.target.value;
        e.target.value = tempString.replace(/\D/g, "");

        const regex = /^[0-9\b]{0,13}$/;
        if (regex.test(e.target.value)) {
            setInputValue(e.target.value);

            if (onChange !== undefined) onChange(e);
        }
    };

    // useEffect(() => {
    //     if (inputValue.length === 10) {
    //         setInputValue(
    //             inputValue.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3")
    //         );
    //     }
    //     if (inputValue.length === 13) {
    //         setInputValue(
    //             inputValue
    //                 .replace(/-/g, "")
    //                 .replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3")
    //         );
    //     }
    // }, [inputValue]);

    return (
        <Input
            className="login-input"
            type="text"
            disabled={disableInput}
            onChange={handleChange}
            value={inputValue}
            placeholder="휴대전화 번호"
            {...props}
        />
    );
}

export default PhoneInput;
