import Carousel from "react-bootstrap/Carousel";
import { Space, Row, Col, Image, Button } from "antd";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import ScrollToTopOnMount from "../component/ScrollTopOnMount";

// import sTypeExterior1 from "../assets/images/model/stype/SType-exterior-1.jpg";
// import sTypeExterior2 from "../assets/images/model/stype/SType-exterior-2.jpg";
// import sTypeExterior3 from "../assets/images/model/stype/SType-exterior-3.jpg";
// import sTypeInterior1 from "../assets/images/model/stype/SType-interior-1.jpg";
// import sTypeInterior2 from "../assets/images/model/stype/SType-interior-2.jpg";
// import sTypeInterior3 from "../assets/images/model/stype/SType-interior-3.jpg";
// import sTypeInterior4 from "../assets/images/model/stype/SType-interior-4.jpg";
// import sTypeInterior5 from "../assets/images/model/stype/SType-interior-5.jpg";
import sTypeOption6 from "../assets/images/model/stype/SType-option-1.png";
import sTypeOption7 from "../assets/images/model/stype/SType-option-2.png";
import digitalKeyImage1 from "../assets/images/digitalKey/digitalKey1.jpg";
import digitalKeyImage5 from "../assets/images/digitalKey/digitalKey10.jpg";
import fmsImage1 from "../assets/images/fms/fms1.jpg";
import fmsImage5 from "../assets/images/fms/fms10.jpg";

export default function ModelRuta40TypeS() {
    const isMobile = useMediaQuery({ maxWidth: 700 });
    const navigate = useNavigate();
    return (
        <div>
            <ScrollToTopOnMount />
            <div className="header-container">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="fadeIn text-center mb-5">
                            <h5 className="section-title bg-white text-center text-dark px-3 mb-4">캠핑카</h5>
                            <h1>RT40 - S타입</h1>
                        </div>
                    </div>
                </div>
            </div>
            <Col
                xs={{ span: 24 }}
                sm={{ span: 22, offset: 1 }}
                lg={{ span: 18, offset: 3 }}
                xl={{ span: 16, offset: 4 }}
                xxl={{ span: 14, offset: 5 }}
                className="fadeInUp p-4"
            >
                {" "}
                {/* <div className="mb-5">
                    <div className="text-center mb-5 mt-3">
                        <h3>디자인</h3>
                    </div>

                    <Carousel interval={3000} variant="light">
                        <Carousel.Item>
                            <img className="d-block w-100 img-fluid" alt="First slide" src={sTypeExterior1} />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img className="d-block w-100 img-fluid" alt="Second slide" src={sTypeExterior2} />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img className="d-block w-100 img-fluid" alt="Third slide" src={sTypeExterior3} />
                        </Carousel.Item>
                    </Carousel>

                    <Row className="row mt-5 px-2">
                        <Col span={8} className="mt-5 col-lg-4 px-1">
                            <Image src={sTypeInterior1} className="w-100 shadow-1-strong" alt="Boat on Calm Water" />
                        </Col>

                        <Col span={8} className="mt-5 col-lg-4 px-1">
                            <Space direction="vertical" size={isMobile ? 4 : 8}>
                                <Image src={sTypeInterior5} className="w-100 shadow-1-strong" alt="Wintry Mountain Landscape" />
                                <Image src={sTypeInterior2} className="w-100 shadow-1-strong" alt="Mountains in the Clouds" />
                            </Space>
                        </Col>

                        <Col span={8} className="mt-5 col-lg-4 px-1">
                            <Space direction="vertical" size={isMobile ? 4 : 8}>
                                <Image src={sTypeInterior4} className="w-100 shadow-1-strong" alt="Waves at Sea" />
                                <Image src={sTypeInterior3} className="w-100 shadow-1-strong" alt="Boat on Calm Water" />
                            </Space>
                        </Col>
                    </Row>
                </div> */}
            </Col>
            <Col
                xs={{ span: 24 }}
                sm={{ span: 22, offset: 1 }}
                lg={{ span: 18, offset: 3 }}
                xl={{ span: 16, offset: 4 }}
                xxl={{ span: 14, offset: 5 }}
                className="fadeInUp p-4"
            >
                {" "}
                <div className="mb-5 ">
                    <div className="text-center mb-5">
                        <h3>옵션</h3>
                    </div>
                    <div className="col-lg-12 text-center optionBox py-4">
                        <div className="col-md-12">
                            <img style={{ width: "70%" }} className="d-block img-fluid" alt="Second slide" src={sTypeOption6} />
                            <img className="d-block img-fluid" alt="Second slide" src={sTypeOption7} />
                        </div>
                        <div className="col-md-12">
                            <table className="table design-table">
                                <thead>
                                    <tr>
                                        <th colSpan={2}>기본 옵션</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <th scope="row">천장형 냉난방기</th>
                                        <td>도메틱 IBIS4(냉/난방)</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">냉장고</th>
                                        <td>
                                            도메틱<span className="mb-textline">(냉장/냉동 겸용, 108L)</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th scope="row">전자렌지</th>
                                        <td>보겔 VOG-2021(무회전 플랫)</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">팝업 테이블</th>
                                        <td>
                                            일세 푸쉬버튼 테이블렉<span className="mb-textline">(유압식 높이조절)</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th scope="row">인버터</th>
                                        <td>5.6K</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">환풍기</th>
                                        <td>맥스펜 7500K(360mm)</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">청수통 / 온수기</th>
                                        <td>100L / 10L(12V)</td>
                                    </tr>
                                </tbody>
                            </table>
                            <table className="table design-table">
                                <thead>
                                    <tr>
                                        <th colSpan={2}>선택 옵션</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <th scope="row">어닝</th>
                                        <td>피암마 F80S 320</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">TV</th>
                                        <td>LG전자 룸앤티비 스마트 TV 27TN600S (27인치)</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </Col>
            <Col
                xs={{ span: 24 }}
                sm={{ span: 22, offset: 1 }}
                lg={{ span: 18, offset: 3 }}
                xl={{ span: 16, offset: 4 }}
                xxl={{ span: 14, offset: 5 }}
                className="fadeInUp p-4"
            >
                {" "}
                <div className="mb-5">
                    <div className="text-center mb-5">
                        <h3>서비스</h3>
                    </div>
                    <div className="col-lg-12 text-center pb-4">
                        <h6 className="text-type">디지털 키 앱</h6>
                        <p style={{ wordBreak: "keep-all" }} className="b-md">
                            고객편의를 위한 차량 문 열림/잠금 기능, 차량 배터리 상태 정보, 충전소 위치 정보 서비스를 제공합니다.
                        </p>
                        {isMobile ? <img className="w-100" src={digitalKeyImage5}></img> : <img className="w-100" src={digitalKeyImage1}></img>}
                    </div>
                    <div className="col-lg-12 text-center py-4">
                        <h6 className="text-type">차량 관제 시스템</h6>
                        <p style={{ wordBreak: "keep-all" }} className="b-md">
                            정밀한 정비 서비스 제공과 고객 안전을 위한 차량 관제 서비스를 지원합니다.
                        </p>
                        {isMobile ? <img className="w-100" src={fmsImage5}></img> : <img className="w-100" src={fmsImage1}></img>}
                    </div>
                </div>
            </Col>
            <Col
                xs={{ span: 24 }}
                sm={{ span: 22, offset: 1 }}
                lg={{ span: 18, offset: 3 }}
                xl={{ span: 16, offset: 4 }}
                xxl={{ span: 14, offset: 5 }}
                className="fadeInUp p-4"
            >
                {" "}
                <div className="mb-5">
                    <div className="text-center mb-5">
                        <h3>제원 정보</h3>
                    </div>
                    <div className="col-lg-12 text-center optionBox py-5">
                        <div className="col-md-12">
                            <table className="table design-table">
                                <thead>
                                    <tr>
                                        <th colSpan={2}>제원명</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <th>최대 속도(km/h)</th>
                                        <td>100</td>
                                    </tr>
                                    <tr>
                                        <th>최대 주행거리(km)</th>
                                        <td>303.68(환경부공인 인증)</td>
                                    </tr>
                                    <tr>
                                        <th>배터리(KWh)</th>
                                        <td>106(Li-Ion)</td>
                                    </tr>
                                    <tr>
                                        <th>모터 PMSM Tyre</th>
                                        <td>215 / 75R</td>
                                    </tr>
                                    <tr>
                                        <th>제동장치</th>
                                        <td>ABS+EBD</td>
                                    </tr>
                                    <tr>
                                        <th>스티어링</th>
                                        <td>파워 스티어링</td>
                                    </tr>
                                    <tr>
                                        <th>구동방식</th>
                                        <td>후륜 구동 방식</td>
                                    </tr>
                                    <tr>
                                        <th>충전 모드</th>
                                        <td>Combo 1(CCS)</td>
                                    </tr>
                                    <tr>
                                        <th>안전사양</th>
                                        <td>ABS</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </Col>
        </div>
    );
}
