import { Button, Checkbox, Input, Modal, Row, Card, Space, Result, Form, Spin } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

import * as Common from "../commons/common";
import * as TypeDTO from "../commons/typeDTO";
import * as Request from "../commons/request";
import * as Utils from "../utils/utils";

import { useAsyncAxios, utilAxiosWithAppToken } from "../utils/customAxios";
import styles from "./Event.module.css";
import BgBanner from "../assets/images/home/event/campnicFair/bg_event_banner.jpg";
import BgEventBg from "../assets/images/home/event/campnicFair/bg_event_bg.png";

import PrizeSticker from "../assets/images/home/ruta40/prize_sticker.png";
import PrizeTowel from "../assets/images/home/ruta40/prize_towel.png";
import PrizeGiftCard from "../assets/images/home/ruta40/prize_giftcard.png";
import PrizeUmbrella from "../assets/images/home/ruta40/prize_umbrella.png";
import PrizeThermos from "../assets/images/home/ruta40/prize_thermos.png";
import PrizeCopelSet from "../assets/images/home/ruta40/prize_copelSet.png";
import Prize1DayCoupon from "../assets/images/home/ruta40/prize_1day_coupon.png";
import Prize2DayCoupon from "../assets/images/home/ruta40/prize_2day_coupon.png";

const CampingFairEvent = ({ eventId, phoneNumber }: { eventId: number; phoneNumber?: string }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const isXS = useMediaQuery({ maxWidth: 300 });
    const [eventPhoneNumber, setEventPhoneNumber] = useState<string>("");
    const [staffCheck, setStaffCheck] = useState<boolean>(false);
    const [agreementEvent, setAgreementEvent] = useState<boolean>(false);
    const [eventUser, setEventUser] = useState<TypeDTO.EventUserDto>();
    const [prizeImage, setPrizeImage] = useState("");

    const [showResult, setShowResult] = useState(false);
    const [showErrorPrize, setShowErrorPrizePopup] = useState(false);

    const [step, setStep] = useState<number>(1);
    const [errorMessage, setErrorMessage] = useState<string>();
    const [resultLoading, setResultLoading] = useState<boolean>(true);

    useEffect(() => {
        if (phoneNumber === undefined) return;
        setEventPhoneNumber(phoneNumber);
        setStep(3);
    }, [phoneNumber]);

    useEffect(() => {
        setErrorMessage(undefined);
    }, [eventPhoneNumber]);

    useEffect(() => {
        if (showResult === true) {
            setStep(4);
            setTimeout(function () {
                setResultLoading(false);
            }, 3000);
        }
    }, [showResult]);

    useEffect(() => {
        if (showErrorPrize === true) {
            setStep(4);
        }
    }, [showErrorPrize]);

    const handlePhoneNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const tempString = e.target.value;
        e.target.value = tempString.replace(/\D/g, "");

        const regex = /^[0-9\b]{0,13}$/;
        if (regex.test(e.target.value)) {
            setEventPhoneNumber(e.target.value);
        }
    };

    const handleEvent = () => {
        if (loadingJoinEvent) return;
        if (step === 2) {
            requestUserCheck();
        }
        if (step === 3) {
            requestJoinEvent();
        }
    };

    const requestAxiosUserCheck = async () => {
        const response = await utilAxiosWithAppToken().post(Request.EVENT_ADD_URL + Request.EVENT_USER_CHECK_URL + "?phone=" + eventPhoneNumber);
        return response.data;
    };

    const {
        loading: loadingUserCheck,
        error: errorUserCheck,
        data: resultUserCheck,
        execute: requestUserCheck,
    } = useAsyncAxios(requestAxiosUserCheck, false, true);

    useEffect(() => {
        if (resultUserCheck === null) return;
        console.log("resultUserCheck", resultUserCheck);
        if (resultUserCheck.eventUser) {
            setStep(3);
        } else {
            setErrorMessage("※ 미가입 된 번호입니다. 회원가입을 먼저 완료해 주세요.");
        }
    }, [resultUserCheck]);

    const requestAxiosJoinEvent = async () => {
        const response = await utilAxiosWithAppToken().post(Request.EVENT_URL + eventId + Request.EVENT_JOIN_URL, null, {
            params: { phone: eventPhoneNumber },
        });

        return response.data;
    };

    const {
        loading: loadingJoinEvent,
        error: errorJoinEvent,
        data: resultJoinEvent,
        execute: requestJoinEvent,
    } = useAsyncAxios(requestAxiosJoinEvent, false, true);

    useEffect(() => {
        if (resultJoinEvent === null) return;

        console.log("resultJoinEvent", resultJoinEvent);
        setEventUser(resultJoinEvent.eventUser);
    }, [resultJoinEvent]);

    useEffect(() => {
        if (errorJoinEvent === null) return;

        console.log("errorJoinEvent", errorJoinEvent);
        if (errorJoinEvent.response?.data?.code === "NONEXISTENT_EVENT_PRIZE") {
            setShowErrorPrizePopup(true);
        }
    }, [errorJoinEvent]);

    const requestAxiosReceivedPrize = async (phoneNumber: string) => {
        const response = await utilAxiosWithAppToken().post(Request.EVENT_URL + eventId + Request.EVENT_RECEIVED_PRIZE_URL, null, {
            params: { phone: phoneNumber },
        });

        return response.data;
    };

    const {
        loading: loadingReceivedPrize,
        error: errorReceivedPrize,
        data: resultReceivedPrize,
        execute: requestReceivedPrize,
    } = useAsyncAxios(requestAxiosReceivedPrize);

    useEffect(() => {
        if (resultReceivedPrize === null) return;

        console.log("resultReceivedPrize", resultReceivedPrize);
        // setEventUser(resultReceivedPrize.eventUser);
        setEventUser(resultReceivedPrize.eventUser);
        setStaffCheck(false);
    }, [resultReceivedPrize]);

    useEffect(() => {
        if (errorReceivedPrize === null) return;

        console.log("errorJoinEvent", errorReceivedPrize);
        setShowResult(false);
        setStaffCheck(false);
    }, [errorReceivedPrize]);

    useEffect(() => {
        switch (eventUser?.eventPrize.prizeName) {
            case "RUTA40 스티커":
                setPrizeImage(PrizeSticker);
                break;
            case "여행용 압축 타월":
                setPrizeImage(PrizeTowel);
                break;
            case "스타벅스 기프트 카드(5천원권)":
                setPrizeImage(PrizeGiftCard);
                break;
            case "6단 미니 UV 양우산":
                setPrizeImage(PrizeUmbrella);
                break;
            case "스탠리 보온병 750ml":
                setPrizeImage(PrizeThermos);
                break;
            case "코베아 코펠 셋트":
                setPrizeImage(PrizeCopelSet);
                break;
            case "[1박 무료] 2024 캠프닉 페어 현장 이벤트 쿠폰":
                setPrizeImage(Prize1DayCoupon);
                break;
            case "[2박 무료] 2024 캠프닉 페어 현장 이벤트 쿠폰":
                setPrizeImage(Prize2DayCoupon);
                break;
            default:
                break;
        }
        if (eventUser !== undefined) {
            setShowResult(true);
        }
    }, [eventUser]);

    const receivedPrize = (phoneNumber: string) => {
        requestReceivedPrize(phoneNumber);
    };

    const displayResult = () => {
        if (eventUser === undefined) return null;

        if (eventUser.eventPrize.received === true) {
            return (
                <Space direction="vertical" className={styles.wrapper} size={12}>
                    <div>
                        <div className={styles.title}>
                            <h5>STEP 4.</h5>
                            <h4>이벤트 응모 결과</h4>
                        </div>
                        <Space direction="vertical" className={`w-100 ${styles.resultWrapper}`} size={4} style={{ textAlign: "center" }}>
                            <p>
                                이미 상품을 수령했습니다. <br />
                                수령일자 : {Utils.utcToLocalTime(eventUser.eventPrize.receivedDate, Common.FORMAT_DATE_TIME)}
                            </p>
                            <Space size={0} direction="vertical" className="fadeIn">
                                <img src={prizeImage} width={180} />
                                <p style={{ fontWeight: 700 }}>{eventUser.eventPrize.prizeName}</p>
                            </Space>
                        </Space>
                    </div>
                    <div className={styles.btnWrapper}>
                        <Button
                            block
                            className={`btn-light-border b-lg ${styles.btn} ${styles.secondaryBtn}`}
                            onClick={() => {
                                setShowResult(false);
                                setShowErrorPrizePopup(false);
                                setEventPhoneNumber("");
                                setStep(1);
                            }}
                        >
                            처음으로
                        </Button>
                    </div>
                </Space>
            );
        } else {
            return (
                <>
                    {staffCheck ? (
                        <Space direction="vertical" className={styles.wrapper} size={12}>
                            <div className={styles.title}>
                                <h5>STEP 4.</h5>
                                <h4 style={{ marginBottom: 0 }}>스태프 확인</h4>
                            </div>
                            <Space direction="vertical" className={`w-100 ${styles.resultWrapper}`} size={4} style={{ textAlign: "center" }}>
                                <p>상품을 수령하셨나요?</p>
                                <p className={styles.textDanger}>※ 수령 완료을 누르면 상품 수령으로 변경되어 더 이상 상품을 수령할 수 없습니다.</p>
                            </Space>
                            <div className={styles.btnWrapper}>
                                <Button
                                    block
                                    className={`btn-light-border b-lg ${styles.btn} ${styles.secondaryBtn}`}
                                    onClick={() => {
                                        setStaffCheck(false);
                                    }}
                                >
                                    취소
                                </Button>
                                <Button
                                    block
                                    className={`btn-dark b-lg ${styles.btn} ${styles.primaryBtn}`}
                                    type="primary"
                                    onClick={() => receivedPrize(eventUser.phone)}
                                >
                                    수령 완료
                                </Button>
                            </div>
                        </Space>
                    ) : (
                        <Space direction="vertical" className={styles.wrapper} size={12}>
                            <div className={styles.title}>
                                <h5>STEP 4.</h5>
                                <h4 style={{ marginBottom: 0 }}>이벤트 응모 결과</h4>
                            </div>
                            {resultLoading ? (
                                <Space className={`fadeOut ${styles.loading}`} size={8}>
                                    <Spin size="large" />
                                    <div>
                                        <span className={styles.text}>상</span>
                                        <span className={styles.text}>품 </span>
                                        <span className={styles.text}> 추</span>
                                        <span className={styles.text}>첨 </span>
                                        <span className={styles.text}> 중</span>
                                        <span className={styles.text}>!</span>
                                    </div>
                                </Space>
                            ) : (
                                <>
                                    <Space direction="vertical" className={`w-100 ${styles.resultWrapper}`} size={4}>
                                        <p>
                                            {eventUser.name}님 당첨을 축하합니다! <br /> 당첨 결과를 스태프에게 보여주세요.
                                        </p>
                                        <Space className="mb-2" size={0} direction="vertical">
                                            <img src={prizeImage} width={180} />
                                            <p style={{ fontWeight: 700 }}>{eventUser.eventPrize.prizeName}</p>
                                        </Space>
                                    </Space>
                                    <div className={styles.btnWrapper}>
                                        <Button
                                            block
                                            className={`btn-dark b-lg ${styles.btn} ${styles.primaryBtn}`}
                                            type="primary"
                                            onClick={() => setStaffCheck(true)}
                                        >
                                            스태프 확인
                                        </Button>
                                    </div>
                                </>
                            )}
                        </Space>
                    )}
                </>
            );
        }
    };

    const displayErrorPrize = () => {
        return (
            <Space direction="vertical" className={styles.wrapper} size={8}>
                <div className={styles.title}>
                    <h4>상품 소진</h4>
                </div>
                <Space direction="vertical" className="w-100" size={4}>
                    <p>준비된 상품이 모두 소진되었습니다.</p>
                </Space>
                <div className={styles.btnWrapper}>
                    <Button
                        block
                        className={`btn-light-border b-lg ${styles.btn} ${styles.secondaryBtn}`}
                        onClick={() => {
                            setShowResult(false);
                            setShowErrorPrizePopup(false);
                            setStep(1);
                        }}
                    >
                        처음으로
                    </Button>
                </div>
            </Space>
        );
    };

    const displayStep = () => {
        switch (step) {
            case 1:
                return (
                    <Space direction="vertical" className={`${styles.wrapper}`} size={12}>
                        <div className={styles.title}>
                            <h5>STEP 1-1.</h5>
                            <h4>비회원이시면</h4>
                            <p>
                                아래 <b>‘회원가입’ 버튼</b>을 눌러 <b>회원가입을 완료</b>해 주세요.
                            </p>
                        </div>
                        <Button
                            block
                            className={`btn-light-border b-lg ${styles.btn} ${styles.secondaryBtn}`}
                            onClick={() => navigate(Common.PAGE_SIGNUP, { state: { nextPath: location.pathname } })}
                        >
                            회원가입
                        </Button>
                        <hr style={{ margin: "8px" }} />
                        <Space className="space-w-100" direction="vertical" size={8}>
                            <div className={styles.title}>
                                <h5>STEP 1-2.</h5>
                                <h4>이미 가입하셨다면</h4>
                                <p>
                                    <b>다음 단계</b>를 진행해 주세요.
                                </p>
                            </div>
                        </Space>
                        <div className={styles.btnWrapper}>
                            <Button block className={`btn-dark b-lg ${styles.btn} ${styles.primaryBtn}`} type="primary" onClick={() => setStep(2)}>
                                다음 단계
                            </Button>
                        </div>
                    </Space>
                );
            case 2:
                return (
                    <Space direction="vertical" className={styles.wrapper} size={12}>
                        <div className={styles.title}>
                            <h5>STEP 2.</h5>
                            <h4>휴대전화번호 입력하기</h4>
                            <p>
                                아래 <b>‘휴대전화번호 입력란’</b>에 <b>가입하신 휴대전화번호를 입력</b>해 주세요.
                            </p>
                        </div>
                        <div>
                            <Input
                                type="text"
                                onChange={handlePhoneNumberChange}
                                value={eventPhoneNumber}
                                placeholder="휴대전화번호 입력"
                                className={`b-md mb-1 ph-blue ${styles.input}`}
                                style={{ height: "42x" }}
                                defaultValue={phoneNumber ? phoneNumber : undefined}
                            />
                            {errorMessage && <p className={styles.textDanger}>{errorMessage}</p>}
                        </div>
                        <div className={styles.btnWrapper}>
                            <Button block className={`btn-light-border b-lg ${styles.btn} ${styles.secondaryBtn}`} onClick={() => setStep(1)}>
                                이전 단계
                            </Button>
                            <Button
                                block
                                className={`btn-dark b-lg ${styles.btn} ${styles.primaryBtn}`}
                                type="primary"
                                disabled={eventPhoneNumber.length < 11}
                                onClick={() => handleEvent()}
                            >
                                다음 단계
                            </Button>
                        </div>
                    </Space>
                );
            case 3:
                return (
                    <Space direction="vertical" className={styles.wrapper} size={12}>
                        <div className={styles.title}>
                            <h5>STEP 3.</h5>
                            <h4>개인정보 수집·이용 동의</h4>
                            <Checkbox
                                className={`event-checkbox ${styles.checkbox}`}
                                checked={agreementEvent}
                                onChange={(e) => setAgreementEvent(e.target.checked)}
                                autoFocus
                            >
                                <p style={{ fontWeight: 700 }}>개인정보 수집·이용을 동의합니다.</p>
                            </Checkbox>
                        </div>

                        <div className={styles.agreeBody}>
                            <span>
                                1. 수집/이용 목적 : 이벤트 참여
                                <br />
                                2. 수집 항목 : 휴대전화번호
                                <br />
                                3. 보유 기간 : 개인정보 수집일로부터 3개월 (90일)
                            </span>
                        </div>
                        <p className={styles.textDanger}>
                            ※ 개인정보 수집·이용에 대한 동의를 거부할 권리가 있으나, 동의하지 않으실 경우 이벤트에 참여하실 수 없습니다.
                        </p>
                        <div className={styles.btnWrapper}>
                            <Button block className={`btn-light-border b-lg ${styles.btn} ${styles.secondaryBtn}`} onClick={() => setStep(2)}>
                                이전 단계
                            </Button>
                            <Button
                                block
                                className={`btn-dark b-lg ${styles.btn} ${styles.primaryBtn}`}
                                type="primary"
                                disabled={!agreementEvent}
                                onClick={() => handleEvent()}
                                style={{ height: "10%" }}
                            >
                                {isXS ? "응모하기" : "이벤트 응모하기"}
                            </Button>
                        </div>
                    </Space>
                );
            case 4:
                if (showResult) {
                    return displayResult();
                }
                if (showErrorPrize) {
                    return displayErrorPrize();
                }
                break;
            default:
                break;
        }
    };

    return (
        <div className={`event mobile ${styles.body}`} style={{ background: "#5FAA53" }}>
            <div
                style={{
                    bottom: 0,
                    left: 0,
                    width: "100vw",
                    height: "100%",
                    minHeight: "100vh",
                    display: "flex",
                    flexDirection: "column",
                    backgroundImage: `url(${BgEventBg})`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "100%",
                    backgroundPosition: "top",
                    padding: "24px 24px 24px",
                    alignItems: "center",
                }}
            >
                <img src={BgBanner} className="mb-3" style={{ width: "70%" }} />
                <div className={isXS ? "p-3" : "p-4"} style={{ width: "100%", backgroundColor: "#fff", borderRadius: "15px" }}>
                    {displayStep()}
                </div>
            </div>
        </div>
    );
};

export default CampingFairEvent;
