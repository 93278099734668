import { useLayoutEffect, useState } from "react";

import { AxiosRequestConfig } from "axios";
import { utilAxiosWithAppToken } from "../../utils/customAxios";

import * as Request from "../../commons/request";
import * as TypeDTO from "../../commons/typeDTO";

function ReviewThumbnail({ boardArticle }: { boardArticle: TypeDTO.BoardArticleDto }) {
    const [imageSrc, setImageSrc] = useState<string | null | undefined>(null);

    useLayoutEffect(() => {
        async function fetchImage(url: string, config?: AxiosRequestConfig<any> | undefined) {
            await utilAxiosWithAppToken()
                .get(url, config)
                .then((response) => {
                    if (response.config.responseType === "blob") {
                        const file = new Blob([response.data]);
                        setImageSrc(URL.createObjectURL(file));
                    } else {
                        setImageSrc(response.data.src);
                    }
                });
        }

        if (boardArticle.thumbnailFileId) {
            // fetchImage(Request.BOARD_URL + "/" + boardArticle.board.boardId + "/download/" + thumbnailFileId, { responseType: "blob" });
            setImageSrc(Request.BOARD_URL + "/" + boardArticle.board.boardId + "/download/" + boardArticle.thumbnailFileId);
            return;
        }

        if (boardArticle.url2) {
            setImageSrc(boardArticle.url2);
            return;
        }

        fetchImage(Request.BOARD_URL + "/" + boardArticle.board.boardId + "/" + boardArticle.boardArticleId + "/image");
    }, [boardArticle]);

    return <img src={imageSrc?.toString()} className="thumbnail" loading={boardArticle.fixed ? "eager" : "lazy"} />;
}

export default ReviewThumbnail;
