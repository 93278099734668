import * as React from "react";
import { Divider, Modal, Space, Image } from "antd";
import { BrowserView, MobileView } from "react-device-detect";

import * as String from "../../commons/string";
import * as TypeDTO from "../../commons/typeDTO";

import ImgStep1 from "../../assets/images/home/rent/img_info_step1.png";
import ImgStep2 from "../../assets/images/home/rent/img_info_step2.png";
import ImgStep3 from "../../assets/images/home/rent/img_info_step3.png";
import ImgStep4 from "../../assets/images/home/rent/img_info_step4.png";
import ImgStep5 from "../../assets/images/home/rent/img_info_step5.png";

import ImgStepPC1 from "../../assets/images/home/rent/img_info_pc_step1.png";
import ImgStepPC2 from "../../assets/images/home/rent/img_info_pc_step2.png";
import ImgStepPC3 from "../../assets/images/home/rent/img_info_pc_step3.png";
import ImgStepPC4 from "../../assets/images/home/rent/img_info_pc_step4.png";
import ImgStepPC5 from "../../assets/images/home/rent/img_info_pc_step5.png";
import IconArrow from "../../assets/images/home/rent/icon_arrow_next.png";

import BgTableHeader from "../../assets/images/home/rent/bg_table_header.png";
import RadiusMap from "../../assets/images/home/rent/radius_map.jpg";
import UsageCard from "../../component/rent/UsageCard";
import RentPlaceMap from "./RentPlaceMap";

type showType = {
    show?: boolean;
    showTitle?: boolean;
    showDivider?: boolean;
    className?: string;
};

const Info = ({
    showInfo,
    showCondition,
    showMethod,
    showInsurance,
    showMap,
    rentRegion,
    legal,
}: {
    showInfo?: showType;
    showCondition?: showType;
    showInsurance?: showType;
    showMethod?: showType;
    showMap?: showType;
    rentRegion?: TypeDTO.RentRegionDto;
    legal?: boolean;
}) => {
    const usageInfos = [
        {
            color: "#88A4DB",
            image: ImgStep1,
            pcImage: ImgStepPC1,
            title: "상품 예약",
            description: "예약 화면에서 상품 예약 및 결제",
        },
        {
            color: "#587BBF",
            image: ImgStep2,
            pcImage: ImgStepPC2,
            title: "차고지 방문",
            description: "이용 당일 차고지 방문 후 본인 확인 절차 진행(운전면허증 필수 지참)",
        },
        {
            color: "#284888",
            image: ImgStep3,
            title: "동의서 작성",
            pcImage: ImgStepPC3,
            description: "차량 대여 동의서 작성",
        },
        {
            color: "#082153",
            image: ImgStep4,
            title: "차량 출차",
            pcImage: ImgStepPC4,
            description: "차량 상태 확인 및 안전교육 후 캠핑카 출차",
        },
        {
            color: "#001236",
            image: ImgStep5,
            title: "차량 반납",
            pcImage: ImgStepPC5,
            description: "반납 시간에 맞춰 캠핑카 상태 확인 및 반납",
        },
    ];

    const infos = [
        "차량 실내에서는 흡연 불가입니다. 규정 위반 시 위약금이 발생됩니다.",
        "차량 내 냄새가 강한 음식 조리 행위는 금지합니다.(육류, 생선구이, 청국장 등) 규정 위반 시 위약금이 발생됩니다.",
        "사용하신 집기류는 깨끗하게 세척하신 뒤 반납함에 넣어주세요.",
        "차량 내 쓰레기와 오물은 청소 후 반납해 주세요. 청소 비용이 발생 될 수 있습니다.",
        "각종 차량법규위반에 따른 범칙금 및 과태료는 고객님께 부과됩니다.",
        "렌트 잔여 시간 및 잔여 기간에 대하여 환불이 되지 않습니다.",
    ];

    const conditions = ["대여 연령 : 만 26세 이상", "운전면허 : 1종 보통 이상", "운전경력 : 2년 이상", "예약자 동행, 운전자 면허증 지참 필수"];

    const guaranteeInfos = [
        {
            th: "대인배상 I",
            td: "자배법에서 정한 금액",
        },
        {
            th: "대인배상 II",
            td: "무한",
        },
        {
            th: "대물배상",
            td: "1억",
        },
        {
            th: "자기신체사고",
            td: "사망 (인당) 3000만원\n부상 (인당) 1500만원\n장애 (인당) 3000만원",
        },
        {
            th: "차량손해",
            td: "9,713만원\n자기부담금:손해액외20%\n(최저 50만원, 최고 50만원)",
        },
    ];

    const noInsurance = ["만 26세 미만 보험 적용 불가", "명시되지 않은 부분에 대한 내용은 보험 약관에 따라 처리"];

    const warning = () => {
        Modal.warning({
            centered: true,
            title: "초과 반경 위약금",
            content: (
                <Space direction="vertical" size={10}>
                    <Image width="100%" src={RadiusMap} />
                    <p style={{ marginBottom: 0 }}>
                        차고지 기준 반경 50km 이내로 이용하실 수 있으며, 초과 시 초과 범위에 따라 위약금이 발생됩니다. (반경 50km 지역 이탈시 위약금
                        20만원, 이후 10km 당 10만원씩 추가 부과)
                    </p>
                </Space>
            ),
            okText: String.confirm,
            onOk() {},
        });
    };

    return (
        <>
            <MobileView className="mobile">
                {showInfo?.show && (
                    <div className={showInfo.className}>
                        {showInfo.showTitle && <h5 className="mb-3">{String.useInstruction}</h5>}
                        <ul style={{ paddingLeft: legal ? 0 : 20 }} className="mb-0 listSpace">
                            {infos.map((info) => (
                                <li key={info}>{info}</li>
                            ))}
                        </ul>
                        {showInfo.showDivider && <Divider style={{ margin: "30px 0" }} />}
                    </div>
                )}
                {showCondition?.show && (
                    <div className={showCondition.className}>
                        {showCondition.showTitle && <h5 className="text-center mb-3">대여 조건</h5>}
                        <ul style={{ paddingLeft: legal ? 0 : 20 }} className="mb-0 listSpace">
                            {conditions.map((condition) => (
                                <li className="mb-1" key={condition}>
                                    {condition}
                                </li>
                            ))}
                            <li className="text-danger" style={{ listStyle: "none" }}>
                                ※조건에 적합하지 않을 경우 대여 불가
                            </li>
                        </ul>
                        {showCondition.showDivider && <Divider style={{ margin: "30px 0" }} />}
                    </div>
                )}
                {showMap?.show && rentRegion && (
                    <div className={showMap.className}>
                        {showMap.showTitle && <h5 className="text-center">대여 장소</h5>}
                        <RentPlaceMap rentRegion={rentRegion} />
                        <ul style={{ paddingLeft: legal ? 0 : 20 }} className="mb-0 mt-3 listSpace">
                            <li>출차 : 오후 2시(안전교육 최소 30분 포함)</li>
                            <li>반납 : 오전 9시 ~ 오전 11시</li>
                            <li>차량 반납 시 배터리는 출차 상태로 충전 후 반납</li>
                            <li>
                                차고지 기준 반경 50km 이내로 이용하실 수 있으며, 초과 시 초과 범위에 따라 위약금이 발생됩니다.
                                <span style={{ color: "rgb(0, 95, 219)", fontWeight: "bold", marginLeft: 8, cursor: "pointer" }} onClick={warning}>
                                    자세히 보기
                                </span>
                            </li>
                        </ul>
                        <div className="custom-bolder-box mb-4 mt-3">
                            <p className="text-danger text-bold">※ {String.caution}</p>
                            <ul className="listSpace mb-0 mt-2">
                                <li>
                                    출차와 반납시간은 반드시 지켜주세요.
                                    <br />
                                    (출차와 반납은 시간 외 불가, 반납시간 지연 시 위약금 발생)
                                </li>
                                <li>배터리 충전량이 출차 상태보다 부족할 경우 충전요금이 부과됩니다. (1%당 600원 부과)</li>
                            </ul>
                        </div>
                        {showMap.showDivider && <Divider style={{ margin: "30px 0" }} />}
                    </div>
                )}
                {showMethod?.show && (
                    <div className={showMethod.className}>
                        {showMethod.showTitle && <h5 className="text-center mb-3">이용 방법</h5>}
                        <UsageCard values={usageInfos} />
                        {showMethod.showDivider && <Divider style={{ margin: "30px 0" }} />}
                    </div>
                )}
                {showInsurance?.show && (
                    <div className={showInsurance.className}>
                        {showInsurance.showTitle && <h5 className="text-center mb-3">보험 안내</h5>}
                        <div className="mb-4">
                            <ul style={{ paddingLeft: legal ? 0 : 20 }} className="mb-0 listSpace">
                                <li className="mb-2">
                                    자차보험은 필수 가입 항목으로 현장에서 1박당 1만원으로 정산합니다. (본인 외 운전자 1인 추가 가능)
                                </li>
                                <li>
                                    자차 보험은 아래와 같습니다.
                                    <br />
                                    1) 자기 부담금 손해액외20%(최저 50만원, 최고 50만원)
                                </li>
                            </ul>
                        </div>
                        <table className="table design-table">
                            <thead style={{ backgroundImage: `url(${BgTableHeader})`, backgroundSize: "cover", border: "none" }}>
                                <tr>
                                    <th colSpan={2} className="text-center">
                                        담보 및 가입금액
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {guaranteeInfos.map((info) => (
                                    <tr key={info.th}>
                                        <th style={{ textAlign: "left" }}>{info.th}</th>
                                        <td style={{ textAlign: "right" }}>{info.td}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <div className="custom-bolder-box">
                            <p className="pb-2 text-danger text-bold">※ 보험 처리가 되지 않는 경우</p>
                            <ul className="listSpace mb-0">
                                {noInsurance.map((insurance) => (
                                    <li key={insurance}>{insurance}</li>
                                ))}
                            </ul>
                        </div>
                        {showInsurance.showDivider && <Divider style={{ margin: "30px 0" }} />}
                    </div>
                )}
            </MobileView>
            <BrowserView className="browser">
                {showInfo?.show && (
                    <div className={showInfo.className}>
                        {showInfo.showTitle && <h5 className="mb-4">{String.useInstruction}</h5>}
                        <ul className="listSpace">
                            {infos.map((info) => (
                                <li key={info}>{info}</li>
                            ))}
                        </ul>
                        {showInfo.showDivider && <Divider />}
                    </div>
                )}
                {showCondition?.show && (
                    <div className={showCondition.className}>
                        {showCondition.showTitle && <h5 className="text-center mb-4">대여 조건</h5>}
                        <ul style={{ paddingLeft: legal ? 0 : 20 }} className="mb-0 listSpace">
                            {conditions.map((condition) => (
                                <li key={condition}>{condition}</li>
                            ))}
                            <li className="text-danger" style={{ listStyle: "none", marginLeft: -20 }}>
                                ※조건에 적합하지 않을 경우 대여 불가
                            </li>
                        </ul>
                        {showCondition.showDivider && <Divider />}
                    </div>
                )}
                {showMap?.show && rentRegion && (
                    <div className={showMap.className}>
                        {showMap.showTitle && <h5 className="text-center mb-4">대여 장소</h5>}
                        <RentPlaceMap rentRegion={rentRegion} />
                        <ul className="listSpace mt-3">
                            <li>출차 : 오후 2시(안전교육 최소 30분 포함)</li>
                            <li>반납 : 오전 9시 ~ 오전 11시</li>
                            <li>차량 반납 시 배터리는 출차 상태로 충전 후 반납</li>
                            <li>
                                차고지 기준 반경 50km 이내로 이용하실 수 있으며, 초과 시 초과 범위에 따라 위약금이 발생됩니다.
                                <span style={{ color: "rgb(0, 95, 219)", fontWeight: "bold", marginLeft: 8, cursor: "pointer" }} onClick={warning}>
                                    자세히 보기
                                </span>
                            </li>
                        </ul>
                        <div className="custom-bolder-box mb-2 mt-3">
                            <p className="text-danger text-bold">※ 유의사항</p>
                            <ul className="listSpace mb-0">
                                <li>
                                    출차와 반납시간은 반드시 지켜주세요.
                                    <br />
                                    (출차와 반납은 시간 외 불가, 반납시간 지연 시 위약금 발생)
                                </li>
                                <li>배터리 충전량이 출차 상태보다 부족할 경우 충전요금이 부과됩니다. (1%당 600원 부과)</li>
                            </ul>
                        </div>
                    </div>
                )}
                {showMethod?.show && (
                    <div className={showMethod.className}>
                        {showMethod.showTitle && <h5 className="text-center mb-4">이용 방법</h5>}
                        <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }}>
                            {usageInfos.map((info, index) => (
                                <div style={{ display: "flex", justifyContent: "space-around", width: "20%", textAlign: "center" }} key={info.title}>
                                    <div className="text-center">
                                        <img className="mb-1" src={info.pcImage} width={110} />
                                        <p>{info.description}</p>
                                    </div>
                                    {usageInfos.length !== index + 1 && (
                                        <img className="text-center" style={{ marginTop: "50px" }} src={IconArrow} height={26} />
                                    )}
                                </div>
                            ))}
                        </div>
                        {showMethod.showDivider && <Divider />}
                    </div>
                )}
                {showInsurance?.show && (
                    <div className={showInsurance.className}>
                        <>
                            {showInsurance.showTitle && <h5 className="text-center mb-4">보험 안내</h5>}
                            <ul>
                                <li className="mb-2">
                                    자차보험은 필수 가입 항목으로 현장에서 1박당 1만원으로 정산합니다. (본인 외 운전자 1인 추가 가능)
                                </li>
                                <li>
                                    자차 보험은 아래와 같습니다.
                                    <br />
                                    1) 자기 부담금 손해액외20%(최저 50만원, 최고 50만원)
                                </li>
                            </ul>
                        </>
                        <table className="table design-table mt-3">
                            <thead style={{ backgroundImage: `url(${BgTableHeader})`, backgroundSize: "cover", border: "none" }}>
                                <tr>
                                    <th colSpan={2} className="text-center">
                                        담보 및 가입금액
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {guaranteeInfos.map((info) => (
                                    <tr key={info.th}>
                                        <th style={{ textAlign: "left" }}>{info.th}</th>
                                        <td style={{ textAlign: "right" }}>{info.td}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <div className="custom-bolder-box">
                            <p className="text-danger text-bold">※ 보험 처리가 되지 않는 경우</p>
                            <ul className="mb-0">
                                {noInsurance.map((insurance) => (
                                    <li key={insurance}>{insurance}</li>
                                ))}
                            </ul>
                        </div>
                        {showInsurance.showDivider && <Divider />}
                    </div>
                )}
            </BrowserView>
        </>
    );
};

export default Info;
