import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAsyncAxios, utilAxiosWithAppToken } from "../../utils/customAxios";
import { Button, Col, Row, Space, Tag } from "antd";
import { BrowserView, MobileView } from "react-device-detect";
import { DownloadOutlined } from "@ant-design/icons";
import { useMediaQuery } from "react-responsive";
import { useAlert } from "../../provider/AlertProvider";

import * as Request from "../../commons/request";
import * as TypeDTO from "../../commons/typeDTO";
import * as Common from "../../commons/common";
import Loading from "../common/Loading";

function ReviewDetail({ boardId }: { boardId: number }) {
    const isMobile = useMediaQuery({ maxWidth: 300 });
    const navigate = useNavigate();
    const alert = useAlert();
    const params = useParams();
    const [boardArticle, setBoardArticle] = useState<TypeDTO.BoardArticleDto>();
    const [attachFiles, setAttachFiles] = useState<TypeDTO.BoardFileDto[]>();

    useEffect(() => {
        requestGetBoardArticle(Number(params.boardArticleId));
    }, [params]);

    const requestAxiosGetBoardArticle = async (boardArticleId: number) => {
        const response = await utilAxiosWithAppToken().get(Request.BOARD_URL + "/" + boardId + "/" + boardArticleId);
        return response.data;
    };

    const {
        loading: loadingGetBoardArticle,
        error: errorGetBoardArticle,
        data: resultGetBoardArticle,
        execute: requestGetBoardArticle,
    } = useAsyncAxios(requestAxiosGetBoardArticle);

    useEffect(() => {
        if (resultGetBoardArticle === null) return;
        console.log("resultGetBoardArticle", resultGetBoardArticle);

        setBoardArticle(resultGetBoardArticle.boardArticle);
        setAttachFiles(resultGetBoardArticle.boardArticle?.files.filter((file: TypeDTO.BoardFileDto) => file.boardFileType === "ATTACH"));
    }, [resultGetBoardArticle]);

    useEffect(() => {
        if (errorGetBoardArticle === null) return;
        console.log("errorGetBoardArticle", errorGetBoardArticle);
    }, [errorGetBoardArticle]);

    const downloadBoardFile = async (boardFileId: number, boardFileName: string) => {
        await utilAxiosWithAppToken()
            .get(Request.RENT_NOTICE_DOWNLOAD_URL + boardFileId, { responseType: "blob" })
            .then((response) => {
                const file = new Blob([response.data]);
                const element = document.createElement("a");
                element.href = URL.createObjectURL(file);
                element.download = boardFileName;
                element.click();
            })
            .catch((error) => {
                console.log("downloadBoardFile error", error);
                alert.setAlert("", "파일 다운로드 실패", "파일 다운로드에 실패했습니다.");
            });
    };

    const displayBoardFile = (files: Array<TypeDTO.BoardFileDto> | undefined) => {
        return (
            <td style={{ textAlign: "left" }}>
                <Space direction="vertical">
                    {files?.map((file) => {
                        return (
                            <Space
                                size={4}
                                key={file.boardFileId}
                                align="center"
                                onClick={() => downloadBoardFile(file.boardFileId, file.boardFileName)}
                                style={{ cursor: "pointer" }}
                            >
                                <DownloadOutlined />
                                <p
                                    style={{
                                        width: isMobile ? "calc(100vw - 74px)" : "auto",
                                        overflow: "hidden",
                                        whiteSpace: "nowrap",
                                        textOverflow: isMobile ? "ellipsis" : "initial",
                                        textDecoration: "underLine",
                                    }}
                                >
                                    {file.boardFileName}
                                </p>
                            </Space>
                        );
                    })}
                </Space>
            </td>
        );
    };

    return (
        <>
            <MobileView className="mobile" style={{ minHeight: "calc(100vh - 70px)" }}>
                {loadingGetBoardArticle === true ? (
                    <Loading />
                ) : (
                    <Col
                        sm={{ span: 24 }}
                        lg={{ span: 18, offset: 3 }}
                        xl={{ span: 18, offset: 3 }}
                        xxl={{ span: 16, offset: 4 }}
                        className="space-page mb-4 pt-4 fadeIn"
                    >
                        <div className="header-container mb-4">
                            <div className="container pt-4">
                                <div className="row justify-content-center">
                                    <div className="p-0">
                                        <h2 className="fs-title d-flex board" style={{ alignItems: "baseline", gap: 5 }}>
                                            {boardArticle && <Title boardArticle={boardArticle} />}
                                        </h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <table className="table design-table">
                            <tbody>
                                <tr style={{ borderTop: "0.5px solid #dfe2e7", borderBottom: "0.5px solid #dfe2e7" }}>
                                    <td style={{ padding: 0 }}>
                                        <Row className="w-100" style={{ minHeight: 48.8 }} justify="space-between" align="middle">
                                            <Col>{boardArticle?.extraInfo1}</Col>
                                            <Col>등록일: {boardArticle?.registeredDate.slice(0, 10)}</Col>
                                        </Row>
                                    </td>
                                </tr>
                                <tr>
                                    {boardArticle?.contents.split("\n").map((line, index) => (
                                        <td
                                            className="pt-3 pb-3 ps-0 pe-0"
                                            key={index}
                                            style={{ textAlign: "left" }}
                                            dangerouslySetInnerHTML={{
                                                __html: line.replace(". -", `.\n -`),
                                            }}
                                        />
                                    ))}
                                </tr>
                                {attachFiles?.length !== 0 && (
                                    <tr
                                        style={{
                                            borderTop: "0.5px solid #dfe2e7",
                                            borderBottom: "0.5px solid #dfe2e7",
                                        }}
                                    >
                                        {displayBoardFile(attachFiles)}
                                    </tr>
                                )}
                            </tbody>
                        </table>
                        <div className="text-center mt-4">
                            <Button
                                className="btn-dark px-5"
                                style={{ width: "auto" }}
                                size="large"
                                type="primary"
                                onClick={() => {
                                    navigate(Common.PAGE_RENT_BEST_REVIEW);
                                }}
                            >
                                목록 보기
                            </Button>
                        </div>
                    </Col>
                )}
            </MobileView>
            <BrowserView className="browser">
                {loadingGetBoardArticle === true ? (
                    <Loading />
                ) : (
                    <Col
                        xs={{ span: 24 }}
                        sm={{ span: 22, offset: 1 }}
                        lg={{ span: 18, offset: 3 }}
                        xl={{ span: 16, offset: 4 }}
                        xxl={{ span: 14, offset: 5 }}
                        className="mb-4 p-4 order fadeIn"
                    >
                        <div className="header-container mb-4">
                            <div className="container">
                                <div className="row justify-content-center">
                                    <div className="p-0">
                                        <h2 className="fs-title d-flex board" style={{ alignItems: "baseline", gap: 5 }}>
                                            {boardArticle && <Title boardArticle={boardArticle} />}
                                        </h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <table className="table design-table">
                            <tbody>
                                <tr style={{ borderTop: "0.5px solid #dfe2e7", borderBottom: "0.5px solid #dfe2e7" }}>
                                    <td style={{ padding: 0 }}>
                                        <Row className="w-100" style={{ minHeight: 48.8 }} justify="space-between" align="middle">
                                            <Col>{boardArticle?.extraInfo1}</Col>
                                            <Col>등록일: {boardArticle?.registeredDate.slice(0, 10)}</Col>
                                        </Row>
                                    </td>
                                </tr>
                                <tr style={{ borderBottom: "0.5px solid #dfe2e7" }}>
                                    {boardArticle?.contents.split("\n").map((line, index) => (
                                        <td
                                            className="pt-3 pb-3 ps-0 pe-0 review-content"
                                            key={index}
                                            style={{ fontSize: 16, textAlign: "left" }}
                                            dangerouslySetInnerHTML={{
                                                __html: line.replace(". -", `.\n -`),
                                            }}
                                        />
                                    ))}
                                </tr>
                                {attachFiles?.length !== 0 && (
                                    <tr style={{ borderBottom: "0.5px solid #dfe2e7" }}>{displayBoardFile(attachFiles)}</tr>
                                )}
                            </tbody>
                        </table>
                        <div className="text-center mt-5">
                            <Button
                                className="btn-dark px-5"
                                style={{ width: "auto" }}
                                size="large"
                                type="primary"
                                onClick={() => {
                                    navigate(Common.PAGE_RENT_BEST_REVIEW);
                                }}
                            >
                                목록 보기
                            </Button>
                        </div>
                    </Col>
                )}
            </BrowserView>
        </>
    );
}

function Title({ boardArticle }: { boardArticle: TypeDTO.BoardArticleDto }) {
    return (
        <>
            <span>📄</span>
            <span>
                {boardArticle.extraInfo2 === "리뷰게시판" ? "리뷰" : boardArticle.extraInfo2 || "리뷰"}_
                {boardArticle.extraInfo3?.replace(/(?<=.{1})./gi, "*")}님
            </span>
        </>
    );
}

export default ReviewDetail;
