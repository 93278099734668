import * as React from "react";

import { Col, Row, Space } from "antd";
import { BrowserView, MobileView } from "react-device-detect";

type ItemType = {
    image: string;
    categoryName: string;
    itemNames: string[];
    notes?: string[];
    price?: string;
};

const ItemCard = ({ items }: { items: Array<ItemType> }) => {
    return (
        <>
            <MobileView className="mobile">
                <Space direction="vertical" style={{ width: "100%" }} size={16}>
                    {items.map((item) => {
                        return (
                            <div key={item.categoryName} style={{ border: "1px solid #DFE2E7", borderRadius: "4px 4px 0 0" }}>
                                <img style={{ borderBottom: "1px solid #DFE2E7" }} className="w-100" src={item.image} />
                                <ul className="mt-2 mb-2">
                                    <li className="text-bold">
                                        <Row justify="space-between" align="middle" style={{ paddingRight: 10 }}>
                                            <Col>{item.categoryName}</Col>
                                            {item.price && <Col className="text-price">{item.price}</Col>}
                                        </Row>
                                    </li>
                                    <li style={{ listStyleType: "none" }}>
                                        {item.itemNames.map((name, index) => (
                                            <p key={name} className="mt-1">
                                                - {name}
                                            </p>
                                        ))}
                                        {item.notes?.map((note) => (
                                            <p key={note} className="text-note mt-1">
                                                ※ {note}
                                            </p>
                                        ))}
                                    </li>
                                </ul>
                            </div>
                        );
                    })}
                </Space>
            </MobileView>
            <BrowserView className="browser">
                <Row gutter={20}>
                    {items.map((item) => {
                        return (
                            <Col key={item.categoryName} span={12} className="mb-3" style={{ minHeight: "200px" }}>
                                <div className="p-0 h-100" style={{ border: "1px solid #DFE2E7", borderRadius: "4px 4px 0 0" }}>
                                    <img style={{ borderBottom: "1px solid #DFE2E7" }} className="w-100" src={item.image} />
                                    <ul className="mt-2 mb-2">
                                        <li className="text-bold">
                                            <Row justify="space-between" align="middle" style={{ paddingRight: 10 }}>
                                                <Col>{item.categoryName}</Col>
                                                {item.price && <Col className="text-price">{item.price}</Col>}
                                            </Row>
                                        </li>
                                        <li style={{ listStyleType: "none" }}>
                                            {item.itemNames.map((name, index) => (
                                                <p key={name} className="mt-1">
                                                    - {name}
                                                </p>
                                            ))}
                                            {item.notes?.map((note) => (
                                                <p key={note} className="text-note mt-1">
                                                    ※ {note}
                                                </p>
                                            ))}
                                        </li>
                                    </ul>
                                </div>
                            </Col>
                        );
                    })}
                </Row>
            </BrowserView>
        </>
    );
};

export default ItemCard;
