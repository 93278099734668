import Autoplay from "embla-carousel-autoplay";
import useEmblaCarousel from "embla-carousel-react";
import { DotButton, useDotButton } from "./MainCarouselDotButton";
import { PrevButton, NextButton, usePrevNextButtons } from "./MainCarouselArrowButtons";

import styles from "./MainCarousel.module.css";

const MainCarousel = ({
    children,
    autoPlay,
    className,
    showBullets,
}: {
    children: React.ReactNode;
    autoPlay?: boolean;
    className?: string;
    showBullets?: boolean;
}) => {
    const [emblaRef, emblaApi] = useEmblaCarousel({ slidesToScroll: "auto", duration: 50 }, [Autoplay({ playOnInit: autoPlay, delay: 5000 })]);

    const { selectedIndex, scrollSnaps, onDotButtonClick } = useDotButton(emblaApi);

    const { prevBtnDisabled, nextBtnDisabled, onPrevButtonClick, onNextButtonClick } = usePrevNextButtons(emblaApi);

    return (
        <div className={`${styles.embla} ${className ?? ""}`}>
            <div className={styles.embla__viewport} ref={emblaRef}>
                <div className={styles.embla__container}>{children}</div>
            </div>

            <div className={styles.embla__controls}>
                <div className={styles.embla__buttons}>
                    <PrevButton onClick={onPrevButtonClick} disabled={prevBtnDisabled} />
                    <NextButton onClick={onNextButtonClick} disabled={nextBtnDisabled} />
                </div>
            </div>
            {showBullets !== false && (
                <div className={styles.embla__dots}>
                    {scrollSnaps.map((_, index) => (
                        <DotButton
                            key={index}
                            onClick={() => onDotButtonClick(index)}
                            className={`${styles.embla__dot} ${index === selectedIndex ? styles["embla__dot--selected"] : ""}`}
                        >
                            {index === selectedIndex ? (
                                <svg xmlns="http://www.w3.org/2000/svg" width="13" height="12" viewBox="0 0 13 12" fill="none">
                                    <circle cx="6.30664" cy="6" r="6" fill="black" />
                                </svg>
                            ) : (
                                <svg xmlns="http://www.w3.org/2000/svg" width="13" height="12" viewBox="0 0 13 12" fill="none">
                                    <circle cx="6.24707" cy="6" r="5.5" stroke="black" />
                                </svg>
                            )}
                        </DotButton>
                    ))}
                </div>
            )}
        </div>
    );
};

export default MainCarousel;
