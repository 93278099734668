import { Link } from "react-router-dom";

import ReviewThumbnail from "./ReviewThumbnail";

import * as TypeDTO from "../../commons/typeDTO";
import * as Common from "../../commons/common";

import ReviewBadge from "./ReviewBadge";

function ReviewSimpleCard({ boardArticle, className }: { boardArticle: TypeDTO.BoardArticleDto; className?: string }) {
    return (
        <div className={`review-item ${className ?? ""}`}>
            {boardArticle.url1 ? (
                <a href={boardArticle.url1} target="_blank" rel="noreferrer">
                    <CardContent boardArticle={boardArticle} />
                </a>
            ) : (
                <Link
                    to={
                        (boardArticle.board.boardId === 1 ? Common.PAGE_RENT_BEST_REVIEW : Common.PAGE_RENT_USER_REVIEW) +
                        "/" +
                        boardArticle.boardArticleId
                    }
                >
                    <CardContent boardArticle={boardArticle} />
                </Link>
            )}
        </div>
    );
}

function CardContent({ boardArticle }: { boardArticle: TypeDTO.BoardArticleDto }) {
    return (
        <>
            <ReviewThumbnail boardArticle={boardArticle} />
            <div className="review-type">
                <ReviewBadge boardArticle={boardArticle} />
            </div>
            <div className="review-title">{boardArticle.title}</div>
        </>
    );
}

export default ReviewSimpleCard;
