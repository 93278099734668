import React, { useCallback, useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useAuthDispatch } from "../../provider/AuthProvider";
import { useAsyncAxios, utilAxiosWithAppToken, utilAxiosWithAuth } from "../../utils/customAxios";
import { CookiesProvider, useCookies } from "react-cookie";
import { UserOutlined } from "@ant-design/icons";
import { useAlert } from "../../provider/AlertProvider";
import { Button, Checkbox, Form, Input, Card, Divider, Modal, Row, Space } from "antd";
import type { CheckboxChangeEvent } from "antd/es/checkbox";

import * as Request from "../../commons/request";
import * as String from "../../commons/string";
import * as Common from "../../commons/common";
import * as TypeDTO from "../../commons/typeDTO";
import * as Utils from "../../utils/utils";

import ModalSearchEmail from "../../component/ModalSearchEmail";
import ModalResetPassword from "../../component/ModalResetPassword";
import InputPassword from "../../component/InputPassword";
import axios from "axios";

function Login() {
    const dispatch = useAuthDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const alert = useAlert();

    const [user, setUser] = useState<any>("");
    const [pwd, setPwd] = useState<string>("");
    const [checkedSaveId, setCheckedSaveId] = useState<boolean>(false);
    const [cookies, setCookie, removeCookie] = useCookies(["savedId"]);
    const [isCapsLock, setIsCapsLock] = useState(false);
    const [openSearchEmailPopup, setOpenSearchEmailPopup] = useState(false);
    const [openResetPasswordPopup, setOpenResetPasswordPopup] = useState(false);

    const [pathAfterUrl, setPathAfterUrl] = useState<string>();

    const [form] = Form.useForm();

    const onChangeSaveId = (e: CheckboxChangeEvent) => {
        setCheckedSaveId(e.target.checked);
    };

    useEffect(() => {
        // console.log(location.state);
        if (location.state !== undefined) {
            setPathAfterUrl(location.state);
        }
    }, [location]);

    useEffect(() => {
        // const savedId = Utils.getLocalStorage(Common.CONTEXT_SAVEID) ? Utils.getLocalStorage(Common.CONTEXT_SAVEID) : null;
        // console.log("savedId", savedId);
        // if (savedId !== null) {
        //     setCheckedSaveId(true);
        //     setUser(savedId);
        // }
        if (cookies.savedId) {
            setCheckedSaveId(true);
            setUser(cookies.savedId);
        }
    }, []);

    const requestLogin = async (url: string, option: Record<string, string>) => {
        const response = await utilAxiosWithAppToken().post(url, option);
        return response.data;
    };

    const { loading, error: errorLogin, data: resultLogin, execute: login } = useAsyncAxios(requestLogin);

    useEffect(() => {
        if (resultLogin === null) return;

        if (dispatch !== null)
            dispatch({
                type: "LOGIN_SUCCESS",
                payload: {
                    user: resultLogin.user,
                    token: resultLogin.token,
                },
            });

        const expires = new Date();
        expires.setDate(expires.getDate() + 30);

        if (checkedSaveId === true) {
            setCookie("savedId", user, { expires: expires });
        } else {
            removeCookie("savedId");
        }

        if (resultLogin.result === "ok") {
            if (Utils.checkHostName().includes("rent")) {
                requestGetRentsMine(resultLogin.token);
            } else {
                if (pathAfterUrl !== undefined && pathAfterUrl !== Common.PAGE_SIGNUP && pathAfterUrl !== Common.PAGE_LOGIN) {
                    navigate(pathAfterUrl, { replace: true });
                } else {
                    navigate(Common.PAGE_HOME, { replace: true });
                }
            }
        }

        setPwd("");
    }, [resultLogin]);

    useEffect(() => {
        if (errorLogin === null) {
            return;
        }
        form.resetFields();
        if (dispatch !== null) {
            dispatch({ type: "LOGIN_ERROR", error: "Login failed" });
        }
        console.log("errorLogin", errorLogin);
        form.setFieldsValue({ username: user });
    }, [errorLogin]);

    //get my rents
    const requestAxiosGetRentsMine = async (token: string) => {
        const response = await axios
            .create({
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
                withCredentials: true,
            })
            .get(Request.RENT_MINE_URL);

        return response.data;
    };

    const {
        loading: loadingGetRentsMine,
        error: errorGetRentsMine,
        data: resultGetRentsMine,
        execute: requestGetRentsMine,
    } = useAsyncAxios(requestAxiosGetRentsMine);

    useEffect(() => {
        if (resultGetRentsMine === null) return;
        console.log("resultGetRentsMine", resultGetRentsMine);

        const currentRents: Array<TypeDTO.RentDto> = [];

        resultGetRentsMine?.rents?.map(
            (item: TypeDTO.RentDto) => Utils.checkRentType(item, [String.reservationComplete, String.inUse, String.inUse]) && currentRents.push(item)
        );

        currentRents.find((rent) => !rent.additionalDriver && !rent.user.driverLicense && openDriverLicensePopup());

        if (pathAfterUrl !== undefined && pathAfterUrl !== Common.PAGE_SIGNUP && pathAfterUrl !== Common.PAGE_LOGIN) {
            navigate(pathAfterUrl, { replace: true });
        } else {
            navigate(Common.PAGE_HOME, { replace: true });
        }
    }, [resultGetRentsMine]);

    useEffect(() => {
        if (errorGetRentsMine === null) return;
        console.log("errorGetRentsMine", errorGetRentsMine);
    }, [errorGetRentsMine]);

    const openDriverLicensePopup = () => {
        Modal.confirm({
            title: "운전면허 미등록",
            content: (
                <p className="text-center mt-3">
                    운전면허 미등록건이 있습니다. <br />
                    렌트 이용일 전까지 <strong>예약자</strong> 또는
                    <strong> 추가 운전자</strong>의 <strong>운전면허</strong>를 등록하셔야 이용 가능합니다.
                </p>
            ),
            okText: "내 운전면허 등록",
            cancelText: "추가 운전자 등록",
            onCancel() {
                navigate(Common.PAGE_RESERVATION_DETAIL);
            },
            onOk() {
                navigate(Common.PAGE_MYPAGE);
            },
            centered: true,
            closable: true,
        });
    };

    const actionLogin = async () => {
        // console.log(user, pwd);
        if (user.length === 0) {
            alert.setAlert("", "로그인 실패", String.msg_email_must);
        } else if (pwd.length === 0) {
            alert.setAlert("", "로그인 실패", String.msg_pwd_must);
        } else await login(Request.LOGIN_URL, JSON.stringify({ email: user, password: pwd }));
    };

    const validateUser = useCallback((_: any, value: string) => {
        if (!value) {
            return Promise.reject(new Error(String.msg_email_must));
        }
        return Promise.resolve();
    }, []);

    const validatePwd = useCallback((_: any, value: string) => {
        // const pwd_regExp = /^[a-zA-Z0-9#?!@$%^&*-]*$/i;
        const pwd_regExp = Common.PASSWORD_REGEXP;
        //console.log("value", value);
        //if (value === undefined) return Promise.resolve();
        if (!value) {
            return Promise.reject(new Error(String.msg_pwd_must));
        }
        if (!pwd_regExp.test(value)) {
            return Promise.reject(new Error(String.msg_pwd_rule));
        }
        return Promise.resolve();
    }, []);

    function onKeyDownHandler(e: any) {
        const checkCapsLock = e.getModifierState("CapsLock");
        setIsCapsLock(checkCapsLock);
    }

    useEffect(() => {
        if (user.includes(" ")) {
            const newUser = user.replace(/\s/g, "");
            form.setFieldsValue({
                username: newUser,
            });
            setUser(newUser);
        }
    }, [user]);

    return (
        <>
            <div className="header-container">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="fadeIn text-center">
                            <h2 className="fs-title">로그인</h2>
                        </div>
                    </div>
                </div>
            </div>
            <Row className="login pb-4" align="middle" justify="center">
                <div className="loginCard">
                    <Card bordered={false}>
                        <Form name="normal_login" form={form} initialValues={{ remember: true }} layout="vertical">
                            <CookiesProvider>
                                <Form.Item
                                    label="이메일"
                                    name="username"
                                    rules={[
                                        {
                                            type: "email",
                                            message: "이메일이 유효하지 않습니다.",
                                        },
                                        { validator: validateUser },
                                        {
                                            max: 50,
                                            message: String.msg_name_max,
                                        },
                                    ]}
                                    initialValue={cookies.savedId ? cookies.savedId : ""}
                                >
                                    <Input
                                        className="login-input"
                                        prefix={<UserOutlined className="site-form-item-icon" />}
                                        placeholder="이메일 주소"
                                        maxLength={100}
                                        value={user}
                                        onInput={(e) => ((e.target as HTMLInputElement).value = (e.target as HTMLInputElement).value.toLowerCase())}
                                        onChange={(e) => setUser(e.target.value)}
                                    />
                                </Form.Item>
                            </CookiesProvider>
                            <Form.Item
                                label="비밀번호"
                                name="password"
                                rules={[{ validator: validatePwd }, { max: 20, message: String.msg_pwd_max }]}
                            >
                                <InputPassword onChange={(value) => setPwd(value)} />
                            </Form.Item>
                            <Form.Item>
                                <Checkbox
                                    onChange={(e) => {
                                        onChangeSaveId(e);
                                    }}
                                    defaultChecked={cookies.savedId ? true : false}
                                >
                                    {String.save_email}
                                </Checkbox>
                            </Form.Item>
                            <Form.Item>
                                <Button loading={loading} block type="primary" htmlType="submit" size="large" onClick={actionLogin}>
                                    {!loading ? String.login : ""}
                                </Button>
                            </Form.Item>
                        </Form>
                        <div className="text-center">
                            <Space size="small">
                                <a onClick={() => setOpenSearchEmailPopup(true)}>이메일 찾기</a>|
                                <a onClick={() => setOpenResetPasswordPopup(true)}>비밀번호 재설정</a>
                            </Space>
                        </div>
                        <Divider className="b-xs">또는</Divider>
                        <Button type="default" block htmlType="submit" size="large">
                            <Link to={Common.PAGE_SIGNUP} state={{ pathAfterLogin: pathAfterUrl }}>
                                {String.signUp}
                            </Link>
                        </Button>
                    </Card>
                </div>
            </Row>

            {openSearchEmailPopup && (
                <ModalSearchEmail
                    open={openSearchEmailPopup}
                    onChangedOpen={(opened) => {
                        setOpenSearchEmailPopup(opened);
                    }}
                />
            )}

            {openResetPasswordPopup && (
                <ModalResetPassword
                    open={openResetPasswordPopup}
                    onChangedOpen={(opened) => {
                        setOpenResetPasswordPopup(opened);
                    }}
                />
            )}
        </>
    );
}

export default Login;
