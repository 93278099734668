import React from "react";
import { Link, useNavigate } from "react-router-dom";
import * as Common from "../commons/common";

function SubFooter() {
    const navigate = useNavigate();
    return (
        <div className="sub-footer">
            <div className="b-sm text-center">
                <p className="b-bd mb-1">
                    <a onClick={() => navigate(Common.PAGE_TOS)} className="px-2">
                        이용약관
                    </a>
                    <a className="px-2" onClick={() => navigate(Common.PAGE_PRIVACPOLICY)}>
                        개인정보처리방침
                    </a>
                </p>
                <p className="b-li mb-1">
                    &copy; <a onClick={() => navigate(Common.PAGE_HOME)}>RUTA40</a>, All Right Reserved. Designed By{" "}
                    <a className="footer-anchor" href="http://rutas.co.kr/">
                        RUTAS.
                    </a>
                </p>
            </div>
        </div>
    );
}

export default SubFooter;
