import React, { useEffect, useState } from "react";
import { useAuthDispatch, useAuthState } from "../../provider/AuthProvider";
import { useAsyncAxios, utilAxiosWithAuth } from "../../utils/customAxios";

import * as Request from "../../commons/request";
import * as Common from "../../commons/common";
import * as TypeDTO from "../../commons/typeDTO";
import * as Utils from "../../utils/utils";
import * as String from "../../commons/string";

import { Row, Col, Card, Input, Space, Button, Modal, Image, Divider } from "antd";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { useLocation, useNavigate } from "react-router-dom";
import ModalResetPassword from "../../component/ModalResetPassword";
import DriverLicense from "../rent/DriverLicense";
import { BrowserView, MobileView } from "react-device-detect";
import CustomLoading from "../../component/rent/CustomLoading";
import Loading from "./Loading";

function MyPage() {
    const userDetails = useAuthState();
    const dispatch = useAuthDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const [userInfo, setUserInfo] = useState<TypeDTO.UserDto>();
    const [openWithdrawPopup, setOpenWithdrawPopup] = useState(false);
    const [openResetPasswordPopup, setOpenResetPasswordPopup] = useState(false);
    const { confirm } = Modal;

    useEffect(() => {
        requestMemberInfo();
    }, []);

    const requestAxiosMemberInfo = async () => {
        if (userDetails === null) return null;
        if (userDetails?.token === null) return null;

        const response = await utilAxiosWithAuth().get(Request.MEMBER_INFO_URL + "/" + userDetails.user?.email);

        return response.data;
    };

    const {
        loading: loadingMembereInfo,
        error: errorMemberInfo,
        data: resultMemberInfo,
        execute: requestMemberInfo,
    } = useAsyncAxios(requestAxiosMemberInfo);

    useEffect(() => {
        if (resultMemberInfo === null) return;

        console.log("resultMemberInfo", resultMemberInfo);
        setUserInfo(resultMemberInfo.user);

        if (location.state?.updated && dispatch !== null) {
            dispatch({
                type: "UPDATE_USER",
                payload: resultMemberInfo.user,
            });
        }
    }, [resultMemberInfo]);

    useEffect(() => {
        if (errorMemberInfo === null) return;

        console.log("errorMemberInfo", errorMemberInfo);

        // if (Utils.checkUnAuthorizedToken(errorMemberInfo)) {
        //     if (dispatch !== null) dispatch({ type: "LOGOUT" });
        //     // sessionStorage.setItem(Common.SESSION_AFTER_LOGIN_PATH, Common.PAGE_HOME);
        //     navigate(Common.PAGE_LOGIN, { replace: true });
        // }
    }, [errorMemberInfo]);

    const showDeleteConfirm = () => {
        confirm({
            title: "정말 탈퇴 하시겠습니까?",
            icon: <ExclamationCircleFilled />,
            content: "계정 이용 내역이 있을 경우 재가입 시\n고객센터 본인인증 절차가 필요할 수 있습니다.",
            okText: "확인",
            okType: "danger",
            cancelText: "취소",
            onOk() {
                setOpenWithdrawPopup(false);
                navigate(Common.PAGE_WITHDRAW_MEMBER);
            },
            onCancel() {
                setOpenWithdrawPopup(false);
            },
        });
    };

    return (
        <>
            <MobileView>
                {loadingMembereInfo === true ? (
                    <Loading />
                ) : (
                    <>
                        <div className="header-container">
                            <div className="container">
                                <div className="row justify-content-center">
                                    <div className="fadeIn text-center mb-3">
                                        <h2 className="fs-title">계정 정보</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Row className="login pb-4" align="middle" justify="center">
                            <div className="loginCard">
                                <Card bordered={false}>
                                    <span>계정 타입</span>
                                    <p className="login-input mt-2">{userInfo?.userType === "INDIVIDUAL" ? "개인 회원" : "법인 회원"}</p>
                                    <span>이름</span>
                                    <p className="login-input mt-2">{userInfo?.name}</p>
                                    {userInfo?.userType !== "INDIVIDUAL" ? (
                                        <>
                                            <span>회사명</span>
                                            <p className="login-input mt-2">{userInfo?.companyName}</p>
                                            <span>사업자 등록번호</span>
                                            <p className="login-input mt-2">{userInfo?.companyRegistrationNo}</p>
                                        </>
                                    ) : null}
                                    <span>휴대전화 번호</span>
                                    <p className="login-input mt-2">{userInfo?.phone}</p>
                                    <span>이메일</span>
                                    <p className="login-input mt-2">{userInfo?.email}</p>
                                    <span>주소</span>
                                    <p
                                        className="p-2 mt-2 mb-0"
                                        style={{ background: "#ffffff", border: " 1px solid #d9d9d9", borderRadius: "4px", minHeight: "42px" }}
                                    >
                                        {userInfo?.address}
                                    </p>
                                    {userInfo?.addressDetails && <p className="login-input mt-2">{userInfo?.addressDetails}</p>}
                                    <Divider
                                        style={{
                                            position: "relative",
                                            border: "5px solid #e8ebef",
                                            margin: "1.5rem 0",
                                            width: "100vw",
                                            left: "-1.5rem",
                                        }}
                                    />
                                    <h6>추가 정보</h6>
                                    <DriverLicense mode="view" driverLicenseId={userInfo?.driverLicense?.driverLicenseId} />
                                    <span>단체 회원 코드</span>
                                    <p className="login-input mt-2">{userInfo?.b2b?.promotionCode}</p>
                                    <Row className="mt-3">
                                        <Col span={12}>
                                            <Space>
                                                <Button
                                                    onClick={() =>
                                                        navigate(Common.PAGE_UPDATE_MEMBER, {
                                                            state: userInfo,
                                                        })
                                                    }
                                                >
                                                    수정
                                                </Button>
                                                <Button onClick={() => setOpenResetPasswordPopup(true)}>비밀번호 재설정</Button>
                                                {openResetPasswordPopup && (
                                                    <ModalResetPassword
                                                        open={openResetPasswordPopup}
                                                        onChangedOpen={(opened) => {
                                                            setOpenResetPasswordPopup(opened);
                                                        }}
                                                    />
                                                )}
                                            </Space>
                                        </Col>
                                        <Col span={12} className="text-end">
                                            <Button danger onClick={showDeleteConfirm}>
                                                탈퇴
                                            </Button>
                                        </Col>
                                    </Row>
                                </Card>
                            </div>
                        </Row>
                    </>
                )}
            </MobileView>
            <BrowserView>
                <>
                    <div className="header-container">
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="fadeIn text-center mb-3">
                                    <h2 className="fs-title">계정 정보</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                    {loadingMembereInfo === true ? (
                        <Loading />
                    ) : (
                        <Row className="login pb-4" align="middle" justify="center">
                            <div className="loginCard">
                                <Card bordered={false}>
                                    <span>계정 타입</span>
                                    <p className="login-input mt-2">{userInfo?.userType === "INDIVIDUAL" ? "개인 회원" : "법인 회원"}</p>
                                    <span>이름{userInfo?.userType !== "INDIVIDUAL" && <>(대표자)</>}</span>
                                    <p className="login-input mt-2">{userInfo?.name}</p>
                                    <span>휴대전화 번호</span>
                                    <p className="login-input mt-2">{userInfo?.phone}</p>
                                    {userInfo?.userType !== "INDIVIDUAL" ? (
                                        <>
                                            <span>회사명</span>
                                            <p className="login-input mt-2">{userInfo?.companyName}</p>
                                            <span>사업자 등록번호</span>
                                            <p className="login-input mt-2">{userInfo?.companyRegistrationNo}</p>
                                        </>
                                    ) : null}
                                    <span>이메일</span>
                                    <p className="login-input mt-2">{userInfo?.email}</p>
                                    <span>주소</span>
                                    <p
                                        className="p-2 mt-2 mb-0"
                                        style={{ background: "#ffffff", border: " 1px solid #d9d9d9", borderRadius: "4px", minHeight: "42px" }}
                                    >
                                        {userInfo?.address}
                                    </p>
                                    {userInfo?.addressDetails && <p className="login-input mt-2">{userInfo?.addressDetails}</p>}
                                    <Divider className="custom-divider" />
                                    <h6>추가 정보</h6>
                                    <span>운전면허</span>
                                    <DriverLicense mode="view" driverLicenseId={userInfo?.driverLicense?.driverLicenseId} />
                                    <span>단체 회원 코드</span>
                                    <p className="login-input mt-2">{userInfo?.b2b?.promotionCode}</p>
                                    <Row className="mt-3">
                                        <Col span={12}>
                                            <Space>
                                                <Button
                                                    onClick={() =>
                                                        navigate(Common.PAGE_UPDATE_MEMBER, {
                                                            state: userInfo,
                                                        })
                                                    }
                                                >
                                                    수정
                                                </Button>
                                                <Button onClick={() => setOpenResetPasswordPopup(true)}>비밀번호 재설정</Button>
                                                {openResetPasswordPopup && (
                                                    <ModalResetPassword
                                                        open={openResetPasswordPopup}
                                                        onChangedOpen={(opened) => {
                                                            setOpenResetPasswordPopup(opened);
                                                        }}
                                                    />
                                                )}
                                            </Space>
                                        </Col>
                                        <Col span={12} className="text-end">
                                            <Button danger onClick={showDeleteConfirm}>
                                                탈퇴
                                            </Button>
                                        </Col>
                                    </Row>
                                </Card>
                            </div>
                        </Row>
                    )}
                </>
            </BrowserView>
        </>
    );
}

export default MyPage;
