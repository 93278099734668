import React, { useEffect, useState } from "react";

import { Button, Carousel, Space } from "antd";
import { useAsyncAxios, utilAxiosWithAuth } from "../../utils/customAxios";

import * as Common from "../../commons/common";
import * as Request from "../../commons/request";
import * as TypeDTO from "../../commons/typeDTO";
import * as Utils from "../../utils/utils";
import * as String from "../../commons/string";

import styles from "./ModalManageCar.module.css";
import CarList from "../../component/rent/CarList";
import CustomArrow from "../../component/CustomArrow";

function ModalManageCar({
    setOpenCarChangeModal,
    setUpdateActiveCar,
}: {
    setOpenCarChangeModal: (openModalDigitalKey: boolean) => void;
    setUpdateActiveCar: (updateActiveCar: boolean) => void;
}) {
    const [changeActiveCar, setChangeActiveCar] = useState("");
    const [myShareCars, setMyShareCars] = useState<Array<TypeDTO.CarSharerDto>>([]);
    const [openModalConfirmActiveCars, setOpenModalConfirmActiveCars] = useState(false);

    useEffect(() => {
        requestGetMyShareCars();
        Utils.onEvent(Common.EVENT_CLOSE_ALL_MODAL, handleCloseAllModal);

        return () => {
            Utils.offEvent(Common.EVENT_CLOSE_ALL_MODAL, handleCloseAllModal);
        };
    }, []);

    const requestAxiosGetMyShareCars = async () => {
        const response = await utilAxiosWithAuth().get(Request.SHARER_MINE_URL);
        return response.data;
    };

    const {
        loading: loadingGetMyShareCars,
        error: errorGetMyShareCars,
        data: resultGetMyShareCars,
        execute: requestGetMyShareCars,
    } = useAsyncAxios(requestAxiosGetMyShareCars);

    useEffect(() => {
        if (!resultGetMyShareCars) return;

        setMyShareCars(resultGetMyShareCars.carSharers);
    }, [resultGetMyShareCars]);

    useEffect(() => {
        if (!errorGetMyShareCars) return;

        console.log("errorGetMyShareCars", errorGetMyShareCars);
    }, [errorGetMyShareCars]);

    const handleCloseAllModal = () => {
        setOpenModalConfirmActiveCars(false);
    };

    useEffect(() => {
        openModalConfirmActiveCars ? Utils.addModalStack() : Utils.popModalStack();
    }, [openModalConfirmActiveCars]);

    const onActiveCar = (carNumber: string) => {
        setChangeActiveCar(carNumber);
        setOpenModalConfirmActiveCars(true);
    };

    const onChangeActiveCar = () => {
        const changeCars = myShareCars?.filter((myCar) => myCar.carOwner?.car?.carNumber === changeActiveCar);
        if (changeCars && changeCars.length > 0) {
            const activeCar = { ...changeCars[0], active: true };
            requestUpdateActiveCar(activeCar);
        }
    };

    const requestAxiosUpdateActiveCar = async (shareCar: TypeDTO.CarSharerDto) => {
        const response = await utilAxiosWithAuth().put(Request.CAR_SHARER_URL + "/" + shareCar.carSharerId, shareCar);
        return response.data;
    };

    const {
        loading: loadingUpdateActiveCar,
        error: errorUpdateActiveCar,
        data: resultUpdateActiveCar,
        execute: requestUpdateActiveCar,
    } = useAsyncAxios(requestAxiosUpdateActiveCar);

    useEffect(() => {
        if (!resultUpdateActiveCar) return;

        requestGetMyShareCars();
        setUpdateActiveCar(true);
    }, [resultUpdateActiveCar]);

    useEffect(() => {
        if (!errorUpdateActiveCar) return;

        console.log("errorUser", errorUpdateActiveCar);
    }, [errorUpdateActiveCar]);

    const settings = {
        nextArrow: <CustomArrow type="next" onClick={() => {}} />,
        prevArrow: <CustomArrow type="prev" onClick={() => {}} />,
    };

    return (
        <div className={styles.popup}>
            <Space direction="vertical" size={16} className="container p-0">
                <p className={styles.titleBody}>차량 변경하기</p>
                {openModalConfirmActiveCars ? (
                    <>
                        <div className={styles.modalText}>
                            {changeActiveCar} 차량을
                            <br />
                            기본 차량으로 변경하시겠습니까?
                        </div>
                        <Space>
                            <Button className={styles.secondaryBtn} type="default" key="back" onClick={() => setOpenModalConfirmActiveCars(false)}>
                                {String.cancel}
                            </Button>
                            <Button
                                className={styles.primaryBtn}
                                type="primary"
                                key="confirm"
                                onClick={() => {
                                    onChangeActiveCar();
                                    setOpenModalConfirmActiveCars(false);
                                }}
                            >
                                {String.confirm}
                            </Button>
                        </Space>
                    </>
                ) : (
                    <>
                        <Carousel dots={false} arrows={true} {...settings} className={styles.carousel}>
                            {myShareCars?.length === 0 ? (
                                <CarList carNumber="" isActive={false} />
                            ) : (
                                myShareCars?.map((carInfo) => (
                                    <CarList
                                        key={carInfo.carOwner?.car?.carNumber}
                                        carId={carInfo.carOwner?.car?.carId}
                                        carNumber={carInfo.carOwner?.car?.carNumber}
                                        isActive={carInfo.active}
                                        onActiveCar={onActiveCar}
                                        isYaxing={carInfo.carOwner?.car?.hasSwingDoor}
                                    />
                                ))
                            )}
                        </Carousel>

                        <Button className={styles.secondaryBtn} type="default" key="back" onClick={() => setOpenCarChangeModal(false)}>
                            {String.close}
                        </Button>
                    </>
                )}
            </Space>
        </div>
    );
}

export default ModalManageCar;
