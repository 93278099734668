import { Button, Card, Input, Row, Col, Space, Modal, Tabs } from "antd";

import React, { useEffect, useState } from "react";

import Coupon from "../../component/Coupon";
import IconTag from "../../assets/images/home/rent/icon_tag.png";

import * as String from "../../commons/string";
import * as Request from "../../commons/request";
import * as TypeDTO from "../../commons/typeDTO";
import * as Common from "../../commons/common";

import { useAuthState } from "../../provider/AuthProvider";
import { useAsyncAxios, utilAxiosWithAuth } from "../../utils/customAxios";
import { AlertType, useAlert } from "../../provider/AlertProvider";

import styles from "./CouponDetails.module.css";
import NoData from "../../component/NoData";
import type { TabsProps } from "antd";
import moment from "moment";
import { BrowserView, MobileView } from "react-device-detect";

const CouponDetails = () => {
    const userDetails = useAuthState();
    const alert = useAlert();
    const isMobile = /Mobile/i.test(navigator.userAgent);
    const [couponCode, setCouponCode] = useState<string>();
    const [unusedCoupons, setUnusedCoupons] = useState<Array<TypeDTO.UserCouponDto>>([]);
    const [usedCoupons, setUsedCoupons] = useState<Array<TypeDTO.UserCouponDto>>([]);

    const mobileItems: TabsProps["items"] = [
        {
            key: "1",
            label: "보유 쿠폰",
            children: (
                <div style={{ paddingBottom: "1.5rem" }}>
                    <div className={`mb-4 ${styles.box}`}>
                        <Row gutter={5}>
                            <Col>
                                <img src={IconTag} width={16} height={16} />
                            </Col>
                            <Col>
                                <p>{String.inputCoupon}</p>
                            </Col>
                        </Row>
                        <Row gutter={10}>
                            <Col xs={16} md={19} lg={21}>
                                <Input
                                    className={styles.input}
                                    value={couponCode}
                                    onChange={(e) => onChange(e.target.value)}
                                    placeholder="쿠폰 코드를 입력해 주세요."
                                />
                            </Col>
                            <Col xs={8} md={5} lg={3}>
                                <Button className={styles.button} onClick={() => onClick("add")}>
                                    {String.addCoupon}
                                </Button>
                            </Col>
                        </Row>
                        <span className={styles.careText}>* 등록하신 쿠폰은 환불되지 않습니다. 등록기간이 지난 쿠폰은 등록하실 수 없습니다.</span>
                    </div>
                    {unusedCoupons?.length ? <Coupon mobile={true} userCoupons={unusedCoupons} /> : <NoData msg={String.noCoupon} />}
                </div>
            ),
        },
        {
            key: "2",
            label: "지난 쿠폰",
            children: (
                <div style={{ paddingBottom: "1.5rem" }}>
                    {usedCoupons?.length ? <Coupon mobile={true} userCoupons={usedCoupons} /> : <NoData msg="지난 쿠폰이 없습니다." />}
                </div>
            ),
        },
    ];

    const items: TabsProps["items"] = [
        {
            key: "1",
            label: "보유 쿠폰",
            children: (
                <div style={{ paddingBottom: "1.5rem" }}>
                    <div className={`mb-4 ${styles.box}`}>
                        <Row gutter={5}>
                            <Col>
                                <img src={IconTag} width={16} height={16} />
                            </Col>
                            <Col>
                                <p style={{ marginBottom: 8 }}>{String.inputCoupon}</p>
                            </Col>
                        </Row>
                        <Row gutter={10} style={{ marginBottom: 8 }}>
                            <Col xs={16} md={19} lg={21}>
                                <Input
                                    className={styles.input}
                                    value={couponCode}
                                    onChange={(e) => onChange(e.target.value)}
                                    placeholder="쿠폰 코드를 입력해 주세요."
                                />
                            </Col>
                            <Col xs={8} md={5} lg={3}>
                                <Button className={styles.button} onClick={() => onClick("add")}>
                                    {String.addCoupon}
                                </Button>
                            </Col>
                        </Row>
                        <span className={styles.careText}>* 등록하신 쿠폰은 환불되지 않습니다. 등록기간이 지난 쿠폰은 등록하실 수 없습니다.</span>
                    </div>
                    {unusedCoupons?.length ? <Coupon mobile={false} userCoupons={unusedCoupons} /> : <NoData msg={String.noCoupon} />}
                </div>
            ),
        },
        {
            key: "2",
            label: "지난 쿠폰",
            children: (
                <div style={{ paddingBottom: "1.5rem" }}>
                    {usedCoupons?.length ? <Coupon mobile={false} userCoupons={usedCoupons} /> : <NoData msg="지난 쿠폰이 없습니다." />}
                </div>
            ),
        },
    ];

    useEffect(() => {
        requestMyCouponList();
    }, []);

    const onChange = (code: string) => {
        setCouponCode(code);
    };

    const onClick = (value: string) => {
        switch (value) {
            case "add":
                registerCouponCode();
                break;
        }
    };

    const requestAxiosMyCouponList = async () => {
        if (userDetails === null) return null;
        if (userDetails?.token === null) return null;

        const response = await utilAxiosWithAuth().get(Request.MY_COUPON_URL);

        return response.data;
    };

    const {
        loading: loadingMyCouponList,
        error: errorMyCouponList,
        data: resultMyCouponList,
        execute: requestMyCouponList,
    } = useAsyncAxios(requestAxiosMyCouponList);

    useEffect(() => {
        if (resultMyCouponList === null) return;
        console.log("resultMyCouponList", resultMyCouponList);

        const coupons = resultMyCouponList.userCoupons.filter(
            (coupon: TypeDTO.UserCouponDto) => (coupon.effectiveDate ?? coupon.coupon.effectiveDate) < moment().format(Common.FORMAT_DATE_TIME)
        );

        setUnusedCoupons(
            coupons.filter(
                (coupon: TypeDTO.UserCouponDto) =>
                    !coupon.usedDate && (coupon.expiredDate ?? coupon.coupon.expiredDate) >= moment().format(Common.FORMAT_DATE_TIME)
            )
        );
        setUsedCoupons(
            coupons.filter(
                (coupon: TypeDTO.UserCouponDto) =>
                    coupon.usedDate || (coupon.expiredDate ?? coupon.coupon.expiredDate) < moment().format(Common.FORMAT_DATE_TIME)
            )
        );
    }, [resultMyCouponList]);

    useEffect(() => {
        if (errorMyCouponList === null) return;

        console.log("errorMyCouponList", errorMyCouponList);
    }, [errorMyCouponList]);

    const requestAxiosRegisterCouponCode = async () => {
        if (userDetails === null) return null;
        if (userDetails?.token === null) return null;

        const response = await utilAxiosWithAuth().post(Request.MY_COUPON_URL, null, { params: { serialNumber: couponCode } });

        return response.data;
    };

    const {
        loading: loadingRegisterCouponCode,
        error: errorRegisterCouponCode,
        data: resultRegisterCouponCode,
        execute: requestRegisterCouponCode,
    } = useAsyncAxios(requestAxiosRegisterCouponCode);

    useEffect(() => {
        if (resultRegisterCouponCode === null) return;

        console.log("resultRegisterCouponCode", resultRegisterCouponCode);
        
        const coupons = resultRegisterCouponCode.userCoupons.filter(
            (coupon: TypeDTO.UserCouponDto) => (coupon.effectiveDate ?? coupon.coupon.effectiveDate) <= moment().format(Common.FORMAT_DATE_TIME)
        );

        setUnusedCoupons(
            coupons.filter(
                (coupon: TypeDTO.UserCouponDto) =>
                    !coupon.usedDate && (coupon.expiredDate ?? coupon.coupon.expiredDate) >= moment().format(Common.FORMAT_DATE_TIME)
            )
        );
        setUsedCoupons(
            coupons.filter(
                (coupon: TypeDTO.UserCouponDto) =>
                    coupon.usedDate || (coupon.expiredDate ?? coupon.coupon.expiredDate) < moment().format(Common.FORMAT_DATE_TIME)
            )
        );

        alert.setAlert(AlertType.SUCCESS, "쿠폰등록 성공", "쿠폰 등록을 성공했습니다.");
        setCouponCode("");
    }, [resultRegisterCouponCode]);

    useEffect(() => {
        if (errorRegisterCouponCode === null) return;

        console.log("errorRegisterCouponCode", errorRegisterCouponCode);
        setCouponCode("");
    }, [errorRegisterCouponCode]);

    const registerCouponCode = () => {
        if (couponCode === undefined || couponCode === null || couponCode.length === 0) {
            alert.setAlert(AlertType.INVALID_PARAM, "쿠폰 에러", "쿠폰번호를 입력해 주세요.");
        } else {
            requestRegisterCouponCode();
        }
    };

    return (
        <>
            <MobileView className="mobile" style={{ minHeight: "calc(100vh - 70px)" }}>
                <div className="header-container">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="fadeIn text-center">
                                <h2 className="fs-title">{String.couponDetails}</h2>
                            </div>
                        </div>
                    </div>
                </div>
                <Tabs className="model-tabs tab-lg mt-3 space-page" defaultActiveKey="1" items={mobileItems} />
            </MobileView>
            <BrowserView className="browser">
                <div className="header-container">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="fadeIn text-center">
                                <h2 className="fs-title">{String.couponDetails}</h2>
                            </div>
                        </div>
                    </div>
                </div>
                <Col
                    xs={{ span: 24 }}
                    sm={{ span: 22, offset: 1 }}
                    lg={{ span: 18, offset: 3 }}
                    xl={{ span: 16, offset: 4 }}
                    xxl={{ span: 14, offset: 5 }}
                    className="mb-4 p-4 order"
                >
                    <Tabs className="order-tabs" defaultActiveKey="1" items={items} />
                </Col>
            </BrowserView>
        </>
    );
};

export default CouponDetails;
