import React, { useEffect } from "react";
import { Modal, Button } from "antd";

import * as String from "../../commons/string";
import * as Utils from "../../utils/utils";
import * as Common from "../../commons/common";

type CustomModalProps = {
    title: string;
    subTitle: string;
    content: any;
    visible: boolean;
    onCancel: () => void;
    onOk: () => void;
};

const RentModal = (props: CustomModalProps) => {
    const { visible, title, subTitle, content, onCancel, onOk } = props;

    useEffect(() => {
        Utils.onEvent(Common.EVENT_CLOSE_ALL_MODAL, handleCancel);

        return () => {
            Utils.offEvent(Common.EVENT_CLOSE_ALL_MODAL, handleCancel);
        };
    }, []);

    useEffect(() => {
        visible ? Utils.addModalStack() : Utils.popModalStack();
    }, [visible]);

    const handleCancel = () => {};

    return (
        <Modal
            className="custom-modal"
            title="환불 정보"
            open={visible}
            closable={false}
            footer={[
                <Button key="cancel" className="btn-light-border" style={{ width: "80px" }} onClick={onCancel}>
                    {String.cancel}
                </Button>,
                <Button key="ok" className="btn-dark" type="primary" style={{ width: "80px" }} onClick={onOk}>
                    {String.confirm}
                </Button>,
            ]}
            centered
        >
            <p className="b-md" style={{ wordBreak: "keep-all", whiteSpace: "pre-wrap" }}>
                {title}
            </p>
            <p className="b-md" style={{ wordBreak: "keep-all", whiteSpace: "pre-wrap" }}>
                {subTitle}
            </p>
            {content}
        </Modal>
    );
};

export default RentModal;
