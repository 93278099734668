import { Col, Row, Space } from "antd";

import iconMap from "../../assets/images/home/rent/icon_map_blue.png";
import iconFacility from "../../assets/images/home/rent/icon_home_blue.png";
import iconDuration from "../../assets/images/home/rent/icon_clock_blue.png";
import iconOption from "../../assets/images/home/rent/icon_check_blue.png";
import RentPlaceMap from "../../component/rent/RentPlaceMap";

function TravelSpotCard({ place }: { place: any }) {
    return (
        <div className="media-card" style={{ padding: 20 }}>
            <RentPlaceMap isGuide={true} rentRegion={place} />
            <h5 className="mt-3 mb-2">
                <a className="text-dark">{place?.rentRegionDescription}</a>
            </h5>
            <Space direction="vertical" size={4}>
                {place?.address && (
                    <Space size={8} align="baseline">
                        <img width={14} src={iconMap} />
                        <p className="news-text">주소 : {place.address}</p>
                    </Space>
                )}
                {place?.description?.facility && (
                    <Space size={8} align="baseline">
                        <img width={14} src={iconFacility} />
                        <p className="news-text">주변시설 : {place.description.facility} 보유</p>
                    </Space>
                )}
                {place?.description?.duration && (
                    <Space size={8} align="baseline">
                        <img width={14} src={iconDuration} />
                        <p className="news-text">소요시간 : 차고지에서 약 {place.description.duration}분 소요</p>
                    </Space>
                )}
                {place?.description?.option && (
                    <Space size={8} align="baseline">
                        <img width={14} src={iconOption} />
                        <p className="news-text">기타 : {place.description.option}</p>
                    </Space>
                )}
                {place?.description?.warning && <p className="news-text text-note">※{place.description.warning}</p>}
            </Space>
        </div>
    );
}

export default TravelSpotCard;
