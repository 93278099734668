import { Col, Space, Tabs } from "antd";
import type { TabsProps } from "antd";
import { BrowserView, MobileView } from "react-device-detect";

import bayrunTypeExterior1 from "../assets/images/model/bayrun/BayrunType-exterior-1.jpg";
import bayrunTypeExterior2 from "../assets/images/model/bayrun/BayrunType-exterior-2.jpg";
import bayrunTypeExterior3 from "../assets/images/model/bayrun/BayrunType-exterior-3.jpg";
import bayrunTypeInterior1 from "../assets/images/model/bayrun/BayrunType-interior-9.jpg";
import bayrunTypeInterior2 from "../assets/images/model/bayrun/BayrunType-interior-10.jpg";
import bayrunTypeInterior3 from "../assets/images/model/bayrun/BayrunType-interior-11.jpg";
import bayrunTypeInterior4 from "../assets/images/model/bayrun/BayrunType-interior-12.jpg";
import bayrunTypeOption1 from "../assets/images/model/bayrun/BayrunType4-option-1.jpg";
import bayrunTypeOption2 from "../assets/images/model/bayrun/BayrunType4-option-2.jpg";
import bayrunTypeOption3 from "../assets/images/model/bayrun/BayrunType4-option-3.jpg";
import bayrunTypeOption4 from "../assets/images/model/bayrun/BayrunType4-option-4.jpg";
import bayrunTypeOption5 from "../assets/images/model/bayrun/BayrunType4-option-5.jpg";
import bayrunTypeOption6 from "../assets/images/model/bayrun/BayrunType4-option-6.jpg";
import bayrunTypeOption7 from "../assets/images/model/bayrun/BayrunType4-option-7.jpg";
import bayrunTypeOption8 from "../assets/images/model/bayrun/BayrunType4-option-8.jpg";
import ModelRuta40Service from "./ModelRuta40Service";
import ModelRuta40Specification from "./ModelRuta40Specification";
import Gallery from "./Gallery";
import WarrantyInfo from "./ruta40/WarrantyInfo";
import OptionTable from "./ruta40/OptionTable";
import OptionCar from "./ruta40/OptionCar";

import * as Options from "../commons/options";
import * as Common from "../commons/common";

export default function ModelRuta40TypeBayrun4() {
    const carType = "bayrun4";

    const items: TabsProps["items"] = [
        {
            key: "1",
            label: "전기 및 설비",
            children: (
                <Space style={{ width: "100%" }} size={16} direction="vertical">
                    <OptionCar carType={carType} options={[...Options.bayrunElectricity, ...Options.bayrun4Equipment]} />
                    <OptionTable hasNo={true} options={[...Options.bayrunElectricity, ...Options.bayrun4Equipment]} />
                </Space>
            ),
        },
        {
            key: "2",
            label: "냉/난방",
            children: (
                <Space style={{ width: "100%" }} size={16} direction="vertical">
                    <OptionCar carType={carType} options={Options.bayrun4AirConditioning} />
                    <OptionTable hasNo={true} options={Options.bayrun4AirConditioning} />
                </Space>
            ),
        },
        {
            key: "3",
            label: "가전",
            children: (
                <Space style={{ width: "100%" }} size={16} direction="vertical">
                    <OptionCar carType={carType} options={Options.bayrun4Appliances} />
                    <OptionTable hasNo={true} options={Options.bayrun4Appliances} />
                </Space>
            ),
        },
        {
            key: "4",
            label: "선택 옵션",
            children: (
                <Space style={{ width: "100%" }} size={16} direction="vertical">
                    <OptionCar carType={carType} options={Options.bayrunSelectOption} />
                    <OptionTable hasNo={true} options={Options.bayrunSelectOption} />
                </Space>
            ),
        },
    ];

    const displayPCContent = () => {
        return (
            <div>
                <div className="mb-5">
                    <div className="text-center mb-4">
                        <h3>디자인</h3>
                    </div>
                    <Gallery
                        showBullets={false}
                        styles="mt-3"
                        images={[
                            bayrunTypeInterior1,
                            bayrunTypeInterior2,
                            bayrunTypeInterior3,
                            bayrunTypeInterior4,
                            bayrunTypeOption1,
                            bayrunTypeOption2,
                            bayrunTypeOption3,
                            bayrunTypeOption4,
                            bayrunTypeOption5,
                            bayrunTypeOption6,
                            bayrunTypeOption7,
                            bayrunTypeOption8,
                            bayrunTypeExterior1,
                            bayrunTypeExterior2,
                            bayrunTypeExterior3,
                        ]}
                    />
                </div>
                <div className="mb-5">
                    <div className="text-center mb-4">
                        <h3>옵션</h3>
                    </div>
                    <Tabs className="model-tabs tab-lg" defaultActiveKey="1" items={items} />
                    <p className="text-note">※단, 시공과정에서 제작사의 사정으로 동종의 유사제품으로 교체될 수 있습니다.</p>
                </div>
                <ModelRuta40Service />
                <div className="mb-5">
                    <div className="text-center mb-4">
                        <h3>제원 정보</h3>
                    </div>
                    <div className="col-lg-12 text-center optionBox">
                        <div className="col-md-12">
                            <ModelRuta40Specification />
                        </div>
                    </div>
                </div>
                <WarrantyInfo />
            </div>
        );
    };

    return (
        <>
            <MobileView>
                <Col xs={{ span: 22, offset: 1 }}>
                    <Col span={24} className="fadeInUp">
                        <div className="my-5">
                            <div className="text-center mb-4">
                                <h3>디자인</h3>
                            </div>
                            <div className="text-center">
                                <Gallery
                                    showBullets={false}
                                    styles="mt-3"
                                    images={[
                                        bayrunTypeInterior1,
                                        bayrunTypeInterior2,
                                        bayrunTypeInterior3,
                                        bayrunTypeInterior4,
                                        bayrunTypeOption1,
                                        bayrunTypeOption2,
                                        bayrunTypeOption3,
                                        bayrunTypeOption4,
                                        bayrunTypeOption5,
                                        bayrunTypeOption6,
                                        bayrunTypeOption7,
                                        bayrunTypeOption8,
                                        bayrunTypeExterior1,
                                        bayrunTypeExterior2,
                                        bayrunTypeExterior3,
                                    ]}
                                />
                            </div>
                        </div>
                    </Col>
                    <Col span={24} className="fadeInUp">
                        <div className="mb-4">
                            <div className="text-center mb-4">
                                <h3>옵션</h3>
                            </div>
                            <Tabs className="option-tabs" defaultActiveKey="1" items={items} />
                            <p className="text-note">※단, 시공과정에서 제작사의 사정으로 동종의 유사제품으로 교체될 수 있습니다.</p>
                        </div>
                    </Col>
                    <Col span={24} className="fadeInUp">
                        <ModelRuta40Service />
                    </Col>
                    <Col span={24} className="fadeInUp" style={{ marginTop: "70px" }}>
                        <div className="mb-4">
                            <div className="text-center mb-4">
                                <h3>제원 정보</h3>
                            </div>
                            <div className="col-lg-12 text-center optionBox">
                                <div className="col-md-12">
                                    <ModelRuta40Specification />
                                </div>
                            </div>
                        </div>
                    </Col>
                </Col>
            </MobileView>
            <BrowserView>
                {location.pathname.includes(Common.PAGE_RENT_DETAIL) ? (
                    displayPCContent()
                ) : (
                    <Col
                        xs={{ span: 24 }}
                        sm={{ span: 22, offset: 1 }}
                        lg={{ span: 18, offset: 3 }}
                        xl={{ span: 16, offset: 4 }}
                        xxl={{ span: 14, offset: 5 }}
                    >
                        <div className="p-4"> {displayPCContent()}</div>
                    </Col>
                )}
            </BrowserView>
        </>
    );
}
