import { Checkbox } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import React from "react";

function AgreementSubsidy({ agreement, onChangeAgreement }: { agreement?: boolean; onChangeAgreement?: (agreement: boolean) => void }) {
    return (
        <div>
            <Checkbox
                checked={agreement}
                onChange={(e: CheckboxChangeEvent) => {
                    if (onChangeAgreement !== undefined) onChangeAgreement(e.target.checked);
                }}
            >
                <p style={{ fontWeight: 700, fontSize: "16px" }}>위 안내 사항에 모두 동의합니다.</p>
            </Checkbox>
        </div>
    );
}

export default AgreementSubsidy;
