import React from "react";
import KeepAlive from "react-activation";
import { useParams } from "react-router";

function CustomKeepAliveWithId(props: any) {
    const params = useParams();
    const keepAliveId = props.name + "/" + params.id;

    return (
        <>
            <KeepAlive name={keepAliveId} id={keepAliveId}>
                {props.children}
            </KeepAlive>
        </>
    );
}

export default CustomKeepAliveWithId;
