import React, { useState, useEffect } from "react";
import { BrowserView, MobileView } from "react-device-detect";
import { Button, Col, Row, Space, Collapse, Divider, Modal, Tag } from "antd";
import { useNavigate } from "react-router-dom";
import { CalendarOutlined, CreditCardOutlined } from "@ant-design/icons";
import { NumericFormat } from "react-number-format";
import { useAsyncAxios, utilAxiosFormWithAuth, utilAxiosWithAppToken } from "../../utils/customAxios";
import { useAlert } from "../../provider/AlertProvider";

import * as Common from "../../commons/common";
import * as String from "../../commons/string";
import * as TypeDTO from "../../commons/typeDTO";
import * as Utils from "../../utils/utils";
import * as Request from "../../commons/request";

import Title from "./Title";
import ReservationDateInfo from "./ReservationDateInfo";
import ReservationDetail from "./detail/ReservationDetail";
import RentModal from "./RentModal";
import MobileDrawer from "./MobileDrawer";
import IconRight from "../../assets/images/home/rent/icon_right.png";
import IconNoLicense from "../../assets/images/home/rent/icon_no_driver_license.png";
import moment from "moment";
import CustomSheet from "../CustomSheet";
import AdditionalDriverInfo from "./AdditionalDriverInfo";
import Info from "./Info";

const ReservationCard = ({
    rentId,
    isLast,
    isPast,
    onCancel,
}: {
    rentId?: number;
    isLast: boolean;
    isPast?: boolean;
    onCancel?: (cancelAmount: number) => void;
}) => {
    const { Panel } = Collapse;
    const navigate = useNavigate();
    const alert = useAlert();
    const [visible, setVisible] = useState<boolean>(false);
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [insurancePrice, setInsurancePrice] = useState<number>(0);
    const [paidItemPrice, setPaidItemPrice] = useState<number>(0);
    const [value, setValue] = useState<TypeDTO.RentDto>();
    const [rentPeakSeasons, setRentPeakSeasons] = useState<Array<TypeDTO.RentPeakSeasonDto>>();

    const availableLiceseType = ["1종 대형", "1종 보통"];

    const title = {
        disabledImage: true,
        title: {
            content: value?.rentCar.title,
        },
        description: {
            content: value?.rentCar.description,
            style: {
                textOverflow: "ellipsis",
                overflow: "hidden",
                display: "-webkit-box",
                WebkitBoxOrient: "vertical",
                WebkitLineClamp: 2,
            },
        },
    };

    useEffect(() => {
        requestGetRentPeakSeasons();
        requestGetRent();
    }, [rentId]);

    useEffect(() => {
        if (value) {
            const rentPayment = value?.rentPayments?.find((payment) => payment?.rentPaymentType === "RENT");
            const insurancePayment = value?.rentPayments?.find((payment) => payment?.rentPaymentType === "INSURANCE");

            if (insurancePayment || rentPayment?.amount === 0) {
                if (insurancePayment?.paidDate) {
                    setInsurancePrice(insurancePayment?.amount);
                }
            } else if (value?.startDate && value?.endDate) {
                const startDate = new Date(value.startDate);
                const endDate = new Date(value.endDate);
                const diffTime = Math.abs(startDate.getTime() - endDate.getTime());
                const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

                setInsurancePrice((value?.rentInsurance?.price || 0) * diffDays);
            }

            setPaidItemPrice(
                value?.rentPaidItems?.reduce(
                    (total, item) =>
                        total +
                        (value.userCoupon?.coupon.rentPaidItemId !== item.rentPaidItemId &&
                        value.userMultiCoupon?.coupon.rentPaidItemId !== item.rentPaidItemId
                            ? item.price
                            : 0),
                    0
                ) || 0
            );
        }
    }, [value]);

    useEffect(() => {
        if (isOpen) {
            checkDriverLicenseOpenPopup();
        }
    }, [isOpen]);

    const getPayment = () => {
        return value?.rentPayments?.find((payment) => payment?.rentPaymentType === "RENT");
    };

    const getRentalPrice = () => {
        const rentPayment = getPayment();
        return rentPayment?.paymentImp ? (Number(rentPayment?.amount) || 0) - paidItemPrice : 0;
    };

    const getRefundPrice = () => {
        const price = value ? (Number(getPayment()?.amount) || 0) - getCancelAmount(value.startDate, getRentalPrice() || 0) : 0;
        return price;
    };

    const getPaymentMethod = () => {
        if (getPayment()?.paymentMethod === "ONLINE" && getPayment()?.paymentImp && getPayment()?.paymentImp.cardName) {
            const stringQuota =
                getPayment()?.paymentImp.cardType === "0"
                    ? getPayment()?.paymentImp.cardQuota === 0
                        ? "/일시불"
                        : `/${getPayment()?.paymentImp.cardQuota}개월`
                    : "/체크";
            return getPayment()?.paymentImp.cardName + stringQuota;
        } else if (getPayment()?.paymentMethod === "OFFLINE_CARD" && getPayment()?.paidDate) {
            return "신용카드";
        } else if (getPayment()?.paymentMethod === "OFFLINE_BANK" && getPayment()?.paidDate) {
            return "계좌이체";
        } else if (getPayment()?.paymentMethod === "OFFLINE_CASH" && getPayment()?.paidDate) {
            return "현금";
        } else if (getPayment()?.paymentImp?.pgProvider === "kakaopay") {
            return "카카오페이";
        }
    };

    const getCancelPercent = (startDate: string) => {
        //위약금 퍼센트
        const days = moment(startDate).diff(moment().subtract(1, "day"), "days");
        let percent = 0;

        if (days >= 3) {
            if (days <= 5) {
                percent = 20;
            } else if (days <= 10) {
                percent = 50;
            } else {
                percent = 100;
            }

            return 100 - percent;
        }
        return 100;

        //return "내부 테스트 중에는 위약금 0";
    };

    const getCancelAmount = (startDate: string, rentalPrice: number) => {
        //취소 금액
        const days = moment(startDate).diff(moment().subtract(1, "day"), "days");
        let percent = 0;

        if (days >= 3) {
            if (days <= 5) {
                percent = 20;
            } else if (days <= 10) {
                percent = 50;
            } else {
                percent = 100;
            }

            return (rentalPrice / 100) * (100 - percent);
        }
        return (rentalPrice / 100) * 100;

        //return 0; //내부테스트용
    };

    //get rent
    const requestAxiosGetRent = async () => {
        const response = await utilAxiosFormWithAuth().get(Request.RENT_URL + rentId);
        return response.data;
    };

    const { loading: loadingGetRent, error: errorGetRent, data: resultGetRent, execute: requestGetRent } = useAsyncAxios(requestAxiosGetRent);

    useEffect(() => {
        if (resultGetRent === null) return;
        console.log("resultGetRent", resultGetRent);

        const rent: TypeDTO.RentDto = resultGetRent.rent;

        rent.rentPaidItems = rent.rentPaidItems.map((item) => ({
            ...item,
            price:
                rent.rentPayments?.find(
                    (payment) => payment.rentPaymentType === "PAID_ITEM" && payment.rentPaymentItem?.rentPaymentItemName === item.rentPaidItemName
                ) === undefined
                    ? item.price
                    : 0,
        }));

        setValue(rent);
    }, [resultGetRent]);

    useEffect(() => {
        if (errorGetRent === null) return;

        console.log("errorGetRent", errorGetRent);
    }, [errorGetRent]);

    //rentPeakSeason
    const requestAxiosGetRentPeakSeasons = async () => {
        const response = await utilAxiosWithAppToken().get(Request.RENT_PEAK_SEASONS_URL);
        return response.data;
    };

    const {
        loading: loadingGetRentPeakSeasons,
        error: errorGetRentPeakSeasons,
        data: resultGetRentPeakSeasons,
        execute: requestGetRentPeakSeasons,
    } = useAsyncAxios(requestAxiosGetRentPeakSeasons);

    useEffect(() => {
        if (!resultGetRentPeakSeasons) return;
        console.log("resultGetRentPeakSeasons", resultGetRentPeakSeasons);

        setRentPeakSeasons(resultGetRentPeakSeasons.rentPeakSeasons);
    }, [resultGetRentPeakSeasons]);

    useEffect(() => {
        if (!errorGetRentPeakSeasons) return;

        console.log("errorGetRentPeakSeasons", errorGetRentPeakSeasons);
    }, [errorGetRentPeakSeasons]);

    const onClick = (type: string, e: React.MouseEvent<HTMLElement, MouseEvent>) => {
        e.stopPropagation();

        switch (type) {
            case "showDetail":
                navigate(Common.PAGE_RENT_DETAIL + "/" + value?.rentCar?.rentCarId + "/" + undefined);
                break;
            case "cancel":
                setVisible(true);
                break;
            default:
                break;
        }
    };

    const handleOk = () => {
        setVisible(false);
        onCancel !== undefined && value !== undefined && onCancel(getRefundPrice());
    };

    const displayDetail = () => {
        return (
            <>
                <div className="space-page">
                    <Space direction="vertical" className="w-100">
                        <Space size={10}>
                            <p style={{ fontWeight: "700", color: !value?.canceledDate ? "#005FDB" : "#E21D12" }}>
                                {value && Utils.convertRentType(value)}
                            </p>
                            <p>|</p>
                            <p style={{ color: "#192739F0" }}>
                                {getPayment()?.paidDate !== undefined &&
                                    Utils.utcToLocalTime(getPayment()?.paidDate as string, Common.FORMAT_DATE_TIME)}
                            </p>
                            {!isPast && !value?.user?.driverLicense && !value?.additionalDriver?.driverLicense && (
                                <Space
                                    className="ml-2"
                                    style={{
                                        color: "#E21D12",
                                        backgroundColor: "white",
                                        border: "1px solid #E21D12",
                                        borderRadius: "20px",
                                        padding: "0 10px",
                                    }}
                                >
                                    <img className="mr-1 mb-1" src={IconNoLicense} height={16} width={16} />
                                    <p>면허</p>
                                </Space>
                            )}
                        </Space>
                        <Title searchType={true} info={title} />
                        <Row gutter={10} className="w-100" style={{ marginBottom: "8px" }}>
                            <Col
                                span={value && Utils.checkRentType(value, [String.cancellationComplete, String.useComplete, String.inUse]) ? 24 : 12}
                                className="w-100"
                            >
                                <Button className="btn-light-border w-100" onClick={(e) => onClick("showDetail", e)}>
                                    {String.showGoods}
                                </Button>
                            </Col>
                            {value && Utils.checkRentType(value, [String.reservationComplete]) && (
                                <Col span={12}>
                                    <Button className="btn-danger w-100" onClick={(e) => onClick("cancel", e)}>
                                        {String.reservationCancel}
                                    </Button>
                                </Col>
                            )}
                        </Row>
                    </Space>
                    <Divider className="custom-divider" />
                    <AdditionalDriverInfo rent={value} disabled={isPast} onSuccess={() => requestGetRent(rentId)} />
                    <Divider className="custom-divider" />
                    <h6>{String.reservationInfo}</h6>
                    <ReservationDateInfo
                        rentNo={value?.rentNo}
                        period={
                            moment(value?.startDate).format(Common.FORMAT_SHORT_DATE_WITH_DAY) +
                            " ~ " +
                            moment(value?.endDate).format(Common.FORMAT_SHORT_DATE_WITH_DAY)
                        }
                    />
                    <Divider className="custom-divider" />
                    <h6>{value && Utils.checkRentType(value, [String.cancellationComplete]) ? "환불 금액" : "대여 금액"}</h6>
                    <ReservationDetail
                        showTitle={false}
                        value={{
                            rentCar: value?.rentCar,
                            regionId: value?.rentCar?.rentRegion?.rentRegionId,
                            startDate: value?.startDate,
                            endDate: value?.endDate,
                            extraTime: value?.rentExtraTime,
                            rentPaidItems: value?.rentPaidItems,
                            insurance: value?.rentInsurance,
                            coupon: value?.userCoupon,
                            multiCoupon: value?.userMultiCoupon,
                            cancelAmount: getPayment()?.paymentImp?.cancelAmount,
                        }}
                        rentPeakSeasons={rentPeakSeasons}
                    />
                    <Divider />
                    <h6>{value && Utils.checkRentType(value, [String.cancellationComplete]) ? String.refundInfo : String.paymentInfo}</h6>
                    <Space style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                        {value && Utils.checkRentType(value, [String.cancellationComplete]) ? (
                            <>
                                <p>취소 일시</p>
                                <p>
                                    {getPayment()?.canceledDate !== undefined &&
                                        Utils.utcToLocalTime(getPayment()?.canceledDate as string, Common.FORMAT_DATE_TIME)}
                                </p>
                            </>
                        ) : (
                            <>
                                <p>결제 일시</p>
                                {getPayment()?.paidDate !== undefined &&
                                    Utils.utcToLocalTime(getPayment()?.paidDate as string, Common.FORMAT_DATE_TIME)}
                            </>
                        )}
                    </Space>
                    <Space style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                        <p>결제 수단</p>
                        <p>{getPayment()?.paidDate !== undefined ? getPaymentMethod() : String.dash}</p>
                    </Space>
                    {!isPast && (
                        <>
                            <Divider className="custom-divider" />
                            <h6>이용 안내</h6>
                            <Info showInfo={{ show: true }} />
                            <Divider className="custom-divider" />
                            <h6>대여 조건</h6>
                            <Info showCondition={{ show: true }} />
                            <Divider className="custom-divider" />
                            <h6>반납 장소</h6>
                            <Info rentRegion={value?.rentCar?.rentRegion} showMap={{ show: true }} />
                        </>
                    )}
                </div>
                <CustomSheet
                    isOpen={visible}
                    content={
                        value !== undefined && (
                            <div className="space-page">
                                <h6>환불 정보</h6>
                                <p>
                                    예약을 취소하시면 환불규정에 따라 아래의 위약금이 부과됩니다. <br />
                                    예약을 취소하시겠습니까?
                                </p>
                                <Divider className="mt-2 mb-2" />
                                <Space style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                                    <p className="b-md">차량 대여 금액</p>
                                    <NumericFormat
                                        className="b-md"
                                        value={getRentalPrice()}
                                        thousandSeparator={true}
                                        suffix={String.priceUnit}
                                        displayType="text"
                                    />
                                </Space>
                                <Space style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                                    <p className="text-danger">{`위약금(${getCancelPercent(value.startDate)}%)`}</p>
                                    <NumericFormat
                                        className="text-danger b-md"
                                        value={getCancelAmount(value.startDate, getRentalPrice() || 0)}
                                        thousandSeparator={true}
                                        suffix={String.priceUnit}
                                        prefix="- "
                                        displayType="text"
                                    />
                                </Space>
                                {insurancePrice > 0 && (
                                    <Space className="mt-3" style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                                        <p className="b-md">자차 보험</p>
                                        <NumericFormat
                                            className="b-md"
                                            value={insurancePrice}
                                            thousandSeparator={true}
                                            suffix={String.priceUnit}
                                            displayType="text"
                                        />
                                    </Space>
                                )}
                                {value.rentPaidItems.length > 0 && (
                                    <div className="mt-3">
                                        {value.rentPaidItems.map((item) => (
                                            <Space
                                                key={item.rentPaidItemId}
                                                style={{ width: "100%", display: "flex", justifyContent: "space-between" }}
                                            >
                                                <p className="b-md">{item.rentPaidItemName}</p>
                                                <NumericFormat
                                                    className="b-md"
                                                    value={
                                                        value?.userCoupon?.coupon.rentPaidItemId !== item.rentPaidItemId &&
                                                        value?.userMultiCoupon?.coupon.rentPaidItemId !== item.rentPaidItemId
                                                            ? item.price
                                                            : 0
                                                    }
                                                    thousandSeparator={true}
                                                    suffix={String.priceUnit}
                                                    displayType="text"
                                                />
                                            </Space>
                                        ))}
                                    </div>
                                )}
                                <Space className="mt-3" style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                                    <h6>총 환불 금액</h6>
                                    <h6 className="text-price">
                                        <NumericFormat
                                            className="b-lg text-price text-bold"
                                            value={getRefundPrice()}
                                            thousandSeparator={true}
                                            suffix={String.priceUnit}
                                            displayType="text"
                                        />
                                    </h6>
                                </Space>
                                <Button
                                    style={{
                                        position: "fixed",
                                        bottom: "14px",
                                        left: "50%",
                                        transform: "translate(-50%,0)",
                                        padding: "0 1.5rem",
                                        color: "white",
                                        backgroundColor: "#e21d12",
                                        border: "1px solid #e21d12",
                                        minHeight: "40px",
                                        borderRadius: "4px",
                                        width: "90%",
                                    }}
                                    onClick={handleOk}
                                >
                                    예약취소
                                </Button>
                            </div>
                        )
                    }
                    snapHeight={450}
                    onClose={() => setVisible(false)}
                />
            </>
        );
    };

    const checkDriverLicenseOpenPopup = () => {
        if (isPast) return;

        if (!value?.user?.driverLicense && !value?.additionalDriver?.driverLicense) {
            openDriverLicensePopup();
        } else if (value?.additionalDriver && !value?.additionalDriver?.driverLicense) {
            openAdditionalDriverLicensePopup();
        } else if (
            !availableLiceseType.includes(value?.user?.driverLicense?.licenseType) &&
            !availableLiceseType.includes(value?.additionalDriver?.driverLicense?.licenseType) &&
            Utils.convertRentType(value) === String.reservationComplete
        ) {
            openConfirmDriverLicensePopup();
        }
    };

    const openDriverLicensePopup = () => {
        Modal.confirm({
            title: "운전면허 미등록",
            content: (
                <p className="text-center mt-3">
                    운전면허가 등록되지 않았습니다. <br />
                    렌트 이용일 전까지 <strong>예약자</strong> 또는
                    <strong> 추가 운전자</strong>의 <strong>운전면허</strong>를 등록하셔야 이용 가능합니다.
                </p>
            ),
            okText: "내 운전면허 등록",
            cancelText: "추가 운전자 등록",
            onCancel() {
                navigate(Common.PAGE_RESERVATION_DETAIL);
            },
            onOk() {
                navigate(Common.PAGE_MYPAGE);
            },
            centered: true,
            closable: true,
        });
    };

    const openAdditionalDriverLicensePopup = () => {
        Modal.info({
            title: "운전면허 미등록",
            content: (
                <p className="text-center mt-3">
                    등록하신 <strong>추가 운전자</strong>의 <strong>운전면허정보</strong>가 없습니다.
                    <br />
                    추가 운전자에게 운전면허 등록을 요청해 주세요.
                </p>
            ),
            cancelText: "확인",
            onCancel() {},
            centered: true,
            closable: false,
        });
    };

    const openConfirmDriverLicensePopup = () => {
        Modal.info({
            title: "운전면허 재확인",
            content: (
                <p className="text-center mt-3">
                    캠핑카 이용은 <strong>예약자</strong> 또는 <strong>추가 운전자</strong>의 면허가
                    <br />
                    <strong className="text-price">1종 보통 이상</strong>이여야 합니다.
                    <br />
                    등록하신 운전면허를 다시 확인해 주세요.
                </p>
            ),
            cancelText: "확인",
            onCancel() {},
            centered: true,
            closable: false,
        });
    };

    return (
        <>
            <MobileView className="mobile">
                <div onClick={() => setIsOpen(true)} style={{ display: "inline-block" }}>
                    <div className="mb-2" style={{ position: "relative" }}>
                        <Space size={10} className="mb-0">
                            <p style={{ fontWeight: "700", color: !value?.canceledDate ? "#005FDB" : "#E21D12" }}>
                                {value && Utils.convertRentType(value)}
                            </p>
                            <p>|</p>
                            <p style={{ color: "#192739F0" }}>
                                {value?.createdDate && Utils.utcToLocalTime(value?.createdDate, Common.FORMAT_DATE_TIME)}
                            </p>
                            {!isPast && !value?.user?.driverLicense && !value?.additionalDriver?.driverLicense && (
                                <Space
                                    className="ml-2"
                                    style={{
                                        color: "#E21D12",
                                        backgroundColor: "white",
                                        border: "1px solid #E21D12",
                                        borderRadius: "20px",
                                        padding: "0 10px",
                                    }}
                                >
                                    <img className="mr-1 mb-1" src={IconNoLicense} height={16} width={16} />
                                    <p>면허</p>
                                </Space>
                            )}
                        </Space>
                        <img src={IconRight} style={{ float: "right", width: "24px", height: "24px" }} />
                    </div>
                    <Title searchType={true} info={title} />
                </div>
                {!isLast && <Divider className="custom-divider" />}
                <MobileDrawer
                    isOpen={isOpen}
                    title={String.reservationInfo}
                    content={displayDetail()}
                    onClose={() => {
                        setIsOpen(false);
                    }}
                />
            </MobileView>

            <BrowserView className="browser">
                <div className="order-card mt-3">
                    <Collapse className="mb-3" expandIconPosition="end" onChange={(e) => e.length !== 0 && checkDriverLicenseOpenPopup()}>
                        <Panel
                            key={Number(value?.rentId)}
                            header={
                                <Space direction="vertical" size="middle">
                                    <Space size={10}>
                                        <p style={{ fontWeight: "700", fontSize: 16, color: !value?.canceledDate ? "#005FDB" : "#E21D12" }}>
                                            {value && Utils.convertRentType(value)}
                                        </p>
                                        <p>|</p>
                                        <p style={{ color: "#192739F0", fontSize: 16 }}>
                                            {getPayment()?.paidDate !== undefined &&
                                                Utils.utcToLocalTime(getPayment()?.paidDate as string, Common.FORMAT_DATE_TIME)}
                                        </p>
                                        {!isPast && !value?.user?.driverLicense && !value?.additionalDriver?.driverLicense && (
                                            <Space
                                                className="ml-2"
                                                style={{
                                                    color: "#E21D12",
                                                    backgroundColor: "white",
                                                    border: "1px solid #E21D12",
                                                    borderRadius: "20px",
                                                    padding: "0 10px",
                                                }}
                                            >
                                                <img className="mr-1 mb-1" src={IconNoLicense} height={16} width={16} />
                                                <p>운전면허 미등록</p>
                                            </Space>
                                        )}
                                    </Space>
                                    <Row gutter={[20, 20]}>
                                        <Col>
                                            <img
                                                style={{ borderRadius: "8px", width: "200px", height: "106px" }}
                                                src={Utils.getCarImage(value?.rentCar.car.carType.typeName)}
                                            />
                                        </Col>
                                        <Col>
                                            <Title info={title} />
                                            <Space size="middle" style={{ marginBottom: "8px" }}>
                                                <Button className="btn-light-border" onClick={(e) => onClick("showDetail", e)}>
                                                    {String.showGoods}
                                                </Button>
                                                {value && Utils.checkRentType(value, [String.reservationComplete]) && (
                                                    <Button className="btn-danger" onClick={(e) => onClick("cancel", e)}>
                                                        {String.reservationCancel}
                                                    </Button>
                                                )}
                                            </Space>
                                        </Col>
                                    </Row>
                                </Space>
                            }
                        >
                            <>
                                <AdditionalDriverInfo rent={value} disabled={isPast} onSuccess={() => requestGetRent(rentId)} />
                                <Divider />
                                <Row gutter={[20, 20]}>
                                    <Col xs={24} sm={24} md={12}>
                                        <h6>{String.reservationInfo}</h6>
                                        <Space direction="vertical" size="small" style={{ width: "100%" }}>
                                            <Space className="box" style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                                                <p>예약번호</p>
                                                <p>{value?.rentNo}</p>
                                            </Space>
                                            <div className="box">
                                                <ReservationDateInfo
                                                    period={
                                                        moment(value?.startDate).format(Common.FORMAT_DATE_WITH_DAY) +
                                                        " ~ " +
                                                        moment(value?.endDate).format(Common.FORMAT_DATE_WITH_DAY)
                                                    }
                                                />
                                            </div>
                                        </Space>
                                    </Col>
                                    <Col xs={24} sm={24} md={12}>
                                        <h6>
                                            {value && Utils.checkRentType(value, [String.cancellationComplete])
                                                ? String.refundInfo
                                                : String.paymentInfo}
                                        </h6>
                                        <Space direction="vertical" size="small" style={{ width: "100%" }}>
                                            <div className="box">
                                                <ReservationDetail
                                                    showTitle={false}
                                                    value={{
                                                        rentCar: value?.rentCar,
                                                        regionId: value?.rentCar?.rentRegion?.rentRegionId,
                                                        startDate: value?.startDate,
                                                        endDate: value?.endDate,
                                                        extraTime: value?.rentExtraTime,
                                                        rentPaidItems: value?.rentPaidItems,
                                                        insurance: value?.rentInsurance,
                                                        coupon: value?.userCoupon,
                                                        multiCoupon: value?.userMultiCoupon,
                                                        cancelAmount: getPayment()?.paymentImp?.cancelAmount,
                                                    }}
                                                    rentPeakSeasons={rentPeakSeasons}
                                                />
                                            </div>
                                            <div className="box">
                                                <Space direction="vertical" size={0}>
                                                    <div style={{ display: "flex", alignItems: "center" }}>
                                                        <CalendarOutlined style={{ marginRight: "8px" }} />
                                                        {value && Utils.checkRentType(value, [String.cancellationComplete]) ? (
                                                            <p>
                                                                취소 일시&nbsp;:&nbsp;
                                                                {getPayment()?.canceledDate !== undefined &&
                                                                    Utils.utcToLocalTime(
                                                                        getPayment()?.canceledDate as string,
                                                                        Common.FORMAT_DATE_TIME
                                                                    )}
                                                            </p>
                                                        ) : (
                                                            <p>
                                                                결제 일시&nbsp;:&nbsp;
                                                                {getPayment()?.paidDate !== undefined &&
                                                                    Utils.utcToLocalTime(getPayment()?.paidDate as string, Common.FORMAT_DATE_TIME)}
                                                            </p>
                                                        )}
                                                    </div>
                                                    <div style={{ display: "flex", alignItems: "center" }}>
                                                        <CreditCardOutlined style={{ marginRight: "8px" }} />
                                                        <p>결제 수단&nbsp;:&nbsp;</p>
                                                        <p>{getPayment()?.paidDate ? getPaymentMethod() : String.dash}</p>
                                                    </div>
                                                </Space>
                                            </div>
                                        </Space>
                                    </Col>
                                </Row>
                                {!isPast && (
                                    <>
                                        <Divider />
                                        <Row gutter={[20, 20]}>
                                            <Col xs={24} sm={24} md={12}>
                                                <h6>이용 안내</h6>
                                                <div className="box">
                                                    <Info
                                                        showInfo={{ show: true, showDivider: true }}
                                                        showCondition={{ show: true, showTitle: true }}
                                                    />
                                                </div>
                                            </Col>
                                            <Col xs={24} sm={24} md={12}>
                                                <h6>반납 장소</h6>
                                                <div className="box">
                                                    <Info rentRegion={value?.rentCar?.rentRegion} showMap={{ show: true }} />
                                                </div>
                                            </Col>
                                        </Row>
                                    </>
                                )}
                            </>
                        </Panel>
                    </Collapse>
                    <RentModal
                        visible={visible}
                        title="예약을 취소하시면 환불규정에 따라 아래의 위약금이 부과됩니다."
                        subTitle="예약을 취소하시겠습니까?"
                        content={
                            value !== undefined && (
                                <>
                                    <Space className="mt-3" style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                                        <p className="b-md">차량 대여 금액</p>
                                        <NumericFormat
                                            className="b-md"
                                            value={getRentalPrice()}
                                            thousandSeparator={true}
                                            suffix={String.priceUnit}
                                            displayType="text"
                                        />
                                    </Space>
                                    <Space style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                                        <p className="text-danger b-md">{`위약금(${getCancelPercent(value.startDate)}%)`}</p>
                                        <NumericFormat
                                            className="text-danger b-md"
                                            value={getCancelAmount(value.startDate, getRentalPrice() || 0)}
                                            thousandSeparator={true}
                                            suffix={String.priceUnit}
                                            prefix="- "
                                            displayType="text"
                                        />
                                    </Space>
                                    {insurancePrice > 0 && (
                                        <Space className="mt-3" style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                                            <p className="b-md">자차 보험</p>
                                            <NumericFormat
                                                className="b-md"
                                                value={insurancePrice}
                                                thousandSeparator={true}
                                                suffix={String.priceUnit}
                                                displayType="text"
                                            />
                                        </Space>
                                    )}
                                    {value.rentPaidItems.length > 0 &&
                                        value.rentPaidItems.map((item, i) => (
                                            <Space
                                                key={item.rentPaidItemId}
                                                className={`${i === 0 ? "mt-3" : ""}`}
                                                style={{ width: "100%", display: "flex", justifyContent: "space-between" }}
                                            >
                                                <p className="b-md">{item.rentPaidItemName}</p>
                                                <NumericFormat
                                                    className="b-md"
                                                    value={
                                                        value?.userCoupon?.coupon.rentPaidItemId !== item.rentPaidItemId &&
                                                        value?.userMultiCoupon?.coupon.rentPaidItemId !== item.rentPaidItemId
                                                            ? item.price
                                                            : 0
                                                    }
                                                    thousandSeparator={true}
                                                    suffix={String.priceUnit}
                                                    displayType="text"
                                                />
                                            </Space>
                                        ))}
                                    <Divider />
                                    <Space style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                                        <p className="b-lg text-bold">총 환불 금액</p>
                                        <NumericFormat
                                            className="b-lg text-price text-bold"
                                            value={getRefundPrice()}
                                            thousandSeparator={true}
                                            suffix={String.priceUnit}
                                            displayType="text"
                                        />
                                    </Space>
                                </>
                            )
                        }
                        onOk={handleOk}
                        onCancel={() => setVisible(false)}
                    />
                </div>
            </BrowserView>
        </>
    );
};

export default ReservationCard;
