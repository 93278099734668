import React from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { useAuthState } from "../../provider/AuthProvider";

import * as Common from "../../commons/common";

import PageLayout from "../../layout/PageLayout";
import Login from "../common/Login";
import MyPage from "../common/MyPage";
import SignUp from "../common/SignUp";
import TOS from "../common/TOS";
import PrivacyPolicy from "../common/PrivacyPolicy";
import UpdateMember from "../common/UpdateMember";
import WithdrawMember from "../common/WithdrawMember";
import NotFound from "../common/NotFound";
import Event from "../common/Event";

import Home from "./Home";
import Story from "./Story";
import Media from "./Media";
import DigitalKey from "./DigitalKey";
import FMS from "./FMS";
import Information from "./Information";
import Faq from "./Faq";
import DownloadCenter from "./DownloadCenter";
import Order from "./Order";
import Payment from "./Payment";
import Model from "./Model";
import MyOrder from "./MyOrder";
import MyOrderDetail from "./MyOrderDetail";

import CustomKeepAliveWithId from "../../component/CustomKeepAliveWithId";
import CouponDetails from "../common/CouponDetails";
import Notice from "./Notice";
import NoticeDetail from "./NoticeDetail";
import BrochureRedMax from "./BrochureRedMax";

const type = "home";

const PrivateRoute = (props: any) => {
    const userDetails = useAuthState();
    const location = useLocation();

    if (props.role && props.role.length > 0) {
        const checkRole = userDetails?.user && props.role.includes(userDetails?.user.authority);
        return checkRole ? (
            props.children
        ) : (
            <PageLayout type={type} theme={{ header: "light" }}>
                <Home />
            </PageLayout>
        );
    }
    return userDetails?.user ? props.children : <Navigate to={Common.PAGE_LOGIN} replace={true} state={location.pathname} />;
};

const LoginRoute = (props: any) => {
    const userDetails = useAuthState();
    const path = sessionStorage.getItem(Common.SESSION_BEFORE_LOGIN_PATH);

    return !userDetails?.user ? props.children : <Navigate to={path ? path : Common.PAGE_HOME} replace={true} />;
};

function Ruta40Router() {
    const location = useLocation();
    return (
        <Routes>
            <Route path="*" element={<Navigate to={Common.PAGE_NOTFOUND} replace />} />
            <Route
                path={Common.PAGE_NOTFOUND}
                element={
                    <PageLayout type={type} theme={{ footer: "sub" }}>
                        <NotFound />
                    </PageLayout>
                }
            />
            <Route path="/" element={<Navigate to={Common.PAGE_HOME} />} />
            <Route
                path={Common.PAGE_HOME}
                element={
                    <PageLayout type={type} theme={{ header: "light", scroll: "none", footer: "none" }}>
                        <Home />
                    </PageLayout>
                }
            ></Route>
            <Route
                path={Common.PAGE_LOGIN}
                element={
                    <LoginRoute>
                        <PageLayout type={type} theme={{ footer: "sub" }}>
                            <Login />
                        </PageLayout>
                    </LoginRoute>
                }
            />
            <Route
                path={Common.PAGE_SIGNUP}
                element={
                    <PageLayout type={type} theme={{ footer: "sub", ...(location.state?.nextPath === Common.PAGE_EVENT ? { header: "none" } : {}) }}>
                        <SignUp />
                    </PageLayout>
                }
            />
            <Route
                path={Common.PAGE_MYPAGE}
                element={
                    <PrivateRoute>
                        <PageLayout type={type} theme={{ footer: "sub" }}>
                            <MyPage />
                        </PageLayout>
                    </PrivateRoute>
                }
            ></Route>
            <Route
                path={Common.PAGE_UPDATE_MEMBER}
                element={
                    <PrivateRoute>
                        <PageLayout type={type} theme={{ footer: "sub" }}>
                            <UpdateMember />
                        </PageLayout>
                    </PrivateRoute>
                }
            ></Route>
            <Route
                path={Common.PAGE_WITHDRAW_MEMBER}
                element={
                    <PrivateRoute>
                        <PageLayout type={type} theme={{ footer: "sub" }}>
                            <WithdrawMember />
                        </PageLayout>
                    </PrivateRoute>
                }
            ></Route>
            <Route
                path={Common.PAGE_MY_ORDER}
                element={
                    <PrivateRoute>
                        <PageLayout type={type}>
                            <MyOrder />
                        </PageLayout>
                    </PrivateRoute>
                }
            />
            <Route
                path={Common.PAGE_COUPON_DETAIL}
                element={
                    <PrivateRoute>
                        <PageLayout type={type}>
                            <CouponDetails />
                        </PageLayout>
                    </PrivateRoute>
                }
            ></Route>
            <Route
                path={Common.PAGE_MY_ORDER_DETAIL + "/:orderId"}
                element={
                    <PrivateRoute>
                        <PageLayout type={type}>
                            <MyOrderDetail />
                        </PageLayout>
                    </PrivateRoute>
                }
            />
            <Route
                path={Common.PAGE_ORDER + "/:modelId"}
                element={
                    <PageLayout type={type} theme={{ footer: "none", scroll: "none" }}>
                        <CustomKeepAliveWithId name={Common.PAGE_ORDER}>
                            <Order />
                        </CustomKeepAliveWithId>
                    </PageLayout>
                }
            />
            <Route
                path={Common.PAGE_PAYMENT}
                element={
                    <PrivateRoute>
                        <PageLayout type={type} theme={{ footer: "none" }}>
                            <Payment />
                        </PageLayout>
                    </PrivateRoute>
                }
            />
            <Route
                path={Common.PAGE_STORY}
                element={
                    <PageLayout type={type}>
                        <Story />
                    </PageLayout>
                }
            ></Route>
            <Route
                path={Common.PAGE_MEDIA}
                element={
                    <PageLayout type={type}>
                        <Media />
                    </PageLayout>
                }
            ></Route>
            <Route
                path={Common.PAGE_DIGITALKEY}
                element={
                    <PageLayout type={type}>
                        <DigitalKey />
                    </PageLayout>
                }
            ></Route>
            <Route
                path={Common.PAGE_FMS}
                element={
                    <PageLayout type={type}>
                        <FMS />
                    </PageLayout>
                }
            ></Route>
            <Route
                path={Common.PAGE_MODEL + "/:modelId"}
                element={
                    <PageLayout type={type}>
                        <Model />
                    </PageLayout>
                }
            ></Route>
            <Route
                path={Common.PAGE_MODEL + "/:modelId/:typeId"}
                element={
                    <PageLayout type={type}>
                        <Model />
                    </PageLayout>
                }
            ></Route>
            <Route
                path={Common.PAGE_INFORMATION}
                element={
                    <PageLayout type={type}>
                        <Information />
                    </PageLayout>
                }
            ></Route>
            <Route
                path={Common.PAGE_FAQ}
                element={
                    <PageLayout type={type}>
                        <Faq />
                    </PageLayout>
                }
            ></Route>
            <Route
                path={Common.PAGE_DOWNLOADCENTER}
                element={
                    <PageLayout type={type}>
                        <DownloadCenter />
                    </PageLayout>
                }
            ></Route>
            <Route
                path={Common.PAGE_NOTICE}
                element={
                    <PageLayout type={type}>
                        <Notice />
                    </PageLayout>
                }
            ></Route>
            <Route
                path={Common.PAGE_NOTICE_DETAIL + "/:noticeId"}
                element={
                    <PageLayout type={type}>
                        <NoticeDetail />
                    </PageLayout>
                }
            ></Route>
            <Route
                path={Common.PAGE_TOS}
                element={
                    <PageLayout type={type}>
                        <TOS />
                    </PageLayout>
                }
            ></Route>
            <Route
                path={Common.PAGE_PRIVACPOLICY}
                element={
                    <PageLayout type={type}>
                        <PrivacyPolicy />
                    </PageLayout>
                }
            ></Route>
            <Route
                path={Common.PAGE_EVENT}
                element={
                    <PageLayout type={type} theme={{ header: "none", footer: "none" }}>
                        <Event />
                    </PageLayout>
                }
            ></Route>
            <Route
                path={Common.PAGE_BROCHURE_REDMAX}
                element={
                    <PageLayout type={type} theme={{ header: "none", footer: "none" }}>
                        <BrochureRedMax />
                    </PageLayout>
                }
            ></Route>
        </Routes>
    );
}

export default Ruta40Router;
