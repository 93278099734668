import { Button, Result, Space } from "antd";
import { FrownOutlined } from "@ant-design/icons";
import React from "react";
import { Link } from "react-router-dom";
import * as Common from "../../commons/common";

function NotFound() {
    return (
        <div className="text-center errorResult">
            <Result icon={<FrownOutlined style={{ color: "#001236", fontSize: "60px" }} />}>
                <Space direction="vertical" size={30}>
                    <h3>페이지를 찾을 수 없습니다.</h3>
                    <p className="b-md">
                        찾으시려는 페이지의 주소를 잘못 입력하였거나 페이지 주소의 삭제 등의 이유로 페이지를 찾을 수 없습니다.
                        <br />
                        입력하신 페이지의 주소와 경로가 정확한지 다시 한 번 확인 후 이용하시기 바랍니다.
                    </p>
                    <Link to={Common.PAGE_HOME}>
                        <Button className="btn-dark px-4" size="large" type="primary">
                            홈으로 돌아가기
                        </Button>
                    </Link>
                </Space>
            </Result>
        </div>
    );
}

export default NotFound;
