import { Button, Checkbox, Input, Modal, Row, Card, Space, Result } from "antd";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

import * as Common from "../commons/common";
import * as TypeDTO from "../commons/typeDTO";
import * as Request from "../commons/request";
import * as Utils from "../utils/utils";

import { useAsyncAxios, utilAxiosWithAppToken } from "../utils/customAxios";
import styles from "./Event.module.css";
import IconDash from "../assets/images/home/ruta40/icon_modal_dash.svg";
import BgBanner from "../assets/images/home/event/campingcarShow/bg_event_banner.png";
import BgEventMethod from "../assets/images/home/event/campingcarShow/bg_event_method.png";
import BgEventNote from "../assets/images/home/event/campingcarShow/bg_event_note.png";
import BgEventTitle from "../assets/images/home/event/campingcarShow/bg_event_title.png";
import IconPopup from "../assets/images/home/ruta40/event_popup_icon.svg";
import IconPopupTitle from "../assets/images/home/ruta40/event_popup_title.svg";
import IconSoldOutTitle from "../assets/images/home/ruta40/event_sold_out_title.svg";

import PrizeChopstickBellRock from "../assets/images/home/ruta40/prize_chopstick_bellrock.svg";
import PrizeChopstickSnowline from "../assets/images/home/ruta40/prize_chopstick_snowline.svg";
import PrizeCoupon from "../assets/images/home/ruta40/prize_coupon.svg";
import PrizeGriptok from "../assets/images/home/ruta40/prize_griptok.svg";
import PrizeHanger from "../assets/images/home/ruta40/prize_hanger.svg";
import PrizeMarshmallow from "../assets/images/home/ruta40/prize_marshmallow.svg";
import PrizeTissue from "../assets/images/home/ruta40/prize_tissue.svg";
import PrizeWaterjug from "../assets/images/home/ruta40/prize_waterjug.svg";

const KoreaCampingcarShowEvent = ({ eventId }: { eventId: number }) => {
    const isMobile = useMediaQuery({ maxWidth: 450 });
    const navigate = useNavigate();
    const location = useLocation();
    const [eventPhoneNumber, setEventPhoneNumber] = useState<string>("");
    const [staffCheck, setStaffCheck] = useState<boolean>(false);
    const [agreementEvent, setAgreementEvent] = useState<boolean>(false);
    const [showResultPopup, setShowResultPopup] = useState(false);
    const [eventUser, setEventUser] = useState<TypeDTO.EventUserDto>();
    const [prizeImage, setPrizeImage] = useState("");
    const [showErrorUserPopup, setShowErrorUserPopup] = useState(false);
    const [showErrorPrizePopup, setShowErrorPrizePopup] = useState(false);

    const handlePhoneNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const tempString = e.target.value;
        e.target.value = tempString.replace(/\D/g, "");

        const regex = /^[0-9\b]{0,13}$/;
        if (regex.test(e.target.value)) {
            setEventPhoneNumber(e.target.value);
        }
    };

    const noPhoneNumber = () => {
        Modal.info({
            centered: true,
            title: "전화번호 입력",
            content: "전화번호를 입력해 주세요.",
            okText: "확인",
            onOk() {},
        });
    };

    const noCheckAgreement = () => {
        Modal.info({
            centered: true,
            title: "개인정보 수집/이용 동의",
            content: "이벤트 참여를 위한 개인정보 수집·이용을 동의해 주세요.",
            okText: "확인",
            onOk() {},
        });
    };

    const handleEvent = () => {
        if (loadingJoinEvent) return;

        if (eventPhoneNumber === undefined || eventPhoneNumber.length === 0) {
            noPhoneNumber();
            return;
        }

        if (!agreementEvent) {
            noCheckAgreement();
            return;
        }

        requestJoinEvent();
    };

    const requestAxiosJoinEvent = async () => {
        const response = await utilAxiosWithAppToken().post(Request.EVENT_URL + eventId + Request.EVENT_JOIN_URL, null, {
            params: { phone: eventPhoneNumber },
        });

        return response.data;
    };

    const {
        loading: loadingJoinEvent,
        error: errorJoinEvent,
        data: resultJoinEvent,
        execute: requestJoinEvent,
    } = useAsyncAxios(requestAxiosJoinEvent, false, true);

    useEffect(() => {
        if (resultJoinEvent === null) return;

        console.log("resultJoinEvent", resultJoinEvent);
        setEventUser(resultJoinEvent.eventUser);
    }, [resultJoinEvent]);

    useEffect(() => {
        if (errorJoinEvent === null) return;

        console.log("errorJoinEvent", errorJoinEvent);
        if (errorJoinEvent.response?.data?.code === "NONEXISTENT_USER") {
            setShowErrorUserPopup(true);
        } else if (errorJoinEvent.response?.data?.code === "NONEXISTENT_EVENT_PRIZE") {
            setShowErrorPrizePopup(true);
        }
    }, [errorJoinEvent]);

    const requestAxiosReceivedPrize = async (phoneNumber: string) => {
        const response = await utilAxiosWithAppToken().post(Request.EVENT_URL + eventId + Request.EVENT_RECEIVED_PRIZE_URL, null, {
            params: { phone: phoneNumber },
        });

        return response.data;
    };

    const {
        loading: loadingReceivedPrize,
        error: errorReceivedPrize,
        data: resultReceivedPrize,
        execute: requestReceivedPrize,
    } = useAsyncAxios(requestAxiosReceivedPrize);

    useEffect(() => {
        if (resultReceivedPrize === null) return;

        console.log("resultReceivedPrize", resultReceivedPrize);
        // setEventUser(resultReceivedPrize.eventUser);
        setShowResultPopup(false);
        setStaffCheck(false);

        navigate(Common.PAGE_HOME);
    }, [resultReceivedPrize]);

    useEffect(() => {
        if (errorReceivedPrize === null) return;

        console.log("errorJoinEvent", errorReceivedPrize);
        setShowResultPopup(false);
        setStaffCheck(false);
    }, [errorReceivedPrize]);

    useEffect(() => {
        switch (eventUser?.eventPrize.prizeName) {
            case "티타늄 젓가락 (벨락)":
                setPrizeImage(PrizeChopstickBellRock);
                break;
            case "티타늄 젓가락 (스노우라인)":
                setPrizeImage(PrizeChopstickSnowline);
                break;
            case "[1박 무료] 2023 캠핑카쇼 현장 이벤트 쿠폰":
                setPrizeImage(PrizeCoupon);
                break;
            case "RUTA40 그립톡":
                setPrizeImage(PrizeGriptok);
                break;
            case "인디언 행어 고리(색상 랜덤)":
                setPrizeImage(PrizeHanger);
                break;
            case "구워먹는 마쉬멜로우":
                setPrizeImage(PrizeMarshmallow);
                break;
            case "RUTA40 물티슈":
                setPrizeImage(PrizeTissue);
                break;
            case "스탠리 워터저그 7.5":
                setPrizeImage(PrizeWaterjug);
                break;
            default:
                break;
        }

        setShowResultPopup(true);
    }, [eventUser]);

    const receivedPrize = (phoneNumber: string) => {
        requestReceivedPrize(phoneNumber);
    };

    const displayResultPopup = () => {
        if (eventUser === undefined) return null;

        if (eventUser.eventPrize.received === true) {
            return (
                <Modal centered className={`event-modal info ${styles.modal}`} open={true} closable={false} maskClosable={false} footer={null}>
                    <div className={styles.title}>
                        <div className={styles.title}>
                            <img src={IconDash} style={{ marginRight: "auto", marginLeft: "auto", paddingLeft: "40px" }} />
                            <img src={IconPopup} style={{ float: "right", padding: "8px 10px 0 0" }} />
                        </div>
                        <img src={IconPopupTitle} className="mt-3 mb-3" style={{ paddingLeft: "40px" }} />
                    </div>
                    <div className={`mb-4 mt-4 ${styles.content}`}>
                        <p>이미 상품을 수령했습니다.</p>
                        <p>수령일자 : {Utils.utcToLocalTime(eventUser.eventPrize.receivedDate, Common.FORMAT_DATE_TIME)}</p>
                    </div>
                    <Space className={`swiperButton btn ${styles.btnBody}`}>
                        <Button className={`btn-secondary b-md ${styles.lightBtn}`} onClick={() => setShowResultPopup(false)}>
                            닫기
                        </Button>
                    </Space>
                </Modal>
            );
        } else {
            return (
                <>
                    {staffCheck ? (
                        <Modal
                            centered
                            className={`event-modal info ${styles.modal}`}
                            open={true}
                            closable={false}
                            maskClosable={false}
                            footer={null}
                        >
                            <div className={styles.title}>
                                <div className={styles.title}>
                                    <img src={IconDash} style={{ marginRight: "auto", marginLeft: "auto", paddingLeft: "40px" }} />
                                    <img src={IconPopup} style={{ float: "right", padding: "8px 10px 0 0" }} />
                                </div>
                                <img src={IconPopupTitle} className="mt-3 mb-3" style={{ paddingLeft: "40px" }} />
                            </div>
                            <div className={`mb-4 mt-4 ${styles.content}`}>
                                <p>상품을 수령하셨나요?</p>
                                <br />
                                <p className="text-danger">
                                    확인을 누르면 상품 수령으로 변경되어
                                    <br />더 이상 상품을 수령할 수 없습니다.
                                </p>
                            </div>
                            <Space size={10} className={`swiperButton ${styles.btnBody}`}>
                                <Button
                                    className={`btn-secondary b-md ${styles.lightBtn}`}
                                    onClick={() => {
                                        setShowResultPopup(false);
                                        setStaffCheck(false);
                                    }}
                                >
                                    취소
                                </Button>
                                <Button className={`btn-primary b-md ${styles.darkBtn}`} onClick={() => receivedPrize(eventUser.phone)}>
                                    수령 완료
                                </Button>
                            </Space>
                        </Modal>
                    ) : (
                        <Modal centered className={`event-modal ${styles.modal}`} open={true} closable={false} maskClosable={false} footer={null}>
                            <div className={styles.title}>
                                <div className={styles.title}>
                                    <img src={IconDash} style={{ marginRight: "auto", marginLeft: "auto", paddingLeft: "40px" }} />
                                    <img src={IconPopup} style={{ float: "right", padding: "8px 10px 0 0" }} />
                                </div>
                                <img src={IconPopupTitle} className="mt-3 mb-3" style={{ paddingLeft: "40px" }} />
                            </div>
                            <div className={`mb-4 mt-4 ${styles.content}`}>
                                <p>{eventUser.name} 님 당첨을 축하합니다.</p>
                                <img style={{ padding: "10px 10px 0 10px" }} src={prizeImage} />
                                <p className="b-lg mb-2">{eventUser.eventPrize.prizeName}</p>
                                <p>당첨 결과를 스태프에게 보여주세요.</p>
                            </div>
                            <Space size={10} className={`swiperButton ${styles.btnBody}`}>
                                <Button className={`btn-secondary b-md ${styles.lightBtn}`} onClick={() => setShowResultPopup(false)}>
                                    닫기
                                </Button>
                                <Button className={`btn-primary b-md ${styles.darkBtn}`} onClick={() => setStaffCheck(true)}>
                                    스태프 확인
                                </Button>
                            </Space>
                        </Modal>
                    )}
                </>
            );
        }
    };

    const displayErrorUser = () => {
        return (
            <Modal centered className={`event-modal info ${styles.modal}`} open={true} closable={false} maskClosable={false} footer={null}>
                <div className={styles.title}>
                    <div className={styles.title}>
                        <img src={IconDash} style={{ marginRight: "auto", marginLeft: "auto", paddingLeft: "40px" }} />
                        <img src={IconPopup} style={{ float: "right", padding: "8px 10px 0 0" }} />
                    </div>
                    <img src={IconPopupTitle} className="mt-3 mb-3" style={{ paddingLeft: "40px" }} />
                </div>
                <div className={`mb-4 mt-4 ${styles.content}`}>
                    <p>등록되지 않는 회원입니다.</p>
                    <p>회원등록을 진행해 주세요</p>
                </div>
                <Space size={10} className={`swiperButton ${styles.btnBody}`}>
                    <Button
                        className={`btn-secondary b-md ${styles.lightBtn}`}
                        onClick={() => {
                            setShowErrorUserPopup(false);
                        }}
                    >
                        닫기
                    </Button>
                    <Button
                        className={`btn-primary b-md ${styles.darkBtn}`}
                        onClick={() => {
                            setShowErrorUserPopup(false);
                            navigate(Common.PAGE_SIGNUP, { state: { nextPath: location.pathname } });
                        }}
                    >
                        회원가입
                    </Button>
                </Space>
            </Modal>
        );
    };

    const displayErrorPrize = () => {
        return (
            <Modal centered className={`event-modal info ${styles.modal}`} open={true} closable={false} maskClosable={false} footer={null}>
                <div className={styles.title}>
                    <div className={styles.title}>
                        <img src={IconDash} style={{ marginRight: "auto", marginLeft: "auto", paddingLeft: "40px" }} />
                        <img src={IconPopup} style={{ float: "right", padding: "8px 10px 0 0" }} />
                    </div>
                    <img src={IconSoldOutTitle} className="mt-3 mb-3" style={{ paddingLeft: "40px" }} />
                </div>
                <div className={`mb-4 mt-4 ${styles.content}`}>
                    <p>준비된 상품이 모두 소진되었습니다.</p>
                </div>
                <Space className={`swiperButton ${styles.btnBody}`}>
                    <Button className={`btn-secondary b-md ${styles.lightBtn}`} onClick={() => setShowErrorPrizePopup(false)}>
                        닫기
                    </Button>
                </Space>
            </Modal>
        );
    };

    return (
        <div className={`event mobile ${styles.body}`}>
            <img src={BgBanner} style={{ width: "100vw" }} />
            <div
                style={{
                    bottom: 0,
                    left: 0,
                    width: "100vw",
                    height: "auto",
                    background: "linear-gradient(45deg,  #3B979E 0%, #1C5B84 100%)",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    backgroundPosition: "center bottom",
                }}
            >
                <div className="px-4">
                    <img src={BgEventMethod} className="mt-4 mb-4 w-100" />
                    <div style={{ position: "relative", margin: "auto" }}>
                        <img src={BgEventTitle} style={{ width: "100%" }} />
                        <div className="p-3" style={{ backgroundColor: "#F4F4F4", borderRadius: "0 0 15px 15px" }}>
                            <Space direction="vertical" className="w-100" size={20}>
                                <Input
                                    type="text"
                                    onChange={handlePhoneNumberChange}
                                    value={eventPhoneNumber}
                                    placeholder="회원가입 시 인증받은 휴대전화번호를 입력해 주세요."
                                    className={`b-md mb-1 ${styles.input}`}
                                    style={{ height: "42x" }}
                                    onPressEnter={() => handleEvent()}
                                />
                                <Space direction="vertical" className="w-100" size={10}>
                                    <Checkbox
                                        className="event-checkbox"
                                        checked={agreementEvent}
                                        onChange={(e) => setAgreementEvent(e.target.checked)}
                                        style={{ height: "15%" }}
                                    >
                                        <span className="b-lg" style={{ fontWeight: 700 }}>
                                            이벤트 참여를 위한 개인정보 수집·이용을 동의합니다.
                                        </span>
                                    </Checkbox>
                                    <div className={styles.agreeBody}>
                                        <span>
                                            1. 수집/이용 목적 : 이벤트 참여
                                            <br />
                                            2. 수집 항목 : 휴대전화번호
                                            <br />
                                            3. 보유 기간 : 개인정보 수집일로부터 3개월 (90일)
                                        </span>
                                    </div>
                                    <span className={`b-sm mt-2 ${styles.textDanger}`} style={{ height: "15%" }}>
                                        ※ 개인정보 수집/이용에 대한 동의를 거부할 권리가 있으나, 동의하지 않으실 경우 이벤트에 참여하실 수 없습니다.
                                    </span>
                                </Space>
                                <div>
                                    <Button
                                        block
                                        className={`btn-dark b-lg ${styles.btn}`}
                                        type="primary"
                                        disabled={!agreementEvent}
                                        onClick={() => handleEvent()}
                                        style={{ height: "10%" }}
                                    >
                                        이벤트 참여하기
                                    </Button>
                                    <hr className="line" />
                                    <Button
                                        block
                                        className={`btn-light-border b-lg ${styles.btn}`}
                                        style={{ height: "10%" }}
                                        onClick={() => navigate(Common.PAGE_SIGNUP, { state: { nextPath: location.pathname } })}
                                    >
                                        회원가입 하러 가기
                                    </Button>
                                </div>

                                {showResultPopup && displayResultPopup()}
                                {showErrorUserPopup && displayErrorUser()}
                                {showErrorPrizePopup && displayErrorPrize()}
                            </Space>
                        </div>
                    </div>
                    <img src={BgEventNote} className="mt-4 mb-4" style={{ width: "100%" }} />
                </div>
            </div>
        </div>
    );
};

export default KoreaCampingcarShowEvent;
