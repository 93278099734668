import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAsyncAxios, utilAxiosWithAuth } from "../../utils/customAxios";
import { Button, Col, Form, Input, List, Modal, Row, Space } from "antd";
import { NumericFormat } from "react-number-format";
import { useAuthDispatch } from "../../provider/AuthProvider";
import { useAlert } from "../../provider/AlertProvider";

import * as Request from "../../commons/request";
import * as TypeDTO from "../../commons/typeDTO";
import * as Utils from "../../utils/utils";
import * as Common from "../../commons/common";
import * as String from "../../commons/string";

import orderImage1 from "../../assets/images/order/order-1.svg";

function MyOrderDetail() {
    const alert = useAlert();
    const params = useParams();
    const navigate = useNavigate();
    const dispatch = useAuthDispatch();

    const [currentOrderId, setCurrentOrderId] = useState<number>();
    const [myOrderDetail, setMyOrderDetail] = useState<TypeDTO.OrderDto>();
    const [isCancelOpen, setIsCancelOpen] = useState(false);

    useEffect(() => {
        setCurrentOrderId(Number(params.orderId));
    }, [params]);

    useEffect(() => {
        currentOrderId && requestGetMyOrderDetail(currentOrderId);
    }, [currentOrderId]);

    const requestAxiosGetMyOrderDetail = async (orderId: number) => {
        const response = await utilAxiosWithAuth().get(Request.ORDER_URL + "/" + orderId.toString());
        return response.data;
    };

    const {
        loading: loadingGetMyOrderDetail,
        error: errorGetMyOrderDetail,
        data: resultGetMyOrderDetail,
        execute: requestGetMyOrderDetail,
    } = useAsyncAxios(requestAxiosGetMyOrderDetail);

    useEffect(() => {
        if (!resultGetMyOrderDetail) return;

        setMyOrderDetail(resultGetMyOrderDetail.order);
    }, [resultGetMyOrderDetail]);

    useEffect(() => {
        if (!errorGetMyOrderDetail) return;
    }, [errorGetMyOrderDetail]);

    const requestAxiosGetMyOrderDetailCancel = async (orderId: number) => {
        const response = await utilAxiosWithAuth().post(Request.ORDER_CANCEL_URL + "/" + orderId.toString());
        return response.data;
    };

    const {
        loading: loadingGetMyOrderDetailCancel,
        error: errorGetMyOrderDetailCancel,
        data: resultGetMyOrderDetailCancel,
        execute: requestGetMyOrderDetailCancel,
    } = useAsyncAxios(requestAxiosGetMyOrderDetailCancel);

    useEffect(() => {
        if (!resultGetMyOrderDetailCancel) return;
    }, [resultGetMyOrderDetailCancel]);

    useEffect(() => {
        if (!errorGetMyOrderDetailCancel) return;
        console.log("errorGetMyOrderDetailCancel", errorGetMyOrderDetailCancel);

        if (Utils.checkUnAuthorizedToken(errorGetMyOrderDetailCancel)) {
            dispatch && dispatch({ type: "LOGOUT" });
            navigate(Common.PAGE_LOGIN, { replace: true });
        }

        alert.setAlert(errorGetMyOrderDetailCancel.response?.data?.code, "Error", errorGetMyOrderDetailCancel.response?.data?.message);
    }, [errorGetMyOrderDetailCancel]);

    const makeNameNPriceItem = (name: string | undefined, price: number | undefined) => {
        return (
            <Row>
                <Col span={15} style={{ textAlign: "left" }}>
                    {name}
                </Col>
                <Col span={9} style={{ textAlign: "right" }}>
                    {price === 0 ? "포함" : <NumericFormat value={price} thousandSeparator={true} prefix={"₩"} displayType="text" />}
                </Col>
            </Row>
        );
    };

    const convertCurrency = (price: number | undefined) => {
        return price ? Utils.convertCurrency(price) : "";
    };

    const orderSelectList = () => {
        return (
            <>
                <List className="orderList" bordered>
                    {myOrderDetail && (
                        <List.Item>
                            {makeNameNPriceItem(
                                myOrderDetail.carType?.model.modelName + " " + myOrderDetail.carType?.typeName,
                                myOrderDetail.carType?.price
                            )}
                        </List.Item>
                    )}
                    {myOrderDetail && <List.Item>{makeNameNPriceItem(myOrderDetail.color?.colorName, 0)}</List.Item>}
                    {myOrderDetail &&
                        myOrderDetail.options?.map((option) => {
                            return <List.Item key={option.optionId}>{makeNameNPriceItem(option.optionName, option.price)}</List.Item>;
                        })}
                </List>
            </>
        );
    };

    const orderPrice = () => {
        const name = "총 구매 가격";
        let totalPrice = 0;
        if (myOrderDetail !== undefined) {
            totalPrice += myOrderDetail.carType?.price !== undefined ? myOrderDetail.carType?.price : 0;

            //TODO: add Color price
            myOrderDetail.options?.forEach((option) => (totalPrice += option.price));
        }

        return (
            <>
                <Row className="mt-3">
                    <Col span={12} style={{ textAlign: "left" }}>
                        <h5>{name}</h5>
                    </Col>
                    <Col span={12} style={{ textAlign: "right" }}>
                        <h5>
                            <NumericFormat value={totalPrice} thousandSeparator={true} prefix={"₩"} displayType="text" />
                        </h5>
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col span={12} style={{ textAlign: "left" }}>
                        <h5>계약금(차량가 10%)</h5>
                    </Col>
                    <Col span={12} style={{ textAlign: "right" }}>
                        <h5>
                            <NumericFormat value={(totalPrice / 100) * 10} thousandSeparator={true} prefix={"₩"} displayType="text" />
                        </h5>
                    </Col>
                </Row>
            </>
        );
    };

    const handleCancel = () => {
        setIsCancelOpen(true);
    };

    const handleOk = () => {
        navigate(-1);
    };

    const handleCancelOk = () => {
        if (currentOrderId !== undefined) {
            requestGetMyOrderDetailCancel(currentOrderId);
        }
        setIsCancelOpen(false);
    };

    const handleCancelCancel = () => {
        setIsCancelOpen(false);
    };

    return (
        <>
            <div className=" header-container  ">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="text-center ">
                            <h1 className="mb-5 fs-title">계약정보</h1>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-xxl category pb-5">
                <div className="container mb-5 ">
                    <div className="text-center">
                        <img className="orderImage img-fluid my-5 pb-5" src={orderImage1} alt="" />
                    </div>
                    <Col className="mt-5" span={24} style={{ textAlign: "left" }}>
                        <h5>구매가격</h5>
                    </Col>
                    {orderSelectList()}
                    {orderPrice()}
                </div>
                <div className="container mb-5 ">
                    <Space direction="vertical" size={20} style={{ display: "flex" }}>
                        <Form name="nest-messages" layout="inline">
                            <Col className="mt-5" span={24}>
                                <div className="text-center">
                                    <h3 className="mb-5 mt-3   design-title">무통장 입금 정보</h3>
                                </div>
                            </Col>
                            <Col span={24}>
                                <Form.Item className="depositInfo mx-1" label="• 입금계좌">
                                    <Input disabled defaultValue="대구은행 504-10-456433-4" />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item className="depositInfo mx-1" label="• 예금주명">
                                    <Input disabled defaultValue="주식회사 루타스(허성만)" />
                                </Form.Item>
                            </Col>
                            <Col className="mb-5" span={24}>
                                <Form.Item className="depositInfo mx-1" label="• 계약금">
                                    <Input className="mb-2" disabled value={convertCurrency(myOrderDetail?.totalPrice)} />
                                </Form.Item>
                            </Col>
                        </Form>
                        <Col className="mt-3 text-center" span={24}>
                            <Button className="btn-dark px-5" size="large" type="primary" onClick={() => handleCancel()}>
                                {String.cancel}
                            </Button>
                            <Button className="btn-dark px-5" size="large" type="primary" onClick={() => handleOk()}>
                                {String.confirm}
                            </Button>
                        </Col>
                    </Space>
                </div>
            </div>
            <Modal title="예약 취소" open={isCancelOpen} onOk={handleCancelOk} onCancel={handleCancelCancel} centered>
                <p>예약을 취소하시겠습니까?</p>
            </Modal>
        </>
    );
}

export default MyOrderDetail;
