import { Button, Tag } from "antd";

import * as String from "../../commons/string";

import styles from "./CarList.module.css";
import bukgi from "../../assets/images/digitalKeyController/carList-bukgi-car.png";
import yaxing from "../../assets/images/digitalKeyController/carList-yaxing-car.png";

function CarList({
    carId,
    carNumber,
    isActive,
    onActiveCar,
    isYaxing,
}: {
    carId?: number;
    carNumber: string;
    isActive: boolean;
    onActiveCar?: (carNumber: string) => void;
    isYaxing?: boolean;
}) {
    return (
        <Button
            disabled={carNumber === "" ? true : false}
            onClick={isActive ? () => {} : () => onActiveCar && onActiveCar(carNumber)}
            className={isActive ? `${styles.activeBody}` : `${styles.inactiveBody}`}
        >
            {carNumber === "" ? (
                <div className={styles.emptyText}>
                    <p>공유된 차량이 없습니다.</p>
                    <p>관리자에게 문의하세요.</p>
                </div>
            ) : (
                <div className={styles.innerBody}>
                    <img width={200} height={140} src={isYaxing ? yaxing : bukgi} className={styles.carImg} alt="ruta40 camping car" />
                    <p className={styles.carNumberText}>{carNumber}</p>
                    <Tag color="black" className={styles.activeText} style={{ opacity: isActive ? 1 : 0 }}>
                        {String.using}
                    </Tag>
                </div>
            )}
        </Button>
    );
}

export default CarList;
