import React, { useEffect } from "react";
import { Modal, Button } from "antd";

import * as String from "../commons/string";

const DefaultModal = ({
    padding,
    isOpen,
    title,
    content,
    onChange,
}: {
    padding?: string;
    isOpen: boolean;
    title: string;
    content?: any;
    onChange: (opened: boolean) => void;
}) => {
    const handleClose = () => {
        onChange(false);
    };

    return (
        <Modal
            centered
            className="login default"
            open={isOpen}
            closable={false}
            title={title}
            onOk={handleClose}
            onCancel={handleClose}
            bodyStyle={{ maxHeight: "70vh", overflow: "auto", padding: padding ? padding : "24px" }}
            footer={[
                <Button key="setPassword" type="primary" onClick={handleClose}>
                    {String.close}
                </Button>,
            ]}
        >
            {content}
        </Modal>
    );
};

export default DefaultModal;
