import React, { useState, useEffect, useCallback } from "react";
import { CustomOverlayMap, Map, MapMarker, Polygon } from "react-kakao-maps-sdk";
import { BrowserView, MobileView } from "react-device-detect";

import * as TypeDTO from "../commons/typeDTO";

import IconMapMarker from "../assets/images/home/rent/icon_map_marker.png";
import IconMarker from "../assets/images/home/rent/icon_marker.png";
import IconSelectedMarker from "../assets/images/home/rent/icon_selected_marker.png";
import IconToiletMarker from "../assets/images/home/rent/icon_toilet_marker.png";
import IconChargingStationMarker from "../assets/images/home/rent/icon_chargingStation_marker.png";
import styles from "./KakaoMap.module.css";

type RegionMarkerType = {
    list: Array<TypeDTO.RentRegionDto>;
    selected?: number | undefined;
    clickEvent?: ((marker: number) => void) | undefined;
};

type MyLocationType = {
    show: boolean;
    click: boolean;
    clickEvent: () => void;
};

type RentPlace = {
    rentRegionId?: number;
    rentRegionDescription: string;
    latitude: number;
    longitude: number;
    address: string;
};

type RentalLocationType = {
    list?: RentPlace;
    selected?: number;
    clickEvent?: () => void;
};

type LocationType = {
    lat: number;
    lng: number;
};

type GuideMarker = Array<{
    facilityType: string;
    facilityLatitude: number;
    facilityLongitude: number;
}>;

type GuideRegion = Array<{
    lat: number;
    lng: number;
}>;

function KakaoMap({
    mapStyle,
    mapCenter,
    myLocation,
    regions,
    rentalLocation,
    guideMarker,
    guideRegion,
}: {
    mapStyle?: React.CSSProperties;
    mapCenter?: LocationType | undefined;
    myLocation?: MyLocationType;
    regions?: RegionMarkerType;
    rentalLocation?: RentalLocationType;
    guideMarker?: GuideMarker | undefined;
    guideRegion?: GuideRegion | undefined;
}) {
    const [currentLocation, setCurrentLocation] = useState<LocationType>();
    const [center, setCenter] = useState<LocationType>({
        lat: mapCenter?.lat || 35.89234191639668,
        lng: mapCenter?.lng || 128.59942253423995,
    });

    useEffect(() => {
        mapCenter !== undefined && setCenter(mapCenter);
    }, [mapCenter]);

    const displayRegions = (regions: RegionMarkerType) => {
        return regions?.list.map((region: TypeDTO.RentRegionDto, index: number) => (
            <div key={region.rentRegionId}>
                <CustomOverlayMap
                    key={region.rentRegionId}
                    position={
                        region.rentRegionName === "서울" ? { lat: 37.459882, lng: 126.951905 } : { lat: 35.89234191639668, lng: 128.59942253423995 }
                    }
                    clickable={true}
                >
                    <div
                        style={{
                            backgroundImage:
                                regions.selected !== undefined && regions.selected === region.rentRegionId
                                    ? `url(${IconSelectedMarker})`
                                    : `url(${IconMarker})`,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "cover",
                            width: "30px",
                            height: "37px",
                        }}
                        onClick={() => handleClickMarker(region.rentRegionId)}
                    >
                        <p style={{ color: "white", paddingTop: "4px" }} className="text-center">
                            {region.rentCars.length}
                        </p>
                    </div>
                </CustomOverlayMap>
            </div>
        ));
    };

    const displayMyLocation = () => {
        return (
            currentLocation?.lat !== undefined &&
            currentLocation?.lng !== undefined && (
                <>
                    <CustomOverlayMap
                        position={{
                            lat: currentLocation?.lat || 35.8886283,
                            lng: currentLocation?.lng || 128.6005226,
                        }}
                    >
                        <div className={styles.myLocationShadow} />
                    </CustomOverlayMap>
                    <CustomOverlayMap
                        position={{
                            lat: currentLocation?.lat || 35.8886283,
                            lng: currentLocation?.lng || 128.6005226,
                        }}
                    >
                        <div className={styles.myLocationDot} />
                    </CustomOverlayMap>
                </>
            )
        );
    };

    const displayRentalLocation = (rentalLocation: RentalLocationType) => {
        const region = rentalLocation.list;
        if (region !== undefined) {
            return (
                <>
                    <MobileView className="mobile">
                        {guideRegion !== undefined &&
                            guideRegion.map((region, index) => {
                                return (
                                    <Polygon
                                        key={index}
                                        path={guideRegion}
                                        strokeWeight={1.4}
                                        strokeStyle="solid"
                                        strokeOpacity={1}
                                        strokeColor="#006CFA"
                                        fillColor="#006CFA"
                                        fillOpacity={0.04}
                                    />
                                );
                            })}
                        {guideMarker !== undefined &&
                            guideMarker.map((marker, index) => {
                                return (
                                    <MapMarker
                                        key={index}
                                        position={{ lat: marker.facilityLatitude, lng: marker.facilityLongitude }}
                                        image={{
                                            src: marker.facilityType === "toilet" ? IconToiletMarker : IconChargingStationMarker,
                                            size: {
                                                width: 30,
                                                height: 37,
                                            },
                                        }}
                                    />
                                );
                            })}
                        <CustomOverlayMap position={{ lat: region.latitude, lng: region.longitude }} clickable={true}>
                            <img src={IconMapMarker} width={30} onClick={() => region.rentRegionId && handleClickMarker(region.rentRegionId)} />
                        </CustomOverlayMap>
                    </MobileView>
                    <BrowserView className="browser">
                        <MapMarker
                            clickable={false}
                            position={{ lat: region.latitude, lng: region.longitude }}
                            image={{
                                src: IconMapMarker,
                                size: {
                                    width: 30,
                                    height: 37,
                                },
                            }}
                        />
                        {guideRegion !== undefined &&
                            guideRegion.map((region, index) => {
                                return (
                                    <Polygon
                                        key={index}
                                        path={guideRegion}
                                        strokeWeight={1.4}
                                        strokeStyle="solid"
                                        strokeOpacity={1}
                                        strokeColor="#006CFA"
                                        fillColor="#006CFA"
                                        fillOpacity={0.04}
                                    />
                                );
                            })}
                        {guideMarker !== undefined &&
                            guideMarker.map((marker, index) => {
                                return (
                                    <MapMarker
                                        clickable={false}
                                        key={index}
                                        position={{ lat: marker.facilityLatitude, lng: marker.facilityLongitude }}
                                        image={{
                                            src: marker.facilityType === "toilet" ? IconToiletMarker : IconChargingStationMarker,
                                            size: {
                                                width: 30,
                                                height: 37,
                                            },
                                        }}
                                    />
                                );
                            })}
                        {guideMarker === undefined && (
                            <CustomOverlayMap position={{ lat: region.latitude, lng: region.longitude }} yAnchor={1.5}>
                                <div
                                    style={{
                                        borderRadius: "2px",
                                    }}
                                    onClick={() => region.rentRegionId && handleClickMarker(region.rentRegionId)}
                                >
                                    <p style={{ background: "#FFFFFF", borderRadius: "2px 2px 0 0" }} className="text-center p-1">
                                        {region.rentRegionDescription}
                                    </p>
                                    <p style={{ background: "#f5f5f5" }} className="text-center p-1">
                                        {region.address}
                                    </p>
                                    <div
                                        style={{
                                            margin: "auto",
                                            width: "10px",
                                            height: "10px",
                                            left: "calc(50% - 10px/2 + 10px)",
                                            bottom: "168px",
                                            transform: "rotate(-180deg)",
                                            borderLeft: " 10px solid transparent",
                                            borderRight: "10px solid transparent",
                                            borderBottom: "10px solid rgba(255, 255, 255, 0.8)",
                                        }}
                                    ></div>
                                </div>
                            </CustomOverlayMap>
                        )}
                    </BrowserView>
                </>
            );
        }

        return <></>;
    };

    const handleClickMarker = (key: number) => {
        if (regions?.clickEvent !== undefined) {
            regions.clickEvent(key);
        }
    };

    const handleCenterChanged = useCallback(
        (map: kakao.maps.Map) => {
            const newCenter = {
                lat: map.getCenter().getLat(),
                lng: map.getCenter().getLng(),
            };

            if (newCenter.lat !== center.lat || newCenter.lng !== center.lng) {
                setCenter(newCenter);
            }
        },
        [center]
    );

    return (
        <Map center={center} isPanto={true} style={mapStyle} scrollwheel={true} onCenterChanged={(map) => handleCenterChanged(map)}>
            {regions !== undefined && displayRegions(regions)}
            {myLocation?.show && displayMyLocation()}
            {rentalLocation !== undefined && displayRentalLocation(rentalLocation)}
        </Map>
    );
}

export default KakaoMap;
