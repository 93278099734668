import React, { useEffect } from "react";
import { Button, Modal } from "antd";

import * as Utils from "../../utils/utils";
import * as String from "../../commons/string";

import engineOffError from "../../assets/images/digitalKeyController/engineOffError.svg";

function ModalCarState({
    carState,
    openModalCarState,
    onChangedOpenModalCarState,
}: {
    carState?: string;
    openModalCarState: boolean;
    onChangedOpenModalCarState: (opened: boolean) => void;
}) {
    useEffect(() => {
        openModalCarState ? Utils.addModalStack() : Utils.popModalStack();
    }, [openModalCarState]);

    return (
        <Modal
            className="confirmModal"
            centered
            key="modalCarState"
            open={openModalCarState}
            closable={false}
            onCancel={() => onChangedOpenModalCarState(false)}
            footer={[
                <Button block type="primary" key="back" onClick={() => onChangedOpenModalCarState(false)}>
                    {String.confirm}
                </Button>,
            ]}
        >
            <img src={engineOffError} alt="image of engine off error" width="90px" />
            <div>{"기어 상태를 P로 변경한 다음\n 시동을 꺼주세요."}</div>
        </Modal>
    );
}

export default ModalCarState;
