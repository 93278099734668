import React, { useEffect, useState } from "react";
import { Select } from "antd";
import { useAsyncAxios, utilAxiosWithAppToken } from "../../utils/customAxios";

import * as Request from "../../commons/request";
import * as TypeDTO from "../../commons/typeDTO";
import * as String from "../../commons/string";

import styles from "./Search.module.css";
import IconMap from "../../assets/images/home/rent/icon_map.png";

const SelectRegion = ({
    className,
    value,
    disabled,
    isOpen,
    onChange,
    onChangeRegion,
}: {
    className?: string;
    value?: number;
    disabled?: boolean;
    isOpen?: boolean;
    onChange?: (name: number) => void;
    onChangeRegion?: (region?: TypeDTO.RentRegionDto) => void;
}) => {
    const { Option } = Select;
    const [regions, setRegions] = useState<Array<TypeDTO.RentRegionDto>>();

    useEffect(() => {
        requestGetRegions();
    }, []);

    const requestAxiosGetRegions = async () => {
        const response = await utilAxiosWithAppToken().get(Request.RENT_REGIONS_URL);
        return response.data;
    };

    const {
        loading: loadingGetRegions,
        error: errorGetRegions,
        data: resultGetRegions,
        execute: requestGetRegions,
    } = useAsyncAxios(requestAxiosGetRegions);

    useEffect(() => {
        if (!resultGetRegions) return;

        setRegions(resultGetRegions.rentRegions);
    }, [resultGetRegions]);

    useEffect(() => {
        if (!errorGetRegions) return;

        console.log("errorGetRegions", errorGetRegions);
    }, [errorGetRegions]);

    return (
        <Select
            className={`${styles.select} ${styles.selectRegion} custom-select w-100 ${className}`}
            onChange={(value) => {
                onChange && onChange(value);
                onChangeRegion && onChangeRegion(regions?.find((region) => region.rentRegionId === value));
            }}
            suffixIcon={<img src={IconMap} width={14} height={14}></img>}
            value={value}
            optionFilterProp="children"
            disabled={disabled}
            placeholder={<p style={{ marginLeft: "18px" }}>{String.region}</p>}
            defaultOpen={isOpen}
        >
            <Option value={0}>{String.all}</Option>
            {regions
                ?.filter((option: any) => !option.b2b)
                .map((option: any) => (
                    <Option key={option.rentRegionId} value={Number(option.rentRegionId)}>
                        {option.rentRegionName}
                    </Option>
                ))}
        </Select>
    );
};

export default SelectRegion;
