import * as TypeDTO from "../../commons/typeDTO";
import * as Common from "../../commons/common";

import IconBadge from "../../assets/images/home/rent/icon_badge.svg";
import IconSpeechBubble from "../../assets/images/home/rent/icon_speech_bubble.svg";

function ReviewBadge({ boardArticle }: { boardArticle: TypeDTO.BoardArticleDto }) {
    return (
        <>
            <img src={boardArticle.board.boardId === Common.BEST_REVIEW_BOARD_ID || boardArticle.fixed ? IconBadge : IconSpeechBubble || IconBadge} />
            <span>{boardArticle.extraInfo2 === "리뷰게시판" ? "리뷰" : boardArticle.extraInfo2 || "리뷰"}</span>
        </>
    );
}

export default ReviewBadge;
