import * as React from "react";

import Sheet from "react-modal-sheet";

const CustomSheet = ({
    isOpen,
    content,
    className,
    snapHeight,
    onClose,
}: {
    isOpen: boolean;
    content: any;
    className?: string;
    snapHeight?: number;
    onClose: () => void;
}) => {
    return (
        <Sheet
            className={`${className} custom-sheet`}
            isOpen={isOpen}
            onClose={onClose}
            style={{ zIndex: 999 }}
            snapPoints={[snapHeight || 700, 0]}
            initialSnap={0}
        >
            <Sheet.Container>
                <Sheet.Header></Sheet.Header>
                <Sheet.Content>{content}</Sheet.Content>
            </Sheet.Container>
        </Sheet>
    );
};

export default CustomSheet;
