import React, { useState, useEffect, useCallback, useRef } from "react";
import { Row, Col, Card, Button, Checkbox, Form, Input, Radio, RadioChangeEvent, Divider } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { useAlert, AlertType } from "../../provider/AlertProvider";
import { useAsyncAxios, utilAxiosWithAppToken } from "../../utils/customAxios";
import AddressMaker, { AddressType } from "../../component/AddressMaker";

import * as TypeDTO from "../../commons/typeDTO";
import * as Request from "../../commons/request";
import * as String from "../../commons/string";
import * as Common from "../../commons/common";

import InputPassword from "../../component/InputPassword";
import InputEmail from "../../component/InputEmail";
import PhoneCertification from "../../component/PhoneCertification";
import DriverLicense from "../rent/DriverLicense";

type pathAfterSignUp = {
    nextPath?: string;
    pathAfterLogin?: string;
};
type Mode = "view" | "update" | "register";
interface ChildProps {
    mode?: Mode;
    driverLicenseId?: number;
    onSuccess?: () => void;
    onFailed?: () => void;
    onRequest?: (email?: string | undefined) => void;
    isValid?: () => boolean;
}

function SignUp() {
    const navigate = useNavigate();
    const location = useLocation();
    const alert = useAlert();
    const [form] = Form.useForm();
    const [form2] = Form.useForm();
    const driverLicenseRef = useRef<ChildProps>(null);
    const { TextArea } = Input;

    const [agreeAllTerms, setAgreeAllTerms] = useState(false);
    const [agreeTosTerms, setAgreeTosTerms] = useState(false);
    const [agreePrivacPolicyTerms, setAgreePrivacPolicyTerms] = useState(false);

    const [nextPath, setNextPath] = useState<string>();
    const [pathAfterLogin, setPathAfterLogin] = useState<string>();

    const [member, setMember] = useState("INDIVIDUAL");
    const [userName, setUserName] = useState("");
    const [certificationResult, setCertificationResult] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState("");
    const [userAddress, setUserAddress] = useState<AddressType>();
    const [disableInputName, setDisableInputName] = useState(false);
    const [email, setEmail] = useState("");

    useEffect(() => {
        const pathAfter = location.state as pathAfterSignUp;

        if (pathAfter) {
            setNextPath(pathAfter.nextPath);
            setPathAfterLogin(pathAfter.pathAfterLogin);
        }
    }, [location]);

    useEffect(() => {
        form.getFieldValue("phone") && form.validateFields(["phone"]);
    }, [certificationResult]);

    useEffect(() => {
        if (email.includes(" ")) {
            const newEmail = email.replace(/\s/g, "");
            form.setFieldsValue({
                email: newEmail,
            });
            setEmail(newEmail);
        }
    }, [email]);

    const requestAxiosAddUser = async (data: TypeDTO.SignupDto) => {
        const response = await utilAxiosWithAppToken().post(Request.SIGNUP_URL, data);

        return response.data;
    };

    const { loading: loadingAddUser, error: errorAddUser, data: resultAddUser, execute: requestAddUser } = useAsyncAxios(requestAxiosAddUser);

    useEffect(() => {
        if (!resultAddUser) return;

        if (location.state?.nextPath !== Common.PAGE_EVENT) {
            driverLicenseRef.current?.onRequest?.(resultAddUser.user.email);
        } else {
            nextPath
                ? navigate(nextPath, { replace: true, state: { phoneNumber: phoneNumber } })
                : navigate(Common.PAGE_LOGIN, { replace: true, state: pathAfterLogin ? pathAfterLogin : Common.PAGE_HOME });

            alert.setAlert(AlertType.SUCCESS, "회원가입 성공", "회원가입을 완료하였습니다.");
        }
    }, [resultAddUser]);

    useEffect(() => {
        if (!errorAddUser) return;

        console.log("errorUser", errorAddUser);
        alert.setAlert(errorAddUser.response?.data?.code, "회원가입 실패", errorAddUser.response?.data?.message);
    }, [errorAddUser]);

    const checkIndividualType = () => {
        if (member === "INDIVIDUAL") return true;
        return false;
    };

    const validateCompanyRegistrationNo = useCallback((_: any, value: string) => {
        const valueMap = value
            .replace(/-/gi, "")
            .split("")
            .map((item) => {
                return parseInt(item, 10);
            });

        if (valueMap.length !== 10) {
            return Promise.reject(new Error(String.msg_company_registration_no_rule));
        }
        const multiply = new Array<number>(1, 3, 7, 1, 3, 7, 1, 3, 5);
        let checksum = 0;

        for (let i = 0; i < multiply.length; ++i) {
            checksum += multiply[i] * valueMap[i];
        }
        checksum += Math.floor((multiply[8] * valueMap[8]) / 10);

        if (Math.floor(valueMap[9]) === (10 - (checksum % 10)) % 10) {
            return Promise.resolve();
        } else {
            return Promise.reject(new Error(String.msg_company_registration_no_rule));
        }
    }, []);

    const onChangedMemberType = (e: RadioChangeEvent) => {
        console.log("Received values of form: ", e.target.value);
        setMember(e.target.value);
    };

    const onCancel = () => {
        nextPath
            ? navigate(nextPath, { replace: true })
            : navigate(Common.PAGE_LOGIN, { replace: true, state: pathAfterLogin ? pathAfterLogin : Common.PAGE_HOME });
    };

    const onFinish = (values: any) => {
        if (location.state?.nextPath !== Common.PAGE_EVENT && !driverLicenseRef.current?.isValid?.()) return;

        delete values["confirm"];
        delete values["fullAddress"];
        const data: TypeDTO.SignupDto = {
            companyName: "",
            ...values,
            phone: phoneNumber,
            address: userAddress?.address,
            addressDetails: userAddress?.addressDetails,
            promotionCode: form2.getFieldValue("promotionCode"),
        };

        requestAddUser(data);
    };

    const handleToggleAllTerm = (checked: boolean) => {
        setAgreeAllTerms(checked);

        if (checked) {
            setAgreeTosTerms(true);
            setAgreePrivacPolicyTerms(true);
        } else {
            setAgreeTosTerms(false);
            setAgreePrivacPolicyTerms(false);
        }
    };

    const handleToggleTosTerm = (checked: boolean) => {
        setAgreeTosTerms(checked);

        if (checked && agreePrivacPolicyTerms) {
            setAgreeAllTerms(true);
        } else {
            setAgreeAllTerms(false);
        }
    };

    const handleTogglePrivacPolicyTerm = (checked: boolean) => {
        setAgreePrivacPolicyTerms(checked);

        if (checked && agreeTosTerms) {
            setAgreeAllTerms(true);
        } else {
            setAgreeAllTerms(false);
        }
    };

    const handleCertificationResult = (success: boolean, phoneNumber: string) => {
        setPhoneNumber(phoneNumber);
        setCertificationResult(success);
        setDisableInputName(success);
    };

    const validatePwd = useCallback((_: any, value: string) => {
        const pwd_regExp = Common.PASSWORD_REGEXP;

        if (value && !pwd_regExp.test(value)) {
            return Promise.reject(new Error(String.msg_pwd_rule));
        }
        return Promise.resolve();
    }, []);

    const onSuccess = () => {
        nextPath
            ? navigate(nextPath, { replace: true, state: { phoneNumber: phoneNumber } })
            : navigate(Common.PAGE_LOGIN, { replace: true, state: pathAfterLogin ? pathAfterLogin : Common.PAGE_HOME });

        alert.setAlert(AlertType.SUCCESS, "회원가입 성공", "회원가입을 완료하였습니다.");
    };

    const onInputName = (event: React.FormEvent<HTMLInputElement>) => {
        if ((event.nativeEvent as InputEvent).inputType === "deleteContentBackward") return;
        (event.target as HTMLInputElement).value = (event.target as HTMLInputElement).value.replace(/\s/g, "");
    };

    return (
        <>
            <div className="header-container">
                <div className="container">
                    <div className="row text-center justify-content-center">
                        <div className="fadeIn mb-4">
                            <h2 className="fs-title">통합 회원 가입</h2>
                        </div>
                        <p>
                            통합 회원 가입으로 RUTA40의 모든 서비스를
                            <br /> 한 번에 이용하실수 있습니다.
                        </p>
                    </div>
                </div>
            </div>
            <Row className="login pb-4" align="middle" justify="center">
                <div className="loginCard">
                    <Card bordered={false}>
                        <Checkbox className="w-100 mb-3 b-bd b-lg" checked={agreeAllTerms} onChange={(e) => handleToggleAllTerm(e.target.checked)}>
                            전체 약관에 동의합니다.
                        </Checkbox>
                        <Checkbox className="ms-0 mb-1" checked={agreeTosTerms} onChange={(e) => handleToggleTosTerm(e.target.checked)}>
                            이용 약관에 동의합니다.
                        </Checkbox>
                        <TextArea className="mb-3" disabled value={String.content_tos} rows={4} />
                        <Checkbox
                            className="ms-0 mb-1"
                            checked={agreePrivacPolicyTerms}
                            onChange={(e) => handleTogglePrivacPolicyTerm(e.target.checked)}
                        >
                            개인정보처리방침 약관에 동의합니다.
                        </Checkbox>
                        <TextArea className="mb-4" disabled value={String.content_privacy} rows={4} />
                        <Form
                            id="signUpForm"
                            layout="vertical"
                            form={form}
                            onFinish={onFinish}
                            disabled={!agreeAllTerms}
                            scrollToFirstError={{ block: "center", scrollMode: "always" }}
                        >
                            <Form.Item required label="계정 타입" name="userType" initialValue={member}>
                                <Radio.Group onChange={onChangedMemberType}>
                                    <Radio value="INDIVIDUAL">개인 회원</Radio>
                                    <Radio value="CORPORATE">법인 회원</Radio>
                                </Radio.Group>
                            </Form.Item>
                            <Form.Item
                                name="name"
                                label={checkIndividualType() ? "이름" : "이름(대표자)"}
                                initialValue={userName}
                                rules={[
                                    {
                                        required: true,
                                        message: "이름을 입력해 주세요.",
                                    },
                                    { max: 50, message: String.msg_name_max },
                                ]}
                            >
                                <Input
                                    className="login-input"
                                    placeholder="휴대전화 명의자 이름을 입력해 주세요."
                                    disabled={disableInputName}
                                    onChange={(e) => setUserName(e.target.value)}
                                    onInput={onInputName}
                                />
                            </Form.Item>

                            <Form.Item
                                name="phone"
                                label="휴대전화 번호"
                                required={true}
                                rules={[
                                    () => ({
                                        validator(_, value) {
                                            if (certificationResult) {
                                                return Promise.resolve();
                                            }

                                            return Promise.reject(new Error("휴대전화 본인 인증을 해 주세요."));
                                        },
                                    }),
                                ]}
                            >
                                <PhoneCertification userName={userName} onChangedResult={handleCertificationResult} />
                            </Form.Item>

                            {checkIndividualType() === false ? (
                                <Form.Item
                                    rules={[
                                        {
                                            required: true,
                                            message: "회사명을 입력해 주세요.",
                                        },
                                    ]}
                                    name="companyName"
                                    label="회사명"
                                >
                                    <Input className="login-input" placeholder="회사명" />
                                </Form.Item>
                            ) : null}

                            {checkIndividualType() === false ? (
                                <Form.Item
                                    name="companyRegistrationNo"
                                    label="사업자 등록번호"
                                    rules={[
                                        {
                                            required: true,
                                            message: String.msg_company_registration_no_must,
                                        },
                                        {
                                            pattern: new RegExp(/^([-]?[1-9][0-9]*|0)$/),
                                            message: String.msg_company_registration_no_only_number,
                                        },
                                        {
                                            validator: validateCompanyRegistrationNo,
                                        },
                                    ]}
                                >
                                    <Input className="login-input" placeholder="사업자 등록번호(- 미포함)" />
                                </Form.Item>
                            ) : null}

                            <Form.Item
                                name="email"
                                label="이메일"
                                rules={[
                                    {
                                        type: "email",
                                        message: "이메일이 유효하지 않습니다.",
                                    },
                                    {
                                        required: true,
                                        message: "이메일을 입력해 주세요.",
                                    },
                                    { max: 50, message: String.msg_name_max },
                                ]}
                            >
                                <InputEmail value={email} onChange={(value) => setEmail(value)} disabled={!agreeAllTerms}></InputEmail>
                            </Form.Item>

                            <Form.Item
                                name="password"
                                label="비밀번호"
                                rules={[
                                    {
                                        required: true,
                                        message: String.msg_pwd_must,
                                    },

                                    { validator: validatePwd },
                                    { max: 20, message: String.msg_pwd_max },
                                ]}
                                hasFeedback
                                className="mb-2"
                            >
                                <InputPassword />
                            </Form.Item>

                            <Form.Item
                                name="confirm"
                                dependencies={["password"]}
                                hasFeedback
                                rules={[
                                    {
                                        required: true,
                                        message: "비밀번호를 확인해 주세요.",
                                    },
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            if (!value || getFieldValue("password") === value) {
                                                return Promise.resolve();
                                            }

                                            return Promise.reject(new Error("입력한 비밀번호가 일치하지 않습니다."));
                                        },
                                    }),
                                ]}
                            >
                                <InputPassword type={"confirm"} />
                            </Form.Item>

                            {location.state?.nextPath !== Common.PAGE_EVENT && (
                                <Form.Item
                                    className="login-group"
                                    name="fullAddress"
                                    label="주소"
                                    required={true}
                                    rules={[
                                        () => ({
                                            validator(_, value) {
                                                if (value.address && value.address !== "" && value.addressDetails && value.addressDetails !== "") {
                                                    return Promise.resolve();
                                                }

                                                return Promise.reject(new Error("주소를 입력해 주세요."));
                                            },
                                        }),
                                    ]}
                                >
                                    <AddressMaker value={userAddress} onChange={(address) => setUserAddress(address)} />
                                </Form.Item>
                            )}
                        </Form>
                        {location.state?.nextPath !== Common.PAGE_EVENT && (
                            <>
                                <Divider />
                                <h6 className="mb-0">추가 정보(선택)</h6>
                                <p>회원 가입 완료 후 [계정] &gt; [계정정보]에서도 입력할 수 있습니다.</p>
                                <DriverLicense ref={driverLicenseRef} mode="register" onSuccess={onSuccess} disabled={!agreeAllTerms} />

                                <Form form={form2} layout="vertical" disabled={!agreeAllTerms}>
                                    <Form.Item name="promotionCode" label="단체 회원 코드">
                                        <Input className="login-input" placeholder="단체 회원 코드" allowClear />
                                    </Form.Item>
                                </Form>
                            </>
                        )}
                        <Row>
                            <Col className="pe-1" span={12}>
                                <Button block type="default" size="large" disabled={false} onClick={() => onCancel()}>
                                    {String.cancel}
                                </Button>
                            </Col>
                            <Col className="ps-1" span={12}>
                                <Button
                                    block
                                    form="signUpForm"
                                    type="primary"
                                    size="large"
                                    htmlType="submit"
                                    disabled={!agreeAllTerms}
                                    style={{ backgroundColor: agreeAllTerms ? "#001236" : "#f5f5f5" }}
                                >
                                    회원가입
                                </Button>
                            </Col>
                        </Row>
                    </Card>
                </div>
            </Row>
        </>
    );
}

export default SignUp;
