import React, { useEffect, useState, useCallback } from "react";
import { Button, Input, Modal, Result, Form } from "antd";
import { useAuthDispatch } from "../provider/AuthProvider";
import { useNavigate } from "react-router-dom";
import { useAsyncAxios, utilAxiosWithAppToken } from "../utils/customAxios";
import { AlertType, useAlert } from "../provider/AlertProvider";

import PhoneCertification from "./PhoneCertification";
import InputPassword from "./InputPassword";

import * as TypeDTO from "../commons/typeDTO";
import * as Request from "../commons/request";
import * as String from "../commons/string";
import * as Common from "../commons/common";
import * as Utils from "../utils/utils";

function ModalResetPassword({ open, onChangedOpen }: { open: boolean; onChangedOpen: (opened: boolean) => void }) {
    const alert = useAlert();
    const dispatch = useAuthDispatch();
    const navigate = useNavigate();

    const [form] = Form.useForm();
    const [userName, setUserName] = useState("");
    const [certificationResult, setCertificationResult] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState("");
    const [result, setResult] = useState("");

    const [disableInputName, setDisableInputName] = useState(false);

    const [email, setEmail] = useState<string>("");

    useEffect(() => {
        Utils.onEvent(Common.EVENT_CLOSE_ALL_MODAL, handleClose);
        return () => {
            Utils.offEvent(Common.EVENT_CLOSE_ALL_MODAL, handleClose);
        };
    }, []);

    useEffect(() => {
        if (open) Utils.addModalStack();
        else Utils.popModalStack();
    }, [open]);

    useEffect(() => {
        form.getFieldValue("phone") && form.validateFields(["phone"]);
    }, [certificationResult])

    const requestResetPassword = async (url: string, option: Record<string, string>) => {
        const response = await utilAxiosWithAppToken().post(url, option);
        return response.data;
    };

    const { loading, error: errorResetPassword, data: resultResetPassword, execute: resetPassword } = useAsyncAxios(requestResetPassword);

    useEffect(() => {
        if (resultResetPassword === null) return;

        console.log("resultFindEmail", resultResetPassword);
        handleClose();

        if (dispatch !== null) dispatch({ type: "LOGOUT" });

        // sessionStorage.setItem(
        //     Common.SESSION_AFTER_LOGIN_PATH,
        //     Common.PAGE_HOME
        // );
        // navigate(Common.PAGE_LOGIN, { replace: true });
        navigate(Common.PAGE_LOGIN, { replace: true, state: Common.PAGE_HOME });

        alert.setAlert(AlertType.SUCCESS, "비밀번호 재설정 성공", "비밀번호를 재설정하였습니다.");
    }, [resultResetPassword]);

    useEffect(() => {
        if (errorResetPassword === null) return;

        alert.setAlert(errorResetPassword.response?.data?.code, "비밀번호 재설정 실패", errorResetPassword.response?.data?.message);
    }, [errorResetPassword]);

    const actionResetPassword = async (data: TypeDTO.ResetPasswordDto) => {
        await resetPassword(Request.RESET_PASSWORD_URL, data);
    };

    const onFinish = (values: any) => {
        delete values["confirm"];
        delete values["name"];
        const data: TypeDTO.ResetPasswordDto = {
            ...values,
            phone: phoneNumber,
        };

        console.log("Received values of form: ", data);
        actionResetPassword(data);
    };

    const handleCertificationResult = (success: boolean, phoneNumber: string) => {
        setPhoneNumber(phoneNumber);
        setCertificationResult(success);

        setDisableInputName(success);
    };

    const handleClose = () => {
        onChangedOpen(false);
    };

    const validatePwd = useCallback((_: any, value: string) => {
        const pwd_regExp = Common.PASSWORD_REGEXP;

        if (!pwd_regExp.test(value)) {
            return Promise.reject(new Error(String.msg_pwd_rule));
        }
        return Promise.resolve();
    }, []);

    useEffect(() => {
        if (email.includes(" ")) {
            const newEmail = email.replace(/\s/g, "");
            form.setFieldsValue({
                email: newEmail,
            });
            setEmail(newEmail);
        }
    }, [email]);

    return (
        <Modal
            centered
            closable={false}
            className="login"
            open={open}
            title="비밀번호 재설정"
            onOk={handleClose}
            onCancel={handleClose}
            footer={[
                <Button key="back" onClick={handleClose}>
                    {String.cancel}
                </Button>,
                <Button key="setPassword" type="primary" onClick={() => form.submit()}>
                    {String.reset}
                </Button>,
            ]}
        >
            <Form form={form} onFinish={onFinish} layout="vertical">
                <Form.Item
                    name="email"
                    label={String.email}
                    rules={[
                        {
                            type: "email",
                            message: String.msg_email_must,
                        },
                        {
                            required: true,
                            message: "이메일을 입력해 주세요.",
                        },
                    ]}
                >
                    <Input
                        className="login-input"
                        placeholder={String.email}
                        value={email}
                        onInput={(e) => ((e.target as HTMLInputElement).value = (e.target as HTMLInputElement).value.toLowerCase())}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </Form.Item>
                <Form.Item
                    name="name"
                    label={String.name}
                    initialValue={userName}
                    rules={[
                        {
                            required: true,
                            message: String.msg_name_must,
                        },
                    ]}
                >
                    <Input
                        className="login-input"
                        placeholder="휴대전화 명의자 이름을 입력해 주세요."
                        disabled={disableInputName}
                        onChange={(e) => setUserName(e.target.value)}
                    />
                </Form.Item>

                <Form.Item
                    name="phone"
                    label={String.phone}
                    required={true}
                    rules={[
                        () => ({
                            validator(_, value) {
                                if (certificationResult) {
                                    return Promise.resolve();
                                }

                                return Promise.reject(new Error("휴대전화 본인 인증을 해 주세요."));
                            },
                        }),
                    ]}
                >
                    <PhoneCertification userName={userName} onChangedResult={handleCertificationResult} />
                </Form.Item>

                {/* {userName.length > 0 ? (
                    <Form.Item
                        name='phone'
                        label='휴대전화 번호'
                        required={true}
                        rules={[
                            () => ({
                                validator(_, value) {
                                    if (certificationResult) {
                                        return Promise.resolve();
                                    }

                                    return Promise.reject(
                                        new Error(
                                            "휴대전화 본인 인증을 해 주세요."
                                        )
                                    );
                                },
                            }),
                        ]}
                    >
                        <PhoneCertification
                            userName={userName}
                            onChangedResult={handleCertificationResult}
                        />
                    </Form.Item>
                ) : null} */}

                {certificationResult && (
                    <>
                        <Form.Item
                            name="password"
                            label={String.password}
                            rules={[
                                {
                                    required: true,
                                    message: String.msg_pwd_must,
                                },

                                { validator: validatePwd },
                                { max: 20, message: String.msg_pwd_max },
                            ]}
                            hasFeedback
                        >
                            {/* <Input.Password className='login-input' /> */}
                            <InputPassword />
                        </Form.Item>

                        <Form.Item
                            name="confirm"
                            label="비밀번호 확인"
                            dependencies={["password"]}
                            hasFeedback
                            rules={[
                                {
                                    required: true,
                                    message: "비밀번호를 확인해 주세요.",
                                },
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if (!value || getFieldValue("password") === value) {
                                            return Promise.resolve();
                                        }

                                        return Promise.reject(new Error("입력한 비밀번호가 일치하지 않습니다."));
                                    },
                                }),
                            ]}
                        >
                            {/* <Input.Password className='login-input' /> */}
                            <InputPassword />
                        </Form.Item>
                    </>
                )}
            </Form>
            {result.length > 0 && <Result status="success" title={result} />}
        </Modal>
    );
}

export default ModalResetPassword;
