import React, { useState, useEffect } from "react";
import { Divider, Space } from "antd";
import { NumericFormat } from "react-number-format";
import { BrowserView, MobileView } from "react-device-detect";

import * as Common from "../../../commons/common";
import * as String from "../../../commons/string";
import * as TypeUtils from "../../../commons/typeUtils";
import * as TypeDTO from "../../../commons/typeDTO";
import * as Utils from "../../../utils/utils";

type priceType = {
    diffDays: number;
    price: number;
    extraTimePrice: number;
    rentPaidItemsPrice?: Array<TypeDTO.RentPaidItemDto>;
    insurancePrice: number;
    couponPrice: number;
    totalPrice: number;
    userCouponDiscounted: number;
    multiCouponDiscounted: number;
};

type DiscountType = {
    rent: number;
    other: number;
};

const calculateCouponPrice = (
    rentCar: TypeDTO.RentCarDto | undefined,
    coupon: TypeDTO.CouponDto | undefined,
    rentPaidItems: TypeDTO.RentPaidItemDto[] | undefined,
    weekdays: number,
    weekends: number,
    discounted: DiscountType
): DiscountType => {
    const defaultWeekdayPrice = 199000;
    const defaultWeekendPrice = 249000;
    const weekendPrice = rentCar?.weekendDiscountPrice ?? rentCar?.weekendPrice ?? defaultWeekendPrice;
    const weekPrice = rentCar?.discountPrice ?? rentCar?.price ?? defaultWeekdayPrice;
    const remainingDaysPrice = coupon?.remainingDaysPrice;
    const remainingDaysWeekendPrice = coupon?.remainingDaysWeekendPrice;

    let effectiveDays = coupon?.effectiveDays || 0;
    let rentDiscounted = discounted.rent;
    let otherDiscounted = discounted.other;

    if (effectiveDays > 0 && coupon?.couponType === "DAYS") {
        if (coupon?.weekendAvailable && weekends > 0) {
            const discountedWeekendDays = Math.min(effectiveDays, weekends);
            effectiveDays = effectiveDays - discountedWeekendDays;

            rentDiscounted +=
                (weekendPrice - (coupon?.weekendDiscountedPrice === undefined ? weekendPrice : coupon?.weekendDiscountedPrice)) *
                    discountedWeekendDays +
                (weekendPrice - (remainingDaysWeekendPrice || weekendPrice)) * Math.max(weekends - discountedWeekendDays, 0);
        }

        if (coupon?.weekdayAvailable && weekdays > 0) {
            const discountedWeekDays = Math.min(effectiveDays, weekdays);
            rentDiscounted +=
                (weekPrice - (coupon?.weekdayDiscountedPrice === undefined ? weekPrice : coupon?.weekdayDiscountedPrice)) * discountedWeekDays +
                (weekPrice - (remainingDaysPrice || weekPrice)) * Math.max(weekdays - discountedWeekDays, 0);
        }
    }

    if (coupon?.couponType === "TOTAL") {
        if (coupon?.weekendAvailable && weekends > 0) {
            const discount = coupon.discountPrice ?? coupon.weekendDiscountedPrice;
            rentDiscounted +=
                coupon.discountUnit === "PERCENT"
                    ? (((rentCar?.weekendDiscountPrice ?? rentCar?.weekendPrice ?? defaultWeekendPrice) * weekends - rentDiscounted) * discount) / 100
                    : discount;
        }

        if (coupon?.weekdayAvailable && weekdays > 0) {
            const discount = coupon.discountPrice ?? coupon.weekdayDiscountedPrice;
            rentDiscounted +=
                coupon.discountUnit === "PERCENT"
                    ? (((rentCar?.discountPrice ?? rentCar?.price ?? defaultWeekdayPrice) * weekdays - rentDiscounted) * discount) / 100
                    : discount;
        }
    }

    if (coupon?.rentPaidItemId) {
        otherDiscounted += rentPaidItems?.find((item) => item.rentPaidItemId === coupon.rentPaidItemId)?.price ?? 0;
    }

    return {
        rent: rentDiscounted,
        other: otherDiscounted,
    };
};

const ReservationDetail = ({
    showTitle,
    value,
    rentPeakSeasons,
    onResult,
}: {
    showTitle?: boolean;
    value?: TypeUtils.ReservationType;
    rentPeakSeasons?: TypeDTO.RentPeakSeasonDto[];
    onResult?: (price: number, totalPrice: number, userCouponDiscounted: number, multiCouponDiscounted: number) => void;
}) => {
    const [price, setPrice] = useState<priceType>();
    const [defaultValue, setDefaultValue] = useState<TypeUtils.ReservationType>();

    useEffect(() => {
        setDefaultValue(value);
        if (value?.startDate && value?.endDate && JSON.stringify(defaultValue) !== JSON.stringify(value)) {
            const startDate = new Date(value.startDate);
            const endDate = new Date(value.endDate);
            const diffTime = Math.abs(startDate.getTime() - endDate.getTime());
            const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
            const { weekdays, weekends } = Utils.countDays(startDate, endDate, rentPeakSeasons);

            const price =
                (value.rentCar?.discountPrice ?? value.rentCar?.price ?? 0) * weekdays +
                (value.rentCar?.weekendDiscountPrice ?? value.rentCar?.weekendPrice ?? 0) * weekends;
            const extraTimePrice = value.extraTime?.price || 0;
            const rentPaidItemsPrice = value.rentPaidItems?.reduce((total, item) => total + item.price, 0) || 0;
            const insurancePrice = (value.insurance?.price || 0) * diffDays;

            const userCouponDiscounted = calculateCouponPrice(value.rentCar, value.coupon?.coupon, value.rentPaidItems, weekdays, weekends, {
                rent: 0,
                other: 0,
            });
            const discounted = calculateCouponPrice(
                value.rentCar,
                value.multiCoupon?.coupon,
                value.rentPaidItems,
                weekdays,
                weekends,
                userCouponDiscounted
            );
            const couponPrice =
                (discounted.rent > price ? price : discounted.rent) + (discounted.other > rentPaidItemsPrice ? rentPaidItemsPrice : discounted.other);
            const userCouponDiscountTotal = Math.min(userCouponDiscounted.rent + userCouponDiscounted.other, couponPrice);

            const totalPrice = price + extraTimePrice + rentPaidItemsPrice + insurancePrice - discounted.rent - discounted.other;

            setPrice({
                diffDays: diffDays,
                price: price,
                extraTimePrice: extraTimePrice,
                rentPaidItemsPrice: value.rentPaidItems,
                insurancePrice: insurancePrice,
                couponPrice: couponPrice,
                totalPrice: totalPrice < 0 ? 0 : totalPrice,
                userCouponDiscounted: userCouponDiscountTotal,
                multiCouponDiscounted: value.multiCoupon?.coupon.discountPrice ?? 0,
            });

            onResult !== undefined && onResult(price, totalPrice, userCouponDiscountTotal, value.multiCoupon?.coupon.discountPrice ?? 0);
        } else if (!value?.startDate && !value?.endDate) {
            setPrice({
                diffDays: 0,
                price: 0,
                extraTimePrice: 0,
                insurancePrice: 0,
                couponPrice: 0,
                totalPrice: 0,
                userCouponDiscounted: 0,
                multiCouponDiscounted: 0,
            });
        }
    }, [value, rentPeakSeasons]);

    return (
        <>
            <MobileView className="mobile">
                {showTitle && <h5 className="mb-2">{String.details}</h5>}
                <Space style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                    <p className="b-md">
                        {price?.diffDays !== undefined && price?.diffDays !== 0
                            ? `차량 대여료 ${price?.diffDays}박${price.diffDays + 1}일(기본요금)`
                            : "차량 대여료(기본 요금)"}
                    </p>
                    <p className="b-md">
                        <NumericFormat value={price?.price} thousandSeparator={true} displayType="text" suffix="원" />
                    </p>
                </Space>
                {value?.insurance?.insuranceName && (
                    <Space style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                        <p className="b-md">
                            {value?.insurance?.insuranceName}&nbsp;
                            {price?.diffDays !== undefined && price?.diffDays !== 0 && `${price?.diffDays}박${price.diffDays + 1}일`}
                        </p>
                        <p className="b-md">
                            <NumericFormat value={price?.insurancePrice} thousandSeparator={true} displayType="text" suffix="원" />
                        </p>
                    </Space>
                )}
                {price?.rentPaidItemsPrice
                    ?.filter(
                        (item) =>
                            value?.coupon?.coupon.rentPaidItemId !== item.rentPaidItemId &&
                            value?.multiCoupon?.coupon.rentPaidItemId !== item.rentPaidItemId
                    )
                    .map((item) => (
                        <Space key={item.rentPaidItemId} style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                            <p className="b-md">{item.rentPaidItemName}</p>
                            <p className="b-md">
                                <NumericFormat value={item.price} thousandSeparator={true} displayType="text" suffix="원" />
                            </p>
                        </Space>
                    ))}
                {value?.coupon && (
                    <Space
                        className="mt-2 text-danger"
                        style={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "start" }}
                    >
                        <p>{value.coupon.coupon.couponName}</p>
                        <p>
                            {!value.coupon.coupon.rentPaidItemId && "-"}
                            <NumericFormat
                                value={value.coupon.coupon.rentPaidItemId ? 0 : price?.userCouponDiscounted}
                                thousandSeparator={true}
                                displayType="text"
                                suffix="원"
                            />
                        </p>
                    </Space>
                )}
                {value?.multiCoupon && (
                    <Space
                        className={`${value?.coupon ? "" : "mt-2"} text-danger`}
                        style={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "start" }}
                    >
                        <p>{value.multiCoupon.coupon.couponName}</p>
                        <p>
                            {!value.multiCoupon.coupon.rentPaidItemId && "-"}
                            <NumericFormat
                                value={value.multiCoupon.coupon.rentPaidItemId ? 0 : price?.multiCouponDiscounted}
                                thousandSeparator={true}
                                displayType="text"
                                suffix="원"
                            />
                        </p>
                    </Space>
                )}
                {value?.cancelAmount ? (
                    <>
                        <Divider className="mt-2 mb-2" />
                        <Space style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                            <p>{String.totalRentalPrice}</p>
                            <p>
                                <NumericFormat value={price?.totalPrice} thousandSeparator={true} displayType="text" suffix="원" />
                            </p>
                        </Space>
                        <Space style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                            <p className="text-danger">위약금</p>
                            <div className="text-danger" style={{ marginLeft: "auto", display: "flex" }}>
                                <p className="text-danger">
                                    <NumericFormat
                                        value={price && price.totalPrice - value.cancelAmount}
                                        thousandSeparator={true}
                                        displayType="text"
                                        prefix="- "
                                        suffix="원"
                                    />
                                </p>
                            </div>
                        </Space>
                        <Space className="mt-3" style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                            <h6>{String.totalRefundPrice}</h6>
                            <h6>
                                <NumericFormat
                                    className="text-price"
                                    value={value.cancelAmount}
                                    thousandSeparator={true}
                                    displayType="text"
                                    suffix="원"
                                />
                            </h6>
                        </Space>
                    </>
                ) : (
                    <>
                        <Space className="mt-2" style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                            <h6 style={{ marginBottom: 0 }}>{String.totalRentalPrice}</h6>
                            <h6 style={{ marginBottom: 0, color: "rgb(0, 95, 219)" }}>
                                <NumericFormat
                                    className="text-price"
                                    value={price?.totalPrice}
                                    thousandSeparator={true}
                                    displayType="text"
                                    suffix="원"
                                />
                            </h6>
                        </Space>
                        <Divider className="mt-3 mb-3" />

                        <span>
                            ※현장 추가 결제 안내
                            <br />
                            - 자차 보험료(1박당1만원/필수)
                            <br />- 무시동히터 연료비(1박당1만5천원/10월~4월까지)
                        </span>
                    </>
                )}
            </MobileView>

            <BrowserView className="browser">
                {showTitle && <h6 className="mb-0 mt-3">{String.details}</h6>}
                <Space style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                    <p>
                        {price?.diffDays !== undefined && price?.diffDays !== 0
                            ? `차량 대여료 ${price?.diffDays}박${price.diffDays + 1}일(기본요금)`
                            : "차량 대여료(기본 요금)"}
                    </p>
                    <p>
                        <NumericFormat value={price?.price} thousandSeparator={true} displayType="text" suffix="원" />
                    </p>
                </Space>
                {value?.insurance?.insuranceName && (
                    <Space style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                        <p>
                            {value?.insurance?.insuranceName}&nbsp;
                            {price?.diffDays !== undefined && price?.diffDays !== 0 && `${price?.diffDays}박${price.diffDays + 1}일`}
                        </p>
                        <p>
                            <NumericFormat value={price?.insurancePrice} thousandSeparator={true} displayType="text" suffix="원" />
                        </p>
                    </Space>
                )}
                {price?.rentPaidItemsPrice
                    ?.filter(
                        (item) =>
                            value?.coupon?.coupon.rentPaidItemId !== item.rentPaidItemId &&
                            value?.multiCoupon?.coupon.rentPaidItemId !== item.rentPaidItemId
                    )
                    .map((item) => (
                        <Space key={item.rentPaidItemId} style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                            <p>{item.rentPaidItemName}</p>
                            <p>
                                <NumericFormat value={item.price} thousandSeparator={true} displayType="text" suffix="원" />
                            </p>
                        </Space>
                    ))}
                {value?.coupon && (
                    <Space
                        className="mt-2 text-danger"
                        style={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "start" }}
                    >
                        <p>{value.coupon.coupon.couponName}</p>
                        <p>
                            {!value.coupon.coupon.rentPaidItemId && "-"}
                            <NumericFormat
                                value={value.coupon.coupon.rentPaidItemId ? 0 : price?.userCouponDiscounted}
                                thousandSeparator={true}
                                displayType="text"
                                suffix="원"
                            />
                        </p>
                    </Space>
                )}
                {value?.multiCoupon && (
                    <Space
                        className={`${value?.coupon ? "" : "mt-2"} text-danger`}
                        style={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "start" }}
                    >
                        <p>{value.multiCoupon.coupon.couponName}</p>
                        <p>
                            {!value.multiCoupon.coupon.rentPaidItemId && "-"}
                            <NumericFormat
                                value={value.multiCoupon.coupon.rentPaidItemId ? 0 : price?.multiCouponDiscounted}
                                thousandSeparator={true}
                                displayType="text"
                                suffix="원"
                            />
                        </p>
                    </Space>
                )}
                {value?.cancelAmount ? (
                    <>
                        <Divider className="mt-2 mb-2" />
                        <Space style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                            <p>{String.totalRentalPrice}</p>
                            <p>
                                <NumericFormat value={price?.totalPrice} thousandSeparator={true} displayType="text" suffix="원" />
                            </p>
                        </Space>
                        <Space style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                            <p className="text-danger">위약금</p>
                            <p className="text-danger">
                                <NumericFormat
                                    value={price && price.totalPrice - value.cancelAmount}
                                    thousandSeparator={true}
                                    displayType="text"
                                    prefix="- "
                                    suffix="원"
                                />
                            </p>
                        </Space>
                        <Divider className="mt-2 mb-2" />
                        <Space className="mt-3" style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                            <h6>{String.totalRefundPrice}</h6>
                            <h6>
                                <NumericFormat value={value.cancelAmount} thousandSeparator={true} displayType="text" suffix="원" />
                            </h6>
                        </Space>
                    </>
                ) : (
                    <Space direction="vertical" className="w-100 mt-2">
                        <Space className="" style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                            <h6 className="m-0">{String.totalRentalPrice}</h6>
                            <h6 style={{ color: "rgb(0, 95, 219)" }}>
                                <NumericFormat value={price?.totalPrice} thousandSeparator={true} displayType="text" suffix="원" />
                            </h6>
                        </Space>
                        {showTitle && (
                            <hr style={{ marginTop: 10, marginBottom: 10, marginLeft: -24, marginRight: -24, borderColor: "#ccc", opacity: 1 }} />
                        )}
                        <p>
                            ※현장 추가 결제 안내
                            <br />
                            - 자차 보험료(1박당1만원/필수)
                            <br />- 무시동히터 연료비(1박당1만5천원/10월~4월까지)
                        </p>
                    </Space>
                )}
            </BrowserView>
        </>
    );
};

export default ReservationDetail;
