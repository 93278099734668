import React, { useEffect } from "react";
import { MobileView } from "react-device-detect";
import { Button, Drawer } from "antd";

import * as Utils from "../../utils/utils";
import * as Common from "../../commons/common";

import IconClose from "../../assets/images/home/rent/icon_close.png";

const MobileDrawer = ({ isOpen, title, content, onClose }: { isOpen: boolean; title?: string; content?: any; onClose?: () => void }) => {
    const onClick = () => {
        onClose && onClose();
    };

    useEffect(() => {
        Utils.sendFCMToken();
        Utils.onEvent(Common.EVENT_CLOSE_ALL_DRAWER, onClick);

        return () => {
            Utils.offEvent(Common.EVENT_CLOSE_ALL_DRAWER, onClick);
        };
    }, []);

    useEffect(() => {
        isOpen ? Utils.addDrawerStack() : Utils.popDrawerStack();
    }, [isOpen]);

    return (
        <>
            <MobileView className="mobile">
                <Drawer className="custom-drawer" placement="bottom" bodyStyle={{ height: "150px" }} open={isOpen} closable={false} onClose={onClick}>
                    <>
                        <div className="mb-5" style={{ position: "relative" }}>
                            <h3 className="w-100 text-center" style={{ position: "absolute" }}>
                                {title}
                            </h3>
                            <Button type="text" className="py-0" style={{ float: "right" }} onClick={onClick}>
                                <img src={IconClose} width={30} height={30} />
                            </Button>
                        </div>
                        {content}
                    </>
                </Drawer>
            </MobileView>
        </>
    );
};

export default MobileDrawer;
