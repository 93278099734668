import React, { useEffect, useState } from "react";
import { Button, Popover, Space } from "antd";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import { InfoCircleFilled, QuestionCircleFilled } from "@ant-design/icons";

import * as String from "../../commons/string";
import * as Common from "../../commons/common";
import * as Utils from "../../utils/utils";
import CustomSheet from "./CustomSheet";

import evStation from "../../assets/images/digitalKeyController/EV-station-icon.svg";
import csCenter from "../../assets/images/digitalKeyController/CS-icon.svg";
import csManual from "../../assets/images/digitalKeyController/manual-icon.svg";
import dash from "../../assets/images/digitalKeyController/dash-image.svg";
import styles from "./AddOnsDrawer.module.css";

const btnAnim = {
    initial: { backgroundColor: `#fff` },
    animate: {},
    whileTap: { scale: 0.9 },
};

function AddOnsDrawer({ openDrawer, setOpenDrawer }: { openDrawer: boolean; setOpenDrawer: (open: boolean) => void }) {
    const [openPopover, setOpenPopover] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        openDrawer ? Utils.addDrawerStack() : Utils.popDrawerStack();
    }, [openDrawer]);

    const findEVStation = () => {
        Utils.popDrawerStack();
        navigate(Common.PAGE_FIND_EV_STATION);
    };

    return (
        <>
            <CustomSheet
                className={styles.drawer}
                isOpen={openDrawer}
                content={
                    <div className={styles.csContainer}>
                        <div className={styles.csInnerContainer}>
                            <div className={styles.pageTitle}>
                                <h1 className={styles.titleText}>{String.convenience}</h1>
                                <img height={10} width={56} src={dash} alt="dash design in yellow" className={styles.dash} />
                            </div>
                            <Space size={"small"} className={styles.csIconList}>
                                <Button type="text" className={styles.button} onClick={findEVStation}>
                                    <div className={styles.iconWrapper}>
                                        <img width={38} height={42} src={evStation} alt="ev station icon" className={styles.csIcon} />
                                        <span className={styles.csIconText}>{String.findEv}</span>
                                    </div>
                                </Button>
                                <Popover
                                    overlayClassName={styles.popover}
                                    content={
                                        <section className={styles.wrapper}>
                                            <div className={styles.pageTitle}>
                                                <h1 className={styles.titleText}>공식홈페이지에서 확인하기</h1>
                                                <img src={dash} height={10} width={56} alt="dash design in yellow" className={styles.dash} />
                                            </div>
                                            <motion.button
                                                className={styles.motionBtn}
                                                variants={btnAnim}
                                                whileTap="whileTap"
                                                onClick={() => {
                                                    Utils.sendLinkToApp("https://rent.ruta40.co.kr/information/use");
                                                }}
                                            >
                                                <a className={styles.link}>
                                                    <InfoCircleFilled style={{ marginRight: "8px" }} />
                                                    {String.keyInfo}
                                                </a>
                                            </motion.button>
                                            <motion.button
                                                className={styles.motionBtn}
                                                variants={btnAnim}
                                                whileTap="whileTap"
                                                onClick={() => {
                                                    Utils.sendLinkToApp("https://rent.ruta40.co.kr/faq");
                                                }}
                                            >
                                                <a className={styles.link}>
                                                    <QuestionCircleFilled style={{ marginRight: "8px" }} />
                                                    {String.faq}
                                                </a>
                                            </motion.button>
                                        </section>
                                    }
                                    trigger="click"
                                    open={openPopover}
                                    onOpenChange={setOpenPopover}
                                >
                                    <Button
                                        type="text"
                                        className={styles.button}
                                        onClick={() => {
                                            setOpenPopover(true);
                                        }}
                                    >
                                        <div className={styles.iconWrapper}>
                                            <img width={40} height={41} src={csCenter} alt="customer center icon" className={styles.csIcon} />
                                            <span className={styles.csIconText}>{String.customerSupport}</span>
                                        </div>
                                    </Button>
                                </Popover>

                                <Button
                                    type="text"
                                    className={styles.button}
                                    onClick={() => {
                                        Utils.sendLinkToApp("https://ruta40.co.kr/downloadcenter");
                                    }}
                                >
                                    <div className={styles.iconWrapper}>
                                        <img width={34} height={42} src={csManual} alt="manual icon" className={styles.csIcon} />
                                        <span className={styles.csIconText}>{String.manual}</span>
                                    </div>
                                </Button>
                            </Space>
                        </div>
                    </div>
                }
                onClose={() => setOpenDrawer(false)}
            />
        </>
    );
}

export default AddOnsDrawer;
