import React, { useEffect, useState } from "react";
import { EyeInvisibleOutlined, EyeInvisibleTwoTone } from "@ant-design/icons";
import { Row, Col, Card, Button, Checkbox, Divider, Input } from "antd";
import { useNavigate } from "react-router";
import { useAsyncAxios, utilAxiosWithAuth } from "../../utils/customAxios";

import * as TypeDTO from "../../commons/typeDTO";
import * as Request from "../../commons/request";
import * as Common from "../../commons/common";
import * as String from "../../commons/string";

import { useAuthDispatch, useAuthState } from "../../provider/AuthProvider";
import { AlertType, useAlert } from "../../provider/AlertProvider";
import { useCookies } from "react-cookie";

function WithdrawMember() {
    const { TextArea } = Input;
    const navigate = useNavigate();
    const dispatch = useAuthDispatch();
    const alert = useAlert();
    const userDetail = useAuthState();

    const [cookies, setCookie, removeCookie] = useCookies(["savedId"]);
    const [isCapsLock, setIsCapsLock] = useState(false);
    const [agreeTerms, setAgreeTerms] = useState(false);
    const [password, setPassword] = useState("");

    const requestAxiosWithdrawUser = async (data: TypeDTO.WithdrawDto) => {
        const response = await utilAxiosWithAuth().post(Request.MEMBER_WITHDRAW_URL, data);
        return response.data;
    };

    const {
        loading: loadingWithdrawUser,
        error: errorWithdrawUser,
        data: resultWithdrawUser,
        execute: requestWithdrawUser,
    } = useAsyncAxios(requestAxiosWithdrawUser);

    useEffect(() => {
        if (!resultWithdrawUser) return;

        dispatch && dispatch({ type: "LOGOUT" });
        removeCookie("savedId");

        navigate(Common.PAGE_HOME);
        alert.setAlert(AlertType.SUCCESS, "성공", "계정 탈퇴가 완료되었습니다.");
    }, [resultWithdrawUser]);

    useEffect(() => {
        if (!errorWithdrawUser) return;

        console.log("errorUser", errorWithdrawUser);
    }, [errorWithdrawUser]);

    const onKeyDownHandler = (e: any) => {
        setIsCapsLock(e.getModifierState("CapsLock"));
    };

    const handleWithdraw = () => {
        requestWithdrawUser({
            email: userDetail?.user?.email,
            password: password,
        });
    };

    return (
        <Row className="login py-4" align="middle" justify="center">
            <div className="loginCard">
                <Card bordered={false}>
                    <div className="text-center mb-4">
                        <h3>통합 회원 탈퇴</h3>
                    </div>
                    <p className="b-md text-center">지금까지 저희 서비스를 이용해 주셔서 감사합니다.</p>
                    <p className="b-md text-center">회원탈퇴를 하시더라도 회원가입이 필요하지 않은 서비스는 이전과 같이 사용하실 수 있습니다.</p>
                    <TextArea
                        disabled
                        value={
                            "• 통합 회원의 탈퇴란 RUTA40 사이트, RUTA40 렌트 사이트/앱, RUTA40 디지털 키 앱 탈퇴를 의미합니다.\n• 계정 이용 내역이 있을 경우 재가입 시 고객센터 본인인증 절차가 필요할 수 있습니다.\n• 디지털 키 앱 서비스, 차량 관제 서비스, 차량 구매 정보는 차량 구매자에 대한 유지보수를 위하여 당사에 보관될 수 있습니다."
                        }
                        rows={5}
                    />
                    <Checkbox checked={agreeTerms} onChange={(e) => setAgreeTerms(e.target.checked)}>
                        안내사항을 모두 확인하였으며, 이에 동의합니다.
                    </Checkbox>

                    <Divider />

                    <Input.Password
                        onKeyDown={onKeyDownHandler}
                        className="login-input"
                        placeholder="비밀번호를 입력해 주세요."
                        iconRender={(visible) => (visible ? <EyeInvisibleTwoTone /> : <EyeInvisibleOutlined />)}
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    {isCapsLock && (
                        <span className="capsLock" style={{ color: "#2871E6" }}>
                            Caps Lock이 켜져 있습니다.
                        </span>
                    )}

                    <Divider />
                    <Row>
                        <Col className="pe-1" span={12}>
                            <Button block type="default" size="large" onClick={() => navigate(-1)}>
                                {String.cancel}
                            </Button>
                        </Col>
                        <Col className="ps-1" span={12}>
                            <Button block type="primary" size="large" disabled={!(agreeTerms && password.length > 0)} onClick={handleWithdraw}>
                                {String.withdraw}
                            </Button>
                        </Col>
                    </Row>
                </Card>
            </div>
        </Row>
    );
}

export default WithdrawMember;
