import { useReducer, useEffect, useCallback } from "react";
import { AlertType, useAlert } from "../provider/AlertProvider";
import { useAuthDispatch } from "../provider/AuthProvider";
import { useLocation, useNavigate } from "react-router-dom";

import * as Common from "../commons/common";
import * as Utils from "../utils/utils";

import axios from "axios";

type AxiosData = {
    [key: string]: any;
};

type AxiosState = {
    loading: boolean;
    data: AxiosData | null;
    error: AxiosData | null;
};

const initialAxiosState: AxiosState = {
    loading: false,
    data: null,
    error: null,
};

type AxiosAction = { type: "LOADING" } | { type: "SUCCESS"; data: AxiosData } | { type: "ERROR"; error: AxiosData } | { type: "CLEAR" };

const ayncAxiosReducer = (state: AxiosState, action: AxiosAction) => {
    switch (action.type) {
        case "LOADING":
            return {
                loading: true,
                data: null,
                error: null,
            };
        case "SUCCESS":
            return {
                loading: false,
                data: action.data,
                error: null,
            };
        case "ERROR":
            return {
                loading: false,
                data: null,
                error: action.error,
            };
        case "CLEAR":
            return initialAxiosState;
        default:
            throw new Error("Unhandled action type");
    }
};

export const useAsyncAxios = (callback: (...args: any[]) => AxiosData, immediate = false, disableAlert = false, unauthorizedProcess = false) => {
    const alert = useAlert();
    const [state, dispatch] = useReducer(ayncAxiosReducer, initialAxiosState);
    const dispatchAuth = useAuthDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const execute = useCallback(
        async (...args: any[]) => {
            dispatch({ type: "LOADING" });
            try {
                const data = await callback(...args);
                if (data.result === "ok") {
                    dispatch({ type: "SUCCESS", data });
                    return true;
                } else {
                    dispatch({ type: "ERROR", error: data });
                }
            } catch (e: any) {
                console.log("e", e);

                if (e.code === AlertType.ERR_NETWORK || e.code === AlertType.ECONNABORTED) {
                    alert.setAlert(e.code, "ERROR", e.code);
                } else {
                    if (disableAlert === false) alert.setAlert(e.response?.data?.code, "ERROR", e.response?.data?.message);

                    if (Utils.checkUnAuthorizedToken(e)) {
                        if (dispatchAuth !== null) dispatchAuth({ type: "LOGOUT" });

                        if (unauthorizedProcess === false) navigate(Common.PAGE_LOGIN, { replace: true, state: location.pathname });
                    }
                }
                dispatch({ type: "ERROR", error: e });
            }
        },
        [callback]
    );

    useEffect(() => {
        immediate && execute();
        return () => dispatch({ type: "CLEAR" });
    }, []);

    return { ...state, execute };
};

export const utilAxios = () => {
    const instance = axios.create({
        headers: {
            "Content-Type": "application/json",
        },
        withCredentials: true,
    });

    return instance;
};

export const utilAxiosWithAuth = () => {
    const token = JSON.parse(Utils.getLocalStorage(Common.CONTEXT_AUTH) || "{}").token;
    const instance = axios.create({
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
        },
        withCredentials: true,
    });

    return instance;
};

export const utilAxiosFormWithAuth = () => {
    const token = JSON.parse(Utils.getLocalStorage(Common.CONTEXT_AUTH) || "{}").token;
    const instance = axios.create({
        headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
        },
        withCredentials: true,
    });

    return instance;
};

export const utilAxiosWithAppToken = () => {
    const token =
        "eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJydDQwIiwiYXV0aCI6IlJPTEVfQVBQTElDQVRJT04iLCJpYXQiOjE2NjkxODUzNDYsImV4cCI6MTczNTY1NzE5OX0.t25268VK2E4ziBmCXN1XJVjXyqKIxvR9v4HOOAta2aJCEhiLr0ptu2ZytPDxr9obE6ywArqhB1642OZHrEpocA";
    const instance = axios.create({
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
        },
        withCredentials: true,
    });

    return instance;
};
