import React, { useEffect, useState } from "react";
import { Button, Modal } from "antd";

import * as Utils from "../../utils/utils";
import * as Strings from "../../commons/string";

import battery40 from "../../assets/images/digitalKeyController/battery40.svg";
import battery30 from "../../assets/images/digitalKeyController/battery30.svg";
import battery20 from "../../assets/images/digitalKeyController/battery20.svg";
import battery10 from "../../assets/images/digitalKeyController/battery10.svg";

type DisplayBattery = {
    title: string;
    body: string;
    image: string;
    textColor: string;
};

function ModalBatteryState({
    batteryLevel,
    openModalBatteryState,
    onChangedOpenModalBatteryState,
}: {
    batteryLevel?: number;
    openModalBatteryState: boolean;
    onChangedOpenModalBatteryState: (opened: boolean) => void;
}) {
    const [batteryInfo, setBatteryInfo] = useState<DisplayBattery>();

    useEffect(() => {
        if (!batteryLevel) return;

        if (batteryLevel <= 40 && batteryLevel > 30)
            setBatteryInfo({
                title: "배터리 충전 후 주행을 권장합니다.",
                body: "20% 이하부터 주행 성능이 현저히 떨어집니다.",
                image: battery40,
                textColor: "#1688F8",
            });
        else if (batteryLevel <= 30 && batteryLevel > 20)
            setBatteryInfo({
                title: "배터리를 30% 이상 유지해주세요.",
                body: "20% 이하부터 주행 성능이 현저히 떨어집니다.",
                image: battery30,
                textColor: "#FF9500",
            });
        else if (batteryLevel <= 20 && batteryLevel > 10)
            setBatteryInfo({ title: "배터리가 부족합니다!", body: "주행 성능이 현저히 떨어집니다.", image: battery20, textColor: "#E80000" });
        else setBatteryInfo({ title: "배터리가 매우 부족합니다!", body: "주행 성능이 현저히 떨어집니다.", image: battery10, textColor: "#E80000" });
    }, [batteryLevel]);

    useEffect(() => {
        openModalBatteryState ? Utils.addModalStack() : Utils.popModalStack();
    }, [openModalBatteryState]);

    return batteryInfo ? (
        <Modal
            className="confirmModal"
            centered
            key="modalBatteryState"
            open={openModalBatteryState}
            closable={false}
            onCancel={() => onChangedOpenModalBatteryState(false)}
            footer={[
                <Button block type="primary" key="back" onClick={() => onChangedOpenModalBatteryState(false)}>
                    {Strings.confirm}
                </Button>,
            ]}
        >
            <div>
                <img src={batteryInfo.image} alt="image of battery" width="90px" />
                <p style={{ fontSize: 26, fontWeight: 900, color: batteryInfo.textColor }}>
                    {batteryLevel && String(Math.round(batteryLevel * 10) / 10) + "%"}
                </p>
            </div>
            <div>
                <p className="title">{batteryInfo.title}</p>
                <span>{batteryInfo.body}</span>
            </div>
        </Modal>
    ) : null;
}

export default ModalBatteryState;
