import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAsyncAxios, utilAxiosWithAppToken } from "../../utils/customAxios";
import { Button, Col, Row, Space, Tag } from "antd";
import { BrowserView, MobileView } from "react-device-detect";
import { DownloadOutlined } from "@ant-design/icons";
import { useMediaQuery } from "react-responsive";
import { useAlert } from "../../provider/AlertProvider";

import * as Request from "../../commons/request";
import * as TypeDTO from "../../commons/typeDTO";
import * as Utils from "../../utils/utils";
import * as Common from "../../commons/common";

import orderImage1 from "../../assets/images/order/order-1.svg";

function NoticeDetail() {
    const isMobile = useMediaQuery({ maxWidth: 300 });
    const navigate = useNavigate();
    const alert = useAlert();
    const params = useParams();
    const [notice, setNotice] = useState<TypeDTO.NoticeDto>();

    useEffect(() => {
        requestGetNotice(Number(params.noticeId));
    }, [params]);

    const requestAxiosGetNotice = async (noticeId: number) => {
        const response = await utilAxiosWithAppToken().get(Request.NOTICE_URL + noticeId);
        return response.data;
    };

    const {
        loading: loadingGetNotice,
        error: errorGetNotice,
        data: resultGetNotice,
        execute: requestGetNotice,
    } = useAsyncAxios(requestAxiosGetNotice);

    useEffect(() => {
        if (!resultGetNotice) return;
        console.log("resultGetNotice", resultGetNotice);

        setNotice(resultGetNotice.notice);
    }, [resultGetNotice]);

    useEffect(() => {
        if (!errorGetNotice) return;
        console.log("errorGetNotice", errorGetNotice);
    }, [errorGetNotice]);

    const downloadNoticeFile = async (noticeFileId: number, noticeFileName: string) => {
        await utilAxiosWithAppToken()
            .get(Request.DOWNLOAD_NOTICE_URL + noticeFileId, { responseType: "blob" })
            .then((response) => {
                const file = new Blob([response.data]);
                const element = document.createElement("a");
                element.href = URL.createObjectURL(file);
                element.download = noticeFileName;
                element.click();
            })
            .catch((error) => {
                console.log("downloadNoticeFile error", error);
                alert.setAlert("", "파일 다운로드 실패", "파일 다운로드에 실패했습니다.");
            });
    };

    const displayNoticeFile = (noticeFiles: Array<TypeDTO.NoticeFileDto>) => {
        return (
            <td style={{ textAlign: "left" }}>
                <Space direction="vertical">
                    {noticeFiles.map((file) => {
                        return (
                            <Space
                                size={4}
                                key={file.noticeFileId}
                                align="center"
                                onClick={() => downloadNoticeFile(file.noticeFileId, file.noticeFileName)}
                                style={{ cursor: "pointer" }}
                            >
                                <DownloadOutlined />
                                <p
                                    style={{
                                        width: isMobile ? "calc(100vw - 74px)" : "auto",
                                        overflow: "hidden",
                                        whiteSpace: "nowrap",
                                        textOverflow: isMobile ? "ellipsis" : "initial",
                                        textDecoration: "underLine",
                                    }}
                                >
                                    {file.noticeFileName}
                                </p>
                            </Space>
                        );
                    })}
                </Space>
            </td>
        );
    };

    return (
        <>
            <MobileView className="mobile" style={{ minHeight: "calc(100vh - 70px)" }}>
                <Col
                    sm={{ span: 24 }}
                    lg={{ span: 18, offset: 3 }}
                    xl={{ span: 18, offset: 3 }}
                    xxl={{ span: 16, offset: 4 }}
                    className="space-page mb-4 pt-4 fadeIn"
                >
                    <div className="header-container mb-4">
                        <div className="container pt-4">
                            <div className="row justify-content-center">
                                <div className="p-0">
                                    <h2 className="fs-title d-flex board" style={{ alignItems: "baseline" }}>
                                        {notice?.noticeStatus === "EVENT_IN_PROGRESS" && (
                                            <Tag
                                                style={{
                                                    border: "2px solid #006AF5",
                                                    color: "#006AF5",
                                                    backgroundColor: "white",
                                                    fontSize: 20,
                                                    lineHeight: "30px",
                                                }}
                                            >
                                                진행
                                            </Tag>
                                        )}
                                        {notice?.noticeStatus === "EVENT_FINISHED" && (
                                            <Tag
                                                style={{
                                                    border: "2px solid #77828F",
                                                    color: "#77828F",
                                                    backgroundColor: "white",
                                                    fontSize: 20,
                                                    lineHeight: "30px",
                                                }}
                                            >
                                                종료
                                            </Tag>
                                        )}
                                        {notice?.title}
                                    </h2>
                                </div>
                            </div>
                        </div>
                    </div>
                    <table className="table design-table">
                        <tbody>
                            <tr className="d-flex" style={{ borderTop: "0.5px solid #dfe2e7" }}>
                                <td className="w-100" style={{ textAlign: "left" }}>
                                    카테고리:{" "}
                                    {notice?.noticeType === "NOTICE" ? "공지사항" : notice?.noticeType === "EVENT" ? "이벤트" : "이벤트 발표"}
                                </td>
                                <td className="w-100" style={{ textAlign: "right" }}>
                                    {!isMobile && "등록일: "}
                                    {notice?.registeredDate.slice(0, 10)}
                                </td>
                            </tr>
                            <tr>
                                {notice?.contents.split("\n").map((line, index) => (
                                    <td
                                        className="pt-3 pb-3 ps-0 pe-0"
                                        key={index}
                                        style={{ textAlign: "left" }}
                                        dangerouslySetInnerHTML={{
                                            __html: line.replace(". -", `.\n -`),
                                        }}
                                    />
                                ))}
                            </tr>
                            {notice?.noticeFiles.length !== 0 && (
                                <tr style={{ borderTop: "0.5px solid #dfe2e7", borderBottom: "0.5px solid #dfe2e7" }}>
                                    {notice?.noticeFiles && displayNoticeFile(notice?.noticeFiles)}
                                </tr>
                            )}
                        </tbody>
                    </table>
                    <div className="text-center mt-4">
                        <Button
                            className="btn-dark px-5"
                            style={{ width: "auto" }}
                            size="large"
                            type="primary"
                            onClick={() => {
                                navigate(Common.PAGE_NOTICE);
                            }}
                        >
                            목록 보기
                        </Button>
                    </div>
                </Col>
            </MobileView>
            <BrowserView className="browser">
                <Col
                    xs={{ span: 24 }}
                    sm={{ span: 22, offset: 1 }}
                    lg={{ span: 18, offset: 3 }}
                    xl={{ span: 16, offset: 4 }}
                    xxl={{ span: 14, offset: 5 }}
                    className="mb-4 p-4 order fadeIn"
                >
                    <div className="header-container mb-4">
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="p-0">
                                    <h2 className="fs-title d-flex board" style={{ alignItems: "baseline" }}>
                                        {notice?.noticeStatus === "EVENT_IN_PROGRESS" && (
                                            <Tag
                                                style={{
                                                    border: "2px solid #006AF5",
                                                    color: "#006AF5",
                                                    backgroundColor: "white",
                                                    fontSize: 24,
                                                    lineHeight: "36px",
                                                }}
                                            >
                                                진행
                                            </Tag>
                                        )}
                                        {notice?.noticeStatus === "EVENT_FINISHED" && (
                                            <Tag
                                                style={{
                                                    border: "2px solid #77828F",
                                                    color: "#77828F",
                                                    backgroundColor: "white",
                                                    fontSize: 24,
                                                    lineHeight: "36px",
                                                }}
                                            >
                                                종료
                                            </Tag>
                                        )}
                                        {notice?.title}
                                    </h2>
                                </div>
                            </div>
                        </div>
                    </div>
                    <table className="table design-table">
                        <tbody>
                            <tr style={{ borderTop: "0.5px solid #dfe2e7", borderBottom: "0.5px solid #dfe2e7" }}>
                                <Row className="w-100" style={{ minHeight: 48.8 }} justify="space-between" align="middle">
                                    <Col>
                                        카테고리:{" "}
                                        {notice?.noticeType === "NOTICE" ? "공지사항" : notice?.noticeType === "EVENT" ? "이벤트" : "이벤트 발표"}
                                    </Col>
                                    <Col>등록일: {notice?.registeredDate.slice(0, 10)}</Col>
                                </Row>
                            </tr>
                            <tr style={{ borderBottom: "0.5px solid #dfe2e7" }}>
                                {notice?.contents.split("\n").map((line, index) => (
                                    <td
                                        className="pt-3 pb-3 ps-0 pe-0 review-content"
                                        key={index}
                                        style={{ textAlign: "left" }}
                                        dangerouslySetInnerHTML={{
                                            __html: line.replace(". -", `.\n -`),
                                        }}
                                    />
                                ))}
                            </tr>
                            {notice?.noticeFiles.length !== 0 && (
                                <tr style={{ borderBottom: "0.5px solid #dfe2e7" }}>
                                    {notice?.noticeFiles && displayNoticeFile(notice?.noticeFiles)}
                                </tr>
                            )}
                        </tbody>
                    </table>
                    <div className="text-center mt-5">
                        <Button
                            className="btn-dark px-5"
                            style={{ width: "auto" }}
                            size="large"
                            type="primary"
                            onClick={() => {
                                navigate(Common.PAGE_NOTICE);
                            }}
                        >
                            목록 보기
                        </Button>
                    </div>
                </Col>
            </BrowserView>
        </>
    );
}

export default NoticeDetail;
