import * as React from "react";
import { BrowserView, MobileView } from "react-device-detect";

import BgTableHeader from "../../assets/images/home/rent/bg_table_header.png";

const RefundInfo = () => {
    const refundInfos = [
        {
            th: "~11일 전 취소 시",
            td: "100% 환불",
        },
        {
            th: "10~6일 전 취소 시",
            td: "50% 환불",
        },
        {
            th: "5~4일 전 취소 시",
            td: "20% 환불",
        },
        {
            th: "3일 전~당일 취소 시",
            td: "환불 불가",
            className: "text-danger",
        },
    ];

    return (
        <>
            <ul className="mb-0 listSpace" style={{ paddingLeft: 20 }}>
                <li className="mb-1">취소 승인 후 결제 수단에 따라 약 1~5 영업일 정도 소요됩니다.</li>
                <li className="mb-1">신용카드 결제 시 각 카드사 규정에 따라 영업일 기준 3~5일 후 확인하실 수 있습니다.</li>
                <li className="mb-1">현금 결제 시 영업일 기준 1~3일 내로 일괄적으로 환불이 진행됩니다.</li>
            </ul>
            <MobileView>
                <div className="mb-2">
                    <table className="table design-table">
                        <thead style={{ backgroundImage: `url(${BgTableHeader})`, backgroundSize: "cover", border: "none" }}>
                            <tr>
                                <th colSpan={2} className="text-center">
                                    예약 취소 및 환불규정(예약 이용일 기준)
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {refundInfos.map((info) => (
                                <tr key={info.th}>
                                    <td style={{ textAlign: "left" }}>{info.th}</td>
                                    <td style={{ textAlign: "right" }} className={info.className}>{info.td}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </MobileView>
            <BrowserView>
                <div>
                    <table className="table design-table mt-3">
                        <thead style={{ backgroundImage: `url(${BgTableHeader})`, backgroundSize: "cover", border: "none" }}>
                            <tr>
                                <th colSpan={2} className="text-center">
                                    예약 취소 및 환불규정(예약 이용일 기준)
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {refundInfos.map((info) => (
                                <tr key={info.th}>
                                    <td style={{ textAlign: "left" }}>{info.th}</td>
                                    <td style={{ textAlign: "right" }} className={info.className}>{info.td}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </BrowserView>
        </>
    );
};

export default RefundInfo;
