import React, { useEffect, useState } from "react";
import { BrowserView, MobileView } from "react-device-detect";
import { Col, Divider } from "antd";
import { useAsyncAxios, utilAxiosWithAppToken } from "../../utils/customAxios";

import Accordion from "react-bootstrap/Accordion";
import Info from "../../component/rent/Info";
import Banner from "../../assets/images/home/ruta40/banner_customer.jpg";
import PenaltyInfo from "../../component/rent/PenaltyInfo";
import RefundInfo from "../../component/rent/RefundInfo";

import * as Request from "../../commons/request";
import * as TypeDTO from "../../commons/typeDTO";

function InformationRent() {
    const [showMap, setShowMap] = useState(false);
    const [regions, setRegions] = useState<Array<TypeDTO.RentRegionDto>>();

    useEffect(() => {
        requestGetRegions();
    }, []);

    const requestAxiosGetRegions = async () => {
        const response = await utilAxiosWithAppToken().get(Request.RENT_REGIONS_URL);
        return response.data;
    };

    const {
        loading: loadingGetRegions,
        error: errorGetRegions,
        data: resultGetRegions,
        execute: requestGetRegions,
    } = useAsyncAxios(requestAxiosGetRegions);

    useEffect(() => {
        if (!resultGetRegions) return;
        console.log("resultGetRegions", resultGetRegions);

        setRegions(resultGetRegions.rentRegions);
    }, [resultGetRegions]);

    useEffect(() => {
        if (!errorGetRegions) return;
        console.log("errorGetRegions", errorGetRegions);
    }, [errorGetRegions]);

    const handleAccordionSelect = (eventKey: any) => {
        setShowMap(eventKey.includes("3"));
    };

    return (
        <div style={{ minHeight: "calc(100vh - 70px)" }}>
            <div className="header-container model">
                <div
                    className="container fadeIn text-center mb-5"
                    style={{ backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.3),rgba(0, 0, 0, 0.3)),url(${Banner})` }}
                >
                    <h5 className="text-center text-light px-3 mb-4">렌트 이용 안내</h5>
                    <h1 className="text-light">INFORMATION</h1>
                </div>
            </div>
            <div className="model-tabs title model-body" style={{ backgroundColor: "white" }}>
                <MobileView className="mobile">
                    <Col
                        sm={{ span: 24 }}
                        lg={{ span: 18, offset: 3 }}
                        xl={{ span: 18, offset: 3 }}
                        xxl={{ span: 16, offset: 4 }}
                        className={`fadeIn p-4`}
                    >
                        <section className="row legal">
                            <>
                                <div className="text-center my-3">
                                    <h3>렌트 이용 안내</h3>
                                </div>
                                <Accordion alwaysOpen defaultActiveKey={["0"]} className="support" onSelect={handleAccordionSelect}>
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>주의사항</Accordion.Header>
                                        <Accordion.Body>
                                            <Info showInfo={{ show: true }} />
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="1">
                                        <Accordion.Header>이용 방법</Accordion.Header>
                                        <Accordion.Body>
                                            <Info showMethod={{ show: true, className: "mt-2" }} />
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="2">
                                        <Accordion.Header>대여 조건</Accordion.Header>
                                        <Accordion.Body>
                                            <Info showCondition={{ show: true }} />
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="3">
                                        <Accordion.Header>대여 장소</Accordion.Header>
                                        <Accordion.Body>
                                            {regions?.map(
                                                (region) =>
                                                    region.rentRegionId === 1 && (
                                                        <Info key={region.rentRegionId} rentRegion={region} showMap={{ show: showMap }} />
                                                    )
                                            )}
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="4">
                                        <Accordion.Header>보험 안내</Accordion.Header>
                                        <Accordion.Body>
                                            <Info showInsurance={{ show: true, className: "mb-3" }} />
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <div className="text-center my-3">
                                        <h3>취소 환불 규정</h3>
                                    </div>
                                    <Accordion.Item eventKey="5">
                                        <Accordion.Header>취소 환불 규정</Accordion.Header>
                                        <Accordion.Body>
                                            <RefundInfo />
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <div className="text-center my-3">
                                        <h3>위약금 규정</h3>
                                    </div>
                                    <Accordion.Item eventKey="6">
                                        <Accordion.Header>차량 반납 지연</Accordion.Header>
                                        <Accordion.Body>
                                            <PenaltyInfo backgroundColor="white" showReturnDelay={{ show: true }} />
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="7">
                                        <Accordion.Header>배터리 미충전 반납</Accordion.Header>
                                        <Accordion.Body>
                                            <PenaltyInfo showUncharged={{ show: true }} />
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="8">
                                        <Accordion.Header>규정 위반</Accordion.Header>
                                        <Accordion.Body>
                                            <PenaltyInfo backgroundColor="white" showBreach={{ show: true }} />
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="9">
                                        <Accordion.Header>기타 위약금</Accordion.Header>
                                        <Accordion.Body>
                                            <PenaltyInfo showEtc={{ show: true }} />
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </>
                        </section>
                    </Col>
                </MobileView>
                <BrowserView className="browser">
                    <Col
                        xs={{ span: 24 }}
                        sm={{ span: 22, offset: 1 }}
                        lg={{ span: 18, offset: 3 }}
                        xl={{ span: 16, offset: 4 }}
                        xxl={{ span: 14, offset: 5 }}
                        className={`fadeIn p-4`}
                    >
                        <section className="row legal">
                            <>
                                <div className="text-center my-3">
                                    <h3>렌트 이용 안내</h3>
                                    <Divider className="title-divider mb-5" />
                                </div>
                                <Accordion alwaysOpen defaultActiveKey={["0"]} className="support" onSelect={handleAccordionSelect}>
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>주의사항</Accordion.Header>
                                        <Accordion.Body>
                                            <Info showInfo={{ show: true }} />
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="1">
                                        <Accordion.Header>이용 방법</Accordion.Header>
                                        <Accordion.Body>
                                            <Info showMethod={{ show: true }} />
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="2">
                                        <Accordion.Header>대여 조건</Accordion.Header>
                                        <Accordion.Body>
                                            <Info showCondition={{ show: true }} />
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="3">
                                        <Accordion.Header>대여 장소</Accordion.Header>
                                        <Accordion.Body>
                                            {regions?.map(
                                                (region) =>
                                                    region.rentRegionId === 1 && (
                                                        <Info key={region.rentRegionId} rentRegion={region} showMap={{ show: showMap }} />
                                                    )
                                            )}
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="4">
                                        <Accordion.Header>보험 안내</Accordion.Header>
                                        <Accordion.Body>
                                            <Info showInsurance={{ show: true, className: "mb-3" }} />
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <div className="text-center mt-5">
                                        <h3>취소 환불 규정</h3>
                                        <Divider className="title-divider mb-5" />
                                    </div>
                                    <Accordion.Item eventKey="5">
                                        <Accordion.Header>예약 취소 및 환불규정</Accordion.Header>
                                        <Accordion.Body>
                                            <RefundInfo />
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <div className="text-center mt-5">
                                        <h3>위약금 규정</h3>
                                        <Divider className="title-divider mb-5" />
                                    </div>
                                    <Accordion.Item eventKey="6">
                                        <Accordion.Header>차량 반납 지연</Accordion.Header>
                                        <Accordion.Body>
                                            <PenaltyInfo backgroundColor="white" showReturnDelay={{ show: true }} />
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="7">
                                        <Accordion.Header>배터리 미충전 반납</Accordion.Header>
                                        <Accordion.Body>
                                            <PenaltyInfo showUncharged={{ show: true }} />
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="8">
                                        <Accordion.Header>규정 위반</Accordion.Header>
                                        <Accordion.Body>
                                            <PenaltyInfo backgroundColor="white" showBreach={{ show: true }} />
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="9">
                                        <Accordion.Header>기타 위약금</Accordion.Header>
                                        <Accordion.Body>
                                            <PenaltyInfo showEtc={{ show: true }} />
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </>
                        </section>
                    </Col>
                </BrowserView>
            </div>
        </div>
    );
}

export default InformationRent;
