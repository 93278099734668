import * as React from "react";
import { Spin } from "antd";

import * as String from "../../commons/string";

const CustomLoading = () => {
    return (
        <div className="h-100 w-100 text-center" style={{ margin: "auto" }}>
            <Spin
                className="loading"
                size="large"
                tip={
                    <>
                        <p>{String.loading}</p>
                        <span>{String.loadingMsg}</span>
                    </>
                }
            />
        </div>
    );
};

export default CustomLoading;
