import Autoplay from "embla-carousel-autoplay";
import useEmblaCarousel from "embla-carousel-react";
import { useCallback, useEffect, useState } from "react";
import "./Gallery.css";
import { DotButton, useDotButton } from "./GalleryDotButton";
type imageType = {
    original: string;
    thumbnail: string;
};

const Gallery = ({
    images,
    autoPlay,
    styles,
    showBullets,
}: {
    images: Array<string>;
    autoPlay?: boolean;
    styles?: string;
    showBullets?: boolean;
}) => {
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [emblaMainRef, emblaMainApi] = useEmblaCarousel({ duration: 50 }, [Autoplay({ playOnInit: autoPlay, delay: 5000 })]);
    const [emblaThumbsRef, emblaThumbsApi] = useEmblaCarousel({
        containScroll: "keepSnaps",
        dragFree: true,
    });

    const { scrollSnaps, onDotButtonClick } = useDotButton(emblaMainApi);

    const onThumbClick = useCallback(
        (index: number) => {
            if (!emblaMainApi || !emblaThumbsApi) return;
            emblaMainApi.scrollTo(index);
        },
        [emblaMainApi, emblaThumbsApi]
    );

    const onSelect = useCallback(() => {
        if (!emblaMainApi || !emblaThumbsApi) return;
        setSelectedIndex(emblaMainApi.selectedScrollSnap());
        emblaThumbsApi.scrollTo(emblaMainApi.selectedScrollSnap());
    }, [emblaMainApi, emblaThumbsApi, setSelectedIndex]);

    useEffect(() => {
        if (!emblaMainApi) return;
        onSelect();

        emblaMainApi.on("select", onSelect).on("reInit", onSelect);
    }, [emblaMainApi, onSelect]);

    return (
        <div className={`gallery embla ${styles}`}>
            <div className="embla__viewport" ref={emblaMainRef}>
                <div className="embla__container">
                    {images.map((image, index) => (
                        <div className="embla__slide" key={index}>
                            <img className="embla__slide__image" src={image} alt={`slide-${index}`} />
                        </div>
                    ))}
                </div>
            </div>

            {showBullets !== false && (
                <div className="embla__controls">
                    <div className="embla__dots">
                        {scrollSnaps.map((_, index) => (
                            <DotButton
                                key={index}
                                onClick={() => onDotButtonClick(index)}
                                className={"embla__dot".concat(index === selectedIndex ? " embla__dot--selected" : "")}
                            />
                        ))}
                    </div>
                </div>
            )}

            <div className="embla-thumbs">
                <div className="embla-thumbs__viewport" ref={emblaThumbsRef}>
                    <div className="embla-thumbs__container">
                        {images.map((image, index) => (
                            <Thumb key={index} onClick={() => onThumbClick(index)} selected={index === selectedIndex} image={image} index={index} />
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

const Thumb = ({ selected, image, index, onClick }: { selected: boolean; image: string; index: number; onClick: () => void }) => {
    return (
        <div className={"embla-thumbs__slide".concat(selected ? " embla-thumbs__slide--selected" : "")}>
            <button onClick={onClick} type="button" className="embla-thumbs__slide__button">
                <img className="embla-thumbs__slide__image" src={image} alt={`thumbnail-${index}`} />
            </button>
        </div>
    );
};

export default Gallery;
