import React, { useEffect } from "react";
import { Row, Tag, Col, Space, Badge } from "antd";
import { NumericFormat } from "react-number-format";
import { BrowserView, MobileView } from "react-device-detect";

import styles from "./Title.module.css";
import Won from "../../assets/images/home/rent/icon_won.svg";
import ImageSlider from "../ImageSlider";

import * as TypeDTO from "../../commons/typeDTO";
import * as Utils from "../../utils/utils";
import * as String from "../../commons/string";

type titleDetailType = {
    content?: string | number;
    style?: any;
};

type titleType = {
    disabledImage?: boolean;
    regionName?: titleDetailType;
    title?: titleDetailType;
    description?: titleDetailType;
    priceDiscounted?: titleDetailType;
    price?: titleDetailType;
    tags?: Array<TypeDTO.RentRestrictionDto>;
};

const Title = ({ searchType, info, className }: { searchType?: boolean; info: titleType; className?: string }) => {
    return (
        <>
            <MobileView className={`mobile w-100 ${styles.mobile}`}>
                {searchType ? (
                    <>
                        <Row gutter={10}>
                            <Col span={10} className={`text-center ${styles.imageWrapper}`}>
                                <Badge.Ribbon
                                    text={
                                        <Space size={4}>
                                            <span className={styles.topText}>{String.coupon}</span>
                                            <span className={styles.middleText}>{String.available}</span>
                                        </Space>
                                    }
                                    className={styles.badge}
                                >
                                    <img src={Utils.getCarImage(info.title?.content?.toString(), true)} className={styles.titleImg} />
                                </Badge.Ribbon>
                            </Col>
                            <Col span={14}>
                                {info.regionName && <p className={styles.regionName}>{info?.regionName?.content}</p>}
                                <p className={styles.title} style={info?.title?.style}>
                                    {info?.title?.content}
                                </p>
                                {info.description && (
                                    <p className={`${styles.description} mt-1`} style={info?.description?.style}>
                                        {info?.description?.content}
                                    </p>
                                )}
                                <div style={{ display: "flex", alignItems: "center", flexWrap: "wrap" }}>
                                    {info.price && (
                                        <>
                                            <img src={Won} />
                                            <p className={`text-price ${styles.priceDiscounted}`} style={info?.priceDiscounted?.style}>
                                                <NumericFormat
                                                    value={info?.priceDiscounted?.content || info.price?.content}
                                                    thousandSeparator={true}
                                                    displayType="text"
                                                    suffix="원~"
                                                />
                                            </p>
                                        </>
                                    )}
                                    {info.priceDiscounted?.content && (
                                        <>
                                            <p className={`${styles.price}`} style={info?.price?.style}>
                                                <NumericFormat value={info?.price?.content} thousandSeparator={true} displayType="text" />원
                                            </p>
                                            <p className={styles.discountRate}>
                                                {Utils.getDiscountRate(Number(info?.price?.content), Number(info?.priceDiscounted?.content))}
                                            </p>
                                        </>
                                    )}
                                </div>
                            </Col>
                        </Row>
                        <div className={`${styles.tagBody} mt-2`}>
                            {info?.tags?.map((tag) => (
                                <Tag key={tag.rentRestrictionId} className={styles.tag}>
                                    {tag.rentRestrictionName}
                                </Tag>
                            ))}
                        </div>
                    </>
                ) : (
                    <Space direction="vertical" size={3} className={`w-100 ${className}`}>
                        <ImageSlider style={{ height: "100px" }} images={[Utils.getCarImage(info?.title?.content?.toString())]} />
                        {info.regionName && <p className={styles.regionName}>{info?.regionName?.content}</p>}
                        <p className={styles.title} style={info?.title?.style}>
                            {info?.title?.content}
                        </p>
                        <p className={styles.description} style={info?.description?.style}>
                            {info?.description?.content}
                        </p>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            {info.price?.content && (
                                <Row gutter={3}>
                                    <img src={Won} />
                                    <p className={`text-price ${styles.priceDiscounted}`} style={info?.priceDiscounted?.style}>
                                        <NumericFormat
                                            value={info?.priceDiscounted?.content || info.price?.content}
                                            thousandSeparator={true}
                                            displayType="text"
                                            suffix="원~"
                                        />
                                    </p>
                                </Row>
                            )}
                            {info.priceDiscounted?.content && (
                                <>
                                    <Col>
                                        <p className={`${styles.price}`} style={info?.price?.style}>
                                            <NumericFormat value={info?.price?.content} thousandSeparator={true} displayType="text" />
                                        </p>
                                    </Col>
                                    <Col>
                                        <p className={styles.discountRate}>
                                            {Utils.getDiscountRate(Number(info?.price?.content), Number(info?.priceDiscounted?.content))}
                                        </p>
                                    </Col>
                                </>
                            )}
                        </div>
                        <div className={styles.tagBody}>
                            {info?.tags?.map((tag) => (
                                <Tag key={tag.rentRestrictionId} className={styles.tag}>
                                    {tag.rentRestrictionName}
                                </Tag>
                            ))}
                        </div>
                    </Space>
                )}
            </MobileView>

            <BrowserView className={`browser ${styles.browser} ${className}`}>
                {searchType ? (
                    <>
                        <Row gutter={10}>
                            <Col span={9} className="text-center">
                                <img src={Utils.getCarImage(info.title?.content?.toString())} className={styles.titleImg} />
                            </Col>
                            <Col span={15}>
                                {info.regionName && <p className={styles.regionName}>{info?.regionName?.content}</p>}
                                <p className={styles.title} style={info?.title?.style}>
                                    {info?.title?.content}
                                </p>
                                {info.description && (
                                    <p className={`${styles.description} mt-1`} style={info?.description?.style}>
                                        {info?.description?.content}
                                    </p>
                                )}
                                <div style={{ display: "flex", alignItems: "center", flexWrap: "wrap" }}>
                                    {info.price?.content && (
                                        <>
                                            <img src={Won} />
                                            <p className={`text-price ${styles.priceDiscounted}`} style={info?.priceDiscounted?.style}>
                                                <NumericFormat
                                                    value={info?.priceDiscounted?.content || info.price?.content}
                                                    thousandSeparator={true}
                                                    displayType="text"
                                                    suffix="원~"
                                                />
                                            </p>
                                        </>
                                    )}
                                    {info.priceDiscounted?.content && (
                                        <>
                                            <p className={`${styles.price}`} style={info?.price?.style}>
                                                <NumericFormat value={info?.price?.content} thousandSeparator={true} displayType="text" />원
                                            </p>
                                            <p className={styles.discountRate}>
                                                {Utils.getDiscountRate(Number(info?.price?.content), Number(info?.priceDiscounted?.content))}
                                            </p>
                                        </>
                                    )}
                                </div>
                            </Col>
                        </Row>
                        <div className={`${styles.tagBody} mt-2`}>
                            {info?.tags?.map((tag) => (
                                <Tag key={tag.rentRestrictionId} className={styles.tag}>
                                    {tag.rentRestrictionName}
                                </Tag>
                            ))}
                        </div>
                    </>
                ) : (
                    <Space direction="vertical" size={3} className="w-100 d-block">
                        {!info.disabledImage && (
                            <ImageSlider style={{ width: "100%" }} images={[Utils.getCarImage(info?.title?.content?.toString())]} />
                        )}
                        {info.regionName && <p className={styles.regionName}>{info?.regionName?.content}</p>}
                        <p className={styles.title} style={info?.title?.style}>
                            {info?.title?.content}
                        </p>
                        <p className={styles.description} style={info?.description?.style}>
                            {info?.description?.content}
                        </p>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            {info.price?.content && (
                                <Row gutter={3}>
                                    <img src={Won} />
                                    <p className={`text-price ${styles.priceDiscounted}`} style={info?.priceDiscounted?.style}>
                                        <NumericFormat
                                            value={info?.priceDiscounted?.content || info?.price?.content}
                                            thousandSeparator={true}
                                            displayType="text"
                                            suffix="원~"
                                        />
                                    </p>
                                </Row>
                            )}
                            {info.priceDiscounted?.content && (
                                <>
                                    <Col className="p-0">
                                        <p className={`${styles.price}`} style={info?.price?.style}>
                                            <NumericFormat value={info?.price?.content} thousandSeparator={true} displayType="text" />
                                        </p>
                                    </Col>
                                    <Col className="p-0">
                                        <p className={styles.discountRate}>
                                            {Utils.getDiscountRate(Number(info?.price?.content), Number(info?.priceDiscounted?.content))}
                                        </p>
                                    </Col>
                                </>
                            )}
                        </div>
                        <div className={styles.tagBody}>
                            {info?.tags?.map((tag) => (
                                <Tag key={tag.rentRestrictionId} className={styles.tag}>
                                    {tag.rentRestrictionName}
                                </Tag>
                            ))}
                        </div>
                    </Space>
                )}
            </BrowserView>
        </>
    );
};

export default Title;
