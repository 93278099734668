import { Col, Empty, Row } from "antd";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAsyncAxios, utilAxiosWithAppToken } from "../../utils/customAxios";

import ReviewCard from "../../component/rent/ReviewCard";

import * as Request from "../../commons/request";
import * as TypeDTO from "../../commons/typeDTO";
import * as Common from "../../commons/common";

import Banner from "../../assets/images/home/ruta40/banner_review.png";

function UserReview() {
    const [boardArticles, setBoardArticles] = useState<TypeDTO.BoardArticleDto[]>();
    const navigate = useNavigate();

    useEffect(() => {
        requestGetBoardArticles();
    }, []);

    const requestAxiosGetBoardArticles = async () => {
        const response = await utilAxiosWithAppToken().get(Request.BOARD_URL + "/" + Common.USER_REVIEW_BOARD_ID, {
            params: { page: 0, size: 0 },
        });
        return response.data;
    };

    const {
        loading: loadingGetBoardArticles,
        error: errorGetBoardArticles,
        data: resultGetBoardArticles,
        execute: requestGetBoardArticles,
    } = useAsyncAxios(requestAxiosGetBoardArticles);

    useEffect(() => {
        if (!resultGetBoardArticles) return;
        console.log("resultGetBoardArticles", resultGetBoardArticles);

        setBoardArticles(resultGetBoardArticles.boardArticles.content);
    }, [resultGetBoardArticles]);

    useEffect(() => {
        if (!errorGetBoardArticles) return;
        console.log("errorGetBoardArticles", errorGetBoardArticles);
    }, [errorGetBoardArticles]);

    return (
        <div style={{ minHeight: "calc(100vh - 70px)" }}>
            <div className="header-container model">
                <div
                    className="container"
                    style={{
                        backgroundImage: `url(${Banner})`,
                        backgroundSize: "cover",
                    }}
                >
                    <div className="row justify-content-center">
                        <div className="fadeIn text-center mb-5">
                            <h5 className="text-center px-3 mb-4" style={{ color: "#4d4d4d" }}>
                                전체 리뷰
                            </h5>
                            <h1>REVIEW</h1>
                        </div>
                    </div>
                </div>
            </div>
            <div className="model-tabs title model-body" style={{ backgroundColor: "white" }}>
                <div className="model-tabs title model-body" style={{ backgroundColor: "white" }}>
                    <Col
                        xs={{ span: 24 }}
                        sm={{ span: 22, offset: 1 }}
                        lg={{ span: 18, offset: 3 }}
                        xl={{ span: 16, offset: 4 }}
                        xxl={{ span: 14, offset: 5 }}
                        className="fadeInUp p-4"
                    >
                        <Row gutter={24} style={{ justifyContent: "center" }}>
                            {boardArticles?.length === 0 && <Empty />}
                            {boardArticles?.map((boardArticle) => {
                                return (
                                    <Col
                                        sm={{ span: 24 }}
                                        xl={{ span: 6 }}
                                        lg={{ span: 8 }}
                                        key={boardArticle.boardArticleId}
                                        className={`mb-5 w-100`}
                                    >
                                        <ReviewCard boardArticle={boardArticle} />
                                    </Col>
                                );
                            })}
                        </Row>
                    </Col>
                </div>
            </div>
        </div>
    );
}

export default UserReview;
