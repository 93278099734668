import React, { useState } from "react";
import { Button, Row, Modal, Col, Checkbox } from "antd";

import styles from "../pages/ruta40/Home.module.css";

import * as Common from "../commons/common";
import * as Utils from "../utils/utils";

import popupBackground from "../assets/images/home/rent_popup_background.jpg";

function RentPopup({ open, className, onChangedOpen }: { open: boolean; className?: string; onChangedOpen: (opened: boolean) => void }) {
    const handleClose = () => {
        onChangedOpen(false);
    };

    const handleCloseToday = () => {
        const saveDay = new Date();
        const obj = {
            value: saveDay.toDateString(),
            expire: new Date(saveDay.setDate(saveDay.getDate() + 1)),
        };
        const rentPopupClosePeriod = JSON.stringify(obj);
        window.localStorage.setItem("rentPopupClosePeriod", rentPopupClosePeriod);
        onChangedOpen(false);
    };

    return (
        <Modal
            closable={false}
            maskClosable={false}
            mask={false}
            className={`${styles.popup} homePopup ${className}`}
            open={open}
            onOk={handleClose}
            onCancel={handleClose}
            footer={
                <Row>
                    <Col style={{ textAlign: "start" }} span={12}>
                        <Button className={styles.popupCloseAlldayButton} type="text" onClick={handleCloseToday}>
                            오늘 하루 닫기
                        </Button>
                    </Col>
                    <Col span={12}>
                        <Button className={styles.popupCloseButton} type="text" onClick={handleClose}>
                            닫기
                        </Button>
                    </Col>
                </Row>
            }
        >
            <div className={styles.leftButtonPopup} style={{ backgroundImage: `url(${popupBackground})` }}>
                <Button
                    size="large"
                    className={styles.leftButton}
                    onClick={() => {
                        Utils.sendLinkToApp("https://rent.ruta40.co.kr/home");
                    }}
                >
                    렌트 바로가기
                </Button>
            </div>
        </Modal>
    );
}

export default RentPopup;
