import * as TypeDTO from "../../commons/typeDTO";

function RentVideoManualCard({ rentVideoManual, className }: { rentVideoManual: TypeDTO.RentVideoManualDto; className?: string }) {
    return (
        <div className={`media-card rent-video-manual-item ${className ?? ""}`} style={{ padding: 0, overflow: "hidden" }}>
            <a href={rentVideoManual.rentVideoManualUri} target="_blank" rel="noreferrer">
                <div className="thumbnail">
                    <img src={rentVideoManual.imageUrl} />
                </div>
                <div className="summary text-dark">
                    <div className="content strong">{rentVideoManual.rentVideoManualName}</div>
                </div>
            </a>
        </div>
    );
}

export default RentVideoManualCard;
