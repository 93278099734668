import React, { useState, useEffect } from "react";
import { Button, Col, Row, Form, Input, Space, Modal, Alert } from "antd";
import { BrowserView, MobileView } from "react-device-detect";
import { useAsyncAxios, utilAxiosWithAuth } from "../../utils/customAxios";

import * as String from "../../commons/string";
import * as TypeDTO from "../../commons/typeDTO";
import * as Request from "../../commons/request";

import styles from "./AdditionalDriverInfo.module.css";
import IconCheck from "../../assets/images/home/rent/icon_alert_check.png";
import IconUnCheck from "../../assets/images/home/rent/icon_alert_error.png";

const AdditionalDriverInfo = ({ rent, disabled, onSuccess }: { rent?: TypeDTO.RentDto; disabled?: boolean; onSuccess?: () => void }) => {
    const availableLiceseType = ["1종 대형", "1종 보통"];
    const [form] = Form.useForm();
    const [inputType, setInputType] = useState<boolean>(false);

    useEffect(() => {
        form.setFieldsValue(rent?.additionalDriver || { name: null, phone: null });
    }, [rent?.additionalDriver]);

    //update rent
    const requestAxiosUpdateRent = async (driver: { name: string | null; phone: string | null }) => {
        const response = await utilAxiosWithAuth().put(Request.RENT_URL + rent?.rentId, { additionalDriver: driver });
        return response.data;
    };

    const {
        loading: loadingUpdateRent,
        error: errorUpdateRent,
        data: resultUpdateRent,
        execute: requestUpdateRent,
    } = useAsyncAxios(requestAxiosUpdateRent, false, true);

    useEffect(() => {
        if (!resultUpdateRent) return;

        setInputType(false);
        onSuccess && onSuccess();
    }, [resultUpdateRent]);

    useEffect(() => {
        if (!errorUpdateRent) return;

        if (errorUpdateRent.response.data.code === "NONEXISTENT_USER") {
            openAdditionalDriverErrorPopup();
        } else if (errorUpdateRent.response.data.code === "NONEXISTENT_ADDITIONAL_DRIVER") {
            openWithdrawnAdditionalDriverErrorPopup();
        }
    }, [errorUpdateRent]);

    const onFinish = (values: any) => {
        if (rent?.user.name === values.name && rent?.user.phone === values.phone) {
            openErrorPopup();
        } else {
            openAddConfirmPopup(values);
        }
    };

    const onRemove = () => {
        openRemoveConfirmPopup();
    };

    const onCancel = () => {
        setInputType(false);
        form.setFieldsValue(rent?.additionalDriver || { name: null, phone: null });
    };

    const openAddConfirmPopup = (value: any) => {
        Modal.confirm({
            title: `${String.additionalDriverInfo} ${String.register}`,
            content: value.name + "님을 추가 운전자로 등록하시겠습니까?",
            okText: String.confirm,
            onOk() {
                requestUpdateRent(value);
            },
            cancelText: String.cancel,
            onCancel() {},
            centered: true,
        });
    };

    const openRemoveConfirmPopup = () => {
        Modal.confirm({
            title: `${String.additionalDriverInfo} ${String.remove}`,
            content: "추가 운전자 정보를 삭제하시겠습니까?",
            okText: String.confirm,
            onOk() {
                requestUpdateRent({ name: null, phone: null });
            },
            cancelText: String.cancel,
            onCancel() {},
            centered: true,
        });
    };

    const openErrorPopup = () => {
        Modal.error({
            title: `${String.additionalDriverInfo} ${String.register} ${String.alarm}`,
            content: "예약자 본인은 추가 운전자로 등록할 수 없습니다.",
            okText: String.confirm,
            onOk() {},
            cancelText: String.cancel,
            onCancel() {},
            centered: true,
        });
    };

    const openAdditionalDriverErrorPopup = () => {
        Modal.error({
            title: "회원 정보 없음",
            content: "입력하신 추가 운전자가 RUTA40 통합회원이 아닙니다.\n회원가입 후 다시 시도해 주세요.",
            okText: String.confirm,
            onOk() {},
            centered: true,
        });
    };

    const openWithdrawnAdditionalDriverErrorPopup = () => {
        Modal.error({
            title: "탈퇴한 회원",
            content: "탈퇴한 회원은 등록하실 수 없습니다",
            okText: String.confirm,
            onOk() {},
            centered: true,
        });
    };

    return (
        <>
            <MobileView className="mobile">
                {inputType ? (
                    <>
                        <Form form={form} onFinish={onFinish} layout="vertical" className="license">
                            <Form.Item className="m-0">
                                <Row justify="space-between">
                                    <Col>
                                        <h5>{String.additionalDriverInfo}</h5>
                                    </Col>
                                    {!disabled && !rent?.usedDate && (
                                        <Col>
                                            <Space>
                                                {inputType && (
                                                    <Button className={`${styles.btn} ${styles.borderLightBtn}`} onClick={() => onCancel()}>
                                                        {String.cancel}
                                                    </Button>
                                                )}
                                                <Button className={`${styles.darkBtn} ${styles.btn}`} type="primary" htmlType="submit">
                                                    {String.save}
                                                </Button>
                                            </Space>
                                        </Col>
                                    )}
                                </Row>
                            </Form.Item>
                            <Form.Item
                                name="name"
                                label={String.name}
                                className="mb-2"
                                rules={[
                                    {
                                        required: true,
                                        message: String.msg_name_must,
                                    },
                                ]}
                            >
                                <Input className="login-input" placeholder={String.name} allowClear />
                            </Form.Item>
                            <Form.Item
                                required
                                name="phone"
                                label={String.phone}
                                rules={[
                                    {
                                        required: true,
                                        message: String.msg_phone_must,
                                    },
                                    {
                                        pattern: new RegExp(/^[0-9]+$/),
                                        message: String.msg_company_registration_no_only_number,
                                    },
                                ]}
                            >
                                <Input className="login-input" placeholder={String.phone} allowClear />
                            </Form.Item>
                        </Form>
                    </>
                ) : (
                    <>
                        <Row justify="space-between" className="mb-2">
                            <Col>
                                <h5>{String.additionalDriverInfo}</h5>
                            </Col>
                            {!disabled && !rent?.usedDate && (
                                <Space>
                                    {rent?.additionalDriver ? (
                                        <Space>
                                            <Button className={`btn-danger ${styles.btn}`} onClick={() => onRemove()}>
                                                {String.remove}
                                            </Button>
                                            <Button className={`${styles.btn}`} onClick={() => setInputType(true)}>
                                                {String.update}
                                            </Button>
                                        </Space>
                                    ) : (
                                        <Button className={`${styles.btn}`} onClick={() => setInputType(true)}>
                                            {String.register}
                                        </Button>
                                    )}
                                </Space>
                            )}
                        </Row>
                        <Row justify="space-between">
                            <Col>
                                <p>{String.name}</p>
                            </Col>
                            <Col>
                                <p>{rent?.additionalDriver ? rent?.additionalDriver.name : String.dash}</p>
                            </Col>
                        </Row>
                        <Row justify="space-between">
                            <Col>
                                <p>{String.phone}</p>
                            </Col>
                            <Col>
                                <p>{rent?.additionalDriver ? rent?.additionalDriver.phone : String.dash}</p>
                            </Col>
                        </Row>
                        {!disabled &&
                            !rent?.usedDate &&
                            rent?.additionalDriver &&
                            (rent?.additionalDriver?.driverLicense ? (
                                availableLiceseType.includes(rent?.additionalDriver?.driverLicense?.licenseType) ? (
                                    <Alert
                                        icon={<img width={16} src={IconCheck} />}
                                        className="mt-2"
                                        style={{ backgroundColor: "#193B670D", border: "1px solid #1C375A29", borderRadius: "4px" }}
                                        message="추가 운전자 정보가 정상적으로 등록되었습니다."
                                        type="success"
                                        showIcon
                                    />
                                ) : (
                                    <Alert
                                        icon={<img width={16} src={IconUnCheck} />}
                                        className="mt-2"
                                        style={{ backgroundColor: "#193B670D", border: "1px solid #1C375A29", borderRadius: "4px" }}
                                        message={
                                            <span>
                                                추가 운전자의 운전면허가 <strong>1종 보통 이상</strong>이 아닙니다.
                                            </span>
                                        }
                                        type="success"
                                        showIcon
                                    />
                                )
                            ) : (
                                <Alert
                                    icon={<img width={16} src={IconUnCheck} />}
                                    className="mt-2"
                                    style={{ backgroundColor: "#193B670D", border: "1px solid #1C375A29", borderRadius: "4px" }}
                                    message={
                                        <span>
                                            추가 운전자에게 운전면허 등록을 요청해 주세요.
                                            <br />∙ 계정 &gt; 계정정보 &gt; 운전면허 등록
                                        </span>
                                    }
                                    type="success"
                                    showIcon
                                />
                            ))}
                    </>
                )}
            </MobileView>
            <BrowserView className="browser">
                <>
                    <Row justify="space-between" className="mb-2">
                        <Col>
                            <h5>추가 운전자 정보</h5>
                        </Col>
                        {!disabled && !rent?.usedDate && (
                            <Col>
                                <Space>
                                    {inputType && (
                                        <Button className={`${styles.btn} ${styles.borderLightBtn}`} onClick={() => onCancel()}>
                                            {String.cancel}
                                        </Button>
                                    )}
                                    {rent?.additionalDriver && !inputType && (
                                        <Button className={`btn-danger ${styles.btn}`} onClick={() => onRemove()}>
                                            {String.remove}
                                        </Button>
                                    )}
                                    {inputType ? (
                                        <Button className={`${styles.btn} ${styles.darkBtn}`} type="primary" onClick={() => form.submit()}>
                                            {String.save}
                                        </Button>
                                    ) : rent?.additionalDriver ? (
                                        <Col>
                                            <Button className={`${styles.btn}`} onClick={() => setInputType(true)}>
                                                {String.update}
                                            </Button>
                                        </Col>
                                    ) : (
                                        <Col>
                                            <Button className={`${styles.btn} ${styles.borderLightBtn}`} onClick={() => setInputType(true)}>
                                                {String.register}
                                            </Button>
                                        </Col>
                                    )}
                                </Space>
                            </Col>
                        )}
                    </Row>
                    <Form form={form} onFinish={onFinish} layout="inline">
                        <Row className="w-100 box">
                            <Col span={12}>
                                <Form.Item
                                    className="searchForm"
                                    name="name"
                                    label={String.name}
                                    rules={[
                                        {
                                            required: inputType,
                                            message: String.msg_name_must,
                                        },
                                    ]}
                                >
                                    {inputType ? (
                                        <Input placeholder={String.name} allowClear />
                                    ) : (
                                        <p>{rent?.additionalDriver ? rent?.additionalDriver.name : String.dash}</p>
                                    )}
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    className="searchForm"
                                    name="phone"
                                    label={String.phone}
                                    rules={[
                                        {
                                            required: inputType,
                                            message: String.msg_phone_must,
                                        },
                                        {
                                            pattern: new RegExp(/^[0-9]+$/),
                                            message: String.msg_company_registration_no_only_number,
                                        },
                                    ]}
                                >
                                    {inputType ? (
                                        <Input placeholder={String.phone} allowClear />
                                    ) : (
                                        <p>{rent?.additionalDriver ? rent?.additionalDriver.phone : String.dash}</p>
                                    )}
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                    {!disabled &&
                        !rent?.usedDate &&
                        rent?.additionalDriver &&
                        (rent?.additionalDriver?.driverLicense ? (
                            availableLiceseType.includes(rent?.additionalDriver?.driverLicense?.licenseType) ? (
                                <Alert
                                    icon={<img width={16} src={IconCheck} />}
                                    className="mt-2"
                                    style={{ backgroundColor: "#193B670D", border: "1px solid #1C375A29", borderRadius: "4px" }}
                                    message="추가 운전자 정보가 정상적으로 등록되었습니다."
                                    type="success"
                                    showIcon
                                />
                            ) : (
                                <Alert
                                    icon={<img width={16} src={IconUnCheck} />}
                                    className="mt-2"
                                    style={{ backgroundColor: "#193B670D", border: "1px solid #1C375A29", borderRadius: "4px" }}
                                    message={
                                        <span>
                                            추가 운전자의 운전면허가 <strong>1종 보통 이상</strong>이 아닙니다.
                                        </span>
                                    }
                                    type="success"
                                    showIcon
                                />
                            )
                        ) : (
                            <Alert
                                icon={<img width={16} src={IconUnCheck} />}
                                className="mt-2"
                                style={{ backgroundColor: "#193B670D", border: "1px solid #1C375A29", borderRadius: "4px" }}
                                message={
                                    <span>
                                        추가 운전자에게 운전면허 등록을 요청해 주세요.
                                        <br />∙ 계정 &gt; 계정정보 &gt; 운전면허 등록
                                    </span>
                                }
                                type="success"
                                showIcon
                            />
                        ))}
                </>
            </BrowserView>
        </>
    );
};

export default AdditionalDriverInfo;
